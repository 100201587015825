import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import "./Report.css";
import "./Home.css";
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Report from "./Report";
import { Col } from "react-bootstrap";
import LGD_mapping from "../Components/LGD_mapping";
import DateRangePicker from "../Components/DateRangePicker";

export default function DailyReport() {
    const [loader, setLoader] = useState(true);
    const [nodalData, setNodalData] = useState([]);
    const history = useHistory();
    const [downloading, setDownlaoding] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [selectedlocation, setSelectedlocation] = useState({});
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [type, setType] = useState("");
    const [phase, setPhase] = useState(true);
    const [userType, setUserType] = useState('');
    const [detailType, setDetailType] = useState("block_level_event");
    const [reportType, setReportType] = useState("");
    const [selectedOrg, setSelectedOrg] = useState("Central Ministry/Department")
    const [sortColumn, setSortColumn] = useState("state");
    const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"

    const [location, setLocation] = useState({
        state: "",
        district: "",
        block: "",
        gp: "",
        village: "",
    });

    const [selectedDates, setSelectedDates] = useState({
        startDate: null,
        endDate: null,
    });
    const orgType = [
        "Central Ministry/Department",
        "State Govt Departments",
        "Institution",
        "dyo",
        "nyv",
    ];
    const formatDateString = (date) => {
        if (date instanceof Date) {
            // Convert the date to the local timezone
            const localDate = new Date(
                date.getTime() - date.getTimezoneOffset() * 60000
            );
            return localDate.toISOString().slice(0, 19).replace("T", " ");
        }
        return null;
    };

    const handleDatesSelected = (dates) => {
        setSelectedDates(dates);
    };

    const formattedStartDate = formatDateString(selectedDates.startDate);
    const formattedEndDate = formatDateString(selectedDates.endDate);

    const minDate = new Date("2023-09-01");
    const maxDate = new Date();

    const updateLocation = (field, value) => {
        console.log(field, value);
        setLocation((prevLocation) => ({
            ...prevLocation,
            [field]: value,
        }));
    };

    //Fetch all States
    useEffect(() => {
        firebase
            .firestore()
            .collection("states")
            .get()
            .then((res) => {
                let array = [];
                res.forEach((doc) => {
                    array.push(doc.data()?.state_name?.toUpperCase());
                });
                array.sort();
                setStates(array);
            });
    }, []);

    const handleStateClick = (stateName) => {
        setSelectedlocation(stateName);
        console.log(stateName);
    };
    const handleType = (typeName) => {
        setType(typeName);
    };
    const handleReportType = (type) => {
        setLoader(true);
        setReportType(type);
        console.log(loader);
        setSelectedState("");
        console.log(type);
    };

    const customSort = (a, b) => {
        const stateComparison = a["state"].localeCompare(b["state"]);
        if (stateComparison !== 0) return stateComparison;

        const districtComparison = (a["district"] || "").localeCompare(
            b["district"] || ""
        );
        if (districtComparison !== 0) return districtComparison;

        const blockComparison = (a["block"] || "").localeCompare(b["block"] || "");
        if (blockComparison !== 0) return blockComparison;

        const panchayatComparison = (
            a["Name of Gram Panchayats"] || ""
        ).localeCompare(b["Name of Gram Panchayats"] || "");
        if (panchayatComparison !== 0) return panchayatComparison;

        const ulbComparison = (a["Name of ULB"] || "").localeCompare(
            b["Name of ULB"] || ""
        );
        if (ulbComparison !== 0) return ulbComparison;

        return 0;
    };

    useEffect(() => {
        // setLoader(true);
        if (detailType) {
            setReportData([]);
            setLoader(true);
            // handle Login/logout
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const email = user.email;
                    const phoneRegex = /\+?\d{10,}/;

                    const phoneNumber = email.match(phoneRegex);
                    const tokenDoc = await firebase.firestore()
                        .collection("ak_nyv_officers")
                        .where("phoneNumber", "==", phoneNumber[0])
                        .limit(1)
                        .get();
                    const apiData = tokenDoc.docs[0].data();
                    setUserType(apiData?.ministry_dept);
                    const token = await user.getIdToken();
                    if (token) {
                        axios
                            .get(
                                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=state&token=${token}&organisation=${selectedOrg}`
                            )
                            .then((res) => {
                                setReportData(res?.data);
                                setLoader(false);
                                // console.log("report data", res?.data);
                            });
                    }
                } else {
                    setLoader(false);
                    // No user is signed in.
                    console.log("no user");
                    history.push("/");
                }
            });



        }

    }, [detailType]);

    useEffect(() => {
        // setLoader(true);
        if (selectedOrg) {
            setReportData([]);
            setLoader(true);
            // handle Login/logout
            firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {

                    const token = await user.getIdToken();
                    if (token) {
                        axios
                            .get(
                                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=state&token=${token}&organisation=${selectedOrg}`
                            )
                            .then((res) => {
                                setReportData(res?.data);
                                setLoader(false);
                                // console.log("report data", res?.data);
                            });
                    }
                } else {
                    setLoader(false);
                    // No user is signed in.
                    console.log("no user");
                    history.push("/");
                }
            });



        }

    }, [selectedOrg]);



    const getIndividualStateReport = (item) => {
        // console.log(item, "state clicked");
        // console.log(item["state"], "state clicked");
        setLoader(true);
        firebase.auth().onAuthStateChanged(async (user) => {
            const token = await user.getIdToken();
            if (token) {

                axios
                    .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=district&state=${item["state"]?.toUpperCase()}&token=${token}&organisation=${selectedOrg}`
                    )
                    .then((res) => {
                        setReportData(
                            res?.data
                        );
                        setLoader(false);
                        // console.log(
                        //   res?.data.map((item, index) =>
                        //     item.sort((a, b) => a?.district?.localeCompare(b?.district))
                        //   )
                        // );
                    });

            }
        });
    };

    const getIndividualDistrictReport = (item) => {
        // console.log(item, "state clicked");
        // console.log(item["state"], "state clicked");
        setLoader(true);
        firebase.auth().onAuthStateChanged(async (user) => {
            const token = await user.getIdToken();
            if (token) {

                axios
                    .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=block&district=${item["district"]?.toUpperCase()}&state=${item["state"]?.toUpperCase()}&token=${token}&organisation=${selectedOrg}`
                    )
                    .then((res) => {
                        setReportData(
                            res?.data
                        );
                        setLoader(false);
                        // console.log(
                        //   res?.data.map((item, index) =>
                        //     item.sort((a, b) => a?.district?.localeCompare(b?.district))
                        //   )
                        // );
                    });

            }
        });
    };

    const getIndividualBlockReport = (item) => {
        setLoader(true);
        firebase.auth().onAuthStateChanged(async (user) => {
            const token = await user.getIdToken();
            if (token) {
                axios
                    .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=panchayat&block=${item["block"]?.toUpperCase()}&district=${item["district"]?.toUpperCase()}&state=${item["state"]?.toUpperCase()}&token=${token}&organisation=${selectedOrg}`
                    )
                    .then((res) => {
                        setReportData(
                            res?.data)
                        setLoader(false);
                    });
            }
        });
    };


    const getIndividualPanchayatReport = (item) => {
        setLoader(true);
        firebase.auth().onAuthStateChanged(async (user) => {
            const token = await user.getIdToken();
            if (token) {
                axios
                    .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?detail_report=${detailType}&type=village&panchayat=${item["panchayat"]?.toUpperCase()}&block=${item["block"]?.toUpperCase()}&district=${item["district"]?.toUpperCase()}&state=${item["state"]?.toUpperCase()}&token=${token}&organisation=${selectedOrg}`
                    )
                    .then((res) => {
                        setReportData(
                            res?.data
                        );
                        setLoader(false);
                    });
            }
        });
    };





    const handleStateChange = (event) => {
        const { name, value } = event.target;
        // console.log(name, value);
        setSelectedState(value);
        // getIndividualULBReport(value);
    };

    const handleDownload = () => {
        // Create a new blank workbook
        const workbook = XLSX.utils.book_new();
        const worksheets = [{ name: "Report", data: reportData[0] }];

        // Convert each data array to a worksheet
        worksheets.forEach((sheet) => {
            const worksheet = XLSX.utils.json_to_sheet(sheet.data);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
        });

        // Create a Blob and download the Excel workbook
        const workbookBlob = new Blob(
            [s2ab(XLSX.write(workbook, { type: "binary" }))],
            {
                type: "application/octet-stream",
            }
        );
        const url = window.URL.createObjectURL(workbookBlob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "Overall_Summary.xlsx";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setDownlaoding(false);
        console.log("Data has been downloaded as nodal_officer_summary.xlsx");
    };
    // useEffect(() => {
    //   if (
    //     location?.state &&
    //     location?.district &&
    //     location?.block &&
    //     location?.panchayat
    //   ) {
    //     setReportData([]);
    //     getIndividualPanchayatReport({
    //       state: location?.state,
    //       district: location?.district,
    //       block: location?.block,
    //       panchayat: location?.panchayat,
    //     });
    //   } else if (location?.state && location?.block && location?.district) {
    //     setReportData([]);
    //     getIndividualBlockReport({
    //       state: location?.state,
    //       district: location?.district,
    //       block: location?.block,
    //     });
    //   } else if (location?.district && location?.state) {
    //     setReportData([]);
    //     getIndividualDistrictReport({
    //       state: location?.state,
    //       district: location?.district,
    //     });
    //   } else if (location?.state) {
    //     setReportData([]);
    //     console.log("stateSelected from kalas", location?.state);
    //     getIndividualStateReport({ state: location?.state });
    //   }
    // }, [location]);

    useEffect(() => {
        if (location?.state) {
            setReportData([]);
            // console.log("stateSelected from kalas", location?.state);
            getIndividualStateReport({ state: location?.state });
        }
    }, [location.state, formattedStartDate, formattedEndDate]);

    useEffect(() => {
        if (location.district) {
            setReportData([]);
            getIndividualDistrictReport({
                state: location?.state,
                district: location?.district,
            });
        }
    }, [location.district, formattedStartDate, formattedEndDate]);






    // Convert data to CSV format
    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
    };
    const sortData = (data, column, order) => {
        if (data[0]) {
            return data[0].sort((a, b) => {
                const valA = a[column];
                const valB = b[column];

                if (order === "asc") {
                    if (valA < valB) return -1;
                    if (valA > valB) return 1;
                } else {
                    if (valA < valB) return 1;
                    if (valA > valB) return -1;
                }

                return 0;
            });
        } else {
            return [];
        }
    };
    const sortedData = sortData(reportData, sortColumn, sortOrder);

    const handleSort = (column) => {
        if (column === sortColumn) {
            console.log("sort called if");
            // Toggle the sort order
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            console.log("sort called else");
            // If sorting a different column, set the new column and default to ascending order
            setSortColumn(column);
            setSortOrder("asc");
        }
    };
    console.log(location);
    return (
        <>
            <div className="main_body ease-in-anim">
                <Header />


                {phase ? (
                    <>

                        {
                            !loader &&

                            (reportData[0]?.length > 0 ? (
                                <div>

                                    <label className="mt-1 mb-1 ">
                                        <select
                                            className="select-input"
                                            tabIndex="2"
                                            name="state"
                                            value={detailType}
                                            onChange={(e) => {
                                                setDetailType(e.target.value)
                                            }}
                                        >
                                            <option value="">Select Report Type</option>
                                            {/* Map over your states data */}
                                            {[{ key: "block_level_event", label: "BLOCK LEVEL" }, { key: "kalash_yatra", label: "GP/VILLAGE LEVEL" }]?.sort().map((state, index) => (
                                                <option key={state + index} value={state.key}>
                                                    {state.label}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    {userType == 'Ministry of Youth Affairs' && <label className="mt-1 mb-1  ml-2">
                                        <select
                                            className="select-input mx-2"
                                            tabIndex="2"
                                            name="state"
                                            value={selectedOrg}
                                            onChange={(e) => {
                                                setSelectedOrg(e.target.value)
                                            }}
                                        >
                                            <option value="">Select Organisation Type</option>
                                            {/* Map over your states data */}
                                            {orgType?.sort().map((state, index) => (
                                                <option key={state + index} value={state}>
                                                    {state?.toUpperCase()}
                                                </option>
                                            ))}
                                        </select>
                                    </label>
                                    }
                                    <div className="d-flex justify-content-end my-3">
                                        <button
                                            className="pledge_btn mx-2"
                                            style={{
                                                fontSize: "13px",
                                                width: "auto",
                                                opacity: downloading ? "0.5" : "1",
                                            }}
                                            onClick={() => {
                                                handleDownload();
                                            }}
                                            disabled={downloading}
                                        >
                                            {downloading ? "Downloading" : "Download Report"}
                                        </button>
                                    </div>
                                    <div style={{ padding: "12px" }} className="table-container">
                                        <table className="report-table">
                                            <thead>
                                                <tr>
                                                    <th onClick={() => handleSort("state")}>State</th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "district"
                                                        ) && "District"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "block"
                                                        ) && "Block"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "panchayat"
                                                        ) && "Panchayat"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "village"
                                                        ) && "Village"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "events_count"
                                                        ) && "Total Events"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "images_count"
                                                        ) && "Images Count"}
                                                    </th>
                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0]).includes(
                                                            "videos_count"
                                                        ) && "Videos Count"}
                                                    </th>


                                                    <th>
                                                        {Object.keys(reportData?.[0]?.[0])?.includes(
                                                            "dignitaries_count"
                                                        ) && "Dignitary name count"}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {sortedData?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td onClick={() => {
                                                            getIndividualStateReport(item)
                                                        }} className="state-link">{item["state"]}</td>

                                                        <td onClick={() => {
                                                            getIndividualDistrictReport(item)
                                                        }} className="state-link">{item["district"]}</td>
                                                        <td onClick={() => {
                                                            if (detailType == 'kalash_yatra') {
                                                                getIndividualBlockReport(item)
                                                            }
                                                        }} className={detailType == 'kalash_yatra' ? "state-link" : ""}>{item["block"]}</td>
                                                        <td onClick={() => {
                                                            getIndividualPanchayatReport(item)
                                                        }} className="state-link">{item["panchayat"] && item["panchayat"]}</td>
                                                        <td className="state-link">{item["village"] && item["village"]}</td>

                                                        <td >{item["events_count"] && item["events_count"]}</td>
                                                        <td>{item["images_count"] && item["images_count"]}</td>
                                                        <td>{item["videos_count"] && item["videos_count"]}</td>

                                                        <td>
                                                            {item["dignitaries_count"] &&
                                                                item["dignitaries_count"]}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            ) : (
                                null
                            ))
                        }
                    </>
                ) : (
                    <>
                        null
                    </>
                )}
                <div>
                    <p className="note mx-2 text-center">
                        {loader
                            ? "Please Wait.."
                            : "Disclaimer: Data is still being collected and is not final."}
                    </p>
                </div>
            </div>
        </>
    );
}
