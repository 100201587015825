import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";
import Header from "../Components/Header/Header";
import Shilpimage from "../Assets/img/Shilpalakham1.svg";
import Calender from "../Assets/img/calendar.png";
import Links from "../Assets/img/links.png";
import Video from "../Assets/img/video.png";
import Plant from "../Assets/img/plant.png";
import Amritvatika from "../Assets/img/Amritvatika.svg";
import PranImage from "../Assets/img/pran.png";
import PledgeSelfieImage from "../Assets/img/pledge.svg";
import HoistImage from "../Assets/img/hoist.svg";
import HonorImage from "../Assets/img/soldier.png";
import KalashImg from "../Assets/img/kalash.jpg";
import stateDistrictMap from "../Assets/data/state_district.json";
import "./Home.css";
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import { FileEarmarkArrowDownFill } from "react-bootstrap-icons";
import axios from "axios";
import * as XLSX from "xlsx";
import DashboardCounterCard from "./DashboardCounterCard";
import DashboardCard from "./DashboardCard";
import DashTotalImages from "./DashTotalImages";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

const Dashboard = ({ showHeader }) => {
  const [organiserProfile, setOrganiserProfile] = React.useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGP, setSelectedGP] = useState("");
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [type, setType] = useState("state");
  const [gps, setGps] = useState([]);
  const [userType, setUserType] = useState(null);
  const [csvData, setCSVData] = useState("");
  const [downloading, setDownlaoding] = useState(false);
  const history = useHistory();
  const [nodalData, setNodalData] = useState([]);

  const [selfieCounter, setSelfieCounter] = useState(0);

  useEffect(() => {
    // Get Pledge Count
    firebase
      .firestore()
      .collection("pledge_count")
      .doc("RNfgGf5e1GiyUl7UZ8YN")
      .get()
      .then((res) => {
        if (res.exists) {
          // Checking if the document exists
          const data = res.data();
          if (data && data.count) {
            // Making sure the data and the count field exists
            setSelfieCounter(data.count);
          }
        }
      });
  }, []);

  function loadEventScripts() {
    // Load your custom script for events here
    var customScript = document.createElement("script");
    customScript.src = "map123.js";
    customScript.async = true;
    customScript.defer = true;
    document.body.appendChild(customScript);

    var googleMapsScript = document.createElement("script");
    googleMapsScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE&libraries=visualization&callback=initMap";
    var googleMapsScript3 = document.createElement("script");
    googleMapsScript3.src =
      "https://cdnjs.cloudflare.com/ajax/libs/heatmap.js/2.0.2/heatmap.min.js";
    setTimeout(() => {
      googleMapsScript.async = true;
      googleMapsScript.defer = true;
      document.body.appendChild(googleMapsScript);
    }, 2000);

    // googleMapsScript2.async = true;
    // googleMapsScript2.defer = true;
    // document.body.appendChild(googleMapsScript2);
    googleMapsScript3.async = true;
    googleMapsScript3.defer = true;
    document.body.appendChild(googleMapsScript3);
  }
  useEffect(() => {
    loadEventScripts();
  }, []);
  // function afterScriptsLoaded(token) {
  //   var map = window.mappls.Map("map", {
  //     center: [23.80946005893908, 78.73641329235636],
  //     zoom: 4,
  //     zoomControl: true,
  //     traffic: false,
  //     clickableIcons: false,
  //     location: true,
  //     gestureHandlling: true,
  //   });
  //   let layerObj = null;
  //   let dataset = "harghar_tiranga";
  //   // let getMapStyle = "harghar_tiranga_cluster_1691499027970_workview";
  //   let getMapStyle = "harghar_tiranga_copy_test_heat_1691562500798_workview";
  //   let getFeatureStyle = "harghar_tiranga";
  //   map.addListener("load", function () {
  //     console.log("inside map");
  //     map.resize();
  //     var hwb = window.getBbox(map),
  //       params = {
  //         map_obj: map,
  //         layerId: dataset,
  //         key: token,
  //         style: getMapStyle,
  //         bound: hwb[2],
  //         hght: hwb[0],
  //         wdth: hwb[1],
  //         cord: hwb[3],
  //       };
  //     window.layerCall(params, map); /* wms layer call once when map loaded  */
  //   });
  //   map.addListener("moveend", function () {
  //     var hwb = window.getBbox(map),
  //       params = {
  //         map_obj: map,
  //         layerId: dataset,
  //         key: token,
  //         style: getMapStyle,
  //         bound: hwb[2],
  //         hght: hwb[0],
  //         wdth: hwb[1],
  //         cord: hwb[3],
  //       };
  //     window.layerCall(params, map); /* wms layer updated according bbox */
  //   });
  //   /* click on cluster or single icon for get getFeature Information */
  //   map.addListener("click", function (e) {
  //     /* find pixel value from LngLat */
  //     var point = map.project(e.lngLat),
  //       params = {
  //         map_obj: map,
  //         layerId: dataset,
  //         key: token,
  //         style: getMapStyle,
  //         x: parseInt(point.x),
  //         y: parseInt(point.y),
  //         lnglat: e.lngLat,
  //         buffer: 25,
  //       };
  //     window.getFeatureCall(params, map, function (data) {
  //       console.log(data); /* getFeature Information get in callBack */
  //     });
  //   });
  //   map.addListener("wheel", function () {
  //     const zoom = map.getZoom();
  //     // console.log(zoom);
  //     if (zoom > 5) {
  //       getMapStyle = "harghar_tiranga_cluster_1691499027970_workview";
  //     } else {
  //       getMapStyle = "harghar_tiranga_copy_test_heat_1691562500798_workview";
  //     }
  //   });
  // }

  useEffect(() => {
    setLoader(true);
    // handle Login/logout
    firebase.auth().onAuthStateChanged((user) => {
      // console.log("user", user.getIdToken());
      if (user) {
        // User is signed in.
        const userJSON = JSON.parse(localStorage.getItem("users"))?.users;

        // Check the nodal_officer_data collection for type of user based on state district_name & block_name
        firebase
          .firestore()
          .collection("nodal_officer_data")
          .where("mobile", "==", userJSON?.phoneNumber.replace("+91", ""))
          .get()
          .then((res) => {
            if (res.docs.length > 0) {
              if (res?.docs[0].data()?.super_admin) {
                axios
                  .get(
                    "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getToken"
                  )
                  .then((res) => {
                    // initialize(res.data.token, afterScriptsLoaded);
                  });
                setNodalData(res?.docs[0].data());
              } else {
                if (res.docs[0].data().name_of_ulb) {
                  setUserType("ulb");
                  setSelectedState(res?.docs[0].data().state?.toUpperCase());
                } else if (res.docs[0].data().name_of_block?.toUpperCase()) {
                  setUserType("block");
                  setSelectedState(res?.docs[0].data().state?.toUpperCase());
                  setSelectedDistrict(
                    res.docs[0].data().district_name?.toUpperCase()
                  );
                  setSelectedBlock(
                    res?.docs[0].data().name_of_block?.toUpperCase()
                  );
                } else if (res.docs[0].data().district_name) {
                  setUserType("district");
                  setSelectedState(res?.docs[0].data().state?.toUpperCase());
                  setSelectedDistrict(
                    res.docs[0].data().district_name?.toUpperCase()
                  );
                } else if (res.docs[0].data().state) {
                  setUserType("state");
                  setSelectedState(res?.docs[0].data()?.state?.toUpperCase());
                }
              }
              setLoader(false);
            } else {
              setLoader(false);
              history.push("/");
            }
          });
      } else {
        setLoader(false);
        // No user is signed in.
        console.log("no user");
        history.push("/");
      }
    });
  }, []);

  // COUNTERS----------------------

  const [totalEvents, setTotalEvents] = useState(0);
  const [nodalOfficers, setNodalOfficers] = useState(0);
  const [organisers, setOrganisers] = useState(0);
  const [pledges, setPledges] = useState(0);
  const [shilaphalakam, setShilaphalakam] = useState(0);
  const [amritvatika, setAmritvatika] = useState(0);
  const [hoist, setHoist] = useState(0);
  const [honor, setHonor] = useState(0);
  const [allNodalDataFecthed, setAllNodalDataFecthed] = useState(false);
  const [allOrganisersFecthed, setAllOrganisersFecthed] = useState(false);

  const [shilaInstalled, setShilaInstalled] = useState(0);
  const [amritvatikaDone, setAmritvatikaDone] = useState(0);
  const [pranTaken, setPranTaken] = useState(0);
  const [flagHoisted, setFlagHoisted] = useState(0);
  const [braveheartsHonoured, setBraveHeartsHonoured] = useState(0);
  const [kalashPic, setKalashPic] = useState(0);
  const [totalEventCount, setTotalEventCount] = useState(0);
  const [totalEventCountPanchayat, setTotalEventCountPanchayat] = useState(0);
  const [totalEventCountULB, setTotalEventCountULB] = useState(0);
  const [totalEventCountInstitute, setTotalEventCountInstitute] = useState(0);
  const [totalEventCountKalash, setTotalEventCountKalash] = useState(0);
  const [
    totalEventCountPanchayatKalashImg,
    setTotalEventCountPanchayatKalashImg,
  ] = useState(0);
  const [totalBlockKalashImg, setTotalBlockKalashImg] = useState(0);
  const [totalEventCountULBKalashImg, setTotalEventCountULBKalashImg] =
    useState(0);
  const [
    totalEventCountPanchayatKalashVid,
    setTotalEventCountPanchayatKalashVid,
  ] = useState(0);

  const [totalBlockKalashVid, setTotalBlockKalashVid] = useState(0);
  const [totalEventCountULBKalashvid, setTotalEventCountULBKalashvid] =
    useState(0);

  const [totalDignitary, setTotalDignitary] = useState(0);
  // const [totalEventCountInstituteKalash, setTotalEventCountInstituteKalash] =
  //   useState(0);
  const [showInfo, setShowInfo] = useState(false);

  const [kalashData, setKalashData] = useState("");
  const [kalashDataImg, setKalashDataImg] = useState("");
  const [kalashDataVid, setKalashDataVid] = useState("");
  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };

  useEffect(() => {
    setKalashDataImg(0);
    let apiUrl =
      "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&category=image";

    if (!selectedState) {
      // if (loader == false) {

      axios
        .get(apiUrl)
        .then((response) => {
          console.log(response.data[0][0].combined_count, "image");
          setKalashDataImg(response.data[0][0].combined_count + 416180);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      // }
    } else {
      if (selectedState) {
        apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=state&category=image&state=${selectedState}`;

        if (selectedDistrict) {
          apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=district&category=image&state=${selectedState}&district=${selectedDistrict}`;

          if (selectedBlock) {
            apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=block&category=image&state=${selectedState}&district=${selectedDistrict}&block=${selectedBlock}`;
          }
        }
      }
      axios
        .get(apiUrl)
        .then((response) => {
          console.log(response.data[0][0].combined_count, "image");
          setKalashDataImg(response.data[0][0].combined_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [selectedState, selectedDistrict, selectedBlock, selectedGP]);
  useEffect(() => {
    setKalashDataVid(0);
    let apiUrl =
      "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&category=video";
    if (!selectedState) {
      // if (loader == false) {

      axios
        .get(apiUrl)
        .then((response) => {
          console.log(response.data[0][0].combined_count, "video");
          setKalashDataVid(response.data[0][0].combined_count + 18584);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
      // }
    } else {
      if (selectedState) {
        apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=state&category=video&state=${selectedState}`;

        if (selectedDistrict) {
          apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=district&category=video&state=${selectedState}&district=${selectedDistrict}`;

          if (selectedBlock) {
            apiUrl = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?db=event_total_count&type=block&category=video&state=${selectedState}&district=${selectedDistrict}&block=${selectedBlock}`;
          }
        }
      }
      axios
        .get(apiUrl)
        .then((response) => {
          console.log(response.data[0][0].combined_count, "video");
          setKalashDataVid(response.data[0][0].combined_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [selectedState, selectedDistrict, selectedBlock, selectedGP]);

  // Get Event Data
  useEffect(() => {
    if (loader == false) {
      let eventUrlReport =
        "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new";

      if (selectedState) {
        eventUrlReport = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new&type=state&state=${selectedState}`;

        if (selectedDistrict) {
          eventUrlReport = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new&type=district&state=${selectedState}&district=${selectedDistrict}`;

          if (selectedBlock) {
            eventUrlReport = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new&type=block&state=${selectedState}&district=${selectedDistrict}&block=${selectedBlock}`;
          }
        }
      }

      axios
        .get(eventUrlReport)
        .then((response) => {
          // console.log("EVENT DATA", response.data[0][0]);
          setTotalEventCount(response.data[0][0].total_event_count);
          setTotalEventCountPanchayat(response.data[0][0].panchayat_count);
          setTotalEventCountULB(response.data[0][0].ulb_count);
          setTotalEventCountInstitute(response.data[0][0].institute_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      let eventUrlReportKalashImg =
        "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&category=image";

      if (selectedState) {
        eventUrlReportKalashImg = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=state&state=${selectedState.toUpperCase()}&category=image`;

        if (selectedDistrict) {
          eventUrlReportKalashImg = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=district&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&category=image`;

          if (selectedBlock) {
            eventUrlReportKalashImg = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=block&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&block=${selectedBlock.toUpperCase()}&category=image`;
          }
        }
      }

      axios
        .get(eventUrlReportKalashImg)
        .then((response) => {
          // console.log("EVENT DATA KALASH", response.data[0][0]);
          // setTotalEventCountKalash(response.data[0][0]?.total_event_count);
          setTotalEventCountPanchayatKalashImg(
            response.data[0][0]?.panchayat_count
          );
          setTotalEventCountULBKalashImg(response.data[0][0]?.ulb_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      let eventUrlReportKalashBlockImg =
        "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&category=image";

      if (selectedState) {
        eventUrlReportKalashBlockImg = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=state&state=${selectedState.toUpperCase()}&category=image`;

        if (selectedDistrict) {
          eventUrlReportKalashBlockImg = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=district&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&category=image`;

          if (selectedBlock) {
            eventUrlReportKalashBlockImg = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=block&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&block=${selectedBlock.toUpperCase()}&category=image`;
          }
        }
      }
      axios
        .get(eventUrlReportKalashBlockImg)
        .then((response) => {
          // console.log("EVENT DATA KALASH BVLoCK", response.data[0][0]);
          setTotalBlockKalashImg(response.data[0][0]?.total_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      let eventUrlReportKalashVid =
        "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&category=video";

      if (selectedState) {
        eventUrlReportKalashVid = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=state&state=${selectedState.toUpperCase()}&category=video`;

        if (selectedDistrict) {
          eventUrlReportKalashVid = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=district&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&category=video`;

          if (selectedBlock) {
            eventUrlReportKalashVid = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?db=event_new_phase1&type=block&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&block=${selectedBlock.toUpperCase()}&category=video`;
          }
        }
      }

      axios
        .get(eventUrlReportKalashVid)
        .then((response) => {
          // console.log("EVENT DATA KALASH", response.data[0][0]);
          // setTotalEventCountKalash(response.data[0][0]?.total_event_count);
          setTotalEventCountPanchayatKalashVid(
            response.data[0][0]?.panchayat_count
          );
          setTotalEventCountULBKalashvid(response.data[0][0]?.ulb_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      let eventUrlReportKalashBlockVid =
        "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&category=video";

      if (selectedState) {
        eventUrlReportKalashBlockVid = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=state&state=${selectedState.toUpperCase()}&category=video`;

        if (selectedDistrict) {
          eventUrlReportKalashBlockVid = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=district&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&category=video`;

          if (selectedBlock) {
            eventUrlReportKalashBlockVid = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=block&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&block=${selectedBlock.toUpperCase()}&category=video`;
          }
        }
      }
      axios
        .get(eventUrlReportKalashBlockVid)
        .then((response) => {
          // console.log("EVENT DATA KALASH BVLoCK", response.data[0][0]);
          setTotalBlockKalashVid(response.data[0][0]?.total_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

      let eventUrlReportKalashBlockDig =
        "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&category=dignitary";

      if (selectedState) {
        eventUrlReportKalashBlockDig = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=state&state=${selectedState.toUpperCase()}&category=dignitary`;

        if (selectedDistrict) {
          eventUrlReportKalashBlockDig = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=district&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&category=dignitary`;

          if (selectedBlock) {
            eventUrlReportKalashBlockDig = `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?db=event_new_phase1&type=block&state=${selectedState.toUpperCase()}&district=${selectedDistrict.toUpperCase()}&block=${selectedBlock.toUpperCase()}&category=dignitary`;
          }
        }
      }
      axios
        .get(eventUrlReportKalashBlockDig)
        .then((response) => {
          // console.log("EVENT DATA KALASH BVLoCK", response.data[0][0]);
          setTotalDignitary(response.data[0][0]?.total_count);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [selectedState, selectedDistrict, selectedBlock, loader]);

  useEffect(() => {
    if (loader == false) {
      let apiUrl =
        "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?type=all";

      if (selectedState) {
        apiUrl = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?type=state&state=${selectedState}`;

        if (selectedDistrict) {
          apiUrl = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?type=district&state=${selectedState}&district=${selectedDistrict}`;

          if (selectedBlock) {
            apiUrl = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReport?type=block&state=${selectedState}&district=${selectedDistrict}&block=${selectedBlock}`;
          }
        }
      }

      axios
        .get(apiUrl)
        .then((response) => {
          const groupedData = {};
          const totalCount = {};

          // console.log(response);

          // Assuming the API response is an array of objects with the same structure as before
          response.data.flat().forEach((entry) => {
            if (!groupedData[entry.category]) {
              groupedData[entry.category] = 0;
              totalCount[entry.category] = 0;
            }
            groupedData[entry.category] +=
              entry.num_panchayats || entry.num_name_of_ulbs;

            totalCount[entry.category] +=
              entry.total_panchayats || entry.total_name_of_ulbs;
          });

          // console.log(groupedData);

          setAmritvatikaDone([groupedData.amritvatika, totalCount.amritvatika]);
          setShilaInstalled([
            groupedData.shilaphalakam,
            totalCount.shilaphalakam,
          ]);
          setFlagHoisted([groupedData.national_flag, totalCount.national_flag]);
          setPranTaken([groupedData.pledge, totalCount.pledge]);
          setBraveHeartsHonoured([
            groupedData.honor_bravehearts,
            totalCount.honor_bravehearts,
          ]);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
    // setKalashData(0);
  }, [selectedState, selectedDistrict, selectedBlock, loader]);

  // Get all data from organisers firestore collection
  const getAllOrganisersDataAndDownload = () => {
    setLoading(true);

    if (selectedBlock) {
      const formattedState = selectedBlock.toLowerCase().replace(/\s/g, "");
      console.log(
        formattedState,
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase())
      );
      firebase
        .firestore()
        .collection("organisers")
        .where("block", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          selectedBlock
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          console.log(res.docs);
          const csv = convertToCSV(
            res.docs?.map((doc) => doc.data()),
            "organisers"
          );

          // Set the CSV data to the state
          setCSVData(csv);
          setAllOrganisersFecthed(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedDistrict) {
      const formattedState = selectedDistrict.toLowerCase().replace(/\s/g, "");
      console.log(
        formattedState,
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase())
      );
      firebase
        .firestore()
        .collection("organisers")
        .where("district", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          selectedDistrict
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          console.log(res.docs);
          const csv = convertToCSV(
            res.docs?.map((doc) => doc.data()),
            "organisers"
          );

          // Set the CSV data to the state
          setCSVData(csv);
          setAllOrganisersFecthed(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (selectedState) {
      console.log(selectedState);
      const formattedState = selectedState.toLowerCase().replace(/\s/g, "");
      console.log(
        formattedState,
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase())
      );
      console.log(
        selectedState
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      );
      firebase
        .firestore()
        .collection("organisers")
        .where("state", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          selectedState
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          console.log(res.docs);
          const csv = convertToCSV(
            res.docs?.map((doc) => doc.data()),
            "organisers"
          );

          // Set the CSV data to the state
          setCSVData(csv);
          setAllOrganisersFecthed(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      firebase
        .firestore()
        .collection("organisers")
        .get()
        .then((res) => {
          console.log("lll");
          setOrganisers(res.docs.length);
          // Convert data to CSV format
          const csv = convertToCSV(
            res.docs?.map((doc) => doc.data()),
            "organisers"
          );

          // Set the CSV data to the state
          setCSVData(csv);
          setAllOrganisersFecthed(true);
        });
    }
  };

  // Get all data from nodal_officer_data firestore collection
  const getAllNodalOfficerData = () => {
    setLoading(true);

    firebase
      .firestore()
      .collection("nodal_officer_data")
      .get()
      .then((res) => {
        console.log(res.docs);
        //setNodalOfficers(res.docs.length);
        // Convert data to CSV format
        const csv = convertToCSV(
          res.docs?.map((doc) => doc.data()),
          "nodal_officer_data"
        );

        // Set the CSV data to the state
        setCSVData(csv);
        setAllNodalDataFecthed(true);
      });
  };

  // Function to convert data to CSV format
  const convertToCSV = (data, forEntity) => {
    try {
      let columns = [];
      if (forEntity == "organisers") {
        columns = [
          "state",
          "district",
          "block",
          "panchayat",
          "name",
          "phoneNumber",
        ]; // Replace with the actual field names in the desired order
      } else {
        columns = [
          "district_name",
          "mobile",
          "name_of_block",
          "state",
          "nodal_officer_name",
        ]; // Replace with the actual field names in the desired order
      }
      const header = columns.join(",");
      const rows = data.map((item) => {
        const rowValues = columns.map((col) => {
          return item[col] || ""; // If the field is not present in the data object, use an empty string
        });
        return rowValues.join(",");
      });

      return `${header}\n${rows.join("\n")}`;
    } catch (error) {
      return "";
    }
  };

  // Function to handle download in case of no state selected
  useEffect(() => {
    if (allNodalDataFecthed || allOrganisersFecthed) {
      console.log("data", csvData);
      const csvContent =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
      const link = document.createElement("a");
      link.setAttribute("href", csvContent);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();

      setAllNodalDataFecthed(false);
      setAllOrganisersFecthed(false);
    }
  }, [allNodalDataFecthed, allOrganisersFecthed]);

  // Function to handle the download button click
  const handleDownloadClick = async () => {
    if (!selectedState) {
      // [TODO] Change this Stupid way of getting data
      getAllNodalOfficerData();
    } else {
      const csvContent =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
      const link = document.createElement("a");
      link.setAttribute("href", csvContent);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const getSummaryNodalOfficers = async () => {
    // Fetch data for states ordered by state_name
    const stateSnapshot = await firebase
      .firestore()
      .collection("nodal_officer_counter")
      .where("type", "==", "state")
      .orderBy("state_name")
      .get();
    const StateArray = stateSnapshot.docs.map((doc) => doc.data());

    // Fetch data for districts ordered by district_name
    const districtSnapshot = await firebase
      .firestore()
      .collection("nodal_officer_counter")
      .where("type", "==", "district")
      .orderBy("district_name")
      .get();
    const districtArray = districtSnapshot.docs.map((doc) => doc.data());

    // Fetch data for blocks ordered by block_name
    const blockSnapshot = await firebase
      .firestore()
      .collection("nodal_officer_counter")
      .where("type", "==", "block")
      .orderBy("block_name")
      .get();
    const blockArray = blockSnapshot.docs.map((doc) => doc.data());

    // Fetch data for panchayats ordered by panchayat_name
    const panchayatSnapshot = await firebase
      .firestore()
      .collection("nodal_officer_counter")
      .where("type", "==", "panchayat")
      .orderBy("panchayat_name")
      .get();
    const panchayatArray = panchayatSnapshot.docs.map((doc) => doc.data());

    // Create worksheets for each data array
    const workbook = XLSX.utils.book_new();
    const worksheets = [
      { name: "State", data: StateArray },
      { name: "District", data: districtArray },
      { name: "Block", data: blockArray },
      { name: "Panchayat", data: panchayatArray },
    ];

    // Convert each data array to a worksheet
    worksheets.forEach((sheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    });

    // Create a Blob and download the Excel workbook
    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "nodal_officer_summary.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    console.log("Data has been downloaded as nodal_officer_summary.xlsx");
  };

  const getSummaryOrgOfficers = async () => {
    // Fetch data for states ordered by state_name
    setDownlaoding(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `http://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report&token=${token}`
          )
          .then((res) => {
            const workbook = XLSX.utils.book_new();
            const worksheets = [{ name: "Report", data: res?.data }];

            // Convert each data array to a worksheet
            worksheets.forEach((sheet) => {
              const worksheet = XLSX.utils.json_to_sheet(sheet.data);
              XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
            });

            // Create a Blob and download the Excel workbook
            const workbookBlob = new Blob(
              [s2ab(XLSX.write(workbook, { type: "binary" }))],
              {
                type: "application/octet-stream",
              }
            );
            const url = window.URL.createObjectURL(workbookBlob);
            const a = document.createElement("a");
            a.href = url;
            a.download = "organisers_summary.xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setDownlaoding(false);
            console.log(
              "Data has been downloaded as nodal_officer_summary.xlsx"
            );
          });
      }
    });
  };
  // Convert data to CSV format
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });
        array.sort();
        setStates(array);
      });
  }, []);

  //Get District of Seleceted States
  useEffect(() => {
    if (selectedState) {
      console.log("selected state", selectedState);
      const formattedState = selectedState.toLowerCase();
      firebase
        .firestore()
        .collection("state_district_mapping")
        .where("state_name", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            // console.log("district array", doc.data().district_mapping);
            array.push(doc.data()?.district_mapping?.toUpperCase());
          });
          array.sort();
          setDistrict(array);
        });
    }
  }, [selectedState]);

  //Get Block of Seleceted States
  useEffect(() => {
    if (selectedDistrict) {
      const formattedDistrict = selectedDistrict.toLowerCase();

      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "in", [
          formattedDistrict,
          formattedDistrict.toUpperCase(),
          formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data()?.block_name?.toUpperCase());
          });
          array.sort();
          setBlocks(array);
        });
    }
  }, [selectedDistrict]);

  //Get GP of Slecetd States
  useEffect(() => {
    if (selectedBlock) {
      const formattedBlock = selectedBlock.toLowerCase();
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "in", [
          formattedBlock,
          formattedBlock.toUpperCase(),
          formattedBlock.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            console.log("gp array", doc.data());
            array.push(doc.data()?.gp_name?.toUpperCase());
          });
          array.sort();
          setGps(array);
        });
    }
  }, [selectedBlock]);

  //counters for amritvatika, honor, flag hoisting, pran, shilpahalakam

  //get State based Nodal officer
  useEffect(() => {
    if (selectedState) {
      const formattedState = selectedState.toLowerCase();

      firebase
        .firestore()
        .collection("nodal_officer_data")
        .where("state", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedState
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          // Convert data to CSV format
          const csv = convertToCSV(
            res.docs.map((doc) => doc.data()),
            "nodal_officer_data"
          );

          // Set the CSV data to the state
          setCSVData(csv);
        });
    }
  }, [selectedState]);

  //CSV export for District
  useEffect(() => {
    if (selectedDistrict && selectedState) {
      const formattedState = selectedState.toLowerCase();
      const formattedDistrict = selectedDistrict.toLowerCase();

      firebase
        .firestore()
        .collection("nodal_officer_data")
        .where("state", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedState
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .where("district_name", "in", [
          formattedDistrict,
          formattedDistrict.toUpperCase(),
          formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedDistrict
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          // Convert data to CSV format
          const csv = convertToCSV(
            res.docs.map((doc) => doc.data()),
            "nodal_officer_data"
          );

          // Set the CSV data to the state
          setCSVData(csv);
        });
    }
  }, [selectedDistrict]);

  //get block counter
  useEffect(() => {
    if (selectedBlock & selectedDistrict & selectedState) {
      const formattedState = selectedState.toLowerCase();
      const formattedDistrict = selectedDistrict.toLowerCase();
      const formattedBlock = selectedDistrict.toLowerCase();

      firebase
        .firestore()
        .collection("nodal_officer_data")
        .where("state", "in", [
          formattedState,
          formattedState.toUpperCase(),
          formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedState
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .where("district_name", "in", [
          formattedDistrict,
          formattedDistrict.toUpperCase(),
          formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedDistrict
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .where("name_of_block", "in", [
          formattedBlock,
          formattedBlock.toUpperCase(),
          formattedBlock.replace(/\b\w/g, (c) => c.toUpperCase()),
          formattedBlock
            .toLowerCase()
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
        ])
        .get()
        .then((res) => {
          // Convert data to CSV format
          const csv = convertToCSV(
            res.docs?.map((doc) => doc.data()),
            "nodal_officer_data"
          );

          // Set the CSV data to the state
          setCSVData(csv);
        });
    }
  }, [selectedBlock]);

  //get gp counter
  useEffect(() => {
    if (selectedGP) {
      firebase
        .firestore()
        .collection("events_counter")
        .where("type", "==", "panchayat")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
              // setTotalEvents(doc.data().count);
            }
          });
        });

      firebase
        .firestore()
        .collection("organisers_counter")
        .where("type", "==", "panchayat")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
              setOrganisers(doc.data().count);
            }
          });
        });

      firebase
        .firestore()
        .collection("events_counter")
        .where("type", "==", "amritvatika")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
              setAmritvatika(doc.data().count);
            }
          });
        });

      firebase
        .firestore()
        .collection("events_counter")
        .where("type", "==", "honour_bravehearts")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
              setHonor(doc.data().count);
            }
          });
        });
      firebase
        .firestore()
        .collection("events_counter")
        .where("type", "==", "national_flag")
        .get()
        .then((res) => {
          res.docs.map((doc) => {
            if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
              setHonor(doc.data().count);
            }
          });
        });
      // firebase
      //   .firestore()
      //   .collection("events_counter")
      //   .where("type", "==", "pledge")
      //   .get()
      //   .then((res) => {
      //     res.docs.map((doc) => {
      //       if (doc.data().panchayat_name == selectedGP.toUpperCase()) {
      //         setPledges(doc.data().count);
      //       }
      //     });
      //   });
    }
  }, [selectedGP]);

  const handleStateChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setSelectedState(value);
    setSelectedDistrict("");
    setSelectedBlock("");
    setSelectedGP("");
    setDistrict([]);
    setBlocks([]);
    setGps([]);
  };

  const [formInput, setFormInput] = useState({
    name: "",
    state: "",
    district: "",
    block: "",
    gp: "",
    time: "",
  });

  function getDistrictsForState(stateName) {
    return stateDistrictMap[stateName] || [];
  }

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 4,
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(name);
    setFormInput((prev) => ({ ...prev, [name]: value }));

    setTotalEvents(0);
    setNodalOfficers(0);
    setOrganisers(0);
    setPledges(0);

    if (name === "state") {
      setSelectedState(value);
      const districts = getDistrictsForState(value);
      setFilteredDistricts(districts);
      setType("state");
      //Nullify selected District
      setSelectedDistrict("");
    }
    if (name === "district") {
      console.log("district", value);
      setType("district");
      setSelectedDistrict(value);
      setSelectedBlock("");
    }

    if (name === "block") {
      console.log("block", value);
      setType("block");
      setSelectedBlock(value);
      setSelectedGP("");
    }

    if (name === "gp") {
      console.log("gp", value);
      setType("gp");
      setSelectedGP(value);
    }
  };

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center py-3">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : (
        <div className={showHeader && "main_body_withoutbg"}>
          {showHeader && <Header />}
          {/* <div className="row mx-4"> */}
          <Row xs={12} className="mx-4 my-3">
            <Col xs={12} lg={3} md={6} className="d-flex justify-content-start">
              <label className="mt-1 mb-1 ">
                <select
                  className="select-input"
                  tabIndex="2"
                  name="state"
                  disabled={["state", "district", "block"].includes(userType)}
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  <option value="">India</option>
                  {/* Map over your states data */}
                  {states?.sort().map((state, index) => (
                    <option key={state + index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </label>
            </Col>
            <Col xs={12} lg={3} md={6} className="d-flex justify-content-start">
              <label className="mx-1">
                <select
                  className="select-input"
                  name="district"
                  value={selectedDistrict}
                  onChange={handleInputChange}
                  disabled={
                    !selectedState || ["district", "block"].includes(userType)
                  }
                >
                  <option value="">Select District</option>
                  {districts?.sort().map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </select>
              </label>
            </Col>
            <Col xs={12} lg={3} md={6} className="d-flex justify-content-start">
              <label className="mx-1">
                <select
                  className="select-input"
                  name="block"
                  value={selectedBlock}
                  onChange={handleInputChange}
                  disabled={!selectedDistrict || userType == "block"}
                >
                  <option value="">Select Block</option>
                  {blocks?.sort()?.map((block) => (
                    <option key={block} value={block}>
                      {block}
                    </option>
                  ))}
                </select>
              </label>
            </Col>
            {/* {nodalData.super_admin && (
              <Col
                xs={12}
                lg={2}
                md={6}
                className="d-flex justify-content-start"
              >
                <label className="mx-1">
                  <button
                    className="pledge_btn"
                    style={{
                      fontSize: "13px",
                      width: "auto",
                      opacity: downloading ? "0.5" : "1",
                    }}
                    name="gp"
                    onClick={() => {
                      getSummaryOrgOfficers();
                    }}
                    disabled={downloading}
                  >
                    {downloading ? "Downloading" : "Download Report"}
                  </button>
                </label>
              </Col>
            )} */}
            {nodalData.super_admin && (
              <Col
                xs={12}
                lg={2}
                md={6}
                className="d-flex justify-content-start"
              >
                <a href="/kalash-report">
                  <button
                    className="pledge_btn"
                    style={{
                      fontSize: "13px",
                      width: "auto",
                      opacity: downloading ? "0.5" : "1",
                    }}
                  >
                    View Reports
                  </button>
                </a>
                <a href="/moderation-report">
                  <button
                    className="pledge_btn"
                    style={{
                      fontSize: "13px",
                      width: "auto",
                      opacity: downloading ? "0.5" : "1",
                    }}
                  >
                    Moderation Reports
                  </button>
                </a>
              </Col>
            )}
          </Row>
          {/* </div> */}
          <Row className="mx-1">
            {/* Google Map */}

            {nodalData?.super_admin ? (
              <Col xs={12} md={8} lg={9} className="border-end">
                {/* Map placeholder, replace with your actual map */}
                <Tabs
                  id="justify-tab-example"
                  className="mb-3"
                  style={{
                    padding: "24px",
                    border: "none",
                    gap: "20px",
                    color: "brown",
                  }}
                  justify
                >
                  <Tab eventKey="block" title="Map for Phase 1">
                    <div style={{ height: "100%", backgroundColor: "#e0e0e0" }}>
                      <div
                        id="mapPhase1"
                        style={{
                          marginTop: "1rem",
                          width: "100%",
                          height: "80vh",
                          border: "1px solid #d3d3d3",
                        }}
                      ></div>
                    </div>
                  </Tab>
                  <Tab eventKey="village" title="Map for Phase 2">
                    <div style={{ height: "100%", backgroundColor: "#e0e0e0" }}>
                      <div
                        id="mapMyIndia"
                        style={{
                          marginTop: "1rem",
                          width: "100%",
                          height: "80vh",
                          border: "1px solid #d3d3d3",
                        }}
                      ></div>
                    </div>
                  </Tab>
                </Tabs>
              </Col>
            ) : (
              <></>
            )}

            {/* Left Profile Section */}
            {nodalData?.super_admin ? (
              <Col xs={12} md={4} lg={3} className="border-end">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          src={"./asset/images/gallery.jpg"}
                          alt="Profile"
                          roundedCircle
                          style={{ width: "80%", height: "220px" }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <h4 className="text-center py-3">Phase-1 Report</h4>
                    </Row>
                    <Row>&nbsp;</Row>

                    <Row className="mt-1">
                      <Col xs={12} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events at different Locations
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCount
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Sum of total registered panchayats, ULBs
                                      and institutions
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col xs={12} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (Panchayat)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountPanchayat
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self
                                      registered/registered via nodal officer
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col xs={12} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (ULB)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountULB
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self
                                      registered/registered via nodal officer
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col xs={12} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (Institute)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountInstitute
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self registered under
                                      institutional login
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>

                    <Row className="mt-1">
                      <Col xs={12} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#fffcf2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Nodal Officers
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543c00" }}>
                                  <DashboardCounterCard
                                    selectedState={selectedState}
                                    selectedDistrict={selectedDistrict}
                                    selectedBlock={selectedBlock}
                                    collectionName={"nodal_officer_data"}
                                    cardName={"Date Finalized"}
                                    returnOnlyCounter={true}
                                    districtKey={"district_name"}
                                    blockKey={"name_of_block"}
                                  />
                                </Card.Text>
                              </Col>
                              <Col>
                                {" "}
                                <FileEarmarkArrowDownFill
                                  size={30}
                                  onClick={handleDownloadClick}
                                  style={{ cursor: "pointer" }}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col
                        xs={12}
                        className="d-flex justify-content-center align-item-center"
                      >
                        <Card
                          style={{
                            background: "#fffcf2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              {/* <Col>
                          <GeoAltFill size={12} />
                        </Col> */}
                              <Col>
                                <Card.Text className="topic">
                                  Organisers
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543c00" }}>
                                  <DashboardCounterCard
                                    selectedState={selectedState}
                                    selectedDistrict={selectedDistrict}
                                    selectedBlock={selectedBlock}
                                    collectionName={"organisers"}
                                    cardName={"Date Finalized"}
                                    returnOnlyCounter={true}
                                  />
                                </Card.Text>
                              </Col>
                              <Col>
                                <FileEarmarkArrowDownFill
                                  size={30}
                                  onClick={getAllOrganisersDataAndDownload}
                                  style={{ cursor: "pointer" }}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <Col xs={12} md={12} lg={12} className="border-end">
                <Card className="custom-card">
                  <Card.Body>
                    <Row>
                      <Col xs={12} className="d-flex justify-content-center">
                        <Image
                          src={"./asset/images/gallery.jpg"}
                          alt="Profile"
                          roundedCircle
                          style={{ height: "220px" }}
                        />
                      </Col>

                      <Col xs={4} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events at different Locations
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCount
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Sum of total registered panchayats, ULBs
                                      and institutions
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col xs={4} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (Panchayat)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountPanchayat
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self
                                      registered/registered via nodal officer
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col xs={4} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (ULB)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountULB
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self
                                      registered/registered via nodal officer
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col xs={4} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#FFFCF2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Total Registered Events (Institute)
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543C00" }}>
                                  {totalEventCountInstitute
                                    ?.toString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </Card.Text>
                              </Col>
                              <Col lg={2}>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip>
                                      Where organiser have self registered under
                                      institutional login
                                    </Tooltip>
                                  }
                                >
                                  <span
                                    className="info-eye"
                                    onClick={toggleInfo}
                                    onMouseEnter={toggleInfo}
                                    onMouseLeave={toggleInfo}
                                  >
                                    <img src="./asset/images/info.png" />
                                  </span>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col xs={4} className="d-flex justify-content-center">
                        <Card
                          style={{
                            // borderRadius: "44px",
                            background: "#fffcf2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              <Col>
                                <Card.Text className="topic">
                                  Nodal Officers
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543c00" }}>
                                  <DashboardCounterCard
                                    selectedState={selectedState}
                                    selectedDistrict={selectedDistrict}
                                    selectedBlock={selectedBlock}
                                    collectionName={"nodal_officer_data"}
                                    cardName={"Date Finalized"}
                                    returnOnlyCounter={true}
                                    districtKey={"district_name"}
                                    blockKey={"name_of_block"}
                                  />
                                </Card.Text>
                              </Col>
                              <Col>
                                {" "}
                                <FileEarmarkArrowDownFill
                                  size={30}
                                  onClick={handleDownloadClick}
                                  style={{ cursor: "pointer" }}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>

                      <Col
                        xs={4}
                        className="d-flex justify-content-center align-item-center"
                      >
                        <Card
                          style={{
                            background: "#fffcf2",
                            width: "100%",
                          }}
                        >
                          <Card.Body>
                            <Row className="d-flex justify-content-center align-item-center">
                              {/* <Col>
                        <GeoAltFill size={12} />
                      </Col> */}
                              <Col>
                                <Card.Text className="topic">
                                  Organisers
                                </Card.Text>
                              </Col>
                              <Col>
                                <Card.Text style={{ color: "#543c00" }}>
                                  <DashboardCounterCard
                                    selectedState={selectedState}
                                    selectedDistrict={selectedDistrict}
                                    selectedBlock={selectedBlock}
                                    collectionName={"organisers"}
                                    cardName={"Date Finalized"}
                                    returnOnlyCounter={true}
                                  />
                                </Card.Text>
                              </Col>
                              <Col>
                                <FileEarmarkArrowDownFill
                                  size={30}
                                  onClick={getAllOrganisersDataAndDownload}
                                  style={{ cursor: "pointer" }}
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
          {/* The Bottom Row */}
          {loader ? (
            <div className="d-flex justify-content-center py-3">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading Report...</span>
              </div>
            </div>
          ) : (
            <Row xs={12} md={12} lg={12} className="my-4 mx-2 dashboard-block">
              <h1 className="py-3">Phase 1</h1>
              <Col xs={12} lg={3} className="mt-1">
                <Card className="custom-card">
                  <Row noGutters>
                    <Col
                      xs={4}
                      className="d-flex align-items-center justify-content-cente"
                    >
                      <Image
                        className="m-2"
                        src={PledgeSelfieImage}
                        alt="Card Image"
                        fluid
                        style={{
                          style: "100%",
                          height: "100px",
                          width: "auto",
                        }}
                      />
                    </Col>
                    <Col xs={8}>
                      <Card.Body>
                        <Card.Title className="count">
                          {" "}
                          {selfieCounter
                            ?.toString()
                            .replace(
                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                              ","
                            )}{" "}
                        </Card.Title>
                        <Card.Text className="topic">
                          Total Pledge / Selfies ( Nationwide )
                        </Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>

              <DashboardCounterCard
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
                selectedBlock={selectedBlock}
                collectionName={"event_new"}
                cardImage={Calender}
                cardName={"Date Finalized"}
                eventDate={"event_date"}
              />

              <DashboardCounterCard
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
                selectedBlock={selectedBlock}
                collectionName={"event_media"}
                cardImage={Links}
                cardName={"Total Links"}
                category={"links"}
              />

              <DashboardCounterCard
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
                selectedBlock={selectedBlock}
                collectionName={"event_media"}
                cardImage={Video}
                cardName={"Total Videos"}
                fileType={"video"}
                date={"2023-09-01T00:00:00.000Z"}
                phase={"phase1"}
              />

              <DashboardCounterCard
                selectedState={selectedState}
                selectedDistrict={selectedDistrict}
                selectedBlock={selectedBlock}
                collectionName={"event_media"}
                cardImage={Plant}
                cardName={"Total Saplings"}
                category={"plants"}
              />
              <div className="row">
                <DashboardCard
                  title="Shilaphalakam"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"event_media"}
                      cardImage={Shilpimage}
                      cardName={"Shilaphalakam Pictures"}
                      category={"shilaphalakam"}
                      returnOnlyCounter={true}
                      date={"2023-09-01T00:00:00.000Z"}
                      phase={"phase1"}
                      // fileType={"image"}
                    />
                  }
                  counter={shilaInstalled}
                  totalLocations={totalEventCountPanchayat + totalEventCountULB}
                  imgSrc={Shilpimage}
                />
                <DashboardCard
                  title="Panch Pran"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"event_media"}
                      cardImage={PranImage}
                      cardName={"Panch Pran Pictures"}
                      category={"pledge"}
                      returnOnlyCounter={true}
                      date={"2023-09-01T00:00:00.000Z"}
                      phase={"phase1"}
                    />
                  }
                  counter={pranTaken}
                  totalLocations={totalEventCountPanchayat + totalEventCountULB}
                  imgSrc={PranImage}
                />
                <DashboardCard
                  title="Amritvatika"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"event_media"}
                      cardImage={Amritvatika}
                      cardName={"Amrit Vatika Pictures"}
                      category={"amritvatika"}
                      returnOnlyCounter={true}
                      date={"2023-09-01T00:00:00.000Z"}
                      phase={"phase1"}
                    />
                  }
                  counter={amritvatikaDone}
                  totalLocations={totalEventCountPanchayat + totalEventCountULB}
                  imgSrc={Amritvatika}
                />
                <DashboardCard
                  title="Flag Hoisted"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"event_media"}
                      cardImage={HoistImage}
                      cardName={"National Flag & National Anthem Pictures"}
                      category={"national_flag"}
                      returnOnlyCounter={true}
                      date={"2023-09-01T00:00:00.000Z"}
                      phase={"phase1"}
                    />
                  }
                  counter={flagHoisted}
                  totalLocations={totalEventCountPanchayat + totalEventCountULB}
                  imgSrc={HoistImage}
                />
                <DashboardCard
                  title="Bravehearts Honoured"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"event_media"}
                      cardImage={HonorImage}
                      cardName={"Honor Bravehearts Pictures"}
                      category={"honor_bravehearts"}
                      returnOnlyCounter={true}
                      date={"2023-09-01T00:00:00.000Z"}
                      phase={"phase1"}
                    />
                  }
                  counter={braveheartsHonoured}
                  totalLocations={totalEventCountPanchayat + totalEventCountULB}
                  imgSrc={HonorImage}
                />
                {/* <DashboardCounterCard
                  selectedState={selectedState}
                  selectedDistrict={selectedDistrict}
                  selectedBlock={selectedBlock}
                  collectionName={"event_media"}
                  returnOnlyCounter={true}
                  fileType={"image"}
                  date={"2023-09-01T00:00:00.000Z"}
                  phase={"phase1"}
                /> */}
                <h1 className="py-3">Phase 2</h1>

                <DashboardCard
                  title="Pictures Phase-2"
                  picturesCounter={kalashDataImg}
                  counter={
                    totalBlockKalashImg +
                    totalEventCountPanchayatKalashImg +
                    totalEventCountULBKalashImg
                  }
                  totalLocations={
                    totalBlockKalashImg +
                    totalEventCountPanchayatKalashImg +
                    totalEventCountULBKalashImg
                  }
                  imgSrc={KalashImg}
                  phase={"phase2"}
                />

                <DashboardCard
                  title="Videos Phase-2"
                  picturesCounter={kalashDataVid}
                  counter={
                    totalBlockKalashVid +
                    totalEventCountPanchayatKalashVid +
                    totalEventCountULBKalashvid
                  }
                  totalLocations={
                    totalBlockKalashVid +
                    totalEventCountPanchayatKalashVid +
                    totalEventCountULBKalashvid
                  }
                  imgSrc={KalashImg}
                  type="videos"
                  phase={"phase2"}
                />

                <DashboardCard
                  title="Dignitaries participated"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      // selectedBlock={selectedBlock}
                      collectionName={"ak_event_media"}
                      cardImage={KalashImg}
                      cardName={"Dignitaries participated"}
                      returnOnlyCounter={true}
                      type="dignitary"
                    />
                  }
                  type="dignitaries"
                  counter={totalDignitary}
                  totalLocations={totalDignitary}
                  imgSrc={KalashImg}
                  phase={"phase2"}
                />

                {/* <DashboardCard
                  title="Total Household Motivated"
                  picturesCounter={
                    <DashboardCounterCard
                      selectedState={selectedState}
                      selectedDistrict={selectedDistrict}
                      selectedBlock={selectedBlock}
                      collectionName={"ak_event_media"}
                      cardImage={KalashImg}
                      cardName={"Total Household Motivated"}
                      category={"household"}
                      returnOnlyCounter={true}
                    />
                  }
                  counter={kalashPic}
                  totalLocations={0}
                  imgSrc={KalashImg}
                  type="Household motivated"
                  across={""}
                  locations={""}
                /> */}
              </div>
            </Row>
          )}
          <div>
            <p className="note mx-2">
              Disclaimer: Data is still being collected and is not final.
            </p>
          </div>

          {/* {nodalData?.super_admin && (
            <>
              <Row className="mx-5 mt-5">
                <StateWiseReport type={"panchayat"} />
              </Row>
              <Row className="mx-5 mt-5">
                <StateWiseReport type={"ulb"} />
              </Row>
            </>
          )} */}
        </div>
      )}
    </>
  );
};
function initialize(mmiToken, loadCallback) {
  try {
    if (mmiToken) {
      let count = 0;
      // Insert your script separated by comma like scriptArr = [mapSDK_url, plugins_url];
      let mapSDK_url = `https://apis.mappls.com/advancedmaps/api/${mmiToken}/map_sdk?layer=vector&v=3.0`;
      let plugins_url = `https://apis.mappls.com/advancedmaps/api/${mmiToken}/map_sdk_plugins?v=3.0`;
      var scriptArr = [mapSDK_url, plugins_url];

      const recursivelyAddScript = (script) => {
        if (count < script.length) {
          const el = document.createElement("script");
          el.src = script[count];
          el.async = true;
          el.type = "text/javascript";
          document.getElementsByTagName("head")[0].appendChild(el);
          count = count + 1;
          el.onload = function () {
            recursivelyAddScript(script);
          };
        } else return loadCallback(mmiToken);
      };
      recursivelyAddScript(scriptArr);
    } else console.log("Please! pass a token inside initialize()");
  } catch (e) {
    console.error(String(e));
  }
}

export default Dashboard;
