import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./Home.css";
import firebase from "../../firebase";
import styled from "styled-components";
import axios from "axios";
import { useTranslation } from "react-i18next";

const formatDate = (timestamp) => {
  const date = new Date(timestamp.seconds * 1000); // Convert seconds to milliseconds
  return date.toLocaleDateString(); // You can customize the format as needed
};

const FormatDate = ({ data }) => {
  // Assuming data is the JSON object you provided
  const formattedDate = formatDate(data?.timestamp);

  return (
    <div>
      {/* Your other JSX */}
      <p>Uploaded on - {formattedDate}</p>
    </div>
  );
};

export default function EventMediaBlock({
  eventId,
  collectionName,
  category,
  fileType,
  mediaFolder,
  type,
}) {
  const { t } = useTranslation();
  const [eventMedia, setEventMedia] = React.useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsPoster, setImageUrlsPoster] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false); // Step 2
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    // Fetch Event Media data
    let db = firebase.firestore();
    const septemberFirst2023 = new Date("2023-09-01T00:00:00");
    if (category == "links" || category == "plants") {
      db.collection(collectionName)
        .where("id", "==", eventId)
        .where("category", "==", category)
        .where("timestamp", "<", septemberFirst2023)
        .get()
        .then((querySnapshot) => {
          const eventMediaData = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              const data = {
                id: doc.id,
                ...doc.data(),
              };
              eventMediaData.push(data);
            } else {
              // Document doesn't exist
              console.log("No such document!");
            }
          });
          if (eventMediaData.length > 0) {
            console.log("eventMediaData of links and plats", eventMediaData);
            setEventMedia(eventMediaData);
          }
        });
    } else {
      // console.log(collectionName, eventId);
      db.collection(collectionName)
        .where("id", "==", eventId)
        .where("timestamp", "<", septemberFirst2023)
        .where("category", "==", category)
        // .where("fileType", "==", fileType)
        // .where("file_type", "==", fileType)
        .get()
        .then((querySnapshot) => {
          const eventMediaData = [];
          querySnapshot.forEach((doc) => {
            if (doc.exists) {
              const data = {
                id: doc.id,
                ...doc.data(),
              };
              eventMediaData.push(data);
            } else {
              // Document doesn't exist
              console.log("No such document!");
            }
          });
          if (eventMediaData.length > 0) {
            // console.log("eventMediaData of other category", eventMediaData);
            setEventMedia(eventMediaData);
          }
        })
        .catch((error) => {
          console.error("Error getting documents:", error);
        });
    }
  }, []);

  // Fetch all user Images Asynchronously
  useEffect(() => {
    if (!eventMedia) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        eventMedia.map((item) => getSignedURLFromCF(item?.media_name))
      );

      const urls2 = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      setImageUrlsPoster(urls2);
      setImageUrls(urls);
    };

    fetchImageUrls();
  }, [eventMedia]);

  // Get Signed URL
  const getSignedURLFromCF = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/400x400/filters:format(webp)/filters:quality(70)/${mediaFolder}/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    return data;
  };

  // Get Signed URL
  const getSignedURLFromCFPoster = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/800x800/filters:format(webp)/filters:quality(70)/${mediaFolder}/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    setSelectedImageIndex(response?.data?.data);
    // console.log(data, "datra");
    return data;
  };
  function downloadImageAsBase64() {
    // Create a new Image object
    var img = new Image();
    // Set the crossorigin attribute and property
    img.crossOrigin = "Anonymous";
    // Set a callback to handle the image load event
    img.onload = function () {
      // Create a canvas element to draw the image
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convert the image to a base64 encoded string
      var base64Data = canvas.toDataURL("image/png");

      // Create a temporary anchor element to trigger the download
      var a = document.createElement("a");
      a.href = base64Data;
      a.download = "image.png";

      // Trigger a click event on the anchor element to initiate the download
      a.click();
    };

    // Set the source of the image to the provided URL
    img.src = imageUrl;
  }

  const handleClickImage = async (index) => {
    // console.log(index, "index");
    setIsPopupLoading(true); // Set loading state to true (Step 3)
    setSelectedImageIndex(index);
    try {
      await getSignedURLFromCFPoster(eventMedia[index]?.media_name);
    } catch (error) {
      console.error("Error loading image: ", error);
    }
    setIsPopupLoading(false); // Set loading state back to false
  };

  const handleClosePopup = () => {
    setSelectedImageIndex(null);
    setImageUrl(null);
  };

  return (
    <>
      {eventMedia.length > 0 && (
        <>
          <h2 className="sub_head my-2">{t(`${category}`)}</h2>
          <div>
            <p>
              {t("total")} {t(`${category}`)}: {eventMedia.length}
            </p>
          </div>
        </>
      )}

      {category === "links" ? (
        <div className="row my-3">
          {eventMedia?.map((item, index) => {
            const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
            const isValidUrl = urlPattern.test(item?.media_name);

            return (
              isValidUrl && (
                <div className="col-lg-4 col-md-4 col-sm-12 my-2" key={index}>
                  <div
                    style={{
                      borderRadius: "15px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      src={item?.media_name}
                      width="600"
                      height="400"
                      title="Embedded Content"
                    />
                  </div>
                </div>
              )
            );
          })}
        </div>
      ) : category === "plants" ? (
        <div className="row mb-3">
          {eventMedia?.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 my-2" key={index}>
                <div
                  style={{
                    borderRadius: "15px",
                    position: "relative",
                    backdropFilter: "blur(40px)",
                    border: "1px solid #476807",
                    padding: "15px 10px",
                  }}
                >
                  <p className="plant_text">
                    {t("common_name")} - {item?.common_name?.toUpperCase()}
                  </p>
                  <p className="plant_text">
                    {t("biological_name")} -{" "}
                    {item?.biological_name?.toUpperCase()}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <>
          {eventMedia?.map((item, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 my-2" key={index}>
              {imageUrls?.length > 0 && (
                <div
                  style={{
                    borderRadius: "15px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    handleClickImage(index);
                    setImageUrl(imageUrls[index]);
                  }}
                >
                  <VillageImageDiv
                    style={{
                      backgroundImage: `url('${imageUrls[index]}')`,
                      backgroundColor: "#fff",
                      borderRadius: "15px",
                      backgroundSize: "cover",
                      filter: "blur(40px)",
                      height: "18vh",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  />
                  <VillageImageDiv
                    className="th-border-top th-border-bottom"
                    style={{
                      backgroundImage: `url('${imageUrls[index]}')`,
                      borderRadius: "15px",
                      position: "absolute",
                      top: "0%",
                      right: "0%",
                      bottom: "0%",
                      backgroundSize: "contain",
                      cursor: "pointer",
                    }}
                  />
                  <div
                    className="p-2 name_text"
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "#1ca63b",
                      color: "#fff",
                      fontSize: "2vh",
                    }}
                  >
                    <FormatDate data={item} />
                  </div>
                </div>
              )}

              {selectedImageIndex !== null && (
                <div className="image-popup-overlay" onClick={handleClosePopup}>
                  <div className="image-popup">
                    {!isPopupLoading && (
                      <button
                        className="close-button"
                        onClick={handleClosePopup}
                      >
                        <img
                          src="https://merimaatimeradesh.web.app/close.svg"
                          alt="Close"
                          style={{ height: "20px" }}
                        />
                      </button>
                    )}
                    {isPopupLoading ? ( // Conditionally render loader (Step 4)
                      <div className="loader">
                        <span></span>
                      </div>
                    ) : (
                      <>
                        <button
                          className="download-button"
                          onClick={downloadImageAsBase64}
                        >
                          <img
                            src="/download.svg"
                            alt="Close"
                            style={{ height: "20px" }}
                          />
                        </button>
                        <img src={selectedImageIndex} alt="Popup" />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
}

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;
