import React from "react";
import Dashboard from "./Dashboard";

const DashboardWithHeader = () => {
  return (
    <div>
      <Dashboard showHeader={true} />
    </div>
  );
};

export default DashboardWithHeader;
