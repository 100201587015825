import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import firebase from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Header from "../Components/Header/Header";
import { useLocation } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import "./Home.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
const Login = () => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [screen, setScreen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOTPVerified, setOTPVerified] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [state_name, setStateName] = useState();
  const [district_name, setDistrictName] = useState();
  const [block_name, setBlockName] = useState();
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [panchayat_name, setPanchayatName] = useState();
  const [userRecord, setUserRecord] = useState({});
  const [exist, setExist] = useState(false);
  const [form, setForm] = useState(false);
  const location = useLocation();
  const [loginLoader, setLoginLoader] = useState(false);

  const lastSegment = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  console.log(lastSegment);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSend = (event) => {
    event.preventDefault();
    setLoading(true);

    // First Check if user exist on our database, if so then go for OTP verification
    let db = firebase.firestore().collection("nodal_officer_data");
    db.where("mobile", "==", phone.slice(3))
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          alert("No nodal officer found !");
          setLoading(false);
          setPhone("+91");
        } else {
          setUserRecord({
            uid: querySnapshot.docs[0].id,
          });
          localStorage.setItem(
            "nodal_user",
            JSON.stringify(querySnapshot.docs[0].data())
          );

          let db2 = firebase.firestore().collection("users");
          db2
            .where("email", "==", phone + "@gmail.com")

            .limit(1)
            .get()
            .then((users) => {
              if (!users.empty) {
                setExist(true);
                setScreen(2);
                setHasFilled(true);
                setLoading(false);
              } else {
                axios
                  .post(
                    "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/sendOTP",
                    { phoneNumber: phone }
                  )
                  .then((res) => {
                    if (res.data.success == true) {
                      setScreen(1);
                      setHasFilled(true);
                      setLoading(false);
                    }
                  });
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);
  };

  const submitOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      // verifu otp

      axios
        .post(
          "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/verifyOTP",
          { phoneNumber: phone, enteredOTP: parseInt(otp) }
        )
        .then((results) => {
          if (results.data.success) {
            console.log(results.data.user);
            setUserRecord(results.data.user);
            setScreen(2);
            setLoading(false);
          }

          setOTPVerified(true);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          alert("User couldn't sign in (bad verification code?)");
          setOtp("");
          setLoading(false);
        });
    }
  };
  const handleLogin = async () => {
    setLoginLoader(true);
    if (password == (exist ? password : repassword)) {
      if (userRecord?.uid) {
        localStorage.setItem(
          "users",
          JSON.stringify({
            users: {
              phoneNumber: phone,
              uid: userRecord?.uid,
              type: "nodal",
            },
          })
        );
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              if (form == false) {
                setTimeout(() => {
                  setLoginLoader(false);
                  window.location.href = "/nodal-officer-add";
                }, 1500);
              } else {
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              let db = firebase.firestore().collection("users");
              db.doc(res.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((res) => {
                  if (form == false) {
                    setTimeout(() => {
                      setLoginLoader(false);
                      window.location.href = "/nodal-officer-add";
                    }, 1500);
                  } else {
                    setScreen(3);
                  }
                });
            });
        }
      } else {
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              localStorage.setItem(
                "users",
                JSON.stringify({
                  users: {
                    phoneNumber: phone,
                    uid: res?.uid,
                    type: "nodal",
                  },
                })
              );

              if (form == false) {
                setTimeout(() => {
                  setLoginLoader(false);
                  window.location.href = "/nodal-officer-add";
                }, 1500);
              } else {
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              localStorage.setItem(
                "users",
                JSON.stringify({
                  users: {
                    phoneNumber: phone,
                    uid: res?.uid,
                    type: "nodal",
                  },
                })
              );
              let db = firebase.firestore().collection("users");
              db.doc(res.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((result) => {
                  if (form == false) {
                    setLoginLoader(false);
                    setTimeout(() => {
                      window.location.href = "/nodal-officer-add";
                    }, 1500);
                  } else {
                    setScreen(3);
                  }
                });
            });
        }
      }
    } else {
      setLoginLoader(false);
      alert("Please enter correct password !");
    }
  };

  const instituteType = [
    "community_based_organisation",
    "college",
    "government_organisation",
    "private_organisation",
    "school",
  ];
  const [organiserName, setOrganiserName] = useState("");
  const [organiserType, setOrganiserType] = useState("");

  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    setOrganiserType(value);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name == "state") {
      setStateName(value);
      firebase
        .firestore()
        .collection("state_district_mapping")
        .where("state_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().district_mapping);
          });
          array.sort();
          setDistrict(array);
        });
    } else if (name == "district") {
      setDistrictName(value);
      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().block_name);
          });
          array.sort();
          setBlocks(array);
        });
    } else if (name == "block") {
      setBlockName(value);
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().gp_name);
          });
          array.sort();
          setPanchayats(array);
        });
    } else {
      setPanchayatName(value);
    }
  };
  const handleInstituteOrganiserLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    let db = firebase.firestore().collection("institute_users");
    const user = JSON.parse(localStorage.getItem("users")).users;

    // Save it to the Events Collection
    db.doc(user.uid)
      .set(
        {
          phoneNumber: user.phoneNumber ? user.phoneNumber : "",
          timestamp: new Date(),
          name: organiserName ? organiserName : "",
          institue_type: organiserType ? organiserType : "",
        },
        { merge: true }
      )
      .then((res) => {
        setLoading(false);
        localStorage.setItem(
          "usersMain",
          JSON.stringify({
            users: {
              phoneNumber: user?.phoneNumber,
              name: organiserName ? organiserName : "",
              institue_type: organiserType ? organiserType : "",
            },
          })
        );
        setTimeout(() => {
          window.location.href = "/nodal-officer-add";
        }, 1500);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        setLoading(false);
      });
  };

  if (!hasFilled) {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        <div
          className="container d-flex justify-content-center text-center align-items-center"
          style={{ height: "70vh" }}
        >
          <div className="app__container">
            <div style={{ width: "300px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ padding: "20px" }} variant="h5" component="div">
                  {t("enter_your_number")}
                </h3>
                <form onSubmit={handleSend}>
                  <input
                    className="login-input"
                    style={{ width: "240px" }}
                    autoComplete="off"
                    label="Phone Number"
                    value={phone}
                    maxLength={13}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                  <button
                    disabled={loading}
                    style={{ opacity: loading ? "0.6" : "1" }}
                    className="pledge_btn mt-5"
                    type="submit"
                  >
                    {loading ? t("pleasewait") : t("send_code")}
                  </button>
                  <br />
                  <br />
                  {loading && (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div id="recaptcha"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        {screen == 1 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                {!isOTPVerified && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("enter_otp")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "100px" }}
                        label="OTP "
                        value={otp}
                        onChange={verifyOtp}
                        maxLength={6}
                      />
                    </div>
                    <br />
                    <button className="pledge_btn" onClick={submitOtp}>
                      {t("submit")}
                    </button>
                    <br />
                    <a
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                      href="#"
                    >
                      {t("change_mobile_number")}
                    </a>
                  </>
                )}

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 2 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{ padding: "20px" }}
                      variant="h5"
                      component="div"
                    >
                      {t("password")}
                    </h3>
                    <input
                      className="login-input"
                      style={{ width: "auto", height: "40px" }}
                      label="password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  {!exist && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("repassword")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "auto", height: "40px" }}
                        label="password"
                        type="password"
                        value={repassword}
                        onChange={(e) => {
                          setRePassword(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <br />
                  <button
                    className="pledge_btn"
                    style={{ opacity: loginLoader ? "0.6" : "1" }}
                    onClick={() => {
                      handleLogin();
                    }}
                    disabled={loginLoader}
                  >
                    {loginLoader ? t("pleasewait") : t("submit")}
                  </button>
                  <br />
                  <br />
                  <a style={{ fontWeight: "12px" }} href="/forget-password">
                    {t("forgot_password")} ?
                  </a>
                </>

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 3 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                <>
                  <label className="mx-1">
                    <div className="form_input_title">
                      {" "}
                      {t("name_of_organisation")}
                    </div>

                    <input
                      className="login-input"
                      style={{ width: "240px" }}
                      autoComplete="off"
                      label="Name"
                      value={organiserName}
                      onChange={(event) => setOrganiserName(event.target.value)}
                    />
                  </label>
                  <br />
                  <label className="mx-1">
                    <div className="form_input_title">
                      {" "}
                      {t("institue_type")}{" "}
                    </div>

                    <select
                      className="select-input"
                      name="institute"
                      onChange={handleInputChange2}
                      value={state_name}
                    >
                      <option value="">Select Institute Type</option>
                      {instituteType.map((institute) => (
                        <option key={institute} value={institute}>
                          {t(institute)}
                        </option>
                      ))}
                    </select>
                  </label>

                  <button
                    className="pledge_btn mt-5"
                    type="button"
                    onClick={handleInstituteOrganiserLogin}
                  >
                    {t("login")}
                  </button>
                  <br />
                  <br />
                </>

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default Login;
