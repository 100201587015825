import React, { useEffect } from "react";

export default function HeatMap() {
  function loadEventScripts() {
    // Load your custom script for events here
    var customScript = document.createElement("script");
    customScript.src = "map123.js";
    customScript.async = true;
    customScript.defer = true;
    document.body.appendChild(customScript);

    // Load Google Maps API script with the callback
    var googleMapsScript = document.createElement("script");
    googleMapsScript.src =
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA93xR8JywwU_tDrKPfpSjqM5TDDQ8i_FE&libraries=visualization&callback=initMap";
    var googleMapsScript3 = document.createElement("script");
    googleMapsScript3.src =
      "https://cdnjs.cloudflare.com/ajax/libs/heatmap.js/2.0.2/heatmap.min.js";
    setTimeout(() => {
      googleMapsScript.async = true;
      googleMapsScript.defer = true;
      document.body.appendChild(googleMapsScript);
    }, 2000);

    // googleMapsScript2.async = true;
    // googleMapsScript2.defer = true;
    // document.body.appendChild(googleMapsScript2);
    googleMapsScript3.async = true;
    googleMapsScript3.defer = true;
    document.body.appendChild(googleMapsScript3);
  }
  useEffect(() => {
    loadEventScripts();
  }, []);
  return (
    <div
      id="mapMyIndia"
      style={{
        marginTop: "1rem",
        width: "100%",
        height: "80vh",
        border: "1px solid #d3d3d3",
      }}
    ></div>
  );
}
