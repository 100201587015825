import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import "./Report.css";
import "./Home.css";
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import StateDistrictBlockGP from "../Components/StateDistrictBlockGP";

export default function Report({ headerHid }) {
  const [loader, setLoader] = useState(false);
  const [nodalData, setNodalData] = useState([]);
  const history = useHistory();
  const [downloading, setDownlaoding] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedlocation, setSelectedlocation] = useState({});
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [type, setType] = useState("");
  const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(false);

  const [sortColumn, setSortColumn] = useState("Name of State");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const initialValue = {
    state: "",
    district: "",
    block: "",
    gp: "",
    type: "",
    ulb: "",
  };
  const [location, setLocation] = useState(initialValue);

  const updateLocation = (field, value) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  // useEffect(() => {
  //   console.log("location", location);
  // }, [location]);

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });
        array.sort();
        setStates(array);
      });
  }, []);

  const handleStateClick = (stateName) => {
    setSelectedlocation(stateName);
    console.log(stateName);
  };
  const handleType = (typeName) => {
    setType(typeName);
  };
  const handleReportType = (type) => {
    setLoader(true);
    setReportType(type);
    console.log(loader);
    setSelectedState("");
    console.log(type);
  };

  const customSort = (a, b) => {
    const stateComparison = a["Name of State"].localeCompare(
      b["Name of State"]
    );
    if (stateComparison !== 0) return stateComparison;

    const districtComparison = (a["Name of District"] || "").localeCompare(
      b["Name of District"] || ""
    );
    if (districtComparison !== 0) return districtComparison;

    const blockComparison = (a["Name of Block"] || "").localeCompare(
      b["Name of Block"] || ""
    );
    if (blockComparison !== 0) return blockComparison;

    const panchayatComparison = (
      a["Name of Gram Panchayats"] || ""
    ).localeCompare(b["Name of Gram Panchayats"] || "");
    if (panchayatComparison !== 0) return panchayatComparison;

    const ulbComparison = (a["Name of ULB"] || "").localeCompare(
      b["Name of ULB"] || ""
    );
    if (ulbComparison !== 0) return ulbComparison;

    return 0;
  };

  useEffect(() => {
    // setLoader(true);
    setReportData([]);
    // handle Login/logout
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        const userJSON = JSON.parse(localStorage.getItem("users"))?.users;

        // Check the nodal_officer_data collection for type of user based on state district_name & block_name
        firebase
          .firestore()
          .collection("nodal_officer_data")
          .where("mobile", "==", userJSON?.phoneNumber.replace("+91", ""))
          .get()
          .then((res) => {
            if (res?.docs?.length > 0) {
              // console.log("res", res.docs[0].data());
              setNodalData(res.docs[0].data());
            }
          });
      } else {
        setLoader(false);
        // No user is signed in.
        console.log("no user");
        history.push("/");
      }
    });
  }, []);

  useEffect(() => {
    // Fetch data for states ordered by state_name
    if (reportType) {
      setLoader(true);
      console.log(reportType);
      firebase.auth().onAuthStateChanged(async (user) => {
        const token = await user.getIdToken();
        if (token) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=${reportType}&token=${token}`
            )
            .then((res) => {
              setReportData(res?.data);
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      });
      console.log(loader);
    }
  }, [reportType]);

  const getIndividualStateReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        console.log("item", item);
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_district&state=${item["Name of State"]}&token=${token}`
          )
          .then((res) => {
            setReportData(
              res?.data.sort((a, b) =>
                a?.["Name of District"]?.localeCompare(b?.["Name of District"])
              )
            );
            setLoader(false);
            // console.log(
            //   "report data",
            //   res?.data.sort((a, b) =>
            //     a?.["Name of District"]?.localeCompare(b?.["Name of District"])
            //   )
            // );
          });
      }
    });
  };

  const getIndividualDistrictReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_block&state=${item["Name of State"]}&district=${item["Name of District"]}&token=${token}`
          )
          .then((res) => {
            setReportData(
              res?.data.sort((a, b) =>
                a?.["Name of Block"]?.localeCompare(b?.["Name of Block"])
              )
            );
            setLoader(false);
            console.log("report data", res?.data);
          });
      }
    });
  };

  const getIndividualBlockReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_panchayat&state=${item["Name of State"]}&block=${item["Name of Block"]}&district=${item["Name of District"]}&token=${token}`
          )
          .then((res) => {
            setReportData(
              res?.data.sort((a, b) =>
                a?.["Name of Gram Panchayats"]?.localeCompare(
                  b?.["Name of Gram Panchayats"]
                )
              )
            );
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const getIndividualULBReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_ulb&state=${item}&token=${token}`
          )
          .then((res) => {
            setReportData(res?.data);
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const handleStateChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setSelectedState(value);
    getIndividualULBReport(value);
  };

  const handleDownload = () => {
    // Create a new blank workbook
    const workbook = XLSX.utils.book_new();
    const worksheets = [{ name: "Report", data: reportData }];

    // Convert each data array to a worksheet
    worksheets.forEach((sheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    });

    // Create a Blob and download the Excel workbook
    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Overall_Summary.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownlaoding(false);
    console.log("Data has been downloaded as nodal_officer_summary.xlsx");
  };

  useEffect(() => {
    if (location?.state) {
      setReportData([]);
      console.log("stateSelected from kalas", location?.state);
      getIndividualStateReport({ "Name of State": location?.state });
    }
  }, [location.state]);

  useEffect(() => {
    if (location.district) {
      setReportData([]);
      getIndividualDistrictReport({
        "Name of State": location?.state,
        "Name of District": location?.district,
      });
    }
  }, [location.district]);

  useEffect(() => {
    if (location.block) {
      setReportData([]);
      getIndividualBlockReport({
        "Name of State": location?.state,
        "Name of District": location?.district,
        "Name of Block": location?.block,
      });
    }
  }, [location.block]);

  useEffect(() => {
    if (location.gp) {
      setReportData([]);
      getIndividualBlockReport({
        "Name of State": location?.state,
        "Name of District": location?.district,
        "Name of Block": location?.block,
      });
    }
  }, [location.block]);

  useEffect(() => {
    if (location.gp) {
      setReportData([]);
      getIndividualBlockReport({
        "Name of State": location?.state,
        "Name of District": location?.district,
        "Name of Block": location?.block,
      });
    }
  }, [location.ulb]);

  // Convert data to CSV format
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const sortData = (data, column, order) => {
    return [...data].sort((a, b) => {
      const valA = a[column];
      const valB = b[column];

      if (order === "asc") {
        if (valA < valB) return -1;
        if (valA > valB) return 1;
      } else {
        if (valA < valB) return 1;
        if (valA > valB) return -1;
      }

      return 0;
    });
  };
  const sortedData = sortData(reportData, sortColumn, sortOrder);

  const handleSort = (column) => {
    if (column === sortColumn) {
      console.log("sort called if");
      // Toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      console.log("sort called else");
      // If sorting a different column, set the new column and default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  // useEffect(() => {
  //   if(location.state){
  //     setSelectedState(location.state)
  //   }
  // }, [location]);

  return (
    <>
      <div className={headerHid ? "" : "main_body ease-in-anim"}>
        {headerHid ? "" : <Header />}

        <StateDistrictBlockGP
          location={location}
          onUpdateLocation={updateLocation}
          showGp={false}
          showType={false}
        />
        {nodalData?.super_admin ? (
          <>
            <div className="container d-flex justify-content-center text-center form_login my-4">
              <button
                className={
                  reportType == "report"
                    ? "report_btn_active mx-2"
                    : "report_btn_inactive mx-2"
                }
                style={{
                  fontSize: "13px",
                  width: "auto",
                  pointerEvents: loader ? "none" : "auto",
                }}
                name="gp"
                onClick={() => {
                  handleReportType("report");
                  handleType("report");
                }}
              >
                Total State wise
              </button>
              <button
                className={
                  reportType == "report_district"
                    ? "report_btn_active mx-2"
                    : "report_btn_inactive mx-2"
                }
                style={{
                  fontSize: "13px",
                  width: "auto",
                  pointerEvents: loader ? "none" : "auto",
                }}
                onClick={() => {
                  handleReportType("report_district");
                  handleType("report_district");
                }}
              >
                Total District wise
              </button>
              <button
                className={
                  reportType == "report_block"
                    ? "report_btn_active mx-2"
                    : "report_btn_inactive mx-2"
                }
                style={{
                  fontSize: "13px",
                  width: "auto",
                  pointerEvents: loader ? "none" : "auto",
                }}
                onClick={() => {
                  handleReportType("report_block");
                  handleType("report_block");
                }}
              >
                Total Block wise
              </button>
              <button
                className={
                  reportType == "report_ulb"
                    ? "report_btn_active mx-2"
                    : "report_btn_inactive mx-2"
                }
                style={{
                  fontSize: "13px",
                  width: "auto",
                  pointerEvents: loader ? "none" : "auto",
                }}
                onClick={() => {
                  handleReportType("report_ulb");
                  handleType("report_ulb");
                }}
              >
                Total ULB wise
              </button>

              {reportType == "report_ulb" && (
                <label className="mt-1 mb-1 ">
                  <select
                    className="select-input"
                    tabIndex="2"
                    name="state"
                    value={selectedState}
                    onChange={handleStateChange}
                  >
                    <option value="">India</option>
                    {/* Map over your states data */}
                    {states?.sort().map((state, index) => (
                      <option key={state + index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </label>
              )}
            </div>
            <div className="justify-content-center d-flex">
              Please select a tab to see the report &nbsp;&nbsp;
              {loader && (
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading Report...Please wait</span>
                </div>
              )}
            </div>

            {reportData.length > 0 && (
              <>
                <div className="report-container">
                  <h1 className="report-title">
                    {type == "report"
                      ? "State wise Report"
                      : type == "report_district"
                      ? "District wise Report"
                      : type == "report_block"
                      ? "Block wise Report"
                      : type == "report_panchayat"
                      ? "Panchayat wise Report"
                      : type == "report_ulb" && "ULB wise Report"}
                  </h1>
                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="pledge_btn mx-2"
                      style={{
                        fontSize: "13px",
                        width: "auto",
                        opacity: downloading ? "0.5" : "1",
                      }}
                      onClick={() => {
                        handleDownload();
                      }}
                      disabled={downloading}
                    >
                      {downloading ? "Downloading" : "Download Report"}
                    </button>
                  </div>
                  <div className="table-container">
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("Name of State")}>
                            Name of State
                          </th>
                          <th onClick={() => handleSort("Name of ULB")}>
                            {reportType == "report_ulb" && "Name of ULB"}
                          </th>
                          <th onClick={() => handleSort("Name of District")}>
                            {Object.keys(reportData[0])?.includes(
                              "Name of District"
                            ) && "Name of District"}
                          </th>

                          <th onClick={() => handleSort("Name of Block")}>
                            {Object.keys(reportData[0]).includes(
                              "Name of Block"
                            ) && "Name of Block"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Name of Gram Panchayats")
                            }
                          >
                            {Object.keys(reportData[0]).includes(
                              "Name of Gram Panchayats"
                            ) && "Name of Gram Panchayats"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort(
                                "Number of Gram Panchayats where programme organized"
                              )
                            }
                          >
                            {Object.keys(reportData[0]).includes(
                              "Number of Gram Panchayats where programme organized"
                            ) &&
                              "Number of Gram Panchayats where programme organized"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort(
                                "Number of Urban Local Bodies where programme organized"
                              )
                            }
                          >
                            {Object.keys(reportData[0]).includes(
                              "Number of Urban Local Bodies where programme organized"
                            ) &&
                              "Number of Urban Local Bodies where programme organized"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Total Number of Programmes organized")
                            }
                          >
                            {" "}
                            {Object.keys(reportData[0]).includes(
                              "Total Number of Programmes organized"
                            ) && "Total Number of Programmes organized"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Shilaphalakam in Panchayat")
                            }
                          >
                            {"Shilaphalakam in Panchayat"}
                          </th>
                          <th
                            onClick={() => handleSort("Shilaphalakam in ULB")}
                          >
                            {"Shilaphalakam in ULB"}
                          </th>
                          <th onClick={() => handleSort("Total Shilaphalakam")}>
                            {"Total Shilaphalakam"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Amritvatika in Panchayat")
                            }
                          >
                            {"Amritvatika in Panchayat"}
                          </th>
                          <th onClick={() => handleSort("Amritvatika in ULB")}>
                            {"Amritvatika in ULB"}
                          </th>
                          <th onClick={() => handleSort("Total Amritvatika")}>
                            {"Total Amritvatika"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Braveheart Felicitation in Panchayat")
                            }
                          >
                            {"Braveheart Felicitation in Panchayat"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Braveheart Felicitation in ULB")
                            }
                          >
                            {"Braveheart Felicitation in ULB"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Total Braveheart Felicitation")
                            }
                          >
                            {"Total Braveheart Felicitation"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Saplings Planted in Panchayat")
                            }
                          >
                            {"Saplings Planted in Panchayat"}
                          </th>
                          <th
                            onClick={() =>
                              handleSort("Saplings Planted in ULB")
                            }
                          >
                            {"Saplings Planted in ULB"}
                          </th>
                          <th
                            onClick={() => handleSort("Total Saplings Planted")}
                          >
                            {"Total Saplings Planted"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData?.map((item, index) => (
                          <tr key={index}>
                            {item["Name of ULB"] ? (
                              <td>{item["Name of State"]}</td>
                            ) : (
                              <td>
                                {item["Name of State"] &&
                                !item["Name of District"] &&
                                !item["Name of Block"] ? (
                                  <a
                                    className="state-link"
                                    style={{
                                      pointerEvents: loader ? "none" : "auto",
                                    }}
                                    onClick={() => {
                                      handleType("report_district");
                                      handleStateClick(item);
                                      getIndividualStateReport(item);
                                    }}
                                  >
                                    {item["Name of State"]}
                                  </a>
                                ) : (
                                  item["Name of State"]
                                )}
                              </td>
                            )}
                            <td>
                              {item["Name of ULB"] && item["Name of ULB"]}
                            </td>
                            <td>
                              {!item["Name of Block"] ? (
                                <a
                                  className="state-link"
                                  style={{
                                    pointerEvents: loader ? "none" : "auto",
                                  }}
                                  onClick={() => {
                                    handleType("report_block");
                                    handleStateClick(item);
                                    getIndividualDistrictReport(item);
                                  }}
                                >
                                  {item["Name of District"]}
                                </a>
                              ) : (
                                item["Name of District"]
                              )}
                            </td>
                            <td>
                              {item["Name of Block"] &&
                              !item["Name of Gram Panchayats"] ? (
                                <a
                                  className="state-link"
                                  style={{
                                    pointerEvents: loader ? "none" : "auto",
                                  }}
                                  onClick={() => {
                                    handleType("report_panchayat");
                                    handleStateClick(item);
                                    getIndividualBlockReport(item);
                                  }}
                                >
                                  {item["Name of Block"]}
                                </a>
                              ) : (
                                item["Name of Block"]
                              )}
                            </td>
                            <td>
                              {item["Name of Gram Panchayats"] &&
                                item["Name of Gram Panchayats"]}
                            </td>

                            <td>
                              {item[
                                "Number of Gram Panchayats where programme organized"
                              ] &&
                                item[
                                  "Number of Gram Panchayats where programme organized"
                                ]}
                            </td>
                            <td>
                              {item[
                                "Number of Urban Local Bodies where programme organized"
                              ] &&
                                item[
                                  "Number of Urban Local Bodies where programme organized"
                                ]}
                            </td>
                            <td>
                              {item["Total Number of Programmes organized"] &&
                                item["Total Number of Programmes organized"]}
                            </td>
                            <td>
                              {item["Shilaphalakam in Panchayat"] &&
                                item["Shilaphalakam in Panchayat"]}
                            </td>
                            <td>
                              {item["Shilaphalakam in ULB"] &&
                                item["Shilaphalakam in ULB"]}
                            </td>
                            <td>
                              {item["Total Shilaphalakam"] &&
                                item["Total Shilaphalakam"]}
                            </td>
                            <td>
                              {item["Amritvatika in Panchayat"] &&
                                item["Amritvatika in Panchayat"]}
                            </td>
                            <td>
                              {item["Amritvatika in ULB"] &&
                                item["Amritvatika in ULB"]}
                            </td>
                            <td>
                              {item["Total Amritvatika"] &&
                                item["Total Amritvatika"]}
                            </td>
                            <td>
                              {item["Braveheart Felicitation in Panchayat"] &&
                                item["Braveheart Felicitation in Panchayat"]}
                            </td>
                            <td>
                              {item["Braveheart Felicitation in ULB"] &&
                                item["Braveheart Felicitation in ULB"]}
                            </td>
                            <td>
                              {item["Total Braveheart Felicitation"] &&
                                item["Total Braveheart Felicitation"]}
                            </td>
                            <td>
                              {item["Saplings Planted in Panchayat"] &&
                                item["Saplings Planted in Panchayat"]}
                            </td>
                            <td>
                              {item["Saplings Planted in ULB"] &&
                                item["Saplings Planted in ULB"]}
                            </td>
                            <td>
                              {item["Total Saplings Planted"] &&
                                item["Total Saplings Planted"]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="container d-flex justify-content-center text-center form_login my-4">
            Not Authorised
          </div>
        )}

        <div>
          {headerHid ? (
            ""
          ) : (
            <p className="note mx-2 text-center">
              Disclaimer: Data is still being collected and is not final.
            </p>
          )}
        </div>
      </div>
    </>
  );
}
