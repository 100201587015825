import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Header from "../Components/Header/Header";
import "./Home.css";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import stateDistrictMap from "../Assets/data/state_district.json";
import axios from "axios";
const styleMap = {
  width: "100%",
  height: "80vh",
  display: "inline-block",
};
export default function EventPage() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get(
        "https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getToken"
      )
      .then((res) => {
        initialize(res.data.token, afterScriptsLoaded);
      });
  }, []);

  function afterScriptsLoaded(token) {
    var map = window.mappls.Map("map", {
      center: [23.80946005893908, 78.73641329235636],
      zoom: 3.5,
      zoomControl: true,
      traffic: false,
      clickableIcons: false,
      location: true,
      gestureHandlling: true,
    });
    let layerObj = null;
    let dataset = "harghar_tiranga";
    let getMapStyle = "harghar_tiranga_cluster_1691499027970_workview";
    let getFeatureStyle = "harghar_tiranga_cluster_1691499027970_workview";
    map.addListener("load", function () {
      var hwb = window.getBbox(map),
        params = {
          map_obj: map,
          layerId: dataset,
          key: token,
          style: getMapStyle,
          bound: hwb[2],
          hght: hwb[0],
          wdth: hwb[1],
          cord: hwb[3],
        };
      window.layerCall(params, map); /* wms layer call once when map loaded  */
    });
    map.addListener("moveend", function () {
      var hwb = window.getBbox(map),
        params = {
          map_obj: map,
          layerId: dataset,
          key: token,
          style: getMapStyle,
          bound: hwb[2],
          hght: hwb[0],
          wdth: hwb[1],
          cord: hwb[3],
        };
      window.layerCall(params, map); /* wms layer updated according bbox */
    });
    /* click on cluster or single icon for get getFeature Information */
    map.addListener("click", function (e) {
      console.log(e);
      /* find pixel value from LngLat */
      var point = map.project(e.lngLat),
        params = {
          map_obj: map,
          layerId: dataset,
          key: token,
          style: getFeatureStyle,
          x: parseInt(point.x),
          y: parseInt(point.y),
          lnglat: e.lngLat,
          buffer: 25,
        };
      window.getFeatureCall(params, map, function (data) {
        console.log(data); /* getFeature Information get in callBack */
      });
    });
  }
  return (
    <div className="main_body">
      <Header />
      <div className="d-flex justify-content-center">
        <Link to="/nyv-report?user=PUBLIC">
          <div className="col d-flex justify-content-center my-4 mx-2">
            <button className="pledge_btn" style={{ width: "fit-content" }}>
              {t("upload_media")}
            </button>
          </div>
        </Link>
        <Link to="/event-list">
          <div className="col d-flex justify-content-center my-4 mx-2">
            <button className="pledge_btn" style={{ width: "fit-content" }}>
              {t("view_events")}
            </button>
          </div>
        </Link>
      </div>

      <div
        style={{ height: "100%", backgroundColor: "#e0e0e0", margin: "20px" }}
      >
        <div id="map" style={styleMap}></div>
      </div>
    </div>
  );

  function initialize(mmiToken, loadCallback) {
    try {
      if (mmiToken) {
        let count = 0;
        // Insert your script separated by comma like scriptArr = [mapSDK_url, plugins_url];
        let mapSDK_url = `https://apis.mappls.com/advancedmaps/api/${mmiToken}/map_sdk?layer=vector&v=3.0`;
        let plugins_url = `https://apis.mappls.com/advancedmaps/api/${mmiToken}/map_sdk_plugins?v=3.0`;
        var scriptArr = [mapSDK_url, plugins_url];

        const recursivelyAddScript = (script) => {
          if (count < script.length) {
            const el = document.createElement("script");
            el.src = script[count];
            el.async = true;
            el.type = "text/javascript";
            document.getElementsByTagName("head")[0].appendChild(el);
            count = count + 1;
            el.onload = function () {
              recursivelyAddScript(script);
            };
          } else return loadCallback(mmiToken);
        };
        recursivelyAddScript(scriptArr);
      } else console.log("Please! pass a token inside initialize()");
    } catch (e) {
      console.error(String(e));
    }
  }
}
