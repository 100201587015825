import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import "./Home.css";
import firebase from "../../firebase";
import axios from "axios";
import { useParams } from "react-router-dom";
import { where } from "firebase/firestore";
import EventMediaBlock from "./EventMediaBlock";
import DashboardCounterCard from "./DashboardCounterCard";
import { Container, Row, Col, Card } from "react-bootstrap";
import YuvaEventImage from "../Assets/img/yuva_events.png";
import KalashGallery from "./KalashGallery";

export default function Gallery() {
  const urlParams = useParams();

  const [eventMedia, setEventMedia] = React.useState([]);
  const [plantsCount, setPlantCount] = useState(0);
  const eventId = window.location.href.split("/")[4];
  const [villageName, setVillageName] = useState([]);
  const [panchayatName, setPanchayatName] = useState("");
  const [uniqueArray, setUniqueArray] = useState([]);
  // console.log(eventId);

  useEffect(() => {
    // Convert the array to a Set to remove duplicates
    const uniqueSet = new Set(villageName);

    // Convert the Set back to an array and update the state
    setUniqueArray([...uniqueSet]);
  }, [villageName]);

  // useEffect(() => {

  //   let db = firebase.firestore();
  //   db.collection("event_media")
  //     .where("category", "==", "plants")
  //     .where("id", "==", urlParams.id)

  //     .get()
  //     .then((querySnapshot) => {
  //       console.log(querySnapshot.data())
  //       setEventMedia(querySnapshot.data());
  //     })
  //     .catch((error) => {
  //       console.error("Error getting documents:", error);
  //     });

  // }, [])
  useEffect(() => {
    //   // Fetch Event Media data
    let db = firebase.firestore();
    db.collection("event_new")
      .doc(eventId)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.data());
        setEventMedia(querySnapshot.data());
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
      });
  }, []);

  return (
    <div className="main_body">
      <Header />

      <Container className="mt-5">
        <div className="row mt-5">
          <h1 className="m_head text-center py-3">Event Media</h1>
        </div>
        <Row className="justify-content-center text-center mx-2 d-flex align-items-center">
          <Card className="profile-block" id="profile-block">
            <Card.Body>
              <Row className="d-flex align-items-center">
                {/* First Column */}
                <Col
                  md={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <img src={YuvaEventImage} />
                </Col>

                {/* Second Column */}
                <Col md={3}>
                  <Row>
                    <p>
                      <b>Name of State: </b>{" "}
                      {eventMedia ? eventMedia?.state : "N/A"}
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <b>Name of Block: </b>
                      {eventMedia ? eventMedia?.block : "N/A"}
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <b>Name of District:</b>{" "}
                      {eventMedia ? eventMedia?.district : "N/A"}
                    </p>
                  </Row>
                  {/* <Row>
                    <p>
                      <b>Shilaphalakam Installed: </b>
                      {eventMedia?.shilaphalakam_installed ? eventMedia?.shilaphalakam_installed : "No"}
                    </p>
                  </Row> */}
                </Col>

                {/* Third Column */}
                {!eventMedia?.name_of_ulb && (
                  <Col>
                    <Row>
                      <p>
                        <b>Name of Gram Panchayat:</b>{" "}
                        {eventMedia ? eventMedia?.panchayat : "N/A"}
                      </p>
                    </Row>
                    {villageName && (
                      <Row className="d-flex">
                        <p>
                          <b>Name of Village:</b>{" "}
                          {uniqueArray?.map((item, index) => (
                            <span key={index}>
                              {item}
                              {index < uniqueArray.length - 1 && ","}
                            </span>
                          ))}
                        </p>
                      </Row>
                    )}
                    {/* <Row>
                      <p>
                        <b>Amritvatika Planted: </b>
                        {eventMedia?.amritvatika_planted ? eventMedia?.amritvatika_planted : "No"}
                      </p>
                    </Row> */}
                  </Col>
                )}

                {eventMedia?.name_of_ulb && (
                  <Col>
                    <Row>
                      <p>
                        <b>ULB:</b>{" "}
                        {eventMedia ? eventMedia?.name_of_ulb : "N/A"}
                      </p>
                    </Row>
                  </Col>
                )}
                {/* Fourth Column */}

                {/* <Col md={3}> */}
                {/* <Row>
                    <p>
                      <b>Event Date: </b>
                      {eventMedia?.event_date}
                    </p>
                  </Row> */}
                {/* <Row>
                    <p>
                      <b>Bravehearts Felicitated: </b>
                      {eventMedia?.bravehearts_fecilitated ? eventMedia?.bravehearts_fecilitated : "No"}
                    </p>
                  </Row> */}
                {/* </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>

      <div className="container mt-4">
        {eventMedia?.panchayat && (
          <>
            <KalashGallery
              mediaFolder={"ak_event_images"}
              panchayat={eventMedia?.panchayat}
              villageName={villageName}
              setVillageName={setVillageName}
              setPanchayatName={setPanchayatName}
            />
            <br />
            <div className="row">
              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"plants"}
                mediaFolder={"organiser_images"}
              />
              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"amritvatika"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"other"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"honor_bravehearts"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"pledge"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"national_flag"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"shilaphalakam"}
                fileType={"image"}
                mediaFolder={"organiser_images"}
              />

              <KalashGallery
                eventId={eventId}
                collectionName={"moderated_event_media"}
                category={"links"}
                mediaFolder={"organiser_images"}
              />
            </div>
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        <br />
        <hr />

        <h1 className="main_head text-center py-3">Phase-1 </h1>
        <h6 className="text-center">
          {eventMedia?.event_date && <span>({eventMedia?.event_date})</span>}
        </h6>
        <div className="row">
          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"plants"}
            mediaFolder={"organiser_images"}
          />
          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"amritvatika"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"other"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"honor_bravehearts"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"pledge"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"national_flag"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"shilaphalakam"}
            fileType={"image"}
            mediaFolder={"organiser_images"}
          />

          <EventMediaBlock
            eventId={eventId}
            collectionName={"moderated_event_media"}
            category={"links"}
            mediaFolder={"organiser_images"}
          />
        </div>
      </div>
    </div>
  );
}
