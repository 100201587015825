import React, { useEffect, useState } from "react";
import "./index.css";
import { Row } from "react-bootstrap";
import Header from "../../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import axios from "axios";
import stateDistrictMap from "../../Assets/data/state_district.json";
import moment from "moment";
import firebase from "../../../firebase";
import ShilaphalakamPreview from "./ShilaphalakamPreview";

const GenrateShipkalam = () => {
  const { t } = useTranslation();
  const engStateList = [
    "Odisha",
    "Jammu and Kashmir",
    "Himachal Pradesh",
    "Manipur",
    "Chhattisgarh",
    "Tamil Nadu",
    "Uttarakhand",
    "Puducherry",
    "Bihar",
    "Sikkim",
    "West Bengal",
    "Tripura",
    "Uttar Pradesh",
    "Maharashtra",
    "Jharkhand",
    "Kerala",
    "Telangana",
    "Punjab",
    "Delhi",
    "Karnataka",
    "Haryana",
    "Gujarat",
    "Rajasthan",
    "Arunachal Pradesh",
    "Mizoram",
    "Meghalaya",
    "Madhya Pradesh",
    "Assam",
    "LADAKH",
    "Nagaland",
    "Andhra Pradesh",
    "Andaman and Nicobar Islands",
    "Lakshadweep",
    "The Dadra and Nagar Haveli and Daman and Diu",
    "Goa",
    "Chandigarh",
  ];
  let history = useHistory();
  const [isChecked, setIsChecked] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState("english");
  const [selectedState, setSelectedState] = useState("");
  const [dataMap, setDataMap] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [stateList, setStateList] = useState(engStateList);

  const [selectedLocation, setSelectedLocation] = useState("rural");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    let db = firebase.firestore();
    db.collection("state_district_mapping")
      .get()
      .then((querySnapshot) => {
        const newDataMap = {};
        querySnapshot.forEach((doc) => {
          const { state_name, district_mapping } = doc.data();
          if (!newDataMap[state_name]) {
            newDataMap[state_name] = [district_mapping];
          } else {
            newDataMap[state_name].push(district_mapping);
          }
        });

        setDataMap(newDataMap);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }, []);

  useEffect(() => {
    console.log("dataMap", dataMap);
  }, [dataMap]);

  const [formInput, setFormInput] = useState({
    state: "",
    panchayat: "",
    date: "",
    names: [],
    language: selectedLanguage,
    location: selectedLocation,
    type: isChecked ? "with_name" : "without_name",
    veerName: [{ name: "" }],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log(formInput);
  }, [formInput]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      language: event.target.value,
    }));
    // if (event.target.value == "hindi") {
    //   setStateList([
    //     "आंध्र प्रदेश",
    //     "अरुणाचल प्रदेश",
    //     "असम",
    //     "बिहार",
    //     "छत्तीसगढ़",
    //     "गोवा",
    //     "गुजरात",
    //     "हरियाणा",
    //     "हिमाचल प्रदेश",
    //     "झारखंड",
    //     "कर्नाटक",
    //     "केरल",
    //     "मध्य प्रदेश",
    //     "माहाराष्ट्र",
    //     "मणिपुर",
    //     "मेघालय",
    //     "मिज़ोरम",
    //     "नागालैंड",
    //     "ओडिशा",
    //     "पंजाब",
    //     "राजस्थान",
    //     "सिक्किम",
    //     "तमिलनाडु",
    //     "तेलंगाना",
    //     "त्रिपुरा",
    //     "उत्तर प्रदेश",
    //     "उत्तराखंड",
    //     "पश्चिम बंगाल",
    //     "अण्डमान और निकोबार",
    //     "चंडीगढ़",
    //     "दिल्ली",
    //     "जम्मू एण्ड कश्मीर",
    //     "लक्षद्वीप",
    //     "लद्दाख",
    //     "पुडुचेरी",
    //     "दादरा और नगर हवेली",
    //   ]);
    // } else {
    //   setStateList(engStateList);
    // }
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);

    //Set with or without name based on this checkbox
    setFormInput((prevFormInput) => ({
      ...prevFormInput,
      type: event.target.checked ? "with_name" : "without_name",
    }));

    // Clear the veerName list when the checkbox is unchecked
    if (!event.target.checked) {
      setFormInput((prevFormInput) => ({
        ...prevFormInput,
        veerName: [{ name: "" }],
      }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "date") {
      setFormInput((prev) => ({
        ...prev,
        [name]: moment(value).format("DD/MM/YYYY"),
      }));
    } else {
      setFormInput((prev) => ({ ...prev, [name]: value }));
    }

    if (name === "state") {
      setSelectedState(value);
    }
  };

  const handleNameChange = (e, index) => {
    let nameData = [...formInput.veerName];
    nameData[index].name = e.target.value;
    setFormInput({ ...formInput, veerName: nameData });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(formInput);
    const obj = {
      location_of_shilafalakam: {
        state: formInput?.state,
        panchayat: formInput?.panchayat,
      },
      name_of_veer: formInput?.veerName,
      language_of_shilafalakam: formInput?.language,
      type_of_shilafalakam_location: formInput.location,
      type: formInput?.type,
      generated_date: formInput?.date,
    };

    let db = firebase.firestore().collection("event_new");
    let dbMedia = firebase.firestore().collection("event_media");
    const org_user = JSON.parse(localStorage.getItem("event_user"))
      ? JSON.parse(localStorage.getItem("event_user"))
      : JSON.parse(localStorage.getItem("usersMain"))?.users
      ? JSON.parse(localStorage.getItem("usersMain"))?.users
      : {};
    if (org_user.name_of_ulb) {
      org_user.state = org_user?.state ? org_user?.state.toUpperCase() : "";
      org_user.name_of_ulb = org_user?.name_of_ulb
        ? org_user?.name_of_ulb.toUpperCase()
        : "";

      db.where(
        "state",
        "==",
        org_user?.state ? org_user?.state.toUpperCase() : ""
      )
        .where(
          "name_of_ulb",
          "==",
          org_user?.name_of_ulb ? org_user?.name_of_ulb.toUpperCase() : ""
        )

        .limit(1)
        .get()
        .then((res) => {
          if (res.size == 0) {
            db.add(
              {
                created_by: org_user ? "organiser" : "institute",
                ...org_user,
                timestamp: new Date(),
                event_date: "",
              },
              { merge: true }
            ).then((result) => {
              dbMedia.add(
                {
                  created_by: org_user?.state ? "organiser" : "institute",
                  ...obj,
                  ...org_user,
                  timestamp: new Date(),
                  id: result.id,
                  moderated: false,
                  category: "shilafalkam_generated",
                },
                { merge: true }
              );
            });
          } else {
            if (org_user.state) {
              dbMedia.add(
                {
                  created_by: org_user?.state ? "organiser" : "institute",
                  ...obj,
                  ...org_user,
                  timestamp: new Date(),
                  id: res.docs[0]?.id,
                  moderated: false,
                  category: "shilafalkam_generated",
                },
                { merge: true }
              );
            } else {
              console.log(org_user);
              db.where("created_by", "==", "institute")
                .where("phoneNumber", "==", org_user.phoneNumber)
                .limit(1)
                .get()
                .then((res) => {
                  if (res.size == 0) {
                    db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      event_date: "",
                    })
                      .then((res2) => {
                        dbMedia.add(
                          {
                            created_by: org_user?.state
                              ? "organiser"
                              : "institute",
                            ...org_user,

                            ...obj,
                            ...org_user,
                            timestamp: new Date(),
                            id: res2.id,
                            moderated: false,
                            category: "shilafalkam_generated",
                          },
                          { merge: true }
                        );
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                      });
                  } else {
                    dbMedia.add(
                      {
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...obj,
                        ...org_user,
                        timestamp: new Date(),
                        id: res.docs[0]?.id,
                        moderated: false,
                        category: "shilafalkam_generated",
                      },
                      { merge: true }
                    );
                  }
                });
            }
          }
          setTimeout(() => {
            setShowPopup(true);
          }, 2000);
        });
    } else {
      org_user.state = org_user?.state ? org_user?.state.toUpperCase() : "";
      org_user.district = org_user?.district
        ? org_user?.district.toUpperCase()
        : "";
      org_user.panchayat = org_user?.panchayat
        ? org_user?.panchayat?.toUpperCase()
        : "";
      org_user.block = org_user?.block ? org_user?.block?.toUpperCase() : "";
      db.where(
        "state",
        "==",
        org_user?.state ? org_user?.state.toUpperCase() : ""
      )
        .where(
          "district",
          "==",
          org_user?.district ? org_user?.district.toUpperCase() : ""
        )
        .where(
          "panchayat",
          "==",
          org_user?.panchayat ? org_user?.panchayat?.toUpperCase() : ""
        )
        .where(
          "block",
          "==",
          org_user?.block ? org_user?.block?.toUpperCase() : ""
        )
        .limit(1)
        .get()
        .then((res) => {
          if (res.size == 0) {
            db.add(
              {
                created_by: org_user ? "organiser" : "institute",
                ...org_user,
                timestamp: new Date(),
                event_date: "",
              },
              { merge: true }
            ).then((result) => {
              dbMedia.add(
                {
                  created_by: org_user?.state ? "organiser" : "institute",
                  ...obj,
                  ...org_user,
                  timestamp: new Date(),
                  id: result.id,
                  moderated: false,
                  category: "shilafalkam_generated",
                },
                { merge: true }
              );
            });
          } else {
            if (org_user.state) {
              dbMedia.add(
                {
                  created_by: org_user?.state ? "organiser" : "institute",
                  ...obj,
                  ...org_user,
                  timestamp: new Date(),
                  id: res.docs[0]?.id,
                  moderated: false,
                  category: "shilafalkam_generated",
                },
                { merge: true }
              );
            } else {
              console.log(org_user);
              db.where("created_by", "==", "institute")
                .where("phoneNumber", "==", org_user?.phoneNumber || "")
                .limit(1)
                .get()
                .then((res) => {
                  if (res.size == 0) {
                    db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      event_date: "",
                    })
                      .then((res2) => {
                        dbMedia.add(
                          {
                            created_by: org_user?.state
                              ? "organiser"
                              : "institute",
                            ...obj,
                            ...org_user,
                            timestamp: new Date(),
                            id: res2.id,
                            moderated: false,
                            category: "shilafalkam_generated",
                          },
                          { merge: true }
                        );
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                      });
                  } else {
                    dbMedia.add(
                      {
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...obj,
                        ...org_user,
                        timestamp: new Date(),
                        id: res.docs[0]?.id,
                        moderated: false,
                        category: "shilafalkam_generated",
                      },
                      { merge: true }
                    );
                  }
                });
            }
          }
          setTimeout(() => {
            setShowPopup(true);
          }, 2000);
        });
    }
  };

  const manageUserList = (e, type, index = 0) => {
    e.preventDefault();
    let veerData = formInput.veerName;
    switch (type) {
      case "add":
        veerData.push({ name: "" });
        setFormInput({ ...formInput, veerName: veerData });
        break;
      case "remove":
        veerData.splice(index, 1);
        setFormInput({ ...formInput, veerName: veerData });
        break;
      default:
        break;
    }
  };
  // let indiaStates = Object.keys(dataMap);
  useEffect(() => {
    if (selectedLanguage == "hindi") {
      setStateList([
        "आंध्र प्रदेश",
        "अरुणाचल प्रदेश",
        "असम",
        "बिहार",
        "छत्तीसगढ़",
        "गोवा",
        "गुजरात",
        "हरियाणा",
        "हिमाचल प्रदेश",
        "झारखंड",
        "कर्नाटक",
        "केरल",
        "मध्य प्रदेश",
        "माहाराष्ट्र",
        "मणिपुर",
        "मेघालय",
        "मिज़ोरम",
        "नागालैंड",
        "ओडिशा",
        "पंजाब",
        "राजस्थान",
        "सिक्किम",
        "तमिलनाडु",
        "तेलंगाना",
        "त्रिपुरा",
        "उत्तर प्रदेश",
        "उत्तराखंड",
        "पश्चिम बंगाल",
        "अण्डमान और निकोबार",
        "चंडीगढ़",
        "दिल्ली",
        "जम्मू एण्ड कश्मीर",
        "लक्षद्वीप",
        "लद्दाख",
        "पुडुचेरी",
        "दादरा और नगर हवेली",
      ]);
    } else {
      setStateList(engStateList);
    }
  }, [selectedLanguage]);

  return (
    <>
      <div className="main_body ease-in-anim">
        <Header />
        <div className="container d-flex justify-content-center text-center form_login">
          <form
            onSubmit={handleSubmit}
            className="form-fields m-auto"
            style={{ width: "80%" }}
          >
            <div className="form_title">{t("fill_in_shila")}</div>
            <div className="my-3">
              <label htmlFor="languageSelect">Select Language:</label>
              <select
                id="languageSelect"
                className="select-input"
                value={selectedLanguage}
                onChange={handleLanguageChange}
              >
                <option value="english">English</option>
                <option value="hindi">Hindi</option>
              </select>
            </div>
            <div>
              <label>
                <input
                  type="checkbox"
                  name="location"
                  value="rural"
                  checked={selectedLocation === "rural"}
                  onChange={handleLocationChange}
                />
                Rural
              </label>
              &nbsp; &nbsp;
              <label>
                <input
                  type="checkbox"
                  name="location"
                  value="urban"
                  checked={selectedLocation === "urban"}
                  onChange={handleLocationChange}
                />
                Urban
              </label>
            </div>
            <div className="" style={{ marginTop: "40px" }}>
              <Row className="d-flex justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center text-center">
                  <label className="my-2 " style={{ width: "100%" }}>
                    <div className="form_input_title"> {t("state")}*</div>

                    <select
                      className="state_input"
                      tabIndex="2"
                      name="state"
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">{t("select_state")}</option>
                      {stateList?.sort()?.map((state) => (
                        <option key={state} value={state}>
                          {state.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className="col-12 d-flex justify-content-center text-center">
                  <label style={{ width: "100%" }}>
                    <div className="form_input_title">{t("enter_gp")}</div>
                    <input
                      className="name-input"
                      tabIndex="1"
                      name="panchayat"
                      onChange={handleInputChange}
                      required
                    />
                  </label>
                </div>
              </Row>
              <div className="col-12 d-flex justify-content-center text-center">
                <label style={{ width: "100%" }}>
                  <div className="form_input_title">{t("enter_date")}</div>
                  <input
                    className="name-input date-field"
                    tabIndex="1"
                    name="date"
                    type="date"
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>

              <div className="text-start my-2">
                <label>
                  <input
                    type="checkbox"
                    name="type"
                    checked={isChecked} // Bind the checked attribute to the state
                    onChange={handleChange} // Step 4: Attach the change event handler
                  />{" "}
                  &nbsp;
                  {t("generate_with_name")}
                </label>
              </div>
              {isChecked && (
                <div className="col-12 d-flex justify-content-center text-center">
                  <label style={{ width: "100%" }}>
                    <div className="form_input_title">
                      {t("enter_veer_name")}
                    </div>
                    {formInput?.veerName?.map((data, index) => {
                      return (
                        <div className="form-wrapper">
                          <input
                            className="name-input"
                            tabIndex="1"
                            name="year"
                            onChange={(e) => handleNameChange(e, index)}
                            required
                            maxLength={20}
                          />
                          {index === formInput?.veerName.length - 1 &&
                            formInput?.veerName.length < 10 && (
                              <button
                                className="form-button"
                                onClick={(e) => manageUserList(e, "add")}
                                type="button"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                >
                                  <path
                                    d="M16.3359 9.69922H9.16406V16.8711C9.16406 17.2379 8.86676 17.5352 8.5 17.5352C8.13324 17.5352 7.83594 17.2379 7.83594 16.8711V9.69922H0.664062C0.297301 9.69922 0 9.40192 0 9.03516C0 8.66839 0.297301 8.37109 0.664062 8.37109H7.83594V1.19922C7.83594 0.832457 8.13324 0.535156 8.5 0.535156C8.86676 0.535156 9.16406 0.832457 9.16406 1.19922V8.37109H16.3359C16.7027 8.37109 17 8.66839 17 9.03516C17 9.40192 16.7027 9.69922 16.3359 9.69922Z"
                                    fill="#A1713D"
                                  />
                                </svg>
                              </button>
                            )}

                          {(formInput?.veerName.length !== 1 &&
                            index !== formInput?.veerName.length - 1) ||
                          formInput?.veerName.length === 10 ? (
                            <button className="form-button">
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.9492 2.67019L13.204 5.94086L4.96526 14.2199L1.71234 10.9492L9.9492 2.67019ZM15.6737 1.88138L14.2222 0.422776C13.6612 -0.140925 12.7504 -0.140925 12.1875 0.422776L10.7971 1.81998L14.0519 5.09068L15.6737 3.46092C16.1088 3.02368 16.1088 2.31859 15.6737 1.88138ZM0.00905723 15.5464C-0.0501753 15.8143 0.190506 16.0544 0.457113 15.9892L4.084 15.1055L0.831082 11.8348L0.00905723 15.5464Z"
                                  fill="#925800"
                                />
                              </svg>
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })}
                  </label>
                </div>
              )}
              <Row>
                <div className="d-flex justify-content-center py-3">
                  {/* <Link to=""> */}
                  <button className="form-submit-btn" onClick={handleSubmit}>
                    {t("generate_shilaphalakam")}
                  </button>
                  <br />
                  <br />
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </div>
              </Row>
            </div>
          </form>
        </div>

        {showPopup && (
          <ShilaphalakamPreview
            formInput={formInput}
            setShowPopup={setShowPopup}
          />
        )}
      </div>
    </>
  );
};

export default GenrateShipkalam;
