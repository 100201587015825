import { React, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import "./Certificate.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// import ShareModal from "./ShareModal";

function Certificate({
  name,
  // share,
  // setShowFlagCertificate,
  // setShare,
  // showThankyou,
  // setShowThankyou,
  // currentLang,
}) {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };
  const [imageUri, setUri] = useState(null);

  useEffect(() => {
    // if (localStorage.getItem("user")) {
    // Setup the Canvas
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    // img.src = currentLang.startsWith('hin')
    //   ? './images/certificate_img/certi_hin.jpg'
    //   : CertificateFlag;
    const laguage = localStorage.getItem("i18nextLng").startsWith("en")
      ? "en"
      : localStorage.getItem("i18nextLng");
    console.log(laguage);
    img.src = `./asset/images/certificate/${laguage}.png`;
    // img.src = `./asset/images/certificate/certificate.png`;
    img.onload = function () {
      // Set Canvas resolution as per image
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.crossOrigin = "Anonymous";

      // Draw Image & Add Name
      ctx.font = "bold 40pt Arial";

      const userName = name[0].toUpperCase() + name.substring(1);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      ctx.fillStyle = "#bd8e33";
      ctx.textAlign = "center";
      ctx.fillText(userName, canvas.width / 2, canvas.height / 1.8);

      // Show the Image  in the UI
      img.uri = canvas.toDataURL("image/jpg");
      setUri(img.uri);
      // };
    };
  }, []);

  const downloadCertificate = () => {
    const link = document.createElement("a");
    link.download = name;
    link.href = imageUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => {
      history.push("/");
    }, 1000);
  };

  return (
    <>
      <div className="modalBackground-certificate popup">
        <div className="modal-container-certificate">
          <div className="popup-header-certificate">
            <Link to="/">
              <div
                className="close_thankyou d-flex justify-content-end"
                // onClick={() => {
                //   setShowFlagCertificate(false);
                //   setShowThankyou(true);
                // }}
              >
                <img src="./asset/images/x-circle.svg" alt="X" />
              </div>
            </Link>
            <canvas id="canvas" className="certificate-canvas">
              {" "}
            </canvas>
          </div>
          <div>
            <div className="row d-flex justify-content-center">
              <div
                className=" text-center cirlce orange  cursor-pointer"
                onClick={() => {
                  downloadCertificate();
                }}
              >
                <img
                  className="share pb-2"
                  src="./asset/images/download.svg"
                  alt="Download"
                />
              </div>
              <p className="download_text text-center">{t("download")}</p>
              {/* <div
                className="col text-center green cursor-pointer"
                // onClick={() => {
                //   setShowFlagCertificate(false);
                //   setShare(true);
                // }}
              >
                <img
                  className="share pb-2"
                  src="./images/share.svg"
                  alt="Share"
                />
                <p>{t("share")}</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Certificate;
