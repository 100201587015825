import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import ShilpkalamImage from "../Assets/img/shilpkalam.svg";

export default function Shilpkalam() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="main_body ease-in-anim">
      <Header />

      <>
        <div className="container d-flex justify-content-center text-center form_login">
          <img src={ShilpkalamImage} />
        </div>
      </>
    </div>
  );
}
