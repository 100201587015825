import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import firebase from "../../firebase";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function AddLinks({ location }) {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const [formInput, setFormInput] = useState({
    link: "",
    type: "",
  });

  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [linkType, setSelectedLinkType] = useState(formInput.type || "");
  const screenSize = window.innerWidth;
  const [urlTypes, setUrlTypes] = useState("other");
  const [url, setUrl] = useState("");
  const [load, setLoad] = useState(false);
  function identifyPlatform(url) {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      setUrlTypes("youtube");
      return "youtube";
    } else if (url.includes("facebook.com") || url.includes("fb.com")) {
      setUrlTypes("facebook");
      return "facebook";
    } else if (url.includes("twitter.com")) {
      setUrlTypes("twitter");
      return "twitter";
    } else {
      setUrlTypes("other");
      return "other";
    }
  }

  function isValidURL(str) {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "link") {
      const urlType = identifyPlatform(value);
      setSelectedLinkType(urlType);
    }
    setUrl(value);
    setFormInput((prev) => ({ ...prev, [name]: value }));
    console.log(name, value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoad(true);
    if (location.type == "Rural") {
      if (!location.gp) {
        toast.error(t("please_select_gram_panchayat"));
        setLoad(false);
        return;
      }
    } else {
      if (!location.ulb) {
        toast.error("Please Select ULB");
        setLoad(false);
        return;
      }
    }

    if (!formInput?.link) {
      // Show toast message when required fields are not filled
      toast.error(t("fill_all_required_fields"));
      setLoad(false);
      return;
    }

    if (isValidURL(formInput?.link) === false) {
      // Show toast message when required fields are not filled
      toast.error("Please Enter a valid URL");
      setLoad(false);
      return;
    }

    setLoading(true);
    console.log(formInput);

    try {
      // Add the timestamp to the form input
      const formData = { ...formInput, time: Date.now() };
      const db = firebase.firestore();
      const panchayatName = location.gp;
      const districtName = location.district;

      let dbMedia = firebase.firestore().collection("event_media");
      // First, check if a document with the specified panchayat exists
      if (location?.ulb) {
        const querySnapshot = await db
          .collection("event_new")
          .where("state", "==", location.state.toUpperCase())
          .where("name_of_ulb", "==", location?.ulb.toUpperCase())
          .limit(1)
          .get();

        if (!querySnapshot.empty) {
          // Update the existing document with the new link

          await dbMedia.add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              name_of_ulb: location?.ulb.toUpperCase(),
              timestamp: new Date(),
              id: querySnapshot.docs[0].id,
              moderated: false,
              category: "links",
              media_name: url ? url : "",
              fileType: urlTypes,
            },
            { merge: true }
          );

          toast.success("Link Added Successfully");
          setLoading(false);
          setLoad(false);
          setFormInput({
            link: "",
            type: "",
          });
          setUrl("");
          setUrlTypes("");
        } else {
          const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;

          const querySnapshot = await db.collection("event_new").add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              name_of_ulb: location?.ulb.toUpperCase(),
              timestamp: new Date(),
              event_date: "",
            },
            { merge: true }
          );
          await dbMedia.add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              name_of_ulb: location?.ulb.toUpperCase(),
              timestamp: new Date(),
              id: querySnapshot?.id,
              moderated: false,
              category: "links",
              media_name: url ? url : "",
              fileType: urlTypes,
            },
            { merge: true }
          );
          toast.success("Link Added Successfully");
          setLoading(false);
          setFormInput({
            link: "",
            type: "",
          });
          return;
        }
      } else {
        const querySnapshot = await db
          .collection("event_new")
          .where("district", "==", districtName.toUpperCase())
          .where("panchayat", "==", panchayatName.toUpperCase())
          .where("state", "==", location.state.toUpperCase())
          .where("block", "==", location?.block.toUpperCase())
          .limit(1)
          .get();

        if (!querySnapshot.empty) {
          // Update the existing document with the new link

          await dbMedia.add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              district: districtName.toUpperCase(),
              panchayat: panchayatName.toUpperCase(),
              block: location?.block.toUpperCase(),
              timestamp: new Date(),
              id: querySnapshot.docs[0].id,
              moderated: false,
              category: "links",
              media_name: url ? url : "",
              fileType: urlTypes,
            },
            { merge: true }
          );

          toast.success("Link Added Successfully");
          setLoading(false);
          setFormInput({
            link: "",
            type: "",
          });
          setUrl("");
          setUrlTypes("");
        } else {
          const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;

          const querySnapshot = await db.collection("event_new").add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              district: districtName.toUpperCase(),
              panchayat: panchayatName.toUpperCase(),
              block: location?.block.toUpperCase(),
              timestamp: new Date(),
              event_date: "",
            },
            { merge: true }
          );
          await dbMedia.add(
            {
              created_by: "other",
              state: location?.state.toUpperCase(),
              district: districtName.toUpperCase(),
              panchayat: panchayatName.toUpperCase(),
              block: location?.block.toUpperCase(),
              timestamp: new Date(),
              id: querySnapshot?.id,
              moderated: false,
              category: "links",
              media_name: url ? url : "",
              fileType: urlTypes,
            },
            { merge: true }
          );
          toast.success("Link Added Successfully");
          setLoading(false);
          setFormInput({
            link: "",
            type: "",
          });
          return;
        }
      }
    } catch (error) {
      console.error("Error submitting form  :", error);
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div
        style={{
          opacity: location.gp ? 1 : location?.ulb ? 1 : 0.4,
          pointerEvents: location.gp ? "" : location?.ulb ? "" : "none",
        }}
      >
        <form onSubmit={handleSubmit} className="form-fields">
          <div className="row">
            <div className="col-lg-8  d-flex justify-content-center text-center">
              <div
                className="justify-content-center text-center"
                style={{ width: "100%" }}
              >
                <label className="mx-1" style={{ width: "inherit" }}>
                  <div className="form_input_title d-flex justify-content-start">
                    Enter URL*
                  </div>
                  <input
                    className="name-input"
                    style={{ width: "100%" }}
                    tabIndex="1"
                    name="link"
                    placeholder={"https://merimaatimeradesh.gov.in"}
                    onChange={handleInputChange}
                    required
                    maxLength={50}
                    value={formInput.link}
                  />
                  <i>
                    Example: <b>https://</b>merimaatimeradesh.gov.in
                  </i>
                </label>
                {nameError && (
                  <div className="error-message pb-3">
                    {t("please_enter_your_name")}
                  </div>
                )}
              </div>
            </div>

            <div className="col-lg-4 d-flex justify-content-center text-center">
              <div className="justify-content-center text-center">
                <label className="mx-1">
                  <div className="form_input_title d-flex justify-content-start">
                    Type*
                  </div>
                  <select
                    value={linkType}
                    name="type"
                    className="select-input"
                    style={{ margin: "20px 0" }}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="youtube">Youtube</option>
                    <option value="facebook">Facebook</option>
                    <option value="twitter">Twitter</option>
                    <option value="other">Other</option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center py-3">
            {loading ? (
              <div className="d-flex justify-content-center py-3">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <button
                disabled={loading}
                className="pledge_btn"
                type="submit"
                onClick={handleSubmit}
              >
                {t("submit")}
              </button>
            )}
            <br />
          </div>
        </form>
      </div>
    </div>
  );
}
