import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import Header from "../Components/Header/Header";
import "./Home.css";
import firebase from "../../firebase";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

export default function KalashGallery({
  panchayat = null,
  eventId,
  collectionName,
  category = null,
  mediaFolder,
  villageName = null,
  setVillageName = null,
  setPanchayatName = null,
}) {
  const { t } = useTranslation();
  const [eventMedia, setEventMedia] = React.useState([]);
  const [eventMedia2, setEventMedia2] = React.useState([]);
  const [initialEventMedia, setInitialEventMedia] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [searchKeyWord, setKeyword] = React.useState("");
  const [lastId, setLastDocId] = React.useState("");
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsPoster, setImageUrlsPoster] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false); // Step 2
  const [eventDate, setEventDate] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);

  const handleClickImage = async (index) => {
    console.log(index, "index");
    setIsPopupLoading(true); // Set loading state to true (Step 3)
    setSelectedImageIndex(index);
    try {
      await getSignedURLFromCFPoster(eventMedia[index]?.media_name);
    } catch (error) {
      console.error("Error loading image: ", error);
    }
    setIsPopupLoading(false); // Set loading state back to false
  };

  const handleClosePopup = () => {
    setSelectedImageIndex(null);
    setImageUrl(null);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp?.seconds * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString(); // You can customize the format as needed
  };

  const FormatDate = ({ date }) => {
    // Assuming data is the JSON object you provided
    let formattedDate = formatDate(date);

    return (
      <>
        {/* Your other JSX */}
        <span>
          {formattedDate != "Invalid Date" && formattedDate
            ? "(" + formattedDate + ")"
            : null}
        </span>
      </>
    );
  };

  const EventDateComponent = ({ eventDate }) => {
    const validDates = eventDate.filter((date) => {
      return formatDate(date) !== "Invalid Date";
    });

    return (
      <>
        {validDates.length > 0 &&
          validDates.map((item, index) => (
            <React.Fragment key={index}>
              <span className="p-2 name_text text-center">
                <FormatDate date={item} />
              </span>
              {index < validDates.length - 1 && ","}
            </React.Fragment>
          ))}
      </>
    );
  };
  useEffect(() => {
    //Fetch top 50 Pledges data
    if (panchayat) {
      let db = firebase.firestore();
      db.collection("ak_event_media_moderated")
        .where("panchayat", "==", panchayat)
        .get()
        .then((querySnapshot) => {
          // console.log(querySnapshot.docs);
          const dataArray = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          console.log(dataArray, "dataArray");
          setEventMedia([...eventMedia, ...dataArray]);

          setVillageName([
            ...villageName,
            ...dataArray.map((item) => item.village),
          ]);
          // console.log(dataArray, "coming from kalash gallery");
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });

      db.collection("ak_events")
        .where("panchayat", "==", panchayat)
        .get()
        .then((querySnapshot) => {
          const dataArray = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          console.log(dataArray, "date and village name coming from kalash");
          setEventDate([
            ...eventDate,
            ...dataArray.map((item) => item?.event_date),
          ]);

          // console.log(dataArray, "coming from kalash gallery");
        })
        .catch((error) => {
          console.error("Error getting documents:", error);
        });
    } else {
      // Fetch Event Media data
      let db = firebase.firestore();
      const septemberFirst2023 = new Date("2023-09-01T00:00:00");
      if (category == "links" || category == "plants") {
        db.collection(collectionName)
          .where("id", "==", eventId)
          .where("category", "==", category)
          .where("timestamp", ">=", septemberFirst2023)
          .get()
          .then((querySnapshot) => {
            const eventMediaData = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists) {
                const data = {
                  id: doc.id,
                  ...doc.data(),
                };
                eventMediaData.push(data);
              } else {
                // Document doesn't exist
                console.log("No such document!");
              }
            });
            if (eventMediaData.length > 0) {
              console.log("eventMediaData of links and plats", eventMediaData);
              setEventMedia([...eventMedia, ...eventMediaData]);
            }
          });
      } else {
        // console.log(collectionName, eventId);
        db.collection(collectionName)
          .where("id", "==", eventId)
          .where("timestamp", ">=", septemberFirst2023)
          .where("category", "==", category)
          // .where("fileType", "==", fileType)
          // .where("file_type", "==", fileType)
          .get()
          .then((querySnapshot) => {
            const eventMediaData = [];
            querySnapshot.forEach((doc) => {
              if (doc.exists) {
                const data = {
                  id: doc.id,
                  ...doc.data(),
                };
                eventMediaData.push(data);
              } else {
                // Document doesn't exist
                console.log("No such document!");
              }
            });
            if (eventMediaData.length > 0) {
              console.log("eventMediaData of other category", eventMediaData);
              setEventMedia([...eventMedia, ...eventMediaData]);
            }
          })
          .catch((error) => {
            console.error("Error getting documents:", error);
          });
      }
    }
  }, []);

  // Fetch all user Images Asynchronously
  useEffect(() => {
    // console.log(eventMedia);
    // if (!eventMedia?.media_name) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      const urls2 = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      setImageUrlsPoster(urls2);
      setImageUrls(urls);
    };
    fetchImageUrls();
  }, [eventMedia]);

  // Get Signed URL
  const getSignedURLFromCF = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/400x400/filters:format(webp)/filters:quality(70)/${mediaFolder}/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    return data;
  };

  // Get Signed URL
  const getSignedURLFromCFPoster = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/800x800/filters:format(webp)/filters:quality(70)/ak_event_images/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    setSelectedImageIndex(response?.data?.data);
    // console.log(data, "datra");
    return data;
  };

  const handleClickScroll = () => {
    const element = document.getElementById("header-nav");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  function downloadImageAsBase64() {
    // Create a new Image object
    var img = new Image();
    // Set the crossorigin attribute and property
    img.crossOrigin = "Anonymous";
    // Set a callback to handle the image load event
    img.onload = function () {
      // Create a canvas element to draw the image
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convert the image to a base64 encoded string
      var base64Data = canvas.toDataURL("image/png");

      // Create a temporary anchor element to trigger the download
      var a = document.createElement("a");
      a.href = base64Data;
      a.download = "image.png";

      // Trigger a click event on the anchor element to initiate the download
      a.click();
    };

    // Set the source of the image to the provided URL
    img.src = imageUrl;
  }
  return (
    <div className="">
      <div className="container mt-4 text-center">
        {eventMedia[0]?.village ? (
          <div className="mt-5">
            <h1 className="main_head text-center py-3">Phase-2</h1>
          </div>
        ) : (
          eventMedia[0]?.timestamp && (
            <div className="mt-5">
              <h1 className="main_head text-center py-3">Phase-2(public)</h1>
            </div>
          )
        )}

        {/* {eventDate.length > 0 &&
          eventDate?.map((item, index) => (
            <span className="p-2 name_text text-center">
              <FormatDate index={index} date={item} />
              {index < eventDate.length - 1 && ","}
            </span>
          ))} */}

        {eventDate.length > 0 && <EventDateComponent eventDate={eventDate} />}
        {!eventMedia?.length == 0 && (
          <div className="row">
            {eventMedia?.map((item, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12 my-2" key={index}>
                <div
                  style={{
                    borderRadius: "15px",
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {/* Replace your existing rendering code for images with this */}
                  {imageUrls?.length > 0 && (
                    <div
                      className="pledge_item"
                      style={{
                        borderRadius: "15px",
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleClickImage(index);
                        setImageUrl(imageUrls[index]);
                      }}
                    >
                      <VillageImageDiv
                        style={{
                          backgroundImage: `url('${imageUrls[index]}')`,
                          backgroundColor: "#fff",
                          borderRadius: "15px",
                          backgroundSize: "cover",
                          filter: "blur(40px)",
                          height: "18vh",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                      />
                      <VillageImageDiv
                        className="th-border-top th-border-bottom"
                        style={{
                          backgroundImage: `url('${imageUrls[index]}')`,
                          borderRadius: "15px",
                          position: "absolute",
                          top: "0%",
                          right: "0%",
                          bottom: "0%",
                          backgroundSize: "contain",
                          cursor: "pointer",
                        }}
                      />
                      {/* {console.log(
                        item?.timestamp,
                        "timestamp",
                        item?.created_at,
                        "created_at"
                      )} */}
                      {/* {item?.timestamp ||
                        (item?.created_at && (
                          <div
                            className="p-2 name_text"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#1ca63b",
                              color: "#fff",
                              fontSize: "2vh",
                            }}
                          >
                            Uploaded on -
                            {item?.timestamp && (
                              <FormatDate date={item?.timestamp} />
                            )}
                            {item?.created_at && (
                              <FormatDate date={item?.created_at} />
                            )}
                          </div>
                        ))}  */}
                    </div>
                  )}

                  {selectedImageIndex !== null && (
                    <div
                      className="image-popup-overlay"
                      onClick={handleClosePopup}
                    >
                      <div className="image-popup">
                        {!isPopupLoading && (
                          <>
                            <button
                              className="close-button"
                              onClick={handleClosePopup}
                            >
                              <img
                                src="https://merimaatimeradesh.web.app/close.svg"
                                alt="Close"
                                style={{ height: "20px" }}
                              />
                            </button>
                          </>
                        )}
                        {isPopupLoading ? ( // Conditionally render loader (Step 4)
                          <div className="loader">
                            <span></span>
                          </div>
                        ) : (
                          <div>
                            <img src={selectedImageIndex} alt="Popup" />
                            <button
                              className="download-button"
                              onClick={downloadImageAsBase64}
                            >
                              <img
                                src="/download.svg"
                                alt="Download"
                                style={{ height: "20px" }}
                              />
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;
