import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Header from "../Components/Header/Header";
import firebase from "../../firebase";
import axios from "axios";
import { handleUpload } from "../../services/upload";
export default function NationlEvent({ userType }) {
  const { t } = useTranslation();
  const [upload, setUpload] = useState(null);
  const [files, setFiles] = useState([]);
  const [states, setStatesList] = useState([]);
  const [selectedState, setSelectedState] = useState();
  const [trains, setTrainsList] = useState([{ name: "train1", code: "123" }]);
  const [selectedTrain, setSelectedTrain] = useState();
  const [userName, setUserName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTab, setTab] = useState("31_oct");
  const [userProfile, setUserProfile] = useState({});
  const [selectedUser, setSelectedUser] = useState();
  const usersList = [
    "I&B Officials",
    "Public",
    "School",
    "College",
    "University",
    "Deemed University",
    "Indo Tibetan Border Police",
    "Assam Rifles",
    "Border Security Force",
    "Sashastra Seema Bal",
    "Central Reserve Police Force",
    "Central Industrial Security Force",
    "Common Service Centre",
    "Department of Youth Affairs - Govt of India",
    "Department of Sports - Govt of India",
    "Department of Post",
    "Bank",
    "District Administration",
    "District Panchayat/Parishad",
    "Department of Panchayati Raj - State Government",
    "Department of Rural Development - State Government",
    "Gram Panchayat",
    "Urban Local Body",
    "Youth Clubs",
  ];

  const resetState = () => {
    setSelectedState("");
    setSelectedTrain("");
    setUserName("");
    setPhoneNumber("");
  };
  useEffect(() => {
    if (userType) {
      setTab("31_oct");
      setUserProfile({
        phoneNumber: "PUBLIC",
        type: "EVENT_MGMT",
      });
    } else {
      firebase.auth().onAuthStateChanged((userResponse) => {
        if (userResponse) {
          firebase
            .firestore()
            .collection("phase3_officers")
            .where("phoneNumber", "==", userResponse.email.split("@")[0]) // Use '==' for comparison
            .limit(1)
            .get()
            .then((res) => {
              if (res?.docs[0].data()?.type == "EVENT_MGMT") {
                setTab("31_oct");
              } else {
                setTab("31_oct");
              }
              setUserProfile(res?.docs[0].data());
            });

          firebase
            .firestore()
            .collection("state_lgd_mapping")
            .orderBy("state")
            .get()
            .then((res) => {
              let array = [];
              res?.docs?.forEach((e) => {
                array.push({
                  ...e?.data(),
                });
              });

              setStatesList(array);
            });
        } else {
          window.location.href = "/";
        }
      });
    }
  }, []);

  const fileUpload = async () => {
    setLoading(true);
    try {
      if (files?.length === 0) {
        toast.error(t("please_select_at_least_one_file_to_upload"));
        setLoading(false);
        return;
      }

      if (files?.length > 20) {
        toast.error(t("select_20"));
        setLoading(false);
        return;
      }

      const validFiles = [];
      const errorFiles = [];
      console.log(files);
      for (const file of files) {
        let maxSize = 0;
        let fileType = "";

        if (file.type.startsWith("image/")) {
          maxSize = 10 * 1024 * 1024; // 10MB for images
          fileType = "image";
        } else if (file.type.startsWith("video/")) {
          maxSize = 25 * 1024 * 1024; // 25MB for videos
          fileType = "video";
        } else {
          errorFiles.push(file.name); // Unsupported file type
          continue;
        }

        if (file.size > maxSize) {
          errorFiles.push(file.name); // File size exceeds limit
        } else {
          validFiles.push({ file, fileType });
        }
      }

      if (errorFiles?.length > 0) {
        toast.error(t("some_files_are_invalid_or_exceed_size_limit"));

        setLoading(false);
        return;
      }

      if (validFiles?.length === 0) {
        setLoading(false);
        toast.error(t("please_select_at_least_one_valid_image_or_video_file"));
        return;
      }

      setUpload("Uploading...");

      const db = firebase.firestore().collection("phase3_media");

      for (const { file, fileType } of validFiles) {
        const fileName = `${Date.now()}_${file.name}`;

        await db.add({
          created_by: userProfile?.phoneNumber || "",
          userType: userType ? selectedUser : userProfile?.type || "",
          category: selectedTab || "",
          state_code: JSON.parse(selectedState || "{}")?.state_code || "",
          state: JSON.parse(selectedState || "{}")?.state || "",
          created_at: new Date(),
          type: "media",
          moderated: false,
          media_name: fileName ? fileName : "",
          fileType: fileType,
          moderated: false,
          youth_name: userName || "",
          //   train_name: JSON.parse(selectedTrain || "{}")?.name || "",
          //   train_code: JSON.parse(selectedTrain || "{}")?.code || "",
          phoneNumber: phoneNumber || "",
        });

        const params = {
          Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
          Key:
            fileType === "image"
              ? `phase3_images/${fileName}`
              : `phase3_videos/${fileName}`,

          Body: file,
          ContentType: file.type,
        };

        try {
          await handleUpload(params);
          setUpload("Uploaded Successfully.");
          toast.success(t("upload_and_sent_for_moderation"));
          setLoading(false);

          setTimeout(() => {
            setUpload(null);
          }, 2000);
        } catch (err) {
          console.error("Error uploading file:", err);
        }
      }
      resetState();
      console.log("Documents successfully updated!");
      toast.success("Data added successfully!");

      setTimeout(() => {
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error uploading files: ", error);
      toast.error("Error uploading files");
      resetState();
    } finally {
      resetState();
      setLoading(false);
    }
  };
  console.log(selectedState);
  return (
    <>
      {!userType && <Header />}
      <Tabs
        id="justify-tab-example"
        className="mb-3"
        style={{
          padding: "24px",
          border: "none",
          gap: "20px",
          color: "brown",
        }}
        activeKey={selectedTab}
        onSelect={(e) => {
          resetState();
          setTab(e);
        }}
        justify
      >
        {userProfile.type !== "EVENT_MGMT" && (
          <Tab eventKey="journey" title="Journey">
            <Row className="d-flex justify-content-center align-items-center ">
              {/* <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Train:</h6>
              <select
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "25px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                name="train"
                onChange={(e) => {
                  setSelectedTrain(e.target.value);
                }}
                value={selectedTrain}
                required // Make this field mandatory
              >
                <option value="">Select Train</option>
                {trains?.map((block, index) => (
                  <option
                    key={block.name + index}
                    value={JSON.stringify(block)}
                  >
                    {block.name}
                  </option>
                ))}
              </select>
            </Col> */}

              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  State:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                  value={selectedState}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select State</option>
                  {states?.map((block, index) => (
                    <option
                      key={block.state + index}
                      value={JSON.stringify(block)}
                    >
                      {block.state}
                    </option>
                  ))}
                </select>
              </Col>
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  Name:
                </h6>
                <input
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "15px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  type="text"
                  value={userName}
                  name="userName"
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    setUserName(inputValue);
                  }}
                  // Make this field mandatory
                />
              </Col>
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  Phone Number:
                </h6>
                <input
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "15px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  type="text"
                  maxLength={10}
                  value={phoneNumber}
                  name="userName"
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setPhoneNumber(inputValue);
                  }}
                  // Make this field mandatory
                />
              </Col>
            </Row>
          </Tab>
        )}
        <Tab eventKey="29_oct" title="29th October Event">
          <Row className="d-flex justify-content-center align-items-center ">
            {!userType ? (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  State:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                  value={selectedState}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select State</option>
                  {states?.map((block, index) => (
                    <option
                      key={block.state + index}
                      value={JSON.stringify(block)}
                    >
                      {block.state}
                    </option>
                  ))}
                </select>
              </Col>
            ) : (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  User:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select User</option>
                  {usersList?.map((block, index) => (
                    <option key={block} value={block}>
                      {block}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Name:</h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                value={userName}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setUserName(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                Phone Number:
              </h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                maxLength={10}
                value={phoneNumber}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  setPhoneNumber(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="30_oct" title="30th October Event">
          <Row className="d-flex justify-content-center align-items-center ">
            {!userType ? (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  State:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                  value={selectedState}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select State</option>
                  {states?.map((block, index) => (
                    <option
                      key={block.state + index}
                      value={JSON.stringify(block)}
                    >
                      {block.state}
                    </option>
                  ))}
                </select>
              </Col>
            ) : (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  User:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select User</option>
                  {usersList?.map((block, index) => (
                    <option key={block} value={block}>
                      {block}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Name:</h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                value={userName}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setUserName(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                Phone Number:
              </h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                maxLength={10}
                value={phoneNumber}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  setPhoneNumber(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="31_oct" title="31th October Event">
          <Row className="d-flex justify-content-center align-items-center ">
            {!userType ? (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  State:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                  }}
                  value={selectedState}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select State</option>
                  {states?.map((block, index) => (
                    <option
                      key={block.state + index}
                      value={JSON.stringify(block)}
                    >
                      {block.state}
                    </option>
                  ))}
                </select>
              </Col>
            ) : (
              <Col md={3}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  User:
                </h6>
                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  name="state"
                  onChange={(e) => {
                    setSelectedUser(e.target.value);
                  }}
                  // value={selectedBlock}
                  required // Make this field mandatory
                >
                  <option value="">Select User</option>
                  {usersList?.map((block, index) => (
                    <option key={block} value={block}>
                      {block}
                    </option>
                  ))}
                </select>
              </Col>
            )}
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Name:</h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                value={userName}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setUserName(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
            <Col md={3}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                Phone Number:
              </h6>
              <input
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "15px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                type="text"
                maxLength={10}
                value={phoneNumber}
                name="userName"
                onChange={(e) => {
                  const inputValue = e.target.value.replace(/[^0-9]/g, "");
                  setPhoneNumber(inputValue);
                }}
                // Make this field mandatory
              />
            </Col>
          </Row>
        </Tab>
      </Tabs>

      <Tabs
        id="justify-tab-example"
        className="mb-3"
        style={{
          padding: "24px",
          border: "none",
          gap: "20px",
          color: "brown",
        }}
        justify
      >
        <Tab eventKey="upload" title="Upload Media">
          <div
            className="d-flex justify-content-center"
            style={{ height: "250px", minWidth: "200px" }}
          >
            <div className="img_box">
              <div className="pt-3">
                <img
                  src="./asset/images/upload.svg"
                  style={{ height: "6vh" }}
                />
                <div className="my-5 ">
                  <input
                    type="file"
                    id="file"
                    accept="image/*,video/*"
                    multiple
                    onChange={(e) => {
                      //console.log(e.target.files, files)
                      if (e.target.files?.length > 20) {
                        toast.error(t("select_20"));
                        e.target.value = "";
                      } else {
                        setFiles([...files, ...e.target.files]);
                      }
                    }}
                    style={{
                      display: "none", // Hide the default input appearance
                    }}
                  />
                  <div className="file_name">
                    {files?.length > 0 && (
                      <p>{files?.map((file) => file.name).join(", ")}</p>
                    )}
                  </div>
                  <label htmlFor="file" className="custom-input-button">
                    {t("choose_file")}
                  </label>
                </div>
              </div>
            </div>
          </div>
          {userType ? (
            <div className="d-flex justify-content-center">
              <button
                className="pledge_btn mt-4"
                disabled={
                  !selectedUser ||
                  !phoneNumber ||
                  files.length == 0 ||
                  loading ||
                  selectedUser === "" ||
                  phoneNumber === ""
                }
                style={{
                  opacity:
                    !selectedUser ||
                    !phoneNumber ||
                    files.length == 0 ||
                    loading ||
                    selectedUser === "" ||
                    phoneNumber === ""
                      ? 0.5
                      : 1,
                }}
                onClick={() => {
                  fileUpload();
                }}
              >
                {loading ? "Please Wait..." : t("submit")}
              </button>
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              {selectedTab == "journey" ? (
                <button
                  className="pledge_btn mt-4"
                  disabled={
                    !selectedState ||
                    !phoneNumber ||
                    files.length == 0 ||
                    loading ||
                    selectedState === "" ||
                    phoneNumber === ""
                  }
                  style={{
                    opacity:
                      !selectedState ||
                      !phoneNumber ||
                      files.length == 0 ||
                      loading ||
                      selectedState === "" ||
                      phoneNumber === ""
                        ? 0.5
                        : 1,
                  }}
                  onClick={() => {
                    fileUpload();
                  }}
                >
                  {loading ? "Please Wait..." : t("submit")}
                </button>
              ) : (
                <button
                  className="pledge_btn mt-4"
                  disabled={
                    !selectedState ||
                    !phoneNumber ||
                    files.length == 0 ||
                    loading ||
                    selectedState === "" ||
                    phoneNumber === ""
                  }
                  style={{
                    opacity:
                      !selectedState ||
                      !phoneNumber ||
                      files.length == 0 ||
                      loading ||
                      selectedState === "" ||
                      phoneNumber === ""
                        ? 0.5
                        : 1,
                  }}
                  onClick={() => {
                    fileUpload();
                  }}
                >
                  {loading ? "Please Wait..." : t("submit")}
                </button>
              )}
            </div>
          )}
        </Tab>
      </Tabs>
    </>
  );
}
