import { lazy, Suspense } from "react";
import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import About from "./App/Screens/About";
import { ThemeProvider } from "./context/ThemeContext";
import Home from "./App/Screens/Home";
import Gallery from "./App/Screens/Gallery";
import Pledge from "./App/Screens/Pledge";
import Step from "./App/Screens/Step";
import EventPage from "./App/Screens/EventPage";
import OrganiserLogin from "./App/Screens/OrganiserLogin";
import OrganiserProfile from "./App/Screens/OrganiserProfile";
import Upload from "./App/Screens/Upload";
import GenrateShipkalam from "./App/Screens/GenrateShipkalam/GenrateShipkalam";
import ArtWorkForm from "./App/Screens/ArtWorkForm";
import Shilpkalam from "./App/Screens/Shilpkalam";
import ShilpkalaPreview from "./App/Screens/ShilpkalaPreview";
import UploadForOrganiser from "./App/Screens/UploadForOrganiser";
import Downloads from "./App/Screens/Downloads";
import Dashboard from "./App/Screens/Dashboard";
import ViewPledges from "./App/Screens/ViewPledges";
import Login from "./App/Screens/InstituteLogin";
import NodalLogin from "./App/Screens/NodalLogin";
import AddNodal from "./App/Screens/AddNodal";
import FlagCode from "./App/Screens/FlagCode";
import Footer from "./App/Screens/Footer";
import AddPlants from "./App/Screens/AddPlants";
import PrivacyPolicy from "./App/Screens/PrivacyPolicy";
import Disclaimer from "./App/Screens/Disclaimer";
import TermsAndConditions from "./App/Screens/Terms&Conditions";
import DashboardWithHeader from "./App/Screens/DashboardWithHeader";
import EventUpload from "./App/Screens/EventUpload";
import ForgetPassword from "./App/Screens/resetPassword";
import AddLinks from "./App/Screens/AddLinks";
import EventList from "./App/Screens/EventList";
import NyvLogin from "./App/Screens/NyvLogin";
import NyvReport from "./App/Screens/NyvReport";
import DyoLogin from "./App/Screens/DyoLogin";
import AddNYV from "./App/Screens/AddNYV";
import Report from "./App/Screens/Report";
import KalashReport from "./App/Screens/KalashReport";
import DailyReport from "./App/Screens/DailyReport";
import BlockNyvReport from "./App/Screens/BlockNyvReport";
import ModerationReport from "./App/Screens/ModerationReport";
import HeatMap from "./App/Screens/HeatMap";
import SummaryReport from "./App/Screens/SummaryReport";
import NationalLogin from "./App/Screens/NationalLogin";
import NationlEvent from "./App/Screens/NationlEvent";
import GrandFinale from "./App/Screens/GrandFinale";

const routes = [
  {
    path: "/",
    exact: true,
    Component: Home,
  },
  {
    path: "/step",
    exact: true,
    Component: Step,
  },
  {
    path: "/event/:id",
    exact: false,
    Component: Gallery,
  },
  {
    path: "/pledge",
    exact: true,
    Component: Pledge,
  },
  {
    path: "/about",
    exact: true,
    Component: About,
  },
  {
    path: "/events",
    exact: true,
    Component: EventPage,
  },
  {
    path: "/events-media",
    exact: true,
    Component: EventUpload,
  },
  {
    path: "/upload",
    exact: true,
    Component: Upload,
  },
  {
    path: "/map",
    exact: true,
    Component: HeatMap,
  },
  {
    path: "/login",
    exact: true,
    Component: OrganiserLogin,
  },

  {
    path: "/register-organiser",
    exact: true,
    Component: OrganiserLogin,
  },
  {
    path: "/organiser-profile",
    exact: true,
    Component: OrganiserProfile,
  },
  {
    path: "/add-plants",
    exact: true,
    Component: AddPlants,
  },
  {
    path: "/generate-shilaphalakam",
    exact: true,
    Component: GenrateShipkalam,
  },
  {
    path: "/art-work",
    exact: true,
    Component: ArtWorkForm,
  },
  {
    path: "/shilpkalam",
    exact: true,
    Component: Shilpkalam,
  },
  {
    path: "/shilaphalakam-preview",
    exact: true,
    Component: ShilpkalaPreview,
  },
  {
    path: "/organiser-upload",
    exact: true,
    Component: UploadForOrganiser,
  },
  {
    path: "/downloads",
    exact: true,
    Component: Downloads,
  },
  {
    path: "/dashboard",
    exact: true,
    Component: DashboardWithHeader,
  },
  {
    path: "/institute-login",
    exact: true,
    Component: Login,
  },

  {
    path: "/nyv-login",
    exact: true,
    Component: NyvLogin,
  },

  {
    path: "/dyo-login",
    exact: true,
    Component: DyoLogin,
  },
  {
    path: "/national-level-login",
    exact: true,
    Component: NationalLogin,
  },
  {
    path: "/add-national-event",
    exact: true,
    Component: NationlEvent,
  },
  {
    path: "/nyv-report",
    exact: true,
    Component: NyvReport,
  },
  {
    path: "/add-nyv",
    exact: true,
    Component: AddNYV,
  },
  {
    path: "/forget-password",
    exact: true,
    Component: ForgetPassword,
  },
  {
    path: "/nodal-officer-login",
    exact: true,
    Component: NodalLogin,
  },
  {
    path: "/nodal-officer-add",
    exact: true,
    Component: AddNodal,
  },
  {
    path: "/view-pledges",
    exact: true,
    Component: ViewPledges,
  },
  {
    path: "/flag-code",
    exact: true,
    Component: FlagCode,
  },
  {
    path: "/privacy-policy",
    exact: true,
    Component: PrivacyPolicy,
  },
  {
    path: "/disclaimer",
    exact: true,
    Component: Disclaimer,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    Component: TermsAndConditions,
  },
  {
    path: "/add-links",
    exact: true,
    Component: AddLinks,
  },
  {
    path: "/event-list",
    exact: true,
    Component: EventList,
  },
  {
    path: "/report",
    exact: true,
    Component: Report,
  },
  {
    path: "/kalash-report",
    exact: true,
    Component: KalashReport,
  },
  {
    path: "/detail-report",
    exact: true,
    Component: DailyReport,
  },
  {
    path: "/moderation-report",
    exact: true,
    Component: ModerationReport,
  },
  {
    path: "/summary-report",
    exact: true,
    Component: SummaryReport,
  },
  {
    path: "/block-nyv-report",
    exact: true,
    Component: BlockNyvReport,
  },
  // {
  //   path: "/grand-finale",
  //   exact: true,
  //   Component: GrandFinale,
  // },
];
const Router = () => {
  const { pathname } = useLocation();

  return (
    <>
      {/* <Header /> */}
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <ThemeProvider>
          {routes.map(({ path, Component, exact }) => (
            <Route
              path={path}
              key={path}
              exact={exact}
              render={() => {
                return (
                  // some pages seem to take time to load so leaving suspense in
                  // <Suspense fallback={null}>
                  <Component />
                  // </Suspense>
                );
              }}
            />
          ))}
          <Footer />
        </ThemeProvider>
      </Switch>
    </>
  );
};

export default Router;
