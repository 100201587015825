import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";

export default function FlagCode() {
  const { t } = useTranslation();
  return (
    <>
      <div className="main_body">
        <Header />
        <br />
        <Container>
          <Row className="about-section align-items-center text-center">
            <Col
              lg={12}
              sm={12}
              style={{
                //   paddingTop: screenSize < 970 ? "22%" : "10%",
                marginBottom: "5%",
              }}
            >
              <div className="about" style={{ paddingTop: "2%" }}>
                <div>
                  <h1 className="main_head text-center"> {t("flag_code")}</h1>
                </div>

                <p className="galary_text p-2">{t("sf1")}</p>
                <p className="galary_text p-2">{t("sf2")}</p>
                <p className="galary_text p-2">{t("sf3")}</p>
                <p className="galary_text p-2">{t("sf4")}</p>
                <p className="galary_text p-2">{t("sf5")}</p>
                <p className="galary_text p-2">{t("sf6")}</p>
                <p className="galary_text p-2">{t("sf7")}</p>
                <p className="galary_text p-2">{t("sf8")}</p>
                <p className="galary_text p-2">{t("sf9")}</p>
                <p className="galary_text p-2">{t("sf10")}</p>
                <p className="galary_text p-2">{t("sf11")}</p>
                <p className="p-2" style={{ fontWeight: "bold" }}>
                  {t("sf12")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="about-section align-items-center text-center">
            <Col lg={12} sm={12}>
              <a href="./asset/files/FlagCode.pdf" download>
                <button className="download-btn mx-2">
                  {t("download")}
                </button>
              </a>
            </Col>
          </Row>

        </Container>
      </div>
    </>
  );
}
