import React, { useEffect } from "react";
import shilpimage from "../Assets/img/Shilpalakham1.png";
import cdrImg from "../Assets/img/cdrIcon.svg";
import pdfImg from "../Assets/img/pdfIcon.png";
import jpgImg from "../Assets/img/jpgIcon.png";
import Header from "../Components/Header/Header";
import axios from "axios";

import { useTranslation } from "react-i18next";

const ShilpkalaPreview = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get Signed URL
  const getSignedURLFromCF = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/600x600/filters:format(webp)/filters:quality(100)/${media}`;
    console.log(url);
    const response = await axios.get(url);
    const data = response?.data?.data;
    return data;
  };

  useEffect(async () => {
    const image_data = JSON.parse(localStorage.getItem("shilafalakam_image"));
    console.log(image_data);
    const url = await getSignedURLFromCF(image_data?.media_name);
    setImageUrl(url);
    const image = document.getElementById("shilpImg");
    image.src = url;
    setLoading(false);
  }, []);

  const downloadImage = () => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "download.png";
        a.click();
      })
      .catch((err) => console.error(err));
  };

  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div style={{ padding: "100px 15px 0px 15px" }}>
        <div className="shilpImg">
          {loading && (
            <>
              <div className="d-flex justify-content-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </>
          )}
          <img
            id="shilpImg"
            src={""}
            alt="shilpimage"
            style={{ width: "100%" }}
          />
        </div>
        <div className="shilpContent">
          <h3>Choose the format to download</h3>
          <div className="icon-block d-flex text-align-center justify-content-center">
            <ul className="icon-list">
              {/* <li className="icon-item">
                <img src={cdrImg} alt="cdr-icon" onClick={downloadImage} />
              </li> */}
              <li className="icon-item">
                <img src={pdfImg} alt="cdr-icon" onClick={downloadImage} />
              </li>
              <li className="icon-item">
                <img src={jpgImg} alt="cdr-icon" onClick={downloadImage} />
              </li>
            </ul>
          </div>
          <button onClick={downloadImage} className="download-btn">
            Download All
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShilpkalaPreview;
