import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPlants = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [organiserProfile, setOrganiserProfile] = React.useState();
  const [startDate, setStartDate] = useState();
  const [users, setUsers] = useState([]);
  const [plants, setPlants] = useState([
    {
      common_name: "",
      biological_name: "",
      count: 1, // Default count is 1
    },
  ]);

  useEffect(() => {
    setUsers(JSON.parse(localStorage.getItem("users"))?.users);
  }, []);

  // const addPlant = () => {
  //   const updatedPlants = [...plants, { common_name: "", biological_name: "" }];
  //   setPlants(updatedPlants);
  // };

  const addPlant = () => {
    const updatedPlants = [...plants];
    updatedPlants.push({
      common_name: "",
      biological_name: "",
      count: 1, // Default count is 1
    });
    setPlants(updatedPlants);
  };

  const removePlant = (index) => {
    const updatedPlants = plants.filter((_, i) => i !== index);
    setPlants(updatedPlants);
  };

  const handleChange = (index, field, value) => {
    const updatedPlants = [...plants];
    updatedPlants[index][field] = value;
    setPlants(updatedPlants);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("plants", plants);

    // Check if any of the fields are empty
    const emptyFields = plants.some(
      (plant) => !plant.common_name.trim() || !plant.biological_name.trim()
    );

    if (emptyFields) {
      toast.error("Please fill in all plant details.");
      return;
    }

    const user_id = users?.uid;
    const org_user = JSON.parse(localStorage.getItem("event_user"))
      ? JSON.parse(localStorage.getItem("event_user"))
      : JSON.parse(localStorage.getItem("usersMain"))?.users
      ? JSON.parse(localStorage.getItem("usersMain"))?.users
      : {};
    if (org_user.name_of_ulb) {
      org_user.state = org_user.state ? org_user.state.toUpperCase() : "";

      org_user.name_of_ulb = org_user.name_of_ulb
        ? org_user.name_of_ulb.toUpperCase()
        : "";

      try {
        const eventQuerySnapshot = await firebase
          .firestore()
          .collection("event_new")
          .where("state", "==", org_user.state)
          .where("name_of_ulb", "==", org_user.name_of_ulb)

          .limit(1)
          .get();

        if (eventQuerySnapshot.size === 0) {
          const newEventRef = await firebase
            .firestore()
            .collection("event_new")
            .add({
              created_by: org_user?.state ? "organiser" : "institute",
              ...org_user,
              timestamp: new Date(),
              event_date: "",
            });

          plants.forEach(async (plant) => {
            const db = firebase.firestore().collection("event_media");
            for (let i = 0; i < plant.count; i++) {
              try {
                await db.add({
                  category: "plants",
                  created_by: org_user?.state ? "organiser" : "institute",
                  moderated: false,
                  timestamp: new Date(),
                  ...org_user,
                  id: newEventRef.id,
                  common_name: plant.common_name,
                  biological_name: plant.biological_name,
                });

                console.log("Plant data added successfully.");
                // toast.success("Plant data added successfully!");
                history.push("/organiser-profile");
              } catch (error) {
                console.error("Error adding plant data: ", error);
                toast.error("Error adding plant data");
              }
            }
          });

          // plants.forEach(async (plant) => {
          //   const db = firebase.firestore().collection("event_media");
          //   try {
          //     await db.add({
          //       category: "plants",
          //       created_by: org_user?.state ? "organiser" : "institute",
          //       moderated: false,
          //       timestamp: new Date(),
          //       ...org_user,
          //       id: newEventRef.id,
          //       common_name: plant.common_name,
          //       biological_name: plant.biological_name,
          //     });
          //     console.log("Plant data added successfully.");
          //     toast.success("Plant data added successfully!");
          //     history.push("/organiser-profile");
          //   } catch (error) {
          //     console.error("Error adding plant data: ", error);
          //     toast.error("Error adding plant data");
          //   }
          // });
        } else {
          const eventId = eventQuerySnapshot.docs[0].id;

          plants.forEach(async (plant) => {
            const db = firebase.firestore().collection("event_media");
            for (let i = 0; i < plant.count; i++) {
              try {
                if (org_user.state) {
                  await db.add({
                    category: "plants",
                    created_by: org_user?.state ? "organiser" : "institute",
                    moderated: false,
                    timestamp: new Date(),
                    ...org_user,
                    id: eventId,
                    common_name: plant.common_name,
                    biological_name: plant.biological_name,
                  });
                } else {
                  const instituteEventQuerySnapshot = await firebase
                    .firestore()
                    .collection("event_new")
                    .where("created_by", "==", "institute")
                    .where("phoneNumber", "==", org_user.phoneNumber)
                    .limit(1)
                    .get();

                  if (instituteEventQuerySnapshot.size === 0) {
                    const newInstituteEventRef = await firebase
                      .firestore()
                      .collection("event_new")
                      .add({
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...org_user,
                        timestamp: new Date(),
                        event_date: "",
                      });

                    await db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      category: "plants",
                      moderated: false,
                      timestamp: new Date(),
                      ...org_user,
                      id: newInstituteEventRef.id,
                      common_name: plant.common_name,
                      biological_name: plant.biological_name,
                    });
                  } else {
                    const instituteEventId =
                      instituteEventQuerySnapshot.docs[0].id;
                    await db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      category: "plants",
                      moderated: false,
                      timestamp: new Date(),
                      ...org_user,
                      id: instituteEventId,
                      common_name: plant.common_name,
                      biological_name: plant.biological_name,
                    });
                  }
                }
                console.log("Plant data added successfully.");
                // toast.success("Plant data added successfully!");
                history.push("/organiser-profile");
              } catch (error) {
                console.error("Error adding plant data: ", error);
                toast.error("Error adding plant data");
              }
            }
          });

          // plants.forEach(async (plant) => {
          //   const db = firebase.firestore().collection("event_media");
          //   try {
          //     if (org_user.state) {
          //       await db.add({
          //         category: "plants",
          //         created_by: org_user?.state ? "organiser" : "institute",
          //         moderated: false,
          //         timestamp: new Date(),
          //         ...org_user,
          //         id: eventId,
          //         common_name: plant.common_name,
          //         biological_name: plant.biological_name,
          //       });
          //     } else {
          //       const instituteEventQuerySnapshot = await firebase
          //         .firestore()
          //         .collection("event_new")
          //         .where("created_by", "==", "institute")
          //         .where("phoneNumber", "==", org_user.phoneNumber)
          //         .limit(1)
          //         .get();

          //       if (instituteEventQuerySnapshot.size === 0) {
          //         const newInstituteEventRef = await firebase
          //           .firestore()
          //           .collection("event_new")
          //           .add({
          //             created_by: org_user?.state ? "organiser" : "institute",
          //             ...org_user,
          //             timestamp: new Date(),
          //             event_date: "",
          //           });

          //         await db.add({
          //           created_by: org_user?.state ? "organiser" : "institute",
          //           category: "plants",
          //           moderated: false,
          //           timestamp: new Date(),
          //           ...org_user,
          //           id: newInstituteEventRef.id,
          //           common_name: plant.common_name,
          //           biological_name: plant.biological_name,
          //         });
          //       } else {
          //         const instituteEventId =
          //           instituteEventQuerySnapshot.docs[0].id;
          //         await db.add({
          //           created_by: org_user?.state ? "organiser" : "institute",
          //           category: "plants",
          //           moderated: false,
          //           timestamp: new Date(),
          //           ...org_user,
          //           id: instituteEventId,
          //           common_name: plant.common_name,
          //           biological_name: plant.biological_name,
          //         });
          //       }
          //     }
          //     console.log("Plant data added successfully.");
          //     toast.success("Plant data added successfully!");
          //     history.push("/organiser-profile");
          //   } catch (error) {
          //     console.error("Error adding plant data: ", error);
          //     toast.error("Error adding plant data");
          //   }
          // });
        }
      } catch (error) {
        console.error("Error querying event: ", error);
      }
    } else {
      org_user.state = org_user.state ? org_user.state.toUpperCase() : "";
      org_user.district = org_user.district
        ? org_user.district.toUpperCase()
        : "";
      org_user.panchayat = org_user.panchayat
        ? org_user.panchayat.toUpperCase()
        : "";
      org_user.block = org_user.block ? org_user.block.toUpperCase() : "";

      try {
        const eventQuerySnapshot = await firebase
          .firestore()
          .collection("event_new")
          .where("state", "==", org_user.state)
          .where("district", "==", org_user.district)
          .where("panchayat", "==", org_user.panchayat)
          .where("block", "==", org_user.block)
          .limit(1)
          .get();

        if (eventQuerySnapshot.size === 0) {
          const newEventRef = await firebase
            .firestore()
            .collection("event_new")
            .add({
              created_by: org_user?.state ? "organiser" : "institute",
              ...org_user,
              timestamp: new Date(),
              event_date: "",
            });

          plants.forEach(async (plant) => {
            const db = firebase.firestore().collection("event_media");
            for (let i = 0; i < plant.count; i++) {
              try {
                await db.add({
                  category: "plants",
                  created_by: org_user?.state ? "organiser" : "institute",
                  moderated: false,
                  timestamp: new Date(),
                  ...org_user,
                  id: newEventRef.id,
                  common_name: plant.common_name,
                  biological_name: plant.biological_name,
                });
                console.log("Plant data added successfully.");
                // toast.success("Plant data added successfully!");
                history.push("/organiser-profile");
              } catch (error) {
                console.error("Error adding plant data: ", error);
                toast.error("Error adding plant data");
              }
            }
          });

          // plants.forEach(async (plant) => {
          //   const db = firebase.firestore().collection("event_media");
          //   try {
          //     await db.add({
          //       category: "plants",
          //       created_by: org_user?.state ? "organiser" : "institute",
          //       moderated: false,
          //       timestamp: new Date(),
          //       ...org_user,
          //       id: newEventRef.id,
          //       common_name: plant.common_name,
          //       biological_name: plant.biological_name,
          //     });
          //     console.log("Plant data added successfully.");
          //     toast.success("Plant data added successfully!");
          //     history.push("/organiser-profile");
          //   } catch (error) {
          //     console.error("Error adding plant data: ", error);
          //     toast.error("Error adding plant data");
          //   }
          // });
        } else {
          const eventId = eventQuerySnapshot.docs[0].id;
          plants.forEach(async (plant) => {
            const db = firebase.firestore().collection("event_media");
            for (let i = 0; i < plant.count; i++) {
              try {
                if (org_user.state) {
                  await db.add({
                    category: "plants",
                    created_by: org_user?.state ? "organiser" : "institute",
                    moderated: false,
                    timestamp: new Date(),
                    ...org_user,
                    id: eventId,
                    common_name: plant.common_name,
                    biological_name: plant.biological_name,
                  });
                } else {
                  const instituteEventQuerySnapshot = await firebase
                    .firestore()
                    .collection("event_new")
                    .where("created_by", "==", "institute")
                    .where("phoneNumber", "==", org_user.phoneNumber)
                    .limit(1)
                    .get();

                  if (instituteEventQuerySnapshot.size === 0) {
                    const newInstituteEventRef = await firebase
                      .firestore()
                      .collection("event_new")
                      .add({
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...org_user,
                        timestamp: new Date(),
                        event_date: "",
                      });

                    await db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      category: "plants",
                      moderated: false,
                      timestamp: new Date(),
                      ...org_user,
                      id: newInstituteEventRef.id,
                      common_name: plant.common_name,
                      biological_name: plant.biological_name,
                    });
                  } else {
                    const instituteEventId =
                      instituteEventQuerySnapshot.docs[0].id;
                    await db.add({
                      created_by: org_user?.state ? "organiser" : "institute",
                      category: "plants",
                      moderated: false,
                      timestamp: new Date(),
                      ...org_user,
                      id: instituteEventId,
                      common_name: plant.common_name,
                      biological_name: plant.biological_name,
                    });
                  }
                }
                console.log("Plant data added successfully.");
                // toast.success("Plant data added successfully!");
                history.push("/organiser-profile");
              } catch (error) {
                console.error("Error adding plant data: ", error);
                toast.error("Error adding plant data");
              }
            }
          });
        }
      } catch (error) {
        console.error("Error querying event: ", error);
      }
    }
  };

  useEffect(() => {
    if (startDate == null) {
      return;
    }

    console.log("startDate", startDate);
    //save date to events collection
    const user_id = users?.uid;
    let db = firebase.firestore().collection("events");

    db.doc(user_id)
      .get()
      .then((res) => {
        if (res.exists) {
          console.log("res", res.data());
          // update date
          // Format date manually
          const day = startDate.getDate();
          const month = startDate.getMonth() + 1; // Months are 0 based in JS
          const year = startDate.getFullYear();
          db.doc(user_id)
            .update({
              event_date: day + "/" + month + "/" + year,
            })
            .then(() => {
              console.log("Document successfully updated!");
              setOrganiserProfile({
                ...organiserProfile,
                event_date: day + "/" + month + "/" + year,
              });
              // toast.success("Date added successfully!");
              console.log("res.data()", res.data());
            })

            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
              toast.error("Error updating document: ");
            });
        } else {
          // Implement Event Creation
          //[TODO]
        }
      });
  }, [startDate]);

  // const onChange = (date) => {
  //   setDate(date);
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // handle Login/logout
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        // console.log("user", user);
      } else {
        // No user is signed in.
        console.log("no user");
        history.push("/login");
      }
    });

    //Fetch Profile data
    const user_id = users?.uid;
    let db = firebase.firestore().collection("events");
    db.doc(user_id)
      .get()
      .then((res) => {
        if (res.exists) {
          console.log("res.data()", res.data());
          const data = res.data();
          console.log("data", data);
          setOrganiserProfile(data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="main_body ease-in-anim">
      <Header />
      <Container className="mt-5">
        <Row className="justify-content-center text-center mx-2">
          <div className="plant-form">
            <h2>{t("add_plants")}</h2>
            <form onSubmit={handleSubmit}>
              {plants.map((plant, index) => (
                <div className="plant-entry" key={index}>
                  <div className="row">
                    <div className="col-lg-5 col-sm-12">
                      <div className="input-group">
                        <label
                          htmlFor={`common_name_${index}`}
                          className="input-label"
                        >
                          {t("common_name")}:
                        </label>
                        <input
                          className="plant-input"
                          type="text"
                          id={`common_name_${index}`}
                          value={plant.common_name}
                          onChange={(e) =>
                            handleChange(index, "common_name", e.target.value)
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-12">
                      <div className="input-group">
                        <label
                          htmlFor={`biological_name_${index}`}
                          className="input-label"
                        >
                          {t("biological_name")}:
                        </label>
                        <input
                          className="plant-input"
                          type="text"
                          id={`biological_name_${index}`}
                          value={plant.biological_name}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "biological_name",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-12">
                      <div className="input-group">
                        <label
                          htmlFor={`count_${index}`}
                          className="input-label"
                        >
                          {t("number_of_sapling_planted")}:
                        </label>
                        <input
                          className="plant-input"
                          type="number"
                          id={`count_${index}`}
                          value={plant.count}
                          onChange={(e) =>
                            handleChange(index, "count", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    className="form-button"
                    type="button"
                    onClick={() => removePlant(index)}
                  >
                    <img src="./asset/images/delete.svg" />
                    {t("remove_plants")}
                  </button>
                </div>
              ))}

              <div className="mt-5">
                <button
                  className="form-button"
                  type="button"
                  onClick={addPlant}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18"
                    viewBox="0 0 17 18"
                    fill="none"
                  >
                    <path
                      d="M16.3359 9.69922H9.16406V16.8711C9.16406 17.2379 8.86676 17.5352 8.5 17.5352C8.13324 17.5352 7.83594 17.2379 7.83594 16.8711V9.69922H0.664062C0.297301 9.69922 0 9.40192 0 9.03516C0 8.66839 0.297301 8.37109 0.664062 8.37109H7.83594V1.19922C7.83594 0.832457 8.13324 0.535156 8.5 0.535156C8.86676 0.535156 9.16406 0.832457 9.16406 1.19922V8.37109H16.3359C16.7027 8.37109 17 8.66839 17 9.03516C17 9.40192 16.7027 9.69922 16.3359 9.69922Z"
                      fill="#A1713D"
                    />
                  </svg>
                  {t("add_plants")}
                </button>
                <button className="form-button" type="submit">
                  {t("submit")}
                </button>
              </div>
            </form>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AddPlants;
