import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import firebase from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Header from "../Components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import "./Home.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
import StateDistrictBlockGP from "../Components/StateDistrictBlockGP";

const Login = () => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [screen, setScreen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [locationType, setLocationtype] = useState();
  const [isOTPVerified, setOTPVerified] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [state_name, setStateName] = useState();
  const [district_name, setDistrictName] = useState();
  const [block_name, setBlockName] = useState();
  const [ulb_name, setULBName] = useState();
  const [ulbs, setUlbs] = useState([]);
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [panchayat_name, setPanchayatName] = useState();
  const [village_name, setVillageName] = useState();
  const [villages, setVillages] = useState([]);
  const [userRecord, setUserRecord] = useState({});
  const [exist, setExist] = useState(false);
  const [form, setForm] = useState(false);
  const location = useLocation();
  const [isEmpty, setIsEmpty] = useState(false);
  const [loginLoader, setLoginLoader] = useState(false);
  const [organisationType, setOrgType] = useState("");
  const [minsitryDep, setMinistryDep] = useState("");
  const [SubminsitryDep, setSubMinistryDep] = useState("");
  const [email, setEmail] = useState("");
  const [pin, setPinCode] = useState("");
  const [nodalOfficer, setNodalOfficerName] = useState("");
  const [instituteHead, setInstitueHead] = useState("");
  const [instName, setInstituteName] = useState("");
  const [StateGovDept, setStateGovDept] = useState("");
  const lastSegment = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  console.log(lastSegment);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSend = async (event) => {
    event.preventDefault();
    setLoading(true);

    // First Check if user exist on our database, if so then go for OTP verification
    let db = firebase.firestore().collection("ak_nyv_officers");
    db.where("phoneNumber", "==", phone)
      .where("type", "==", "institute")
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          db.where("phoneNumber", "==", phone)
            .get()
            .then((res) => {
              if (res?.size > 0) {
                alert("you are already registered as other user !");
                setLoading(false);
                return;
              } else {
                setForm(true);
                setIsEmpty(true);
                let db2 = firebase.firestore().collection("users");
                db2
                  .where("email", "==", phone + "@gmail.com")

                  .limit(1)
                  .get()
                  .then((users) => {
                    if (!users.empty) {
                      setExist(true);
                      setScreen(2);
                      setHasFilled(true);
                      setLoading(false);
                    } else {
                      axios
                        .post(
                          "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/sendOTP",
                          { phoneNumber: phone }
                        )
                        .then((res) => {
                          if (res.data.success == true) {
                            setScreen(1);
                            setHasFilled(true);
                            setLoading(false);
                          }
                        });
                    }
                  });
              }
            });
        } else {
          localStorage.setItem(
            "event_user",
            JSON.stringify({
              phoneNumber: phone ? phone : "",
              block: querySnapshot.docs[0]?.data()?.block_name,
              state: querySnapshot.docs[0]?.data()?.state,
              district: querySnapshot.docs[0]?.data()?.district,
              panchayat: querySnapshot.docs[0]?.data()?.local_body_name,
              name: nodalOfficer,
              name_of_ulb: querySnapshot.docs[0]?.data()?.ulb,
              loginType: "institute",
            })
          );
          setUserRecord({
            uid: querySnapshot.docs[0].id,
          });
          // localStorage.setItem(
          //   "event_user",
          //   JSON.stringify({
          //     users: {
          //       phoneNumber: phone,

          //       name: querySnapshot.docs[0].data().name
          //         ? querySnapshot.docs[0].data().name
          //         : "",
          //       institue_type: querySnapshot.docs[0].data().institue_type
          //         ? querySnapshot.docs[0].data().institue_type
          //         : "",
          //     },
          //   })
          // );

          localStorage.setItem(
            "usersMain",
            JSON.stringify({
              users: {
                phoneNumber: phone,

                name: querySnapshot.docs[0].data().name
                  ? querySnapshot.docs[0].data().name
                  : "",
                institue_type: querySnapshot.docs[0].data().institue_type
                  ? querySnapshot.docs[0].data().institue_type
                  : "",
              },
            })
          );

          let db2 = firebase.firestore().collection("users");
          db2
            .where("email", "==", phone + "@gmail.com")

            .limit(1)
            .get()
            .then((users) => {
              if (!users.empty) {
                setExist(true);
                setScreen(2);
                setHasFilled(true);
                setLoading(false);
              } else {
                axios
                  .post(
                    "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/sendOTP",
                    { phoneNumber: phone }
                  )
                  .then((res) => {
                    if (res.data.success == true) {
                      setScreen(1);
                      setHasFilled(true);
                      setLoading(false);
                    }
                  });
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);
  };

  const submitOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      // verifu otp

      axios
        .post(
          "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/verifyOTP",
          { phoneNumber: phone, enteredOTP: parseInt(otp) }
        )
        .then((results) => {
          if (results.data.success) {
            console.log(results.data.user);
            setUserRecord(results.data.user);
            setScreen(2);
            setLoading(false);
          }

          setOTPVerified(true);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          alert("User couldn't sign in (bad verification code?)");
          setOtp("");
          setLoading(false);
        });
    }
  };
  const handleLogin = async () => {
    setLoginLoader(true);
    if (password == (exist ? password : repassword)) {
      if (userRecord?.uid) {
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              if (form == false) {
                setTimeout(() => {
                  setLoginLoader(false);
                  if (isEmpty) {
                    setUserRecord({
                      uid: res?.user?.uid,
                    });
                    setScreen(2);
                  } else {
                    setUserRecord({
                      uid: res?.user?.uid,
                    });
                    localStorage.setItem(
                      "nyv_user",
                      JSON.stringify([{ phoneNumber: phone }])
                    );
                    if (form == false) {
                      localStorage.setItem(
                        "users",
                        JSON.stringify({
                          users: {
                            phoneNumber: phone,
                            uid: userRecord?.uid,
                            type: "institute",
                          },
                        })
                      );
                    }
                    window.location.href = "/nyv-report";
                  }
                }, 1500);
              } else {
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              let db = firebase.firestore().collection("users");
              db.doc(res?.user?.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((res) => {
                  if (form == false) {
                    setUserRecord({
                      uid: res?.user?.uid,
                    });
                    setTimeout(() => {
                      setLoginLoader(false);
                      window.location.href = "/nyv-report";
                    }, 1500);
                  } else {
                    setUserRecord({
                      uid: res?.user?.uid,
                    });
                    setScreen(3);
                  }
                });
            });
        }
      } else {
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              if (form == false) {
                localStorage.setItem(
                  "users",
                  JSON.stringify({
                    users: {
                      phoneNumber: phone,
                      uid: res?.user?.uid,
                      type: "institute",
                    },
                  })
                );

                setTimeout(() => {
                  setLoginLoader(false);
                  window.location.href = "/nyv-report";
                }, 1500);
              } else {
                setUserRecord({ uid: res?.user?.uid });
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              console.log("res", res);

              let db = firebase.firestore().collection("users");
              db.doc(res?.user?.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((result) => {
                  if (form == false) {
                    setLoginLoader(false);
                    setTimeout(() => {
                      window.location.href = "/organiser-profile";
                    }, 1500);
                  } else {
                    setScreen(3);
                  }
                });
            });
        }
      }
    } else {
      setLoginLoader(false);
      alert("Please enter correct password !");
    }
  };

  const instituteType = ["school", "Higher Education Institutions (HEIs)"];

  const HeiTypes = [
    "IITs",
    "IIMs",
    "IISERs",
    "NITs",
    "IIITs",
    "NITTTRs",
    "SPAs",
    "IISc",
    "Central University",
    "State University",
    "Deemed University",
    "Others",
  ];

  const orgType = [
    "Central Ministry/Department",
    "State Govt Departments",
    "Institution",
  ];
  const subDepartment = {
    "Central Armed Police Force": [
      "Indo Tibertan Border Police",
      "Assam Rifles",
      "Sashastra Seema Bal",
      "Central Reserve Police Force",
      "Central Industrial Security Force",
      "Border Security Force",
    ],
    "Ministry of Electronics and Information Technolgy": [
      "Common Service Centre",
    ],
    "Ministry of Youth Affairs": [
      "Department of Youth Affairs",
      "Department of Sports",
    ],
    "Department of Financial Services": ["Name of all PSU Banks"],
    "Ministry of Communications": ["Department of Post"],
  };
  const stateGovDept = [
    "District Administration",
    "District Panchayat/Parishad",
    "Department of Panchayati Raj",
    "Department of Rural Development",
    "Urban Local Bodies",
    "Gram Panchayat",
  ];
  const ministryType = [
    "Central Armed Police Force",
    "Ministry of Electronics and Information Technolgy",
    "Ministry of Youth Affairs",
    "Department of Financial Services",
    "Ministry of Communications",
  ];

  const [organiserName, setOrganiserName] = useState("");
  const [organiserType, setOrganiserType] = useState("");
  const [heiType, setHeiType] = useState("");
  const [locationOrg, setLocationOrg] = useState({
    state: "",
    district: "",
    block: "",
    gp: "",
  });

  useEffect(() => {
    firebase
      .firestore()
      .collection("state_lgd_mapping")
      .get()
      .then((res) => {
        let array = [];
        res?.docs?.forEach((e) => {
          array.push({
            ...e?.data(),
          });
        });

        setStates(array);
      });
  }, []);
  const updateLocation = (field, value) => {
    setLocationOrg((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (organiserType) {
      if (organiserType == "school") {
        setOrganiserName("Department of School Education");
      } else {
        setOrganiserName("Department of Higher Education");
      }
    }
  }, [organiserType]);

  useEffect(() => {
    if (state_name) {
      firebase
        .firestore()
        .collection("state_district_lgd_mapping")
        .where("state_code", "==", JSON.parse(state_name)?.state_code)
        .get()
        .then((res) => {
          let array = [];
          res?.docs?.forEach((e) => {
            array.push({
              ...e?.data(),
            });
          });

          setDistrict(array);
        });
    }
  }, [state_name]);

  useEffect(() => {
    if (district_name) {
      LGDSelector(
        "district_code",
        JSON.parse(district_name).district_code,
        setBlocks
      );

      firebase
        .firestore()
        .collection("ulb_lgd_mapping")
        .where("district_code", "==", JSON.parse(district_name)?.district_code)
        .get()
        .then((res) => {
          let array = [];
          res?.docs?.forEach((e) => {
            array.push({
              ...e?.data(),
            });
          });

          setUlbs(array);
        });
    }
  }, [district_name]);

  useEffect(() => {
    if (block_name) {
      LGDSelector(
        "block_code",
        JSON.parse(block_name).block_code,
        setPanchayats
      );
    }
  }, [block_name]);

  useEffect(() => {
    if (panchayat_name) {
      LGDSelector(
        "local_body_code",
        JSON.parse(panchayat_name).local_body_code,
        setVillages
      );
    }
  }, [panchayat_name]);

  function removeDuplicatesByKey(arr, key) {
    const uniqueValues = new Set();
    const deduplicatedArray = [];

    for (const obj of arr) {
      const keyValue = obj[key];

      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        deduplicatedArray.push(obj);
      }
    }

    // Sort the deduplicated array alphabetically based on the specified key
    deduplicatedArray.sort((a, b) => {
      const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const valueB = b[key].toUpperCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    return deduplicatedArray;
  }

  const LGDSelector = (keyToSearch, valueToSearch, setReturnData) => {
    console.log(keyToSearch, valueToSearch);
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("lgd_data_mapped"); // Replace with your Firestore collection name

    // Use Firestore queries to filter data based on key and value
    collectionRef
      .where(keyToSearch, "==", valueToSearch)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        console.log(keyToSearch.split("_")[0] + "_name");
        if (keyToSearch == "district_code") {
          setReturnData(removeDuplicatesByKey(data, "block_code"));
        }

        if (keyToSearch == "block_code") {
          setReturnData(removeDuplicatesByKey(data, "local_body_code"));
        }

        if (keyToSearch == "local_body_code") {
          setReturnData(removeDuplicatesByKey(data, "village_code"));
        }

        // setReturnData(data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  };

  const handleInstituteOrganiserLogin = (event) => {
    event.preventDefault();
    const stateObj = state_name ? JSON.parse(state_name) : {};
    const districtObj = district_name ? JSON.parse(district_name) : {};
    const blockObj = block_name ? JSON.parse(block_name) : {};
    const ulbObj = ulb_name ? JSON.parse(ulb_name) : {};
    const panchayatObj = panchayat_name ? JSON.parse(panchayat_name) : {};
    const villageObj = village_name ? JSON.parse(village_name) : {};

    let obj = {
      ...stateObj,
      ...districtObj,
      ...ulbObj,
      ...blockObj,
      ...panchayatObj,
      ...villageObj,
      pin_code: pin,
      email: email,
      name: nodalOfficer || instName,
      institute_head: instituteHead,
      organization_name: organisationType,
      ministry_dept: minsitryDep,
      sub_ministry_dept: SubminsitryDep,
      state_gov_dept: StateGovDept,
      institute_type: organiserType,
      institute_dep: organiserName,
      hei_type: heiType,
      phoneNumber: phone,
      type: "institute",
    };

    firebase
      .firestore()
      .collection("ak_nyv_officers")
      .doc(userRecord.uid)
      .set(obj)
      .then((response) => {
        localStorage.setItem(
          "users",
          JSON.stringify({
            users: {
              phoneNumber: phone,
              uid: userRecord?.uid,
              type: "institute",
            },
          })
        );
        localStorage.setItem(
          "event_user",
          JSON.stringify({
            phoneNumber: phone ? phone : "",
            block: blockObj?.block_name,
            state: stateObj?.state,
            district: districtObj?.district,
            panchayat: panchayatObj?.local_body_name,
            name: nodalOfficer,
            loginType: "institute",
            name_of_ulb: ulbObj?.ulb,
          })
        );
        localStorage.setItem(
          "nyv_user",
          JSON.stringify([{ phoneNumber: phone }])
        );
        window.location.href = "/nyv-report";
      })
      .catch((error) => {
        // Handle any errors that occur during the operation
        console.error("Error adding data: ", error);
      });
  };

  if (!hasFilled) {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        <div
          className="container d-flex justify-content-center text-center align-items-center"
          style={{ height: "70vh" }}
        >
          <div className="app__container">
            <div style={{ width: "300px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ padding: "20px" }} variant="h5" component="div">
                  {t("enter_your_number")}
                </h3>
                <form onSubmit={handleSend}>
                  <input
                    className="login-input"
                    style={{ width: "240px" }}
                    autoComplete="off"
                    label="Phone Number"
                    value={phone}
                    maxLength={13}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                  <button
                    disabled={loading}
                    style={{ opacity: loading ? "0.6" : "1" }}
                    className="pledge_btn mt-5"
                    type="submit"
                  >
                    {loading ? t("pleasewait") : t("send_code")}
                  </button>
                  <br />
                  <br />
                  {loading && (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  )}
                </form>
              </div>
            </div>
            <div id="recaptcha"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        {screen == 1 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                {!isOTPVerified && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("enter_otp")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "100px" }}
                        label="OTP "
                        value={otp}
                        onChange={verifyOtp}
                        maxLength={6}
                      />
                    </div>
                    <br />
                    <button className="pledge_btn" onClick={submitOtp}>
                      {t("submit")}
                    </button>
                    <br />
                    <a
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                      href="#"
                    >
                      {t("change_mobile_number")}
                    </a>
                  </>
                )}

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 2 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{ padding: "20px" }}
                      variant="h5"
                      component="div"
                    >
                      {t("password")}
                    </h3>
                    <input
                      className="login-input"
                      style={{ width: "auto", height: "40px" }}
                      label="password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  {!exist && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("repassword")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "auto", height: "40px" }}
                        label="password"
                        type="password"
                        value={repassword}
                        onChange={(e) => {
                          setRePassword(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <br />
                  <button
                    className="pledge_btn"
                    style={{ opacity: loginLoader ? "0.6" : "1" }}
                    onClick={() => {
                      handleLogin();
                    }}
                    disabled={loginLoader}
                  >
                    {loginLoader ? t("pleasewait") : t("submit")}
                  </button>
                  <br />
                  <br />
                  <Link style={{ fontWeight: "12px" }} to="/forget-password">
                    {t("forgot_password")} ?
                  </Link>
                </>

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 3 ? (
          <form
            onSubmit={(e) => {
              handleInstituteOrganiserLogin(e);
            }}
            style={{ marginTop: "7rem" }}
          >
            <div className="container">
              <div className={organisationType ? "row" : "text-center"}>
                <div className={organisationType ? "col-md-3" : ""}>
                  <h6>Select Organisation * :</h6>
                  <select
                    className="select-input"
                    name="organisation_type"
                    style={{ width: organisationType ? "100%" : "200px" }}
                    onChange={(e) => {
                      setOrgType(e.target.value);
                    }}
                    value={organisationType}
                    required
                  >
                    <option value="">Select Organization</option>
                    {orgType.map((institute) => (
                      <option key={institute} value={institute}>
                        {institute}
                      </option>
                    ))}
                  </select>
                </div>
                {organisationType === "Central Ministry/Department" && (
                  <>
                    <div className="col-md-3">
                      <h6>Select Ministry/Dept * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="ministry_dept"
                        onChange={(e) => {
                          setMinistryDep(e.target.value);
                        }}
                        value={minsitryDep}
                        required
                      >
                        <option value="">Select Options</option>
                        {ministryType.map((institute) => (
                          <option key={institute} value={institute}>
                            {institute}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="col-md-3">
                      <h6>Select Sub Ministry/Dept * :</h6>
                      <select
                        className="select-input"
                        name="sub_ministry_dept"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setSubMinistryDep(e.target.value);
                        }}
                        value={SubminsitryDep}
                        required
                      >
                        <option value="">Select Options</option>
                        {subDepartment?.[minsitryDep]?.map((institute) => (
                          <option key={institute} value={institute}>
                            {institute}
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                )}
                {organisationType === "State Govt Departments" && (
                  <div className="col-md-3">
                    <h6>Select State/Dept * :</h6>
                    <select
                      className="select-input"
                      style={{ width: "100%" }}
                      name="ministry_dept"
                      onChange={(e) => {
                        setStateGovDept(e.target.value);
                      }}
                      value={StateGovDept}
                      required
                    >
                      <option value="">Select Options</option>
                      {stateGovDept.map((institute) => (
                        <option key={institute} value={institute}>
                          {institute}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {organisationType === "Institution" && (
                  <div className="col-md-3">
                    <h6>Select Institution * :</h6>
                    <select
                      className="select-input"
                      style={{ width: "100%" }}
                      name="ministry_dept"
                      onChange={(e) => {
                        setOrganiserType(e.target.value);
                      }}
                      value={organiserType}
                      required
                    >
                      <option value="">Select Options</option>
                      {instituteType.map((institute) => (
                        <option key={institute} value={institute}>
                          {institute}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {organisationType === "Institution" &&
                  organiserType !== "school" &&
                  organiserType !== "" && (
                    <div className="col-md-3">
                      <h6>Select HEI TYPE * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="HEI"
                        onChange={(e) => {
                          setHeiType(e.target.value);
                        }}
                        value={heiType}
                        required
                      >
                        <option value="">Select Options</option>
                        {HeiTypes.map((institute) => (
                          <option key={institute} value={institute}>
                            {institute}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
              </div>
              <br />
              {organisationType === "Institution" && (
                <>
                  <div className="row">
                    <div className="col-md-3">
                      <h6>Select Type</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setLocationtype(e.target.value);
                        }}
                        value={locationType}
                        required
                      >
                        <option value="">Select Options</option>
                        {["RURAL", "URBAN"].map((institute) => (
                          <option key={institute} value={institute}>
                            {institute}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <h6>Select State * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setStateName(e.target.value);
                        }}
                        value={state_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {states.map((institute) => (
                          <option
                            key={institute.state_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <h6>Select District * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setDistrictName(e.target.value);
                        }}
                        value={district_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {districts.map((institute) => (
                          <option
                            key={institute.district_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.district}
                          </option>
                        ))}
                      </select>
                    </div>
                    {locationType == "RURAL" && (
                      <div className="col-md-3">
                        <h6>Select Block * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="state"
                          onChange={(e) => {
                            setBlockName(e.target.value);
                          }}
                          value={block_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {blocks.map((institute) => (
                            <option
                              key={institute.block_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.block_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {locationType == "RURAL" && (
                      <div className="col-md-3">
                        <h6>Select Panchayat * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setPanchayatName(e.target.value);
                          }}
                          value={panchayat_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {panchayats.map((institute) => (
                            <option
                              key={institute.local_body_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.local_body_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {locationType == "URBAN" && (
                      <div className="col-md-3">
                        <h6>Select ULB * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="state"
                          onChange={(e) => {
                            setULBName(e.target.value);
                          }}
                          value={ulb_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {ulbs.map((institute) => (
                            <option
                              key={institute.ulb_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.ulb}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    {locationType == "RURAL" && (
                      <div className="col-md-3">
                        <h6>Select Village * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setVillageName(e.target.value);
                          }}
                          value={village_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {villages.map((institute) => (
                            <option
                              key={institute.village_name}
                              value={JSON.stringify(institute)}
                            >
                              {institute.village_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    <div className="col-md-3">
                      <h6> Pin Code * :</h6>
                      <input
                        required
                        type="number"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPinCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <h6>Name of Institution * :</h6>
                      <input
                        required
                        type="text"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInstituteName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <h6>Name of Principal/Head of Institution/VC * :</h6>
                      <input
                        required
                        type="text"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setInstitueHead(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <h6>Email :</h6>
                      <input
                        type="email"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {organisationType === "Central Ministry/Department" && (
                <>
                  <div className="row"></div>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <h6>Select State * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setStateName(e.target.value);
                        }}
                        value={state_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {states.map((institute) => (
                          <option
                            key={institute.state_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <h6>Select District * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setDistrictName(e.target.value);
                        }}
                        value={district_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {districts.map((institute) => (
                          <option
                            key={institute.district_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.district}
                          </option>
                        ))}
                      </select>
                    </div>

                    {[
                      "Central Armed Police Force",
                      "Ministry of Electronics and Information Technolgy",
                      "Department of Financial Services",
                      "Ministry of Communications",
                    ].includes(minsitryDep) && (
                      <div className="col-md-3">
                        <h6>Select Block * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="state"
                          onChange={(e) => {
                            setBlockName(e.target.value);
                          }}
                          value={block_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {blocks.map((institute) => (
                            <option
                              key={institute.block_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.block_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {minsitryDep ==
                      "Ministry of Electronics and Information Technolgy" && (
                      <div className="col-md-3">
                        <h6>Select Panchayat * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setPanchayatName(e.target.value);
                          }}
                          value={panchayat_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {panchayats.map((institute) => (
                            <option
                              key={institute.local_body_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.local_body_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    {minsitryDep ==
                      "Ministry of Electronics and Information Technolgy" && (
                      <div className="col-md-3">
                        <h6>Select Village * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setVillageName(e.target.value);
                          }}
                          value={village_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {villages.map((institute) => (
                            <option
                              key={institute.village_name}
                              value={JSON.stringify(institute)}
                            >
                              {institute.village_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    <div className="col-md-3">
                      <h6> Pin Code * :</h6>
                      <input
                        required
                        type="number"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPinCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <h6>Name of Nodal Officer * :</h6>
                      <input
                        required
                        type="text"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setNodalOfficerName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <h6>Email :</h6>
                      <input
                        type="email"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}

              {organisationType === "State Govt Departments" && (
                <>
                  <div className="row"></div>
                  <br />
                  <div className="row">
                    <div className="col-md-3">
                      <h6>Select State * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setStateName(e.target.value);
                        }}
                        value={state_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {states.map((institute) => (
                          <option
                            key={institute.state_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.state}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <h6>Select District * :</h6>
                      <select
                        className="select-input"
                        style={{ width: "100%" }}
                        name="state"
                        onChange={(e) => {
                          setDistrictName(e.target.value);
                        }}
                        value={district_name}
                        required
                      >
                        <option value="">Select Options</option>
                        {districts.map((institute) => (
                          <option
                            key={institute.district_code}
                            value={JSON.stringify(institute)}
                          >
                            {institute.district}
                          </option>
                        ))}
                      </select>
                    </div>

                    {[
                      "Department of Panchayati Raj",
                      "Department of Rural Development",
                      "Gram Panchayat",
                    ].includes(StateGovDept) && (
                      <div className="col-md-3">
                        <h6>Select Block * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="state"
                          onChange={(e) => {
                            setBlockName(e.target.value);
                          }}
                          value={block_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {blocks.map((institute) => (
                            <option
                              key={institute.block_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.block_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {StateGovDept == "Gram Panchayat" && (
                      <div className="col-md-3">
                        <h6>Select Panchayat * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setPanchayatName(e.target.value);
                          }}
                          value={panchayat_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {panchayats.map((institute) => (
                            <option
                              key={institute.local_body_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.local_body_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {StateGovDept == "Urban Local Bodies" && (
                      <div className="col-md-3">
                        <h6>Select ULB * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="state"
                          onChange={(e) => {
                            setULBName(e.target.value);
                          }}
                          value={ulb_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {ulbs.map((institute) => (
                            <option
                              key={institute.ulb_code}
                              value={JSON.stringify(institute)}
                            >
                              {institute.ulb}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </div>
                  <br />
                  <div className="row">
                    {StateGovDept == "Gram Panchayat" && (
                      <div className="col-md-3">
                        <h6>Select Village * :</h6>
                        <select
                          className="select-input"
                          style={{ width: "100%" }}
                          name="panachayat"
                          onChange={(e) => {
                            setVillageName(e.target.value);
                          }}
                          value={village_name}
                          required
                        >
                          <option value="">Select Options</option>
                          {villages.map((institute) => (
                            <option
                              key={institute.village_name}
                              value={JSON.stringify(institute)}
                            >
                              {institute.village_name}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    <div className="col-md-3">
                      <h6> Pin Code * :</h6>
                      <input
                        required
                        type="number"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setPinCode(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <h6>Name of Nodal Officer * :</h6>
                      <input
                        required
                        type="text"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setNodalOfficerName(e.target.value);
                        }}
                      />
                    </div>

                    <div className="col-md-3">
                      <h6>Email :</h6>
                      <input
                        type="email"
                        className="select-input"
                        style={{ width: "100%" }}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div style={{ textAlign: "center", marginTop: "4rem" }}>
              <button
                // disabled={loginLoader}
                // style={{ opacity: loginLoader ? "0.5" : "1" }}
                className="pledge_btn"
              >
                Enter
              </button>
            </div>
          </form>
        ) : null}
      </div>
    );
  }
};

export default Login;
