import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import firebase from "../../firebase";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

export default function Pledge() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const history = useHistory();
  const [formInput, setFormInput] = useState({
    name: "",
    state: "",
    district: "",
    block: "",
    gp: "",
    time: "",
    mobile: "",
  });

  const [selectedState, setSelectedState] = useState("");
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [states, setStates] = useState([]);
  const [dataMap, setDataMap] = useState({});
  const screenSize = window.innerWidth;

  function getDistrictsForState(stateName) {
    return dataMap[stateName] || [];
  }

  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name);
        });
        array.sort();
        setStates(array);
      });
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "mobile") {
      //only allow numbers
      if (!/^[0-9\b]+$/.test(value) && value !== "") {
        console.log(formInput?.mobile);
        console.log("not a number");
        return;
      }
    }
    console.log(name);
    setFormInput((prev) => ({ ...prev, [name]: value }));

    if (name === "state") {
      setSelectedState(value);
      const districts = getDistrictsForState(value);
      districts.sort();
      setFilteredDistricts(districts);
    }
  };

  useEffect(() => {
    let db = firebase.firestore();
    db.collection("state_district_mapping")
      .get()
      .then((querySnapshot) => {
        const newDataMap = {};
        querySnapshot.forEach((doc) => {
          const { state_name, district_mapping } = doc.data();
          if (!newDataMap[state_name]) {
            newDataMap[state_name] = [district_mapping];
          } else {
            newDataMap[state_name].push(district_mapping);
          }
        });
        setDataMap(newDataMap);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }, []);

  useEffect(() => {
    // console.log("dataMap", dataMap);
  }, [dataMap]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // if (!formInput?.name) {
    //   setNameError(true);
    //   return;
    // }

    if (!formInput?.name || !formInput?.state || !formInput?.district) {
      // Show toast message when required fields are not filled
      toast.error(t("fill_all_required_fields"));
      return;
    }

    if (formInput?.name !== "") {
      // Check if Mofbile length is correct
      if (formInput?.mobile.length < 10) {
        toast.error(t("mobile_number_incorrect"));
        return;
      }
      setLoading(true);
      console.log(formInput);
      try {
        // Get the current timestamp
        const timestamp = Date.now();

        // Add the timestamp to the form input
        const formInputWithTimestamp = { ...formInput, time: timestamp };
        let db = firebase.firestore();
        // Write the form input to Firestore
        const result = await db
          .collection("pledges")
          .add(formInputWithTimestamp);
        console.log("result", result.id);
        localStorage.setItem("pledge_id", result.id);
        localStorage.setItem("pledge_name", formInput?.name);
        history.push("/upload");
        // setUserLocation(formInput?.state);
        // Clear the form inputs
        // setFormInput({
        //   name: "",
        //   state: "",
        //   // district: "",
        //   braveheart: "",
        //   time: "",
        // });
        // setSubmitbtn("Tribute Paid");
        // console.log("Form submitted successfully!");

        // setShowForm(false);
        // setShowMap(true);
      } catch (error) {
        // setSubmitbtn("Please Try Again");
        console.error("Error submitting form:", error);
        setLoading(false);
      }
    } else {
      setNameError(false);
    }
  };
  return (
    <div className="main_body">
      <Header />
      <div className="container mt-5">
        <div>
          <form onSubmit={handleSubmit} className="form-fields">
            <div className="row">
              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-${
                  screenSize > 970 ? "end" : "center"
                } text-center`}
              >
                <div className="justify-content-end text-center">
                  <label className="mx-1">
                    <div className="form_input_title d-flex justify-content-center">
                      {t("enter_your_name")}*
                    </div>
                    <input
                      className="name-input"
                      tabIndex="1"
                      name="name"
                      placeholder={t("enter_your_name")}
                      onChange={handleInputChange}
                      required
                      maxLength={50}
                    />
                  </label>
                  {nameError && (
                    <div className="error-message pb-3">
                      {t("please_enter_your_name")}
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-${
                  screenSize > 970 ? "start" : "center"
                } text-center`}
              >
                <label className="mx-1">
                  <div className="form_input_title d-flex justify-content-center">
                    {t("enter_your_number")}*
                  </div>
                  <input
                    className="name-input"
                    tabIndex="1"
                    name="mobile"
                    placeholder={t("enter_your_number")}
                    onChange={handleInputChange}
                    maxLength={10}
                    value={formInput?.mobile}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="row">
              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-${
                  screenSize > 970 ? "end" : "center"
                } text-center`}
              >
                <label className="mx-1 ">
                  <div className="form_input_title d-flex justify-content-center">
                    {" "}
                    {t("state")}*
                  </div>

                  <select
                    className="select-input"
                    tabIndex="2"
                    name="state"
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">{t("select_state")}</option>
                    {states?.sort()?.map((state) => (
                      <option key={state} value={state}>
                        {state.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div
                className={`col-lg-6 col-sm-12 d-flex justify-content-${
                  screenSize > 970 ? "start" : "center"
                } text-center`}
              >
                <label className="mx-1">
                  <div className="form_input_title d-flex justify-content-center">
                    {" "}
                    {t("district")}*
                  </div>

                  <select
                    className="select-input"
                    name="district"
                    onChange={handleInputChange}
                    disabled={!selectedState}
                    required
                  >
                    <option value=""> {t("select_district")}</option>
                    {filteredDistricts?.sort()?.map((district) => (
                      <option key={district} value={district}>
                        {district.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>

            <div className="pt-3">
              <p className="quote">{t("pledge_line_1")}</p>
              <p className="quote">{t("pledge_line_2")}</p>
              <p className="quote">{t("pledge_line_3")}</p>
              <p className="quote">{t("pledge_line_4")}</p>
              <p className="quote">{t("pledge_line_5")}</p>
              <p className="quote">{t("pledge_line_6")}</p>
            </div>

            <div className="d-flex justify-content-center py-3">
              {/* <Link to="/upload">
                <button className="pledge_btn" type="button">
                  Next
                </button>
              </Link> */}

              {loading ? (
                <div className="d-flex justify-content-center py-3">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <button
                  className="pledge_btn"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {t("submit")}
                </button>
              )}
              <br />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
