import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Popup from "../Components/Popup/Popup";
import AWS from "aws-sdk";
import firebase from "../../firebase";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleUpload } from "../../services/upload";

export default function UploadForOrganiser() {
  const { t } = useTranslation();
  const history2 = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const [category, setCategory] = useState();
  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        console.log("user", user);
      } else {
        // No user is signed in.
        console.log("no user");
        history2.push("/login");
      }
    });
  }, []);

  let history = useHistory();
  const [upload, setUpload] = useState(null);
  const [file, setFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const myParam = queryParams.get("category"); // Replace 'myParam' with the actual query parameter name you are looking for
    setCategory(myParam);
  }, [location.search]);
  const fileUpload = async () => {
    if (file != null) {
      let maxSize = 10 * 1024 * 1024;

      //If file size is image allow up to 10 MB
      if (file[0].type.startsWith("image/")) {
        maxSize = 10 * 1024 * 1024; // in bytes
      }

      //If file size is video allow up to 300 MB
      if (file[0].type.startsWith("video/")) {
        maxSize = 300 * 1024 * 1024; // in bytes
      }

      if (file[0].size > maxSize) {
        setUploadingMessage(
          t("file_is_too_large_please_select_a_file_smaller_than_10mb")
        );
        setTimeout(() => {
          setUploadingMessage("");
        }, 2000);
        return;
      }

      if (
        file[0].type.startsWith("image/") ||
        file[0].type.startsWith("video/")
      ) {
        // allow
      } else {
        console.log("file", file[0].type);
        setUploadingMessage(
          t("incorrect_file_type_please_select_an_image_file")
        );
        setTimeout(() => {
          setUploadingMessage("");
        }, 2000);
        return;
      }

      if (!file[0].type.startsWith("image/"))
        if (
          file[0].type.startsWith("image/") ||
          file[0].type.startsWith("video/")
        ) {
          // Check the file type (must be an image)
        }

      setUpload("Uploading...");
      setUploadingMessage(t("uploading"));
      const fileName = `${Date.now()}.${file[0].name.split(".").pop()}`;
      const contentType = file[0].type.startsWith("video")
        ? "video/mp4"
        : "image/*";
      const params = {
        Bucket: "static.mmmd.gov.in",
        Key: file[0].type.startsWith("image")
          ? `organiser_images/${fileName}`
          : `organiser_videos/${fileName}`,
        Body: file[0],
        ContentType: contentType,
      };

      await handleUpload(params);
      const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;
      let db = firebase.firestore().collection("event_new");
      let dbMedia = firebase.firestore().collection("event_media");
      const org_user = JSON.parse(localStorage.getItem("event_user"))
        ? JSON.parse(localStorage.getItem("event_user"))
        : JSON.parse(localStorage.getItem("usersMain"))?.users
        ? JSON.parse(localStorage.getItem("usersMain"))?.users
        : JSON.parse(localStorage.getItem("users"))?.users
        ? JSON.parse(localStorage.getItem("users"))?.users
        : {};
      if (org_user.name_of_ulb) {
        org_user.state = org_user?.state ? org_user?.state.toUpperCase() : "";
        org_user.name_of_ulb = org_user?.name_of_ulb
          ? org_user?.name_of_ulb.toUpperCase()
          : "";

        db.where(
          "state",
          "==",
          org_user?.state ? org_user?.state.toUpperCase() : ""
        )
          .where(
            "name_of_ulb",
            "==",
            org_user?.name_of_ulb ? org_user?.name_of_ulb.toUpperCase() : ""
          )

          .limit(1)
          .get()
          .then((res) => {
            if (res.size == 0) {
              db.add(
                {
                  created_by: org_user ? "organiser" : "institute",
                  ...org_user,
                  timestamp: new Date(),
                  event_date: "",
                },
                { merge: true }
              ).then((result) => {
                dbMedia
                  .add(
                    {
                      created_by: org_user ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      id: result.id,
                      moderated: false,
                      category: category ? category : "",
                      media_name: fileName ? fileName : "",
                      fileType: file[0]?.type.startsWith("image/")
                        ? "image"
                        : "video",
                    },
                    { merge: true }
                  )
                  .then((res2) => {
                    setFile(null);
                    setUpload("Uploaded and sent for moderation.");
                    setUploadingMessage(t("upload_and_sent_for_moderation"));
                    toast.success(t("upload_and_sent_for_moderation"));
                    setTimeout(() => {
                      setUpload(null);
                      setUploadingMessage("");
                      setUploadPercentage("");
                    }, 2000);
                  });
              });
            } else {
              if (org_user.state) {
                dbMedia
                  .add(
                    {
                      created_by: org_user ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      id: res.docs[0]?.id,
                      moderated: false,
                      category: category ? category : "",
                      media_name: fileName ? fileName : "",
                      fileType: file[0]?.type.startsWith("image/")
                        ? "image"
                        : "video",
                    },
                    { merge: true }
                  )

                  .then((res) => {
                    setFile(null);
                    setUpload("Uploaded and sent for moderation.");
                    toast.success(t("upload_and_sent_for_moderation"));
                    setUploadingMessage(t("upload_and_sent_for_moderation"));
                    setTimeout(() => {
                      setUpload(null);
                      setUploadingMessage("");
                      setUploadPercentage("");
                    }, 2000);
                  });
              } else {
                console.log(org_user);
                db.where("created_by", "==", "institute")
                  .where("phoneNumber", "==", org_user.phoneNumber)
                  .limit(1)
                  .get()
                  .then((res) => {
                    if (res.size == 0) {
                      db.add({
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...org_user,
                        timestamp: new Date(),
                        event_date: "",
                      })
                        .then((res2) => {
                          dbMedia
                            .add(
                              {
                                created_by: org_user
                                  ? "organiser"
                                  : "institute",
                                ...org_user,
                                timestamp: new Date(),
                                id: res2.id,
                                moderated: false,
                                category: category ? category : "",
                                media_name: fileName ? fileName : "",
                                fileType: file[0]?.type.startsWith("image/")
                                  ? "image"
                                  : "video",
                              },
                              { merge: true }
                            )

                            .then((res) => {
                              setFile(null);
                              setUpload("Uploaded and sent for moderation.");
                              toast.success(
                                t("upload_and_sent_for_moderation")
                              );
                              setUploadingMessage(
                                t("upload_and_sent_for_moderation")
                              );
                              setTimeout(() => {
                                setUpload(null);
                                setUploadingMessage("");
                                setUploadPercentage("");
                              }, 2000);
                            });
                        })
                        .catch((error) => {
                          // The document probably doesn't exist.
                          console.error("Error updating document: ", error);
                          toast.error("Error updating document: ");
                        });
                    } else {
                      dbMedia
                        .add(
                          {
                            created_by: org_user ? "organiser" : "institute",
                            ...org_user,
                            timestamp: new Date(),
                            id: res.docs[0]?.id,
                            moderated: false,
                            category: category ? category : "",
                            media_name: fileName ? fileName : "",
                            fileType: file[0]?.type.startsWith("image/")
                              ? "image"
                              : "video",
                          },
                          { merge: true }
                        )

                        .then((res) => {
                          setFile(null);
                          setUpload("Uploaded and sent for moderation.");
                          toast.success(t("upload_and_sent_for_moderation"));
                          setUploadingMessage(
                            t("upload_and_sent_for_moderation")
                          );
                          setTimeout(() => {
                            setUpload(null);
                            setUploadingMessage("");
                            setUploadPercentage("");
                          }, 2000);
                        });
                    }
                  });
              }
            }
          });
      } else {
        org_user.state = org_user?.state ? org_user?.state.toUpperCase() : "";
        org_user.district = org_user?.district
          ? org_user?.district.toUpperCase()
          : "";
        org_user.panchayat = org_user?.panchayat
          ? org_user?.panchayat?.toUpperCase()
          : "";
        org_user.block = org_user?.block ? org_user?.block?.toUpperCase() : "";
        db.where(
          "state",
          "==",
          org_user?.state ? org_user?.state.toUpperCase() : ""
        )
          .where(
            "district",
            "==",
            org_user?.district ? org_user?.district.toUpperCase() : ""
          )
          .where(
            "panchayat",
            "==",
            org_user?.panchayat ? org_user?.panchayat?.toUpperCase() : ""
          )
          .where(
            "block",
            "==",
            org_user?.block ? org_user?.block?.toUpperCase() : ""
          )
          .limit(1)
          .get()
          .then((res) => {
            if (res.size == 0) {
              db.add(
                {
                  created_by: org_user ? "organiser" : "institute",
                  ...org_user,
                  timestamp: new Date(),
                  event_date: "",
                },
                { merge: true }
              ).then((result) => {
                dbMedia
                  .add(
                    {
                      created_by: org_user ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      id: result.id,
                      moderated: false,
                      category: category ? category : "",
                      media_name: fileName ? fileName : "",
                      fileType: file[0]?.type.startsWith("image/")
                        ? "image"
                        : "video",
                    },
                    { merge: true }
                  )
                  .then((res2) => {
                    setFile(null);
                    setUpload("Uploaded and sent for moderation.");
                    setUploadingMessage(t("upload_and_sent_for_moderation"));
                    toast.success(t("upload_and_sent_for_moderation"));
                    setTimeout(() => {
                      setUpload(null);
                      setUploadingMessage("");
                      setUploadPercentage("");
                    }, 2000);
                  });
              });
            } else {
              if (org_user.state) {
                dbMedia
                  .add(
                    {
                      created_by: org_user ? "organiser" : "institute",
                      ...org_user,
                      timestamp: new Date(),
                      id: res.docs[0]?.id,
                      moderated: false,
                      category: category ? category : "",
                      media_name: fileName ? fileName : "",
                      fileType: file[0]?.type.startsWith("image/")
                        ? "image"
                        : "video",
                    },
                    { merge: true }
                  )

                  .then((res) => {
                    setFile(null);
                    setUpload("Uploaded and sent for moderation.");
                    toast.success(t("upload_and_sent_for_moderation"));
                    setUploadingMessage(t("upload_and_sent_for_moderation"));
                    setTimeout(() => {
                      setUpload(null);
                      setUploadingMessage("");
                      setUploadPercentage("");
                    }, 2000);
                  });
              } else {
                console.log(org_user);
                db.where("created_by", "==", "institute")
                  .where("phoneNumber", "==", org_user.phoneNumber)
                  .limit(1)
                  .get()
                  .then((res) => {
                    if (res.size == 0) {
                      db.add({
                        created_by: org_user?.state ? "organiser" : "institute",
                        ...org_user,
                        timestamp: new Date(),
                        event_date: "",
                      })
                        .then((res2) => {
                          dbMedia
                            .add(
                              {
                                created_by: org_user
                                  ? "organiser"
                                  : "institute",
                                ...org_user,
                                timestamp: new Date(),
                                id: res2.id,
                                moderated: false,
                                category: category ? category : "",
                                media_name: fileName ? fileName : "",
                                fileType: file[0]?.type.startsWith("image/")
                                  ? "image"
                                  : "video",
                              },
                              { merge: true }
                            )

                            .then((res) => {
                              setFile(null);
                              setUpload("Uploaded and sent for moderation.");
                              toast.success(
                                t("upload_and_sent_for_moderation")
                              );
                              setUploadingMessage(
                                t("upload_and_sent_for_moderation")
                              );
                              setTimeout(() => {
                                setUpload(null);
                                setUploadingMessage("");
                                setUploadPercentage("");
                              }, 2000);
                            });
                        })
                        .catch((error) => {
                          // The document probably doesn't exist.
                          console.error("Error updating document: ", error);
                          toast.error("Error updating document: ");
                        });
                    } else {
                      dbMedia
                        .add(
                          {
                            created_by: org_user ? "organiser" : "institute",
                            ...org_user,
                            timestamp: new Date(),
                            id: res.docs[0]?.id,
                            moderated: false,
                            category: category ? category : "",
                            media_name: fileName ? fileName : "",
                            fileType: file[0]?.type.startsWith("image/")
                              ? "image"
                              : "video",
                          },
                          { merge: true }
                        )

                        .then((res) => {
                          setFile(null);
                          setUpload("Uploaded and sent for moderation.");
                          toast.success(t("upload_and_sent_for_moderation"));
                          setUploadingMessage(
                            t("upload_and_sent_for_moderation")
                          );
                          setTimeout(() => {
                            setUpload(null);
                            setUploadingMessage("");
                            setUploadPercentage("");
                          }, 2000);
                        });
                    }
                  });
              }
            }
          });
      }

      setUploadPercentage("100" + "%");
    } else {
      setUpload("Select an image/video to upload.");
      setTimeout(() => {
        setUpload(null);
      }, 2000);
    }
  };
  useEffect(() => {
    if (upload === "Uploaded and sent for moderation.") {
      console.log("upload", upload);
      setTimeout(() => {
        setShowPopup(true);
        // history.push("/");
      }, 2000);
    }
  }, [upload]);
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="container" style={{ marginTop: "70px" }}>
        <div className="d-flex justify-content-center align-items-center">
          <p className="step_title py-2">
            {t("upload_picture_of_your_activity")}
          </p>
        </div>

        <div className="d-flex justify-content-center">
          <div className="img_box">
            <div className="">
              <img src="./asset/images/upload.svg" style={{ height: "6vh" }} />
              <div className="my-5">
                <input
                  type="file"
                  id="file"
                  accept="image/*,video/*"
                  onChange={(e) => {
                    setFile(e.target.files);
                  }}
                  style={{
                    display: "none", // Hide the default input appearance
                  }}
                />
                {file && <p>{file[0].name}</p>}
                <label htmlFor="file" className="custom-input-button">
                  {t("choose_file")}
                </label>
              </div>
              {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <button
            className="pledge_btn mt-4"
            onClick={() => {
              fileUpload();
            }}
          >
            {t("submit")}
          </button>
        </div>

        {/* {upload && (
          <div className="d-flex justify-content-center align-items-center">
            <p className="step_title py-2">{upload}</p>
          </div>
        )} */}
        {uploadingMessage && (
          <div className="d-flex justify-content-center align-items-center">
            <p className="step_title py-2">{uploadingMessage}</p>
          </div>
        )}
        {uploadPercentage && (
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: uploadPercentage }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {uploadPercentage}
            </div>
          </div>
        )}

        {/* {showPopup && <Certificate name="Shivangi" />} */}
      </div>
    </div>
  );
}

//  const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/${media}`;
//const response = await axios.get(url);
//const data = response?.data?.data;
