import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ onDatesSelected, minDate, maxDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    if (date) {
      date.setHours(0, 0, 0, 0); // Set time to midnight
    }
    setStartDate(date);
    onDatesSelected({ startDate: date, endDate });
    console.log("date", date);
  };

  const handleEndDateChange = (date) => {
    if (date) {
      date.setHours(0, 0, 0, 0); // Set time to midnight
    }
    setEndDate(date);
    onDatesSelected({ startDate, endDate: date });
    console.log("date", date);
  };

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
    onDatesSelected({ startDate: null, endDate: null });
  };

  return (
    <div className="row d-flex justify-content-center">
      <div className="col-3">
        <label className="px-2">
          <h6 style={{ fontSize: "13px" }}>Start Date:</h6>
        </label>
        <DatePicker
          className="date_btn"
          selected={startDate}
          onChange={handleStartDateChange}
          minDate={minDate}
          maxDate={endDate ? new Date(endDate) : maxDate}
        />
      </div>
      <div className="col-3">
        <label className="px-2">
          <h6 style={{ fontSize: "13px" }}>End Date:</h6>
        </label>
        <DatePicker
          className="date_btn"
          selected={endDate}
          onChange={handleEndDateChange}
          minDate={startDate ? new Date(startDate) : minDate}
          maxDate={maxDate}
        />
      </div>
      <div className="row d-flex justify-content-center py-3">
        <button className="clear_btn" onClick={handleClearDates}>
          Clear Dates
        </button>
      </div>
    </div>
  );
};

export default DateRangePicker;

//phase2 - 1 sept - onwards
//phase1 - 5 - 31 aug
