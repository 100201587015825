import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import "./Report.css";
import "./Home.css";
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Report from "./Report";
import { Col } from "react-bootstrap";
import LGD_mapping from "../Components/LGD_mapping";
import DateRangePicker from "../Components/DateRangePicker";

export default function SummaryReport() {
  const [loader, setLoader] = useState(true);
  const [nodalData, setNodalData] = useState([]);
  const history = useHistory();
  const [downloading, setDownlaoding] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedlocation, setSelectedlocation] = useState({});
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [type, setType] = useState("");
  const [phase, setPhase] = useState(true);
  const [districts, setDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(false);
  const [stateSelected, setStateSelected] = useState("");
  const [districtSelected, setDistrictSelected] = useState("");
  const [blockSelected, setBlockSelected] = useState("");
  const [panchayatSelected, setPanchayatSelected] = useState("");
  const [villageSelected, setVillageSelected] = useState("");
  const [stateCode, setStateCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  const [sortColumn, setSortColumn] = useState("type");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"
  const [organisationType, setOrgType] = useState("");

  const orgType = [
    {
      key: "Central Ministry/Department",
      value: "Central Ministry/Department",
    },
    { key: "State Govt Departments", value: "State Govt Departments" },
    { key: "Institution", value: "Institution" },
    { key: "dyo", value: "DYOs" },
    { key: "nyv", value: "NYVs" },
  ];

  const [location, setLocation] = useState({
    state: "",
    district: "",
    block: "",
    gp: "",
    village: "",
  });

  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const formatDateString = (date) => {
    if (date instanceof Date) {
      // Convert the date to the local timezone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().slice(0, 19).replace("T", " ");
    }
    return null;
  };

  const handleDatesSelected = (dates) => {
    setSelectedDates(dates);
  };

  const formattedStartDate = formatDateString(selectedDates.startDate);
  const formattedEndDate = formatDateString(selectedDates.endDate);

  const minDate = new Date("2023-09-01");
  const maxDate = new Date();

  const updateLocation = (field, value) => {
    console.log(field, value);
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });
        array.sort();
        setStates(array);
      });
  }, []);

  const handleStateClick = (stateName) => {
    setSelectedlocation(stateName);
    console.log(stateName);
  };
  const handleType = (typeName) => {
    setType(typeName);
  };
  const handleReportType = (type) => {
    setLoader(true);
    setReportType(type);
    console.log(loader);
    setSelectedState("");
    console.log(type);
  };

  useEffect(() => {
    // setLoader(true);
    setReportData([]);
    // handle Login/logout
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        const userJSON = JSON.parse(localStorage.getItem("users"))?.users;

        // Check the nodal_officer_data collection for type of user based on state district_name & block_name
        firebase
          .firestore()
          .collection("ak_nyv_officers")
          .where("phoneNumber", "==", userJSON?.phoneNumber)
          .get()
          .then((res) => {
            if (res?.docs?.length > 0) {
              // console.log("res", res.docs?.[0].data());
              setNodalData(res.docs?.[0].data());
              setStateCode(res.docs?.[0].data()?.state_code);
              setDistrictCode(res.docs?.[0].data()?.district_code);
              setLoader(true);
              let data = [];
              res?.docs.forEach((e) => {
                data.push(e.data());
              });
              const user = data;

              const fetchedDistricts = [];

              user?.forEach((e) => {
                fetchedDistricts.push({
                  district: e.district,
                  district_code: e?.district_code,
                });
              });
              setDistrict(fetchedDistricts);
              setSelectedDistrict(fetchedDistricts[0]?.district_code);
              if (!res.docs?.[0].data()?.super_admin) {
                firebase.auth().onAuthStateChanged(async (user) => {
                  const token = await user.getIdToken();
                  if (token) {
                    axios
                      .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${
                          res.docs?.[0].data()?.state_code
                        }&district=${res.docs?.[0].data()?.district_code}`
                      )
                      .then((res) => {
                        setReportData(res?.data);
                        setLoader(false);
                        // console.log("report data", res?.data);
                      });
                  }
                });
              } else {
                setLoader(false);
              }
            }
          });
      } else {
        setLoader(false);
        // No user is signed in.
        console.log("no user");
        history.push("/");
      }
    });
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (organisationType) {
          setLoader(true);
          setReportData([]);
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getKalashReport?org_name=${organisationType}&token=${token}`
            )
            .then((res) => {
              setReportData(res?.data);
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      }
    });
  }, [organisationType]);

  //   useEffect(() => {
  //     firebase.auth().onAuthStateChanged(async (user) => {
  //       const token = await user.getIdToken();
  //       if (token) {
  //         if (stateCode && districtCode) {
  //           if (formattedStartDate && formattedEndDate) {
  //             axios
  //               .get(
  //                 `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${stateCode}&district=${districtCode}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
  //               )
  //               .then((res) => {
  //                 setReportData(res?.data);
  //                 setLoader(false);
  //                 // console.log("report data", res?.data);
  //               });
  //           } else {
  //             axios
  //               .get(
  //                 `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${stateCode}&district=${districtCode}`
  //               )
  //               .then((res) => {
  //                 setReportData(res?.data);
  //                 setLoader(false);
  //                 // console.log("report data", res?.data);
  //               });
  //           }
  //         }
  //       }
  //     });
  //   }, [formattedStartDate, formattedEndDate]);

  //   useEffect(() => {
  //     if (!loader && !nodalData?.super_admin) {
  //       // console.log("hey", nodalData);
  //       firebase.auth().onAuthStateChanged(async (user) => {
  //         const token = await user.getIdToken();
  //         if (token) {
  //           axios
  //             .get(
  //               `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${nodalData?.state_code}&district=${selectedDistrict}`
  //             )
  //             .then((res) => {
  //               setReportData(res?.data);
  //               setLoader(false);

  //               console.log("report data", res?.data);
  //             });
  //         }
  //       });
  //     }
  //   }, [selectedDistrict]);

  //   useEffect(() => {
  //     // Fetch data for states ordered by state_name
  //     if (reportType) {
  //       setLoader(true);
  //       console.log(reportType);
  //       firebase.auth().onAuthStateChanged(async (user) => {
  //         const token = await user.getIdToken();
  //         if (token) {
  //           if (formattedStartDate && formattedEndDate) {
  //             axios
  //               .get(
  //                 `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=${reportType}&token=${token}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
  //               )
  //               .then((res) => {
  //                 if (reportType == "district_all") {
  //                   setReportData(
  //                     res?.data.map((item, index) =>
  //                       item.sort((a, b) =>
  //                         a?.district?.localeCompare(b?.district)
  //                       )
  //                     )
  //                   );
  //                 } else if (reportType == "block_all") {
  //                   setReportData(
  //                     res?.data.map((item, index) =>
  //                       item.sort((a, b) => a?.block?.localeCompare(b?.block))
  //                     )
  //                   );
  //                 } else {
  //                   setReportData(res?.data);
  //                   console.log(res?.data);
  //                 }

  //                 setLoader(false);
  //                 // console.log("report data", res?.data);
  //               });
  //           } else {
  //             axios
  //               .get(
  //                 `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=${reportType}&token=${token}`
  //               )
  //               .then((res) => {
  //                 if (reportType == "district_all") {
  //                   setReportData(
  //                     res?.data.map((item, index) =>
  //                       item.sort((a, b) =>
  //                         a?.district?.localeCompare(b?.district)
  //                       )
  //                     )
  //                   );
  //                 } else if (reportType == "block_all") {
  //                   setReportData(
  //                     res?.data.map((item, index) =>
  //                       item.sort((a, b) => a?.block?.localeCompare(b?.block))
  //                     )
  //                   );
  //                   console.log(
  //                     res?.data.map((item, index) =>
  //                       item.sort((a, b) => a?.block?.localeCompare(b?.block))
  //                     )
  //                   );
  //                 } else {
  //                   setReportData(res?.data);
  //                   console.log(res?.data);
  //                 }

  //                 setLoader(false);
  //                 // console.log("report data", res?.data);
  //               });
  //           }
  //         }
  //       });
  //       // console.log(loader);
  //     }
  //   }, [reportType]);

  const getIndividualStateReport = (item) => {
    // console.log(item, "state clicked");
    // console.log(item["state"], "state clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        // console.log("item", item);

        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=state_filter&token=${token}&state=${item["state"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.district?.localeCompare(b?.district))
                )
              );
              setLoader(false);
              // console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=state_filter&token=${token}&state=${item["state"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.district?.localeCompare(b?.district))
                )
              );
              setLoader(false);
              // console.log(
              //   res?.data.map((item, index) =>
              //     item.sort((a, b) => a?.district?.localeCompare(b?.district))
              //   )
              // );
            });
        }
      }
    });
  };

  const getIndividualDistrictReport = (item) => {
    // console.log(item, "district clicked");
    // console.log(item["district"], item["state"], "state clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.block?.localeCompare(b?.block))
                )
              );
              setLoader(false);
              // console.log("report data", res?.data);
            });
        }
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${item["state"]}&district=${item["district"]}`
          )
          .then((res) => {
            setReportData(
              res?.data.map((item, index) =>
                item.sort((a, b) => a?.block?.localeCompare(b?.block))
              )
            );
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const getIndividualBlockReport = (item) => {
    // console.log(item, "block clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=block_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.panchayat?.localeCompare(b?.panchayat))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=block_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.panchayat?.localeCompare(b?.panchayat))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      }
    });
  };

  const getIndividualPanchayatReport = (item) => {
    // console.log(item, "panchayat clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=panchayat_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&panchayat=${item["panchayat"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.village?.localeCompare(b?.village))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=panchayat_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&panchayat=${item["panchayat"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.village?.localeCompare(b?.village))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      }
    });
  };

  const getIndividualULBReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_ulb&state=${item}&token=${token}`
          )
          .then((res) => {
            setReportData(res?.data);
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const handleStateChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setSelectedState(value);
    getIndividualULBReport(value);
  };

  const handleDownload = () => {
    // Create a new blank workbook
    const workbook = XLSX.utils.book_new();
    const worksheets = [{ name: "Report", data: reportData[0] }];

    // Convert each data array to a worksheet
    worksheets.forEach((sheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    });

    // Create a Blob and download the Excel workbook
    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Overall_Summary.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownlaoding(false);
    console.log("Data has been downloaded as nodal_officer_summary.xlsx");
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const sortData = (data, column, order) => {
    if (data[0]) {
      return data[0].sort((a, b) => {
        const valA = a[column];
        const valB = b[column];

        if (order === "asc") {
          if (valA < valB) return -1;
          if (valA > valB) return 1;
        } else {
          if (valA < valB) return 1;
          if (valA > valB) return -1;
        }

        return 0;
      });
    } else {
      return [];
    }
  };
  const sortedData = sortData(reportData, sortColumn, sortOrder);

  const handleSort = (column) => {
    if (column === sortColumn) {
      console.log("sort called if");
      // Toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      console.log("sort called else");
      // If sorting a different column, set the new column and default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  return (
    <>
      <div className="main_body ease-in-anim">
        <Header />

        {/* <LGD_mapping
          setStateSelected={setStateSelected}
          setDistrictSelected={setDistrictSelected}
          setBlockSelected={setBlockSelected}
          setPanchayatSelected={setPanchayatSelected}
          setVillageSelected={setVillageSelected}
          location={location}
          onUpdateLocation={updateLocation}
        /> */}

        <div className="text-center justify-content-center d-flex">
          <div className="col-md-3">
            <h6>Select Organisation * :</h6>
            <select
              className="select-input"
              name="organisation_type"
              style={{ width: organisationType ? "100%" : "200px" }}
              onChange={(e) => {
                setOrgType(e.target.value);
              }}
              value={organisationType}
              required
            >
              <option value="">Select Organization</option>
              {orgType.map((institute) => (
                <option key={institute.key} value={institute.key}>
                  {institute.value}
                </option>
              ))}
            </select>
          </div>
        </div>

        {reportData.length > 0 && (
          <>
            <div className="d-flex justify-content-end my-3">
              <button
                className="pledge_btn mx-2"
                style={{
                  fontSize: "13px",
                  width: "auto",
                  opacity: downloading ? "0.5" : "1",
                }}
                onClick={() => {
                  handleDownload();
                }}
                disabled={downloading}
              >
                {downloading ? "Downloading" : "Download Report"}
              </button>
            </div>
            <div className="table-container m-2">
              <table className="report-table">
                <thead>
                  <tr>
                    <th>
                      {Object.keys(reportData?.[0]?.[0])?.includes("type") &&
                        "Type"}
                    </th>
                    <th>
                      {Object.keys(reportData?.[0]?.[0])?.includes(
                        "total_images_count"
                      ) && "Total Images Count"}
                    </th>
                    <th>
                      {Object.keys(reportData?.[0]?.[0])?.includes(
                        "total_videos_count"
                      ) && "Total Videos Count"}
                    </th>
                    <th>
                      {Object.keys(reportData?.[0]?.[0])?.includes(
                        "total_locations"
                      ) && "Total Locations"}
                    </th>
                    <th>
                      {Object.keys(reportData?.[0]?.[0])?.includes(
                        "total_events"
                      ) && "Total Events"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedData?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item["type"] == "block_level_event"
                          ? "Block/ULB Level"
                          : item["type"] == "kalash_yatra"
                          ? "GP/Village level"
                          : item["type"] == "total" && "Total"}
                      </td>
                      <td>
                        {item["total_images_count"] &&
                          item["total_images_count"]}
                      </td>
                      <td>
                        {item["total_videos_count"] &&
                          item["total_videos_count"]}
                      </td>
                      <td>
                        {item["total_locations"] && item["total_locations"]}
                      </td>
                      <td>{item["total_events"] && item["total_events"]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <span>Nodal Officers registered - </span>
              {reportData?.[1]?.userCount}
            </div>
          </>
        )}
        <div>
          <p className="note mx-2 text-center mt-5">
            {loader
              ? "Please Wait.."
              : "Disclaimer: Data is still being collected and is not final."}
          </p>
        </div>
      </div>
    </>
  );
}
