import React, { useEffect, useState } from "react";
import { Tab, Tabs, Row, Col, Card } from "react-bootstrap";
import DatePicker from "react-datepicker";
import firebase from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import AWS from "aws-sdk";
import { Link } from "react-router-dom";
import NationlEvent from "./NationlEvent";
import { handleUpload } from "../../services/upload";

export default function NyvReport() {
  const { t } = useTranslation();
  const [states, setStates] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGramPanchayat, setSelectedGramPanchayat] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [householdNumber, setHouseholdNumber] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [datesOption, setDateOption] = useState([]);
  const [currentUser, setUser] = useState([]);
  const [userName, setUserName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [tab, setselectedTab] = useState("national_event");
  const [dignitaries, setDignitaries] = useState([
    {
      nameOfDig: "",
    },
  ]);

  const [LGDPanchayats, setLGDPanchayats] = useState([]);
  const [upload, setUpload] = useState(null);
  const [files, setFiles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState("");
  const [loading, setLoading] = useState(false);
  const [LGDVillages, setLGDVillages] = useState([]);
  const [LGDBlocks, setLGDBlocks] = useState([]);
  const [LGDBlocks2, setLGDBlocks2] = useState([]);
  const [dateDropDown, setDateDropDown] = useState([]);
  const [publicUser, setPublicUser] = useState(false);
  const [formArray, setFormArray] = useState([
    {
      block: "",
      gramPanchayat: "",
      village: "",
      village_code: "",
      block_code: "",
      panchayat_code: "",
      selectedDate: null,
    },
  ]);
  const [selectedUser, setSelectedUser] = useState();
  const usersList = [
    "I&B Officials",
    "Public",
    "School",
    "College",
    "University",
    "Deemed University",
    "Indo Tibetan Border Police",
    "Assam Rifles",
    "Border Security Force",
    "Sashastra Seema Bal",
    "Central Reserve Police Force",
    "Central Industrial Security Force",
    "Common Service Centre",
    "Department of Youth Affairs - Govt of India",
    "Department of Sports - Govt of India",
    "Department of Post",
    "Bank",
    "District Administration",
    "District Panchayat/Parishad",
    "Department of Panchayati Raj - State Government",
    "Department of Rural Development - State Government",
    "Gram Panchayat",
    "Urban Local Body",
    "Youth Clubs",
  ];
  const location = useLocation();
  useEffect(() => {
    firebase
      .firestore()
      .collection("state_lgd_mapping")
      .orderBy("state")
      .get()
      .then((res) => {
        let array = [];
        res?.docs?.forEach((e) => {
          array.push({
            ...e?.data(),
          });
        });

        setStates(array);
      });
  }, []);
  useEffect(() => {
    if (publicUser) {
      if (selectedState) {
        if (tab !== "state") {
          firebase
            .firestore()
            .collection("state_district_lgd_mapping")

            .where("state_code", "==", selectedState?.state_code)
            .get()
            .then((res) => {
              let array = [];
              res?.docs?.forEach((e) => {
                array.push({
                  ...e?.data(),
                });
              });

              setDistricts(array);
            });
        } else {
          firebase
            .firestore()
            .collection("ak_events")
            .limit(10)
            .get()
            .then((res) => {
              console.log(res.empty);
            });
          callStates();

          async function callStates() {
            const event_db = firebase.firestore().collection("ak_events");
            let event_rep = await event_db
              .where("category", "==", "state_kalash_yatra")
              .where("state_code", "==", selectedState.state_code)
              .get();
            let dateData = [];

            if (!event_rep.empty) {
              event_rep.docs.forEach((e) => {
                let date = e?.data()?.event_date?.toDate();
                const day = date?.getDate();
                const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
                const year = date?.getFullYear();

                // Format the date components with leading zeros as needed
                const formattedDate = `${day}/${month
                  .toString()
                  .padStart(2, "0")}/${year}`;

                console.log(formattedDate);
                dateData.push({
                  id: e.id,
                  date: formattedDate,
                });
              });

              const uniqueDates = {};

              // Use the filter() method to remove duplicates
              const filteredArray = dateData.filter((obj) => {
                // Check if the date is already in the uniqueDates object
                if (uniqueDates[obj.date]) {
                  // If it is, it's a duplicate, so return false to filter it out
                  return false;
                }
                // If it's not a duplicate, add it to the uniqueDates object and return true
                uniqueDates[obj.date] = true;
                return true;
              });
              setDateDropDown(filteredArray);
            } else {
              let event_rep2 = await event_db
                .where("state", "in", [
                  selectedState.state,
                  selectedState.state.toUpperCase(),
                  selectedState.state.replace(/\b\w/g, (c) => c.toUpperCase()),
                ])
                .get();
              event_rep2.docs.forEach((e) => {
                let date = e?.data()?.event_date?.toDate();
                const day = date?.getDate();
                const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
                const year = date?.getFullYear();

                // Format the date components with leading zeros as needed
                const formattedDate = `${day}/${month
                  .toString()
                  .padStart(2, "0")}/${year}`;

                console.log(formattedDate);
                dateData.push({
                  id: e.id,
                  date: formattedDate,
                });
              });
            }
          }
        }
      }
    }
  }, [selectedState]);

  async function callStateCode(statecode, state) {
    console.log(statecode, state);
    const event_db = firebase.firestore().collection("ak_events");
    let event_rep = await event_db
      .where("category", "==", "state_kalash_yatra")
      .where("state_code", "==", statecode)
      .get();
    let dateData = [];

    if (!event_rep.empty) {
      event_rep.docs.forEach((e) => {
        let date = e?.data()?.event_date?.toDate();
        const day = date?.getDate();
        const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
        const year = date?.getFullYear();

        // Format the date components with leading zeros as needed
        const formattedDate = `${day}/${month
          .toString()
          .padStart(2, "0")}/${year}`;

        console.log(formattedDate);
        dateData.push({
          id: e.id,
          date: formattedDate,
        });
      });

      const uniqueDates = {};

      // Use the filter() method to remove duplicates
      const filteredArray = dateData.filter((obj) => {
        // Check if the date is already in the uniqueDates object
        if (uniqueDates[obj.date]) {
          // If it is, it's a duplicate, so return false to filter it out
          return false;
        }
        // If it's not a duplicate, add it to the uniqueDates object and return true
        uniqueDates[obj.date] = true;
        return true;
      });
      setDateDropDown([]);
      setDateDropDown(filteredArray);
    } else {
      let event_rep2 = await event_db
        .where("state", "in", [
          state,
          state.toUpperCase(),
          state.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get();
      event_rep2.docs.forEach((e) => {
        let date = e?.data()?.event_date?.toDate();
        const day = date?.getDate();
        const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
        const year = date?.getFullYear();

        // Format the date components with leading zeros as needed
        const formattedDate = `${day}/${month
          .toString()
          .padStart(2, "0")}/${year}`;

        console.log(formattedDate);
        dateData.push({
          id: e.id,
          date: formattedDate,
        });
      });
    }
  }
  useEffect(() => {
    if (publicUser) {
      if (selectedDistrict) {
        firebase
          .firestore()
          .collection("district_block_lgd_mapping")
          .where("district_code", "==", selectedDistrict?.district_code)
          .get()
          .then((res) => {
            let array = [];
            res?.docs?.forEach((e) => {
              array.push({
                ...e?.data(),
              });
            });

            setLGDBlocks(array);
          });
      }
    }
  }, [selectedDistrict]);
  //handle Change function for multiple state handling...
  const handleFieldChange = (
    index,
    value,
    fieldName,
    updateFieldName,
    filterArray,
    setSelectedField,
    fieldCode,
    updateFieldCode
  ) => {
    const filter = filterArray.filter((e) => e[fieldName] === value)[0];
    setSelectedField(filter);
    const updatedForms = [...formArray];
    updatedForms[index][updateFieldName] = value;
    updatedForms[index][updateFieldCode] = filter?.[fieldCode];
    setFormArray(updatedForms);
  };
  const handleBlockChange = (index, value) => {
    handleFieldChange(
      index,
      value,
      "block_name",
      "block",
      LGDBlocks,
      setSelectedBlock,
      "block_code",
      "block_code"
    );
  };

  const handleGramPanchayatChange = (index, value) => {
    handleFieldChange(
      index,
      value,
      "local_body_name",
      "gramPanchayat",
      LGDPanchayats,
      setSelectedGramPanchayat,
      "local_body_code",
      "panchayat_code"
    );
  };

  const handleVillageChange = (index, value) => {
    handleFieldChange(
      index,
      value,
      "village_name",
      "village",
      LGDVillages,
      setSelectedVillage,
      "village_code",
      "village_code"
    );
  };

  const handleDateChange = (index, date) => {
    const updatedForms = [...formArray];
    updatedForms[index].selectedDate = date;
    setFormArray(updatedForms);
  };

  const handleSubmitForEvent = async (event, category) => {
    event.preventDefault();
    setLoading(true);
    const event_db = firebase.firestore().collection("ak_events");

    for (const form of formArray) {
      let isAnyFieldEmpty = false;

      if (category === "kalash_yatra") {
        isAnyFieldEmpty =
          form.block === "" ||
          form.gramPanchayat === "" ||
          form.village === "" ||
          form.selectedDate === null;
      } else if (category === "block_level_event") {
        isAnyFieldEmpty = form.block === "" || form.selectedDate === null;
      } else if (category === "state_kalash_yatra") {
        isAnyFieldEmpty = form.selectedDate === null;
      }

      if (isAnyFieldEmpty) {
        toast.error("Please fill in all fields in all forms.");
        setLoading(false);
        return; // Exit the loop if any form is incomplete
      }

      try {
        const eventObject = {
          ...currentUser,
          created_at: new Date(),
          state: selectedState,
          district: selectedDistrict,
          category,
          event_date: form.selectedDate,
          after_multiple: true,
        };
        let queryCheck;
        if (category === "kalash_yatra") {
          queryCheck = event_db
            .where("category", "==", category)
            .where("village_code", "==", form.village_code)
            .where("event_date", ">=", form.selectedDate)
            .where(
              "event_date",
              "<",
              new Date(form.selectedDate.getTime() + 24 * 60 * 60 * 1000)
            );
          eventObject.block = form.block;
          eventObject.panchayat = form.gramPanchayat;
          eventObject.village = form.village;
          eventObject.village_code = form.village_code;
          eventObject.panchayat_code = form.panchayat_code;
          eventObject.block_code = form.block_code;
        } else if (category === "block_level_event") {
          queryCheck = event_db
            .where("category", "==", category)
            .where("block_code", "==", form.block_code)
            .where("event_date", ">=", form.selectedDate)
            .where(
              "event_date",
              "<",
              new Date(form.selectedDate.getTime() + 24 * 60 * 60 * 1000)
            );
          eventObject.block = form.block;
          eventObject.block_code = form.block_code;
        } else if (category === "state_kalash_yatra") {
          queryCheck = event_db
            .where("category", "==", category)
            .where("state_code", "==", eventObject?.state_code)
            .where("event_date", ">=", form.selectedDate)
            .where(
              "event_date",
              "<",
              new Date(form.selectedDate.getTime() + 24 * 60 * 60 * 1000)
            );
        }
        const snapshot = await queryCheck.get();
        console.log(eventObject);
        if (snapshot.empty) {
          await event_db.add(eventObject);
          toast.success("Event Created successfully!");
        } else {
          toast.error(`Event is already organized for the same date.`);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
      }
    }

    // Reset form values and other states after processing
    setFormArray([
      {
        block: "",
        gramPanchayat: "",
        village: "",
        village_code: "",
        block_code: "",
        panchayat_code: "",
        selectedDate: null,
      },
    ]);
    setSelectedGramPanchayat("");
    setLGDPanchayats([]);
    setSelectedBlock("");
    setLGDBlocks2([]);
    setTimeout(() => {
      setLGDBlocks2(LGDBlocks);
    }, 500);
    setLGDVillages([]);
    setDateDropDown([]);
    setSelectedVillage("");
    setLoading(false);
  };

  // Usage for Kalash event submission
  const handleSubmitForDignitariesKalash = async (event) => {
    await handleSubmitForEvent(event, "kalash_yatra");
  };

  // Usage for Block event submission
  const handleSubmitForDignitariesBlock = async (event) => {
    await handleSubmitForEvent(event, "block_level_event");
  };

  const handleSubmitForDignitariesState = async (event) => {
    await handleSubmitForEvent(event, "state_kalash_yatra");
    await callStateCode(currentUser?.state_code, currentUser?.state);
  };

  useEffect(() => {
    if (publicUser) {
      if (selectedVillage) {
        console.log(selectedVillage);
        callPanachayt();
      }
      async function callPanachayt() {
        const event_db = firebase.firestore().collection("ak_events");
        const event_rep = await event_db
          .where("block_code", "==", selectedBlock?.block_code)
          .where("village_code", "==", selectedVillage?.village_code)
          .where("category", "in", ["kalash_yatra", "Kalash_yatra"])
          .where("panchayat_code", "==", selectedGramPanchayat?.panchayat_code)
          .get();
        let dateData = [];
        event_rep.docs.forEach((e) => {
          let date = e?.data()?.event_date?.toDate();
          const day = date?.getDate();
          const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
          const year = date?.getFullYear();

          // Format the date components with leading zeros as needed
          const formattedDate = `${day}/${month
            .toString()
            .padStart(2, "0")}/${year}`;

          console.log(formattedDate);
          if (e?.data()?.event_date) {
            dateData.push({
              id: e.id,
              date: formattedDate,
            });
          }
        });
        const uniqueDates = {};

        // Use the filter() method to remove duplicates
        const filteredArray = dateData.filter((obj) => {
          // Check if the date is already in the uniqueDates object
          if (uniqueDates[obj.date]) {
            // If it is, it's a duplicate, so return false to filter it out
            return false;
          }
          // If it's not a duplicate, add it to the uniqueDates object and return true
          uniqueDates[obj.date] = true;
          return true;
        });
        setDateDropDown(filteredArray);
      }
    } else {
      if (selectedVillage) {
        console.log(selectedVillage);
        callPanachayt();
      }
      async function callPanachayt() {
        const event_db = firebase.firestore().collection("ak_events");
        const event_rep = await event_db
          .where("block_code", "==", selectedBlock?.block_code)
          .where("village_code", "==", selectedVillage?.village_code)
          .where("category", "in", ["kalash_yatra", "Kalash_yatra"])
          .where("panchayat_code", "==", selectedGramPanchayat?.local_body_code)
          .get();
        let dateData = [];
        event_rep.docs.forEach((e) => {
          let date = e?.data()?.event_date?.toDate();
          const day = date?.getDate();
          const month = date?.getMonth() + 1; // Month is zero-indexed, so add 1
          const year = date?.getFullYear();

          // Format the date components with leading zeros as needed
          const formattedDate = `${day}/${month
            .toString()
            .padStart(2, "0")}/${year}`;

          console.log(formattedDate);
          dateData.push({
            id: e.id,
            date: formattedDate,
          });
        });
        const uniqueDates = {};

        // Use the filter() method to remove duplicates
        const filteredArray = dateData.filter((obj) => {
          // Check if the date is already in the uniqueDates object
          if (uniqueDates[obj.date]) {
            // If it is, it's a duplicate, so return false to filter it out
            return false;
          }
          // If it's not a duplicate, add it to the uniqueDates object and return true
          uniqueDates[obj.date] = true;
          return true;
        });
        setDateDropDown(filteredArray);
      }
    }
  }, [selectedVillage]);
  const handleAddForm = () => {
    const previousForm = formArray[formArray.length - 1];
    if (
      previousForm.block === "" ||
      previousForm.gramPanchayat === "" ||
      previousForm.village === "" ||
      previousForm.selectedDate === null
    ) {
      toast.error(
        "Please fill in all fields in the previous form before adding another form."
      );
      return;
    }

    setFormArray([
      ...formArray,
      { block: "", gramPanchayat: "", village: "", selectedDate: null },
    ]);
  };
  const handleAddFormBlock = () => {
    const previousForm = formArray[formArray.length - 1];
    if (previousForm.block === "" || previousForm.selectedDate === null) {
      toast.error(
        "Please fill in all fields in the previous form before adding another form."
      );
      return;
    }

    setFormArray([
      ...formArray,
      { block: "", gramPanchayat: "", village: "", selectedDate: null },
    ]);
  };

  const handleAddFormState = () => {
    const previousForm = formArray[formArray.length - 1];
    if (previousForm.selectedDate === null) {
      toast.error(
        "Please fill in all fields in the previous form before adding another form."
      );
      return;
    }

    setFormArray([
      ...formArray,
      { block: "", gramPanchayat: "", village: "", selectedDate: null },
    ]);
  };

  const handleRemoveForm = (index) => {
    const updatedForms = [...formArray];
    updatedForms.splice(index, 1);
    setFormArray(updatedForms);
  };

  // Function to remove duplicates based on entire objects
  function removeDuplicatesByKey(arr, key) {
    const uniqueValues = new Set();
    const deduplicatedArray = [];

    for (const obj of arr) {
      const keyValue = obj[key];

      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        deduplicatedArray.push(obj);
      }
    }

    // Sort the deduplicated array alphabetically based on the specified key
    deduplicatedArray.sort((a, b) => {
      const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const valueB = b[key].toUpperCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    return deduplicatedArray;
  }

  const LGDSelector = (keyToSearch, valueToSearch, setReturnData) => {
    console.log(keyToSearch, valueToSearch);
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("lgd_data_mapped"); // Replace with your Firestore collection name

    // Use Firestore queries to filter data based on key and value
    collectionRef
      .where(keyToSearch, "==", valueToSearch)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        console.log(keyToSearch.split("_")[0] + "_name");
        if (keyToSearch == "district_code") {
          setReturnData(removeDuplicatesByKey(data, "block_code"));
        }

        if (keyToSearch == "block_code") {
          setReturnData(removeDuplicatesByKey(data, "local_body_code"));
        }

        if (keyToSearch == "local_body_code") {
          setReturnData(removeDuplicatesByKey(data, "village_code"));
        }

        // setReturnData(data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  };

  const fileUploadKalash = async () => {
    setLoading(true);

    try {
      if (files?.length === 0) {
        toast.error(t("please_select_at_least_one_file_to_upload"));
        setLoading(false);
        return;
      }

      if (files?.length > 20) {
        toast.error(t("select_20"));
        setLoading(false);
        return;
      }

      if (!selectedEvent) {
        toast.error("Please select a event");
        setLoading(false);
        return;
      }

      const validFiles = [];
      const errorFiles = [];
      console.log(files);
      for (const file of files) {
        let maxSize = 0;
        let fileType = "";

        if (file.type.startsWith("image/")) {
          maxSize = 10 * 1024 * 1024; // 10MB for images
          fileType = "image";
        } else if (file.type.startsWith("video/")) {
          maxSize = 25 * 1024 * 1024; // 25MB for videos
          fileType = "video";
        } else {
          errorFiles.push(file.name); // Unsupported file type
          continue;
        }

        if (file.size > maxSize) {
          errorFiles.push(file.name); // File size exceeds limit
        } else {
          validFiles.push({ file, fileType });
        }
      }

      if (errorFiles?.length > 0) {
        const errorMessage = t("some_files_are_invalid_or_exceed_size_limit");
        setUploadingMessage(errorMessage);
        setTimeout(() => {
          setUploadingMessage("");
        }, 3000);
        setLoading(false);
        return;
      }

      if (validFiles?.length === 0) {
        setLoading(false);
        toast.error(t("please_select_at_least_one_valid_image_or_video_file"));
        return;
      }

      setUpload("Uploading...");
      setUploadingMessage(t("uploading"));
      const event_db = firebase.firestore().collection("ak_events");
      const db = firebase.firestore().collection("ak_event_media");

      // If category is  kalash Yatra check for village Code as well
      const event_rep = await event_db.doc(selectedEvent).get();

      for (const { file, fileType } of validFiles) {
        const fileName = `${Date.now()}_${file.name}`;
        await db.add({
          ...event_rep.data(),
          type: "media",
          media_name: fileName ? fileName : "",
          fileType: fileType,
          moderated: false,
          event_doc_id: selectedEvent,
        });

        const params = {
          Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
          Key:
            fileType === "image"
              ? `ak_event_images/${fileName}`
              : `ak_event__videos/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        try {
          await handleUpload(params);

          setUpload("Uploaded Successfully.");
          setFormArray([
            {
              block: "",
              gramPanchayat: "",
              village: "",
              village_code: "",
              block_code: "",
              panchayat_code: "",
              selectedDate: null,
            },
          ]);

          setSelectedEvent("");
          setSelectedVillage("");
          setSelectedGramPanchayat("");
          setLGDPanchayats([]);
          setSelectedBlock();
          setLGDBlocks2([]);
          setTimeout(() => {
            setLGDBlocks2(LGDBlocks);
          }, 500);
          setLGDVillages([]);
          setDateDropDown([]);
          setSelectedVillage("");

          setUploadingMessage(t("upload_and_sent_for_moderation"));
          setSelectedEvent();
          // toast.success(t("upload_and_sent_for_moderation"));
          setLoading(false);

          setTimeout(() => {
            setUpload(null);
            setShowPopup(true);
          }, 2000);
        } catch (err) {
          console.error("Error uploading file:", err);
        }
      }

      console.log("Documents successfully updated!");
      toast.success("Data added successfully!");

      setTimeout(() => {
        setDisabled(false);
        // setSelectedGramPanchayat("");
        // setLGDPanchayats();
        // setSelectedBlock();
        // setSelectedVillage("");
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setDisabled(false);
      toast.error("Error uploading files");
    } finally {
      setLoading(false);
    }
  };

  const fileUploadPublic = async () => {
    setLoading(true);
    try {
      if (files?.length === 0) {
        toast.error(t("please_select_at_least_one_file_to_upload"));
        setLoading(false);
        return;
      }

      if (files?.length > 20) {
        toast.error(t("select_20"));
        setLoading(false);
        return;
      }

      if (!selectedUser) {
        toast.error(t("Please select a user"));
        setLoading(false);
        return;
      }

      if (tab == "village") {
        if (!selectedVillage?.village) {
          setLoading(false);
          toast.error("Please select all fields !");
          return;
        }
      }

      if (tab == "block") {
        if (!selectedBlock?.block) {
          setLoading(false);
          toast.error("Please select all fields !");
          return;
        }
      }

      if (!userName || !phoneNumber || phoneNumber == "" || userName == "") {
        setLoading(false);
        toast.error("Please select all fields !");
        return;
      }

      if (dateDropDown.length > 0) {
        if (!selectedEvent) {
          toast.error("Please select a event");
          setLoading(false);
          return;
        }
      }
      const validFiles = [];
      const errorFiles = [];
      console.log(files);
      for (const file of files) {
        let maxSize = 0;
        let fileType = "";

        if (file.type.startsWith("image/")) {
          maxSize = 10 * 1024 * 1024; // 10MB for images
          fileType = "image";
        } else if (file.type.startsWith("video/")) {
          maxSize = 25 * 1024 * 1024; // 25MB for videos
          fileType = "video";
        } else {
          errorFiles.push(file.name); // Unsupported file type
          continue;
        }

        if (file.size > maxSize) {
          errorFiles.push(file.name); // File size exceeds limit
        } else {
          validFiles.push({ file, fileType });
        }
      }

      if (errorFiles?.length > 0) {
        const errorMessage = t("some_files_are_invalid_or_exceed_size_limit");
        setUploadingMessage(errorMessage);
        setTimeout(() => {
          setUploadingMessage("");
        }, 3000);
        setLoading(false);
        return;
      }

      if (validFiles?.length === 0) {
        setLoading(false);
        toast.error(t("please_select_at_least_one_valid_image_or_video_file"));
        return;
      }

      setUpload("Uploading...");
      setUploadingMessage(t("uploading"));
      const event_db = firebase.firestore().collection("ak_events");
      const db = firebase.firestore().collection("ak_event_media");
      let event_rep;
      // If category is  kalash Yatra check for village Code as well
      if (dateDropDown.length > 0) {
        event_rep = await event_db.doc(selectedEvent).get();
      } else {
        event_rep = await event_db.add({
          created_by: selectedUser?.toUpperCase(),
          userType: "PUBLIC",
          phone_number: phoneNumber,
          user_name: userName,
          sub_category: tab,
          category:
            tab == "state"
              ? "state_kalash_yatra"
              : tab == "village"
              ? "kalash_yatra"
              : "block_level_event",
          district_code: selectedDistrict?.district_code || "",
          district: selectedDistrict?.district || "",
          block_code: selectedBlock?.block_code || "",
          block: selectedBlock?.block || "",
          panchayat_code: selectedGramPanchayat?.panchayat_code || "",
          panchayat: selectedGramPanchayat?.panchayat || "",
          village_code: selectedVillage?.village_code || "",
          village: selectedVillage?.village || "",
          state_code: selectedState?.state_code || "",
          state: selectedState?.state || "",
          event_date: new Date(),
        });
      }

      for (const { file, fileType } of validFiles) {
        const fileName = `${Date.now()}_${file.name}`;

        await db.add({
          created_by: selectedUser?.toUpperCase(),
          userType: "PUBLIC",
          phone_number: phoneNumber,
          user_name: userName,
          sub_category: tab,
          category:
            tab == "state"
              ? "state_kalash_yatra"
              : tab == "village"
              ? "kalash_yatra"
              : "block_level_event",
          district_code: selectedDistrict?.district_code || "",
          district: selectedDistrict?.district || "",
          block_code: selectedBlock?.block_code || "",
          block: selectedBlock?.block || "",
          panchayat_code: selectedGramPanchayat?.panchayat_code || "",
          panchayat: selectedGramPanchayat?.panchayat || "",
          village_code: selectedVillage?.village_code || "",
          village: selectedVillage?.village || "",
          state_code: selectedState?.state_code || "",
          state: selectedState?.state || "",
          event_date: new Date(),
          type: "media",
          media_name: fileName ? fileName : "",
          fileType: fileType,
          moderated: false,
          event_doc_id: dateDropDown?.length > 0 ? selectedEvent : event_rep.id,
        });

        const params = {
          Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
          Key:
            fileType === "image"
              ? `ak_event_images/${fileName}`
              : `ak_event__videos/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        try {
          await handleUpload(params);

          setUpload("Uploaded Successfully.");
          setFormArray([
            {
              block: "",
              gramPanchayat: "",
              village: "",
              village_code: "",
              block_code: "",
              panchayat_code: "",
              selectedDate: null,
            },
          ]);
          if (tab == "state") {
            setUploadingMessage(t("upload_and_sent_for_moderation"));

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          setSelectedEvent("");
          setSelectedVillage("");
          setDistricts([]);
          setSelectedUser();
          setLGDPanchayats([]);
          setLGDBlocks2([]);
          setUserName("");
          setPhoneNumber("");
          setTimeout(() => {
            setLGDBlocks2(LGDBlocks);
          }, 500);
          setLGDVillages([]);
          setDateDropDown([]);
          setSelectedVillage("");

          setUploadingMessage(t("upload_and_sent_for_moderation"));
          setSelectedEvent();
          // toast.success(t("upload_and_sent_for_moderation"));
          setLoading(false);

          setTimeout(() => {
            setUpload(null);
            setShowPopup(true);
          }, 2000);
        } catch (err) {
          console.error("Error uploading file:", err);
        }
      }

      console.log("Documents successfully updated!");
      toast.success("Data added successfully!");

      setTimeout(() => {
        setDisabled(false);
        // setSelectedGramPanchayat("");
        // setLGDPanchayats();
        // setSelectedBlock();
        // setSelectedVillage("");
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setDisabled(false);
      toast.error("Error uploading files");
    } finally {
      setLoading(false);
    }
  };

  const fileUploadBlock = async (state) => {
    setLoading(true);

    try {
      if (files?.length === 0) {
        toast.error(t("please_select_at_least_one_file_to_upload"));
        setLoading(false);
        return;
      }

      if (files?.length > 20) {
        toast.error(t("select_20"));
        setLoading(false);
        return;
      }

      if (!selectedEvent) {
        toast.error("Please select a event");
        setLoading(false);
        return;
      }

      const validFiles = [];
      const errorFiles = [];
      console.log(files);
      for (const file of files) {
        let maxSize = 0;
        let fileType = "";

        if (file.type.startsWith("image/")) {
          maxSize = 10 * 1024 * 1024; // 10MB for images
          fileType = "image";
        } else if (file.type.startsWith("video/")) {
          maxSize = 25 * 1024 * 1024; // 25MB for videos
          fileType = "video";
        } else {
          errorFiles.push(file.name); // Unsupported file type
          continue;
        }

        if (file.size > maxSize) {
          errorFiles.push(file.name); // File size exceeds limit
        } else {
          validFiles.push({ file, fileType });
        }
      }

      if (errorFiles?.length > 0) {
        const errorMessage = t("some_files_are_invalid_or_exceed_size_limit");
        setUploadingMessage(errorMessage);
        setTimeout(() => {
          setUploadingMessage("");
        }, 3000);
        setLoading(false);
        return;
      }

      if (validFiles?.length === 0) {
        setLoading(false);
        toast.error(t("please_select_at_least_one_valid_image_or_video_file"));
        return;
      }

      setUpload("Uploading...");
      setUploadingMessage(t("uploading"));
      const event_db = firebase.firestore().collection("ak_events");
      const db = firebase.firestore().collection("ak_event_media");

      // If category is  kalash Yatra check for village Code as well
      const event_rep = await event_db.doc(selectedEvent).get();

      for (const { file, fileType } of validFiles) {
        const fileName = `${Date.now()}_${file.name}`;
        await db.add({
          ...event_rep.data(),
          type: "media",
          media_name: fileName ? fileName : "",
          fileType: fileType,
          moderated: false,
          event_doc_id: selectedEvent,
        });

        const params = {
          Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
          Key:
            fileType === "image"
              ? `ak_event_images/${fileName}`
              : `ak_event__videos/${fileName}`,
          Body: file,
          ContentType: file.type,
        };

        try {
          await handleUpload(params);
          setUpload("Uploaded Successfully.");
          setFormArray([
            {
              block: "",
              gramPanchayat: "",
              village: "",
              village_code: "",
              block_code: "",
              panchayat_code: "",
              selectedDate: null,
            },
          ]);
          setSelectedEvent();
          setUploadingMessage(t("upload_and_sent_for_moderation"));
          // toast.success(t("upload_and_sent_for_moderation"));
          setLoading(false);

          setTimeout(() => {
            setUpload(null);
            setShowPopup(true);
          }, 2000);
        } catch (err) {
          console.error("Error uploading file:", err);
        }
      }

      console.log("Documents successfully updated!");
      setFormArray([
        {
          block: "",
          gramPanchayat: "",
          village: "",
          village_code: "",
          block_code: "",
          panchayat_code: "",
          selectedDate: null,
        },
      ]);

      setSelectedEvent("");
      setSelectedVillage("");
      setSelectedGramPanchayat("");
      setLGDPanchayats([]);
      setSelectedBlock();
      setLGDBlocks2([]);
      setTimeout(() => {
        setLGDBlocks2(LGDBlocks);
      }, 500);
      setLGDVillages([]);
      if (state !== "state") {
        setDateDropDown([]);
      } else {
        callStateCode(currentUser?.state_code, currentUser?.state);
      }
      setSelectedVillage("");
      toast.success("Data added successfully!");

      setTimeout(() => {
        setDisabled(false);
        // setSelectedGramPanchayat("");
        // setLGDPanchayats();
        // setSelectedBlock();
        // setSelectedVillage("");
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error uploading files: ", error);
      setDisabled(false);
      toast.error("Error uploading files");
    } finally {
      setLoading(false);
    }
  };
  const addDignitary = () => {
    setDignitaries([...dignitaries, { nameOfDig: "" }]);
  };

  const removeDignitary = (index) => {
    const updatedDignitaries = [...dignitaries];
    updatedDignitaries.splice(index, 1);
    setDignitaries(updatedDignitaries);
  };

  const handleNameChange = (e, index) => {
    const updatedDignitaries = [...dignitaries];
    updatedDignitaries[index].nameOfDig = e.target.value;
    setDignitaries(updatedDignitaries);
  };

  useEffect(() => {
    // Get the query parameters from the location object
    const searchParams = new URLSearchParams(location.search);

    // Access a specific query parameter by name
    const myQueryParam = searchParams.get("user");

    if (myQueryParam) {
      if (myQueryParam == "PUBLIC") {
        setPublicUser(true);
      }
    } else {
      let userDetails = [];

      firebase.auth().onAuthStateChanged((userResponse) => {
        if (userResponse) {
          const user = JSON.parse(localStorage.getItem("nyv_user"));
          firebase
            .firestore()
            .collection("ak_nyv_officers")
            .where("phoneNumber", "==", user[0]?.phoneNumber) // Use '==' for comparison
            .get()
            .then((res) => {
              res?.docs?.forEach((e) => {
                userDetails.push(e?.data());
              });
              console.log(userDetails[0]);
              let dataUser = userDetails[0];
              dataUser.userType = dataUser?.type || "nyv";
              setUser(dataUser);

              const fetchedStates = [];
              const fetchedBlocks = [];
              const fetchedDistricts = [];

              userDetails.forEach((e) => {
                fetchedStates.push(e.state);
                fetchedBlocks.push({
                  block: e.block,
                  block_code: e.block_code,
                });
                fetchedDistricts.push(e.district);
              });

              setStates(fetchedStates);
              setBlocks(fetchedBlocks);
              setDistricts(fetchedDistricts);

              if (fetchedStates.length > 0) {
                setSelectedState(fetchedStates[0]);
              }
              if (fetchedDistricts.length > 0) {
                setSelectedDistrict(fetchedDistricts[0]);
              }
              if (fetchedBlocks.length > 0) {
                setSelectedBlock(fetchedBlocks[0]);
              }

              // Fetch all the Blocks of the District that the logged-in user belongs to
              LGDSelector(
                "district_code",
                userDetails?.[0]?.district_code,
                setLGDBlocks
              );
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        } else {
          window.location.href = "/";
        }
      });
    }
  }, [location]);

  useEffect(() => {
    setTimeout(() => {
      setLGDBlocks2(LGDBlocks);
    }, 500);
    setLGDVillages([]);
    setDateDropDown([]);
  }, [LGDBlocks]);

  // Fetch Panchayat from LGD data When Block is selected
  useEffect(() => {
    if (publicUser) {
      if (tab !== "block") {
        firebase
          .firestore()
          .collection("block_panchayat_lgd_mapping")
          .where("block_code", "==", selectedBlock?.block_code)
          .get()
          .then((res) => {
            let array = [];
            res?.docs?.forEach((e) => {
              array.push({
                ...e?.data(),
              });
            });

            setLGDPanchayats(array);
          });
      } else {
        if (selectedBlock?.block) {
          callPanachay();
        } else {
          setLGDPanchayats([]);
        }

        async function callPanachay() {
          const event_db = firebase.firestore().collection("ak_events");
          const event_rep = await event_db
            .where("block_code", "==", selectedBlock?.block_code)
            .where("category", "==", "block_level_event")
            .get();
          let dateData = [];
          event_rep.docs.forEach((e) => {
            if (
              e?.data()?.panchayat_code == undefined ||
              e?.data()?.panchayat_code == null ||
              e?.data()?.panchayat_code == ""
            ) {
              let date = e?.data()?.event_date?.toDate();
              const day = date.getDate();
              const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
              const year = date.getFullYear();

              // Format the date components with leading zeros as needed
              const formattedDate = `${day}/${month
                .toString()
                .padStart(2, "0")}/${year}`;

              console.log(formattedDate);
              if (e?.data()?.event_date) {
                dateData.push({
                  id: e.id,
                  date: formattedDate,
                });
              }
            }
          });
          const uniqueDates = {};

          // Use the filter() method to remove duplicates
          const filteredArray = dateData.filter((obj) => {
            // Check if the date is already in the uniqueDates object
            if (uniqueDates[obj.date]) {
              // If it is, it's a duplicate, so return false to filter it out
              return false;
            }
            // If it's not a duplicate, add it to the uniqueDates object and return true
            uniqueDates[obj.date] = true;
            return true;
          });
          setDateDropDown(filteredArray);
        }
      }
    } else {
      if (selectedBlock?.block_name) {
        if (tab == "block") {
          callPanachay();
        }
        LGDSelector("block_code", selectedBlock.block_code, setLGDPanchayats);
      } else {
        setLGDPanchayats([]);
      }

      async function callPanachay() {
        const event_db = firebase.firestore().collection("ak_events");
        const event_rep = await event_db
          .where("block_code", "==", selectedBlock?.block_code)
          .where("category", "==", "block_level_event")
          .get();
        let dateData = [];
        event_rep.docs.forEach((e) => {
          if (
            e?.data()?.panchayat_code == undefined ||
            e?.data()?.panchayat_code == null
          ) {
            let date = e?.data()?.event_date?.toDate();
            const day = date.getDate();
            const month = date.getMonth() + 1; // Month is zero-indexed, so add 1
            const year = date.getFullYear();

            // Format the date components with leading zeros as needed
            const formattedDate = `${day}/${month
              .toString()
              .padStart(2, "0")}/${year}`;

            console.log(formattedDate);
            dateData.push({
              id: e.id,
              date: formattedDate,
            });
          }
        });
        const uniqueDates = {};

        // Use the filter() method to remove duplicates
        const filteredArray = dateData.filter((obj) => {
          // Check if the date is already in the uniqueDates object
          if (uniqueDates[obj.date]) {
            // If it is, it's a duplicate, so return false to filter it out
            return false;
          }
          // If it's not a duplicate, add it to the uniqueDates object and return true
          uniqueDates[obj.date] = true;
          return true;
        });
        setDateDropDown(filteredArray);
      }
    }
  }, [selectedBlock]);

  // Fetch Village from LGD data When Panchayat is selected
  useEffect(() => {
    if (publicUser) {
      firebase
        .firestore()
        .collection("panchayat_village_lgd_mapping")
        .where("panchayat_code", "==", selectedGramPanchayat?.panchayat_code)
        .get()
        .then((res) => {
          let array = [];
          res?.docs?.forEach((e) => {
            array.push({
              ...e?.data(),
            });
          });

          setLGDVillages(array);
        });
    } else {
      selectedGramPanchayat &&
        LGDSelector(
          "local_body_code",
          selectedGramPanchayat.local_body_code,
          setLGDVillages
        );
    }
  }, [selectedGramPanchayat]);

  // Submit Form data of Household
  const handleSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    let db = firebase.firestore().collection("ak_event_media");

    db.add({
      ...currentUser,
      created_at: new Date(),
      state: selectedState,
      district: selectedDistrict,
      block: selectedBlock?.block_name,
      block_code: selectedBlock?.block_code,
      panchayat: selectedGramPanchayat?.local_body_name,
      panchayat_code: selectedGramPanchayat?.local_body_code,
      village: selectedVillage?.village_name,
      village_code: selectedVillage?.village_code,
      event_date: selectedDate,
      category: "household",
      household_number: parseInt(householdNumber),
    })
      .then(() => {
        setFormArray([
          {
            block: "",
            gramPanchayat: "",
            village: "",
            village_code: "",
            block_code: "",
            panchayat_code: "",
            selectedDate: null,
          },
        ]);

        setSelectedEvent("");
        setSelectedVillage("");
        setSelectedGramPanchayat("");
        setLGDPanchayats([]);
        setSelectedBlock();
        setLGDBlocks2([]);
        setTimeout(() => {
          setLGDBlocks2(LGDBlocks);
        }, 500);
        setLGDVillages([]);
        setDateDropDown([]);
        setSelectedVillage("");
        toast.success("Date added successfully!");
        setTimeout(() => {
          setDisabled(false);
          // setSelectedGramPanchayat("");
          // setLGDPanchayats();
          // setSelectedBlock();
          // setSelectedVillage("");
          setHouseholdNumber("");
          setSelectedDate("");
        }, 2000);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating document: ", error);
        setDisabled(false);
        toast.error("Error updating document: ");
      });
  };

  const handleSubmitDigKalash = async (e) => {
    setDisabled(true);
    e.preventDefault();
    if (!selectedEvent) {
      toast.error("please select a event!");
      setDisabled(false);
      return;
    }
    const event_db = firebase.firestore().collection("ak_events");
    const db = firebase.firestore().collection("ak_event_media");

    try {
      // If category is  kalash Yatra check for village Code as well
      const event_rep = await event_db.doc(selectedEvent).get();

      for (const dignitary of dignitaries) {
        await db.add({
          ...currentUser,
          ...event_rep.data(),
          type: "dignitary",
          dignitary_name: dignitary.nameOfDig,
          event_doc_id: selectedEvent,
        });
      }
      setFormArray([
        {
          block: "",
          gramPanchayat: "",
          village: "",
          village_code: "",
          block_code: "",
          panchayat_code: "",
          selectedDate: null,
        },
      ]);

      setSelectedEvent("");
      setSelectedVillage("");
      setSelectedGramPanchayat("");
      setLGDPanchayats([]);
      setSelectedBlock();
      setLGDBlocks2([]);
      setTimeout(() => {
        setLGDBlocks2(LGDBlocks);
      }, 500);
      setLGDVillages([]);
      // setDateDropDown([]);
      setSelectedVillage("");
      console.log("Documents successfully updated!");
      toast.success("Data added successfully!");
      setTimeout(() => {
        setDisabled(false);
        // setSelectedGramPanchayat("");
        // setLGDPanchayats();
        // setSelectedBlock();
        // setSelectedVillage("");

        setHouseholdNumber();
        setDignitaries([]);
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error updating documents: ", error);
      setDisabled(false);
      toast.error("Error updating documents: ");
    }
  };
  const handleSubmitDigBlock = async (e, state) => {
    setDisabled(true);
    e.preventDefault();
    if (!selectedEvent) {
      toast.error("please select a event!");
      setDisabled(false);
      return;
    }
    const event_db = firebase.firestore().collection("ak_events");
    const db = firebase.firestore().collection("ak_event_media");

    try {
      // If category is  kalash Yatra check for village Code as well
      const event_rep = await event_db.doc(selectedEvent).get();

      for (const dignitary of dignitaries) {
        await db.add({
          ...currentUser,
          ...event_rep.data(),
          type: "dignitary",
          dignitary_name: dignitary.nameOfDig,
          event_doc_id: selectedEvent,
        });
      }

      setFormArray([
        {
          block: "",
          gramPanchayat: "",
          village: "",
          village_code: "",
          block_code: "",
          panchayat_code: "",
          selectedDate: null,
        },
      ]);

      setSelectedEvent("");
      setSelectedVillage("");
      setSelectedGramPanchayat("");
      setLGDPanchayats([]);
      setSelectedBlock();
      setLGDBlocks2([]);
      setTimeout(() => {
        setLGDBlocks2(LGDBlocks);
      }, 500);
      setLGDVillages([]);
      if (state !== "state") {
        setDateDropDown([]);
      } else {
        callStateCode(currentUser?.state_code, currentUser?.state);
      }

      setSelectedVillage("");

      console.log("Documents successfully updated!");
      toast.success("Data added successfully!");
      setTimeout(() => {
        setDisabled(false);
        // setSelectedGramPanchayat("");
        // setLGDPanchayats();
        // setSelectedBlock();
        // setSelectedVillage("");

        setHouseholdNumber();
        setDignitaries([
          {
            nameOfDig: "",
          },
        ]);
        setFiles([]);
      }, 2000);
    } catch (error) {
      console.error("Error updating documents: ", error);
      setDisabled(false);
      toast.error("Error updating documents: ");
    }
  };

  console.log(dateDropDown);
  return (
    <div style={{ minHeight: "100vh" }}>
      <Header />
      {publicUser ? (
        <>
          <Tabs
            id="justify-tab-example"
            className="mb-3"
            style={{
              padding: "24px",
              border: "none",
              gap: "20px",
              color: "brown",
            }}
            onSelect={(e) => {
              setUserName("");
              setPhoneNumber("");
              setDateDropDown([]);
              setselectedTab(e);
            }}
            justify
          >
            <Tab eventKey="national_event" title="National Level Event">
              {tab == "national_event" && <NationlEvent userType={true} />}
            </Tab>
            <Tab eventKey="state" title="Kalash Yatra State Level">
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    State:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedState(states[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select State</option>
                    {states?.map((block, index) => (
                      <option key={block.state + index} value={index}>
                        {block.state}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="block" title=" Kalash Yatra Block/ULB Level">
              {" "}
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    State:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedState(states[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select State</option>
                    {states?.map((block, index) => (
                      <option key={block.state + index} value={index}>
                        {block.state}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    District:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedDistrict(districts[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select District</option>
                    {districts?.map((block, index) => (
                      <option key={block.district + index} value={index}>
                        {block.district}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Block/ULB:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="block"
                    onChange={(e) => {
                      setSelectedBlock(LGDBlocks[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select Block/ULB</option>
                    {LGDBlocks2?.map((block, index) => (
                      <option key={block.block + index} value={index}>
                        {block.block}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="village" title="Kalash Yatra GP/Village Level">
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    State:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedState(states[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select State</option>
                    {states?.map((block, index) => (
                      <option key={block.state + index} value={index}>
                        {block.state}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    District:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedDistrict(districts[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select District</option>
                    {districts?.map((block, index) => (
                      <option key={block.district + index} value={index}>
                        {block.district}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Block/ULB:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="block"
                    onChange={(e) => {
                      setSelectedBlock(LGDBlocks[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select Block/ULB</option>
                    {LGDBlocks2?.map((block, index) => (
                      <option key={block.block + index} value={index}>
                        {block.block}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col my={2} md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Gram Panchayat:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="gramPanchayat"
                    onChange={(e) => {
                      setSelectedGramPanchayat(LGDPanchayats[e.target.value]);
                    }}
                    // value={selectedGramPanchayat}
                    required // Make this field mandatory
                  >
                    <option value="">Select Gram Panchayat</option>
                    {LGDPanchayats?.map((gramPanchayat, index) => (
                      <option
                        key={gramPanchayat.panchayat + index}
                        value={index}
                      >
                        {gramPanchayat.panchayat}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Village:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setSelectedVillage(LGDVillages[e.target.value]);
                    }}
                    name="village"
                    // value={selectedVillage}
                    required // Make this field mandatory
                  >
                    <option value="">Select Village</option>
                    {LGDVillages?.map((village, index) => (
                      <option key={village?.village + index} value={index}>
                        {village?.village}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
            </Tab>
          </Tabs>

          <br />
          {tab != "national_event" && (
            <>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={3}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    User:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="state"
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select User</option>
                    {usersList?.map((block, index) => (
                      <option key={block} value={block}>
                        {block}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={3}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Name:
                  </h6>
                  <input
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "15px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    type="text"
                    value={userName}
                    name="userName"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      setUserName(inputValue);
                    }}
                    // Make this field mandatory
                  />
                </Col>
                <Col md={3}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Phone Number:
                  </h6>
                  <input
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "15px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    type="text"
                    maxLength={10}
                    value={phoneNumber}
                    name="userName"
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/[^0-9]/g, "");
                      setPhoneNumber(inputValue);
                    }}
                    // Make this field mandatory
                  />
                </Col>
              </Row>
              {dateDropDown.length > 0 && (
                <>
                  <h6 className="m-2">Select Event:</h6>
                  <div
                    style={{ flexWrap: "wrap", padding: "15px" }}
                    className="d-flex  align-items-center"
                  >
                    {dateDropDown?.map((village, index) => (
                      <div
                        style={{
                          margin: "1rem",
                          fontSize: "17px",
                          padding: "4px",
                        }}
                        className="form-check"
                        key={village?.date + index}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="date"
                          value={village?.id}
                          onChange={(e) => {
                            setSelectedEvent(e.target.value);
                          }}
                          required
                        />
                        <label className="form-check-label">
                          {village?.date}
                        </label>
                      </div>
                    ))}
                  </div>
                </>
              )}
              <br />
              <Row>
                <div className="container d-flex justify-content-center text-center ">
                  <div className="row d-flex justify-content-center align-items-center form_card ">
                    <Tabs
                      id="justify-tab-example"
                      className="mb-3"
                      style={{
                        padding: "24px",
                        border: "none",
                        gap: "20px",
                        color: "brown",
                      }}
                      justify
                    >
                      <Tab eventKey="upload" title="Upload Media">
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: "250px", minWidth: "200px" }}
                        >
                          <div className="img_box">
                            <div className="pt-3">
                              <img
                                src="./asset/images/upload.svg"
                                style={{ height: "6vh" }}
                              />
                              <div className="my-5 ">
                                <input
                                  type="file"
                                  id="file"
                                  accept="image/*,video/*"
                                  multiple
                                  onChange={(e) => {
                                    //console.log(e.target.files, files)
                                    if (e.target.files?.length > 20) {
                                      toast.error(t("select_20"));
                                      e.target.value = "";
                                    } else {
                                      setFiles([...files, ...e.target.files]);
                                    }
                                  }}
                                  style={{
                                    display: "none", // Hide the default input appearance
                                  }}
                                />
                                <div className="file_name">
                                  {files?.length > 0 && (
                                    <p>
                                      {files
                                        ?.map((file) => file.name)
                                        .join(", ")}
                                    </p>
                                  )}
                                </div>
                                <label
                                  htmlFor="file"
                                  className="custom-input-button"
                                >
                                  {t("choose_file")}
                                </label>
                              </div>
                              {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            disabled={loading}
                            style={{ opacity: upload ? 0.5 : 1 }}
                            className="pledge_btn mt-4"
                            onClick={() => {
                              fileUploadPublic();
                            }}
                          >
                            {loading ? "Please Wait..." : t("submit")}
                          </button>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          {currentUser?.type == "institute" && (
            <button
              style={{
                position: "absolute",
                right: "5px",
              }}
              onClick={() => {
                window.location.href = "/organiser-profile";
              }}
              className="pledge_btn"
            >
              Change to Phase 1
            </button>
          )}
          <br />
          <Tabs
            id="justify-tab-example"
            className="mb-3 mt-2"
            style={{
              padding: "24px",
              border: "none",
              gap: "20px",
              color: "brown",
            }}
            justify
            onSelect={(e) => {
              setselectedTab(e);
              setFormArray([
                {
                  block: "",
                  gramPanchayat: "",
                  village: "",
                  village_code: "",
                  block_code: "",
                  panchayat_code: "",
                  selectedDate: null,
                },
              ]);
              setSelectedEvent("");
              setSelectedVillage("");
              setSelectedGramPanchayat("");
              setLGDPanchayats([]);
              setSelectedBlock();
              setLGDBlocks2([]);
              setTimeout(() => {
                setLGDBlocks2(LGDBlocks);
              }, 500);
              setLGDVillages([]);
              if (e == "state") {
                callStateCode(currentUser?.state_code, currentUser?.state);
              } else {
                setDateDropDown([]);
              }

              setSelectedVillage("");
            }}
          >
            <Tab eventKey="household" title="Household">
              <Col className="d-flex justify-content-end pb-3">
                <Link
                  to={{
                    pathname: "/block-nyv-report",
                    search: `?state=${selectedState}&district=${selectedDistrict}`,
                  }}
                >
                  <button
                    className="submit_btn"
                    style={{ fontSize: "13px", width: "auto" }}
                  >
                    Block Reports
                  </button>
                </Link>
              </Col>

              <form onSubmit={handleSubmit}>
                <div>
                  <Row className="d-flex justify-content-center align-items-center ">
                    <Col md={4}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <h6
                            style={{ fontSize: "18px", marginLeft: "0.5rem" }}
                          >
                            State: {selectedState}
                          </h6>

                          <h6
                            style={{ fontSize: "18px", marginLeft: "0.5rem" }}
                          >
                            District: {selectedDistrict}
                          </h6>
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                        Block:
                      </h6>
                      <select
                        style={{
                          fontSize: "15px",
                          color: "#543c00",
                          padding: "8px",
                          height: "40px",
                          borderRadius: "25px",
                          border: "1px solid #543c00",
                          background: "#e7d3a9",
                          width: "100%",
                        }}
                        name="block"
                        onChange={(e) => {
                          setSelectedBlock(LGDBlocks[e.target.value]);
                        }}
                        value={LGDBlocks.findIndex(
                          (obj) => obj.block_code === selectedBlock?.block_code
                        )}
                        required // Make this field mandatory
                      >
                        <option value="-1">Select Block</option>
                        {LGDBlocks?.map((block, index) => (
                          <option key={block.block_name + index} value={index}>
                            {block.block_name}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                  <br />

                  <hr />
                  <br />

                  <Row>
                    <div className="container d-flex justify-content-center text-center ">
                      <div className="row d-flex justify-content-center align-items-center form_card ">
                        <div className="text-center">
                          <h3 className="household_title">
                            Household Motivated
                          </h3>
                        </div>
                        <Col md={3}>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Gram Panchayat:
                          </h6>

                          <select
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "25px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                              width: "100%",
                            }}
                            name="gramPanchayat"
                            onChange={(e) => {
                              setSelectedGramPanchayat(
                                LGDPanchayats[e.target.value]
                              );
                            }}
                            // value={selectedGramPanchayat}
                            required // Make this field mandatory
                          >
                            <option value="-1">Select Gram Panchayat</option>

                            {LGDPanchayats?.map((gramPanchayat, index) => (
                              <option key={gramPanchayat + index} value={index}>
                                {gramPanchayat["local_body_name"]}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md={3}>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Village:
                          </h6>
                          <select
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "25px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                              width: "100%",
                            }}
                            onChange={(e) => {
                              setSelectedVillage(LGDVillages[e.target.value]);
                            }}
                            name="village"
                            // value={selectedVillage}
                            required // Make this field mandatory
                          >
                            <option value="">Select Village</option>
                            {LGDVillages?.map((village, index) => (
                              <option key={village + index} value={index}>
                                {village.village_name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col md={3}>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Date:
                          </h6>
                          <DatePicker
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "15px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                              width: "100%",
                            }}
                            popperPlacement="top"
                            className="rounded"
                            selected={selectedDate}
                            minDate={new Date("2023-09-01")}
                            maxDate={new Date("2023-10-31")}
                            onChange={(date) => setSelectedDate(date)}
                            required // Make this field mandatory
                          />
                        </Col>
                        <Col md={3}>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Number of Households:
                          </h6>
                          <input
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "15px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                              width: "100%",
                            }}
                            type="number"
                            value={householdNumber}
                            name="numberOfHouseholds"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              // Check if the input is a valid number with up to 3 digits
                              if (/^\d{0,3}$/.test(inputValue)) {
                                console.log(inputValue);
                                setHouseholdNumber(inputValue);
                              }
                            }}
                            required // Make this field mandatory
                          />
                        </Col>
                        <Col md={3}>
                          <div>
                            <h6
                              style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                            ></h6>
                            <button
                              disabled={disabled}
                              style={{
                                marginTop: "1rem",
                                width: "200px",
                                fontSize: "15px",
                                color: "#fff",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00 ",
                                background: "#543c00",
                                opacity: disabled ? 0.4 : 1,
                              }}
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </div>
              </form>
            </Tab>
            <Tab eventKey="kalash" title="Kalash Yatra at GP/Village Level">
              <Col className="d-flex justify-content-end pb-3">
                <Link
                  to={{
                    pathname: "/block-nyv-report",
                    search: `?state=${selectedState}&district=${selectedDistrict}`,
                  }}
                >
                  <button
                    className="submit_btn"
                    style={{ fontSize: "13px", width: "auto" }}
                  >
                    Block Reports
                  </button>
                </Link>
              </Col>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={6}>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                        State: {selectedState}
                      </h6>

                      <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                        District: {selectedDistrict}
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                {formArray.map((form, index) => (
                  <>
                    <form key={index}>
                      <Row className="d-flex justify-content-center align-items-center ">
                        <Col md={2}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Block:
                          </label>
                          {formArray.length - 1 !== index ? (
                            <input
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              disabled
                              value={form.block}
                              type="text"
                            />
                          ) : (
                            <select
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              onChange={(e) => {
                                handleBlockChange(index, e.target.value);
                              }}
                              value={form.block}
                              required
                            >
                              <option value="">Select Block</option>
                              {[...LGDBlocks]?.map((block, blockIndex) => (
                                <option
                                  key={block.block_name + blockIndex}
                                  value={block.block_name}
                                >
                                  {block.block_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>
                        <Col md={2}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Gram Panchayat:
                          </label>
                          {formArray.length - 1 !== index ? (
                            <input
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              disabled
                              value={form.gramPanchayat}
                              type="text"
                            />
                          ) : (
                            <select
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              onChange={(e) =>
                                handleGramPanchayatChange(index, e.target.value)
                              }
                              value={form.gramPanchayat}
                              required
                            >
                              <option value="">Select Gram Panchayat</option>
                              {LGDPanchayats?.map((gramPanchayat, gpIndex) => (
                                <option
                                  key={gramPanchayat.local_body_name + gpIndex}
                                  value={gramPanchayat.local_body_name}
                                >
                                  {gramPanchayat.local_body_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>
                        <Col md={3}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Village:
                          </label>
                          {formArray.length - 1 !== index ? (
                            <input
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              disabled
                              value={form.village}
                              type="text"
                            />
                          ) : (
                            <select
                              className="form-control"
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              onChange={(e) =>
                                handleVillageChange(index, e.target.value)
                              }
                              value={form.village}
                              required
                            >
                              <option value="">Select Village</option>
                              {[...LGDVillages]?.map(
                                (village, villageIndex) => (
                                  <option
                                    key={village.village_name + villageIndex}
                                    value={village.village_name}
                                  >
                                    {village.village_name}
                                  </option>
                                )
                              )}
                            </select>
                          )}
                        </Col>
                        <Col md={3}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Date of Kalash Yatra at Village Level:
                          </label>
                          <DatePicker
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "15px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                              minWidth: "200px",
                            }}
                            popperPlacement="top"
                            className="rounded"
                            minDate={new Date("2023-09-01")}
                            maxDate={new Date("2023-10-13")}
                            selected={form.selectedDate}
                            onChange={(date) => handleDateChange(index, date)}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          {index > 0 && (
                            <button
                              className="form-button my-2"
                              onClick={() => handleRemoveForm(index)}
                            >
                              Remove{" "}
                              <svg
                                fill={"#a1713d"}
                                width={25}
                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="RemoveCircleIcon"
                                tabindex="-1"
                                title="RemoveCircle"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"></path>
                              </svg>
                            </button>
                          )}
                        </Col>
                      </Row>
                    </form>
                    <br />
                  </>
                ))}
                <button
                  disabled={loading}
                  style={{ opacity: loading ? "0.5" : 1 }}
                  className="form-button my-2"
                  onClick={(e) => handleSubmitForDignitariesKalash(e)}
                  type="submit"
                >
                  Create Event
                </button>
                <button
                  className="form-button my-2"
                  type="button"
                  onClick={handleAddForm}
                >
                  Add Event{" "}
                  <svg
                    fill={"#a1713d"}
                    width={25}
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="AddCircleIcon"
                    tabindex="-1"
                    title="AddCircle"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                  </svg>
                </button>
              </div>
              <br />

              <hr />
              <br />
              <h5>Select Event :</h5>
              <br />
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Block:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="block"
                    onChange={(e) => {
                      setSelectedBlock(LGDBlocks[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select Block</option>
                    {LGDBlocks2?.map((block, index) => (
                      <option key={block.block_name + index} value={index}>
                        {block.block_name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col my={2} md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Gram Panchayat:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="gramPanchayat"
                    onChange={(e) => {
                      setSelectedGramPanchayat(LGDPanchayats[e.target.value]);
                    }}
                    // value={selectedGramPanchayat}
                    required // Make this field mandatory
                  >
                    <option value="">Select Gram Panchayat</option>
                    {LGDPanchayats?.map((gramPanchayat, index) => (
                      <option key={gramPanchayat + index} value={index}>
                        {gramPanchayat.local_body_name}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Village:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    onChange={(e) => {
                      setSelectedVillage(LGDVillages[e.target.value]);
                    }}
                    name="village"
                    // value={selectedVillage}
                    required // Make this field mandatory
                  >
                    <option value="">Select Village</option>
                    {LGDVillages?.map((village, index) => (
                      <option key={village?.village_name + index} value={index}>
                        {village?.village_name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <br />
              {dateDropDown.length > 0 && (
                <div className="d-flex flex-column align-items-center">
                  <h6 className="m-2">Select Event:</h6>
                  {dateDropDown?.map((village, index) => (
                    <div
                      style={{ margin: "5px 0px", fontSize: "17px" }}
                      className="form-check"
                      key={village?.date + index}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="date"
                        value={village?.id}
                        onChange={(e) => {
                          setSelectedEvent(e.target.value);
                        }}
                        required
                      />
                      <label className="form-check-label">
                        {village?.date}
                      </label>
                    </div>
                  ))}
                </div>
              )}
              <br />
              <Row>
                <div className="container d-flex justify-content-center text-center ">
                  <div className="row d-flex justify-content-center align-items-center form_card ">
                    <Tabs
                      id="justify-tab-example"
                      className="mb-3"
                      style={{
                        padding: "24px",
                        border: "none",
                        gap: "20px",
                        color: "brown",
                      }}
                      justify
                    >
                      <Tab eventKey="Add-Dignitaries" title="Add Dignitaries">
                        <div className="text-center">
                          <h3 className="household_title">Add Dignitaries</h3>
                        </div>

                        <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                          Name of Dignitary's:
                        </h6>
                        {dignitaries?.map((dignitary, index) => (
                          <div key={index} className="my-2">
                            <div className="d-flex text-center justify-content-center">
                              <input
                                style={{
                                  fontSize: "15px",
                                  color: "#543c00",
                                  padding: "8px",
                                  height: "40px",
                                  borderRadius: "25px",
                                  border: "1px solid #543c00",
                                  background: "#e7d3a9",
                                  width: "200px",
                                }}
                                type="text"
                                name="nameOfDig"
                                value={dignitary.nameOfDig}
                                onChange={(e) => handleNameChange(e, index)}
                                required
                              />
                              {/* <button
                            className="form-button"
                            onClick={() => manageDignitariesList(index, "add")}
                            type="button"
                          >
                            add
                            
                          </button> */}
                              {index > 0 && (
                                <button
                                  className="form-button"
                                  onClick={() => removeDignitary(index)}
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    fill="#543c00"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <button
                            className="form-button my-2"
                            onClick={addDignitary}
                          >
                            Add Dignitary{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="18"
                              viewBox="0 0 17 18"
                              fill="none"
                            >
                              <path
                                d="M16.3359 9.69922H9.16406V16.8711C9.16406 17.2379 8.86676 17.5352 8.5 17.5352C8.13324 17.5352 7.83594 17.2379 7.83594 16.8711V9.69922H0.664062C0.297301 9.69922 0 9.40192 0 9.03516C0 8.66839 0.297301 8.37109 0.664062 8.37109H7.83594V1.19922C7.83594 0.832457 8.13324 0.535156 8.5 0.535156C8.86676 0.535156 9.16406 0.832457 9.16406 1.19922V8.37109H16.3359C16.7027 8.37109 17 8.66839 17 9.03516C17 9.40192 16.7027 9.69922 16.3359 9.69922Z"
                                fill="#A1713D"
                              />
                            </svg>
                          </button>
                        </div>
                        <Col>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          ></h6>
                          <button
                            disabled={disabled}
                            style={{
                              marginTop: "1rem",
                              width: "200px",
                              fontSize: "15px",
                              color: "#fff",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "25px",
                              border: "1px solid #885d1c ",
                              background: "#885d1c",
                              opacity: disabled ? 0.4 : 1,
                            }}
                            onClick={(e) => {
                              handleSubmitDigKalash(e);
                            }}
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Tab>
                      <Tab eventKey="upload" title="Upload Media">
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: "250px", minWidth: "200px" }}
                        >
                          <div className="img_box">
                            <div className="pt-3">
                              <img
                                src="./asset/images/upload.svg"
                                style={{ height: "6vh" }}
                              />
                              <div className="my-5 ">
                                <input
                                  type="file"
                                  id="file"
                                  accept="image/*,video/*"
                                  multiple
                                  onChange={(e) => {
                                    //console.log(e.target.files, files)
                                    if (e.target.files?.length > 20) {
                                      toast.error(t("select_20"));
                                      e.target.value = "";
                                    } else {
                                      setFiles([...files, ...e.target.files]);
                                    }
                                  }}
                                  style={{
                                    display: "none", // Hide the default input appearance
                                  }}
                                />
                                <div className="file_name">
                                  {files?.length > 0 && (
                                    <p>
                                      {files
                                        ?.map((file) => file.name)
                                        .join(", ")}
                                    </p>
                                  )}
                                </div>
                                <label
                                  htmlFor="file"
                                  className="custom-input-button"
                                >
                                  {t("choose_file")}
                                </label>
                              </div>
                              {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            disabled={upload}
                            style={{ opacity: upload ? 0.5 : 1 }}
                            className="pledge_btn mt-4"
                            onClick={() => {
                              fileUploadKalash();
                            }}
                          >
                            {upload ? upload : t("submit")}
                          </button>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Row>
            </Tab>
            <Tab eventKey="block" title="Kalash Yatra at Block/ULB Level">
              <Col className="d-flex justify-content-end pb-3">
                <Link
                  to={{
                    pathname: "/block-nyv-report",
                    search: `?state=${selectedState}&district=${selectedDistrict}`,
                  }}
                >
                  <button
                    className="submit_btn"
                    style={{ fontSize: "13px", width: "auto" }}
                  >
                    Block Reports
                  </button>
                </Link>
              </Col>
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={6}>
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                        State: {selectedState}
                      </h6>

                      <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                        District: {selectedDistrict}
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                {formArray.map((form, index) => (
                  <>
                    <form key={index}>
                      <Row className="d-flex justify-content-center align-items-center ">
                        <Col md={3}></Col>
                        <Col md={3}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Block:
                          </label>
                          {formArray.length - 1 !== index ? (
                            <input
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              disabled
                              value={form.block}
                              type="text"
                            />
                          ) : (
                            <select
                              style={{
                                fontSize: "15px",
                                color: "#543c00",
                                padding: "8px",
                                height: "40px",
                                borderRadius: "25px",
                                border: "1px solid #543c00",
                                background: "#e7d3a9",
                                width: "100%",
                              }}
                              className="form-control"
                              onChange={(e) => {
                                handleBlockChange(index, e.target.value);
                              }}
                              value={form.block}
                              required
                            >
                              <option value="">Select Block</option>
                              {[...LGDBlocks]?.map((block, blockIndex) => (
                                <option
                                  key={block.block_name + blockIndex}
                                  value={block.block_name}
                                >
                                  {block.block_name}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>

                        <Col md={3}>
                          <label
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          >
                            Date of Kalash Yatra at Block Level:
                          </label>
                          <DatePicker
                            style={{
                              fontSize: "15px",
                              color: "#543c00",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "15px",
                              border: "1px solid #543c00",
                              background: "#e7d3a9",
                            }}
                            popperPlacement="top"
                            className="rounded"
                            minDate={new Date("2023-10-01")}
                            maxDate={new Date("2023-10-20")}
                            selected={form.selectedDate}
                            onChange={(date) => handleDateChange(index, date)}
                            required
                          />
                        </Col>
                        <Col md={2}>
                          {index > 0 && (
                            <button
                              className="form-button my-2"
                              onClick={() => handleRemoveForm(index)}
                            >
                              Remove{" "}
                              <svg
                                fill={"#a1713d"}
                                width={25}
                                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                                focusable="false"
                                aria-hidden="true"
                                viewBox="0 0 24 24"
                                data-testid="RemoveCircleIcon"
                                tabindex="-1"
                                title="RemoveCircle"
                              >
                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"></path>
                              </svg>
                            </button>
                          )}
                        </Col>
                      </Row>
                    </form>
                    <br />
                  </>
                ))}
                <button
                  disabled={loading}
                  style={{ opacity: loading ? "0.5" : 1 }}
                  className="form-button my-2"
                  onClick={(e) => handleSubmitForDignitariesBlock(e)}
                  type="submit"
                >
                  Create Event
                </button>
                <button
                  className="form-button my-2"
                  type="button"
                  onClick={handleAddFormBlock}
                >
                  Add Event{" "}
                  <svg
                    fill={"#a1713d"}
                    width={25}
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="AddCircleIcon"
                    tabindex="-1"
                    title="AddCircle"
                  >
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                  </svg>
                </button>
              </div>
              <br />

              <hr />
              <br />
              <h5>Select Event :</h5>
              <br />
              <Row className="d-flex justify-content-center align-items-center ">
                <Col md={2}>
                  <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                    Block:
                  </h6>
                  <select
                    style={{
                      fontSize: "15px",
                      color: "#543c00",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #543c00",
                      background: "#e7d3a9",
                      width: "100%",
                    }}
                    name="block"
                    onChange={(e) => {
                      setSelectedBlock(LGDBlocks[e.target.value]);
                    }}
                    // value={selectedBlock}
                    required // Make this field mandatory
                  >
                    <option value="">Select Block</option>
                    {LGDBlocks2?.map((block, index) => (
                      <option key={block.block_name + index} value={index}>
                        {block.block_name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <br />
              {dateDropDown.length > 0 && (
                <div className="d-flex flex-column align-items-center">
                  <h6 className="m-2">Select Event:</h6>
                  {dateDropDown?.map((village, index) => (
                    <div
                      style={{ margin: "5px 0px", fontSize: "17px" }}
                      className="form-check"
                      key={village?.date + index}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="date"
                        value={village?.id}
                        onChange={(e) => {
                          setSelectedEvent(e.target.value);
                        }}
                        required
                      />
                      <label className="form-check-label">
                        {village?.date}
                      </label>
                    </div>
                  ))}
                </div>
              )}
              <br />
              <Row>
                <div className="container d-flex justify-content-center text-center ">
                  <div className="row d-flex justify-content-center align-items-center form_card ">
                    <Tabs
                      id="justify-tab-example"
                      className="mb-3"
                      style={{
                        padding: "24px",
                        border: "none",
                        gap: "20px",
                        color: "brown",
                      }}
                      justify
                    >
                      <Tab eventKey="Add-Dignitaries" title="Add Dignitaries">
                        <div className="text-center">
                          <h3 className="household_title">Add Dignitaries</h3>
                        </div>

                        <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                          Name of Dignitary's:
                        </h6>
                        {dignitaries?.map((dignitary, index) => (
                          <div key={index} className="my-2">
                            <div className="d-flex text-center justify-content-center">
                              <input
                                style={{
                                  fontSize: "15px",
                                  color: "#543c00",
                                  padding: "8px",
                                  height: "40px",
                                  borderRadius: "25px",
                                  border: "1px solid #543c00",
                                  background: "#e7d3a9",
                                  width: "200px",
                                }}
                                type="text"
                                name="nameOfDig"
                                value={dignitary.nameOfDig}
                                onChange={(e) => handleNameChange(e, index)}
                                required
                              />
                              {/* <button
                            className="form-button"
                            onClick={() => manageDignitariesList(index, "add")}
                            type="button"
                          >
                            add
                            
                          </button> */}
                              {index > 0 && (
                                <button
                                  className="form-button"
                                  onClick={() => removeDignitary(index)}
                                  type="button"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    fill="#543c00"
                                  >
                                    <path d="M0 0h24v24H0z" fill="none" />
                                    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <div>
                          <button
                            className="form-button my-2"
                            onClick={addDignitary}
                          >
                            Add Dignitary{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="17"
                              height="18"
                              viewBox="0 0 17 18"
                              fill="none"
                            >
                              <path
                                d="M16.3359 9.69922H9.16406V16.8711C9.16406 17.2379 8.86676 17.5352 8.5 17.5352C8.13324 17.5352 7.83594 17.2379 7.83594 16.8711V9.69922H0.664062C0.297301 9.69922 0 9.40192 0 9.03516C0 8.66839 0.297301 8.37109 0.664062 8.37109H7.83594V1.19922C7.83594 0.832457 8.13324 0.535156 8.5 0.535156C8.86676 0.535156 9.16406 0.832457 9.16406 1.19922V8.37109H16.3359C16.7027 8.37109 17 8.66839 17 9.03516C17 9.40192 16.7027 9.69922 16.3359 9.69922Z"
                                fill="#A1713D"
                              />
                            </svg>
                          </button>
                        </div>
                        <Col>
                          <h6
                            style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                          ></h6>
                          <button
                            disabled={disabled}
                            style={{
                              marginTop: "1rem",
                              width: "200px",
                              fontSize: "15px",
                              color: "#fff",
                              padding: "8px",
                              height: "40px",
                              borderRadius: "25px",
                              border: "1px solid #885d1c ",
                              background: "#885d1c",
                              opacity: disabled ? 0.4 : 1,
                            }}
                            onClick={(e) => {
                              handleSubmitDigBlock(e);
                            }}
                            type="submit"
                          >
                            Submit
                          </button>
                        </Col>
                      </Tab>
                      <Tab eventKey="upload" title="Upload Media">
                        <div
                          className="d-flex justify-content-center"
                          style={{ height: "250px", minWidth: "200px" }}
                        >
                          <div className="img_box">
                            <div className="pt-3">
                              <img
                                src="./asset/images/upload.svg"
                                style={{ height: "6vh" }}
                              />
                              <div className="my-5 ">
                                <input
                                  type="file"
                                  id="file"
                                  accept="image/*,video/*"
                                  multiple
                                  onChange={(e) => {
                                    //console.log(e.target.files, files)
                                    if (e.target.files?.length > 20) {
                                      toast.error(t("select_20"));
                                      e.target.value = "";
                                    } else {
                                      setFiles([...files, ...e.target.files]);
                                    }
                                  }}
                                  style={{
                                    display: "none", // Hide the default input appearance
                                  }}
                                />
                                <div className="file_name">
                                  {files?.length > 0 && (
                                    <p>
                                      {files
                                        ?.map((file) => file.name)
                                        .join(", ")}
                                    </p>
                                  )}
                                </div>
                                <label
                                  htmlFor="file"
                                  className="custom-input-button"
                                >
                                  {t("choose_file")}
                                </label>
                              </div>
                              {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            disabled={upload}
                            style={{ opacity: upload ? 0.5 : 1 }}
                            className="pledge_btn mt-4"
                            onClick={() => {
                              fileUploadBlock();
                            }}
                          >
                            {upload ? upload : t("submit")}
                          </button>
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Row>
            </Tab>
            {currentUser?.organization_name &&
              currentUser?.organization_name !== "Institution" &&
              currentUser?.ministry_dept !== "Ministry of Youth Affairs" && (
                <Tab eventKey="state" title="Kalash Yatra at State Level">
                  <Col className="d-flex justify-content-end pb-3">
                    <Link
                      to={{
                        pathname: "/block-nyv-report",
                        search: `?state=${selectedState}&district=${selectedDistrict}`,
                      }}
                    >
                      <button
                        className="submit_btn"
                        style={{ fontSize: "13px", width: "auto" }}
                      >
                        Block Reports
                      </button>
                    </Link>
                  </Col>
                  <Row className="d-flex justify-content-center align-items-center ">
                    <Col md={6}>
                      <div className="d-flex justify-content-center align-items-center">
                        <div>
                          <h6
                            style={{ fontSize: "18px", marginLeft: "0.5rem" }}
                          >
                            State: {selectedState}
                          </h6>

                          <h6
                            style={{ fontSize: "18px", marginLeft: "0.5rem" }}
                          >
                            District: {selectedDistrict}
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <div style={{ textAlign: "center" }}>
                    {formArray.map((form, index) => (
                      <>
                        <form key={index}>
                          <Row className="d-flex justify-content-center align-items-center ">
                            <Col md={2}></Col>
                            <Col md={3}>
                              <label
                                style={{
                                  fontSize: "13px",
                                  marginLeft: "0.5rem",
                                }}
                              >
                                Date of Kalash Yatra at State Level:
                              </label>
                              <DatePicker
                                style={{
                                  fontSize: "15px",
                                  color: "#543c00",
                                  padding: "8px",
                                  height: "40px",
                                  borderRadius: "15px",
                                  border: "1px solid #543c00",
                                  background: "#e7d3a9",
                                }}
                                popperPlacement="top"
                                className="rounded"
                                minDate={new Date("2023-10-14")}
                                maxDate={new Date("2023-10-26")}
                                selected={form.selectedDate}
                                onChange={(date) =>
                                  handleDateChange(index, date)
                                }
                                required
                              />
                            </Col>
                            <Col md={2}>
                              {index > 0 && (
                                <button
                                  className="form-button my-2"
                                  onClick={() => handleRemoveForm(index)}
                                >
                                  Remove{" "}
                                  <svg
                                    fill={"#a1713d"}
                                    width={25}
                                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                                    focusable="false"
                                    aria-hidden="true"
                                    viewBox="0 0 24 24"
                                    data-testid="RemoveCircleIcon"
                                    tabindex="-1"
                                    title="RemoveCircle"
                                  >
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"></path>
                                  </svg>
                                </button>
                              )}
                            </Col>
                          </Row>
                        </form>
                        <br />
                      </>
                    ))}
                    <button
                      disabled={loading}
                      style={{ opacity: loading ? "0.5" : 1 }}
                      className="form-button my-2"
                      onClick={(e) => handleSubmitForDignitariesState(e)}
                      type="submit"
                    >
                      Create Event
                    </button>
                    <button
                      className="form-button my-2"
                      type="button"
                      onClick={handleAddFormState}
                    >
                      Add Event{" "}
                      <svg
                        fill={"#a1713d"}
                        width={25}
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="AddCircleIcon"
                        tabindex="-1"
                        title="AddCircle"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"></path>
                      </svg>
                    </button>
                  </div>
                  <br />

                  <hr />
                  <br />
                  <h5>Select Event :</h5>
                  <br />
                  <Row className="d-flex justify-content-center align-items-center "></Row>
                  <br />
                  {dateDropDown.length > 0 && (
                    <div className="d-flex flex-column align-items-center">
                      <h6 className="m-2">Select Event:</h6>
                      {dateDropDown?.map((village, index) => (
                        <div
                          style={{ margin: "5px 0px", fontSize: "17px" }}
                          className="form-check"
                          key={village?.date + index}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="date"
                            value={village?.id}
                            onChange={(e) => {
                              setSelectedEvent(e.target.value);
                            }}
                            required
                          />
                          <label className="form-check-label">
                            {village?.date}
                          </label>
                        </div>
                      ))}
                    </div>
                  )}
                  <br />
                  <Row>
                    <div className="container d-flex justify-content-center text-center ">
                      <div className="row d-flex justify-content-center align-items-center form_card ">
                        <Tabs
                          id="justify-tab-example"
                          className="mb-3"
                          style={{
                            padding: "24px",
                            border: "none",
                            gap: "20px",
                            color: "brown",
                          }}
                          justify
                        >
                          <Tab
                            eventKey="Add-Dignitaries"
                            title="Add Dignitaries"
                          >
                            <div className="text-center">
                              <h3 className="household_title">
                                Add Dignitaries
                              </h3>
                            </div>

                            <h6
                              style={{ fontSize: "13px", marginLeft: "0.5rem" }}
                            >
                              Name of Dignitary's:
                            </h6>
                            {dignitaries?.map((dignitary, index) => (
                              <div key={index} className="my-2">
                                <div className="d-flex text-center justify-content-center">
                                  <input
                                    style={{
                                      fontSize: "15px",
                                      color: "#543c00",
                                      padding: "8px",
                                      height: "40px",
                                      borderRadius: "25px",
                                      border: "1px solid #543c00",
                                      background: "#e7d3a9",
                                      width: "200px",
                                    }}
                                    type="text"
                                    name="nameOfDig"
                                    value={dignitary.nameOfDig}
                                    onChange={(e) => handleNameChange(e, index)}
                                    required
                                  />
                                  {/* <button
                            className="form-button"
                            onClick={() => manageDignitariesList(index, "add")}
                            type="button"
                          >
                            add
                            
                          </button> */}
                                  {index > 0 && (
                                    <button
                                      className="form-button"
                                      onClick={() => removeDignitary(index)}
                                      type="button"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        fill="#543c00"
                                      >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
                                      </svg>
                                    </button>
                                  )}
                                </div>
                              </div>
                            ))}
                            <div>
                              <button
                                className="form-button my-2"
                                onClick={addDignitary}
                              >
                                Add Dignitary{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17"
                                  height="18"
                                  viewBox="0 0 17 18"
                                  fill="none"
                                >
                                  <path
                                    d="M16.3359 9.69922H9.16406V16.8711C9.16406 17.2379 8.86676 17.5352 8.5 17.5352C8.13324 17.5352 7.83594 17.2379 7.83594 16.8711V9.69922H0.664062C0.297301 9.69922 0 9.40192 0 9.03516C0 8.66839 0.297301 8.37109 0.664062 8.37109H7.83594V1.19922C7.83594 0.832457 8.13324 0.535156 8.5 0.535156C8.86676 0.535156 9.16406 0.832457 9.16406 1.19922V8.37109H16.3359C16.7027 8.37109 17 8.66839 17 9.03516C17 9.40192 16.7027 9.69922 16.3359 9.69922Z"
                                    fill="#A1713D"
                                  />
                                </svg>
                              </button>
                            </div>
                            <Col>
                              <h6
                                style={{
                                  fontSize: "13px",
                                  marginLeft: "0.5rem",
                                }}
                              ></h6>
                              <button
                                disabled={disabled}
                                style={{
                                  marginTop: "1rem",
                                  width: "200px",
                                  fontSize: "15px",
                                  color: "#fff",
                                  padding: "8px",
                                  height: "40px",
                                  borderRadius: "25px",
                                  border: "1px solid #885d1c ",
                                  background: "#885d1c",
                                  opacity: disabled ? 0.4 : 1,
                                }}
                                onClick={(e) => {
                                  handleSubmitDigBlock(e, "state");
                                }}
                                type="submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Tab>
                          <Tab eventKey="upload" title="Upload Media">
                            <div
                              className="d-flex justify-content-center"
                              style={{ height: "250px", minWidth: "200px" }}
                            >
                              <div className="img_box">
                                <div className="pt-3">
                                  <img
                                    src="./asset/images/upload.svg"
                                    style={{ height: "6vh" }}
                                  />
                                  <div className="my-5 ">
                                    <input
                                      type="file"
                                      id="file"
                                      accept="image/*,video/*"
                                      multiple
                                      onChange={(e) => {
                                        //console.log(e.target.files, files)
                                        if (e.target.files?.length > 20) {
                                          toast.error(t("select_20"));
                                          e.target.value = "";
                                        } else {
                                          setFiles([
                                            ...files,
                                            ...e.target.files,
                                          ]);
                                        }
                                      }}
                                      style={{
                                        display: "none", // Hide the default input appearance
                                      }}
                                    />
                                    <div className="file_name">
                                      {files?.length > 0 && (
                                        <p>
                                          {files
                                            ?.map((file) => file.name)
                                            .join(", ")}
                                        </p>
                                      )}
                                    </div>
                                    <label
                                      htmlFor="file"
                                      className="custom-input-button"
                                    >
                                      {t("choose_file")}
                                    </label>
                                  </div>
                                  {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-center">
                              <button
                                disabled={upload}
                                style={{ opacity: upload ? 0.5 : 1 }}
                                className="pledge_btn mt-4"
                                onClick={() => {
                                  fileUploadBlock("state");
                                }}
                              >
                                {upload ? upload : t("submit")}
                              </button>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </Row>
                </Tab>
              )}
          </Tabs>
        </>
      )}
    </div>
  );
}
