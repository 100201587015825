import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/functions";
import { getAuth } from "firebase/auth";
const { getFunctions } = require("firebase/functions");

const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyDjGhY86RoxAWNNM2QfftwiYhSn_Zv99qM",
  authDomain: "ekta-shrinkhila-59ad9.firebaseapp.com",
  projectId: "ekta-shrinkhila-59ad9",
  storageBucket: "ekta-shrinkhila-59ad9.appspot.com",
  messagingSenderId: "342134626558",
  appId: "1:342134626558:web:c9c7c282fec5fa34abadaf",
  measurementId: "G-99EJZ7GMTX",
};
const app = firebase.initializeApp(firebaseConfig);
firebase.database();
firebase.storage();
firebase.firestore();
firebase.analytics();
export const db = firebase.firestore();
// if (window.location.hostname == "localhost") {
//   window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// }

export const functions = getFunctions(app);
export const auth = getAuth(app);
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6Ld8NXsnAAAAAB_AKPoWIRgKyE9ssGjkF6bjrDE4"),
//   isTokenAutoRefreshEnabled: true,
// });

export default firebase;
