import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import "./Home.css";
import { useTranslation } from "react-i18next";
// import StateDistrictBlockGP from "../Components/StateDistrictBlockGP";

export default function Home() {
  const { t } = useTranslation();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [showVid, setShowVid] = useState(false);
  const [showHome, setShowHome] = useState(true);
  const screenSize = window.innerWidth;

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setShowVid(false);
    }, 4000);
  }, []);

  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };

  return (
    <>
      {showVid ? (
        <>
          <img
            src="./asset/images/default.jpg"
            className="video-thumb tiny"
            alt="thumb"
            style={{ opacity: isVideoLoaded ? 0 : 1 }}
          />
          <video
            id="background-video"
            onLoadedData={onLoadedData}
            autoPlay
            muted
            playsinline
            poster="./asset/images/default.jpg"
            style={{ opacity: isVideoLoaded ? 1 : 0 }}
          >
            <source src="./asset/video/mb_vid.mp4" type="video/mp4" />
          </video>
        </>
      ) : (
        <div className="main_body ease-in-anim">
          <Header />

          {/* <StateDistrictBlockGP /> */}
          <div className="ease-in-anim">
            <div className="d-flex justify-content-center align-items-center">
              <img
                className="img-fluid akam"
                src={t("akam_logo_url")}
                alt="braveheart"
              />
            </div>
            <div>
              <h1 className="main_head text-center">
                {t("meri_maati_mera_desh")}
              </h1>
              <p className="sub_head text-center" style={{ marginTop: "0%" }}>
                {t("mati_ko_naman_viro_ko_vandan")}
              </p>
              <p className="mmmd_date">{t("campaign_has_organised")}</p>
              <h1 className="main_head text-center green">
                {t("amrit_kalash_yatra")}
              </h1>
              <p className="mmmd_date green">
                1<sup>{t("st")}</sup> {t("september")} {t("to")} 31
                <sup>{t("st")}</sup>
                {t("october")} 2023
              </p>
            </div>

            <div className="d-flex justify-content-center align-items-center mt-3">
              <div>
                <Link to="/downloads">
                  <div
                    className="row d-flex align-items-center mx-2"
                    style={{
                      backgroundImage: `url(${"./asset/images/green_bg.svg"})`,
                      backgroundColor: "#00811A",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      minHeight: "4vh",
                      height: "auto",
                      width: "fit-content",
                      borderRadius: "10px",
                      border: "2px solid green",
                      cursor: "pointer",
                      padding: "5px 10px",
                    }}
                  >
                    <div className="">MMMD Grand Finale</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center pt-3">
              <img
                src="./asset/images/map.svg"
                alt="map of India"
                className="img-fluid"
                style={{ height: "48vh" }}
              />
            </div>
            {/* <div className="container"> */}
            {/* <div className="landing_cont row py-4 px-5 my-2">
              <div className="col-lg-5 col-md-12 d-flex justify-content-center">
                <img
                  className="img-fluid landing_img_1 p-2"
                  src="./asset/images/background/landing1.jpg"
                />
              </div>
              <div className="col-lg-7 col-md-12 p-2 d-flex justify-content-center">
                <img
                  className="img-fluid landing_img_2"
                  src="./asset/images/background/landing2.png"
                />
              </div>
            </div> */}
            {/* </div> */}
            <div className="d-flex justify-content-center align-items-center mt-3">
              <div>
                <Link to="/events">
                  <button className="pledge_btn_org mx-2">
                    {t("view_events")}
                  </button>
                </Link>
              </div>
              <div>
                <Link to="/step">
                  <button className="pledge_btn_org mx-2 my-2">
                    {t("take_pledge")}
                  </button>
                </Link>
              </div>
              {screenSize > 768 && (
                <div>
                  <Link to="/nyv-report?user=PUBLIC">
                    <button className="pledge_btn_org mx-2">
                      {t("upload_media/share_link")}
                    </button>
                  </Link>
                </div>
              )}
            </div>
            {screenSize < 768 && (
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Link to="/events-media">
                  <button className="pledge_btn_org mx-2">
                    {t("upload_media/share_link")}
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
