import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Certificate from "../Components/Certificate/Certificate";
import Header from "../Components/Header/Header";
import Popup from "../Components/Popup/Popup";
import AWS from "aws-sdk";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import { Carousel, Tabs, Tab, TabList } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import StateDistrictBlockGP from "../Components/StateDistrictBlockGP";
import Dashboard from "./Dashboard";
import AddLinks from "./AddLinks";
import { handleUpload } from "../../services/upload";

export default function EventUpload() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [upload, setUpload] = useState(null);
  const [files, setFiles] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState("");
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({
    state: "",
    district: "",
    block: "",
    gp: "",
    ulb: "",
    type: "Rural",
  });
  const [progress, setProgress] = useState(0); // Progress state
  const [uploading, setUploading] = useState(false); // Uploading state

  // const handleLocationUpdate = (updatedLocation) => {
  //   setLocation(updatedLocation);
  // };

  // Function to update the progress
  const updateProgress = (completed, total) => {
    const percentage = Math.floor((completed / total) * 100);
    setProgress(percentage);
  };

  const updateLocation = (field, value) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  const fileUpload = async () => {
    setLoading(true);
    let successfulUploads = 0; // Counter for successful uploads
    if (files.length === 0) {
      // toast.error(t("please select_at_least_one_file_to_upload"));
      toast.error("Please select at least one file to upload");
      setLoading(false);
      return;
    }

    if (files.length > 20) {
      toast.error(t("select_20"));

      setLoading(false);
      return;
    }

    const validFiles = [];
    const errorFiles = [];

    for (const file of files) {
      let maxSize = 0;
      let category = ""; // Define category based on your logic

      if (file.type.startsWith("image/")) {
        maxSize = 10 * 1024 * 1024; // 10MB for images
        category = "image";
      } else if (file.type.startsWith("video/")) {
        maxSize = 200 * 1024 * 1024; // 300MB for videos
        category = "video";
      } else {
        errorFiles.push(file.name); // Unsupported file type
        continue;
      }

      if (file.size > maxSize) {
        errorFiles.push(file.name); // File size exceeds limit
      } else {
        validFiles.push(file);
      }
    }

    if (errorFiles.length > 0) {
      const errorMessage = t("some_files_are_invalid_or_exceed_size_limit");
      setUploadingMessage(errorMessage);
      setFiles([]);
      setTimeout(() => {
        setUploadingMessage("");
      }, 3000);
      setLoading(false);
      return;
    }

    if (validFiles.length === 0) {
      setLoading(false);
      toast.error(t("please_select_at_least_one_valid_image_or_video_file"));
      return;
    }

    setUpload("Uploading...");
    setUploadingMessage(t("uploading"));
    setUploading(true);

    try {
      const db = firebase.firestore();
      const eventsRef = db.collection("event_new");

      const panchayatName = location.gp;
      const districtName = location.district;
      if (location.ulb) {
        const querySnapshot = await eventsRef
          .where("state", "==", location.state.toUpperCase())
          .where("name_of_ulb", "==", location?.ulb.toUpperCase())
          .limit(1)
          .get();
        // Check if no documents were found
        if (querySnapshot.empty) {
          console.log("heyy");
          const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;
          eventsRef
            .add(
              {
                created_by: "other",
                state: location?.state.toUpperCase(),
                name_of_ulb: location?.ulb.toUpperCase(),
                timestamp: new Date(),
                event_date: "",
              },
              { merge: true }
            )
            .then(async (res) => {
              console.log(res);
              const updatePromises = [];
              const newMediaArray = []; // Array to hold newMedia objects for all files

              for (const file of validFiles) {
                const fileName = `${Date.now()}_${file.name}`;
                const newMedia = {
                  img_url: fileName,
                  moderated: false,
                };

                newMediaArray.push(newMedia);

                let dbMedia = firebase.firestore().collection("event_media");

                updatePromises.push(
                  dbMedia.add({
                    created_by: "other",
                    state: location?.state.toUpperCase(),
                    name_of_ulb: location?.ulb.toUpperCase(),
                    timestamp: new Date(),
                    id: res.id,
                    moderated: false,
                    category: "other",
                    media_name: fileName ? fileName : "",
                    fileType: file?.type.startsWith("image/")
                      ? "image"
                      : "video",
                  })
                );

                // Upload the file to S3 (adjust S3 upload code accordingly)
                const contentType = file.type.startsWith("video")
                  ? "video/mp4"
                  : "image/*";

                const uploadParams = {
                  Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
                  Key: file.type.startsWith("image")
                    ? `organiser_images/${fileName}`
                    : `organiser_videos/${fileName}`,
                  //   Key: `event_media/${fileName}`,
                  Body: file, // The actual file object
                  ContentType: contentType, // The MIME type of the file
                };

                // const upload = s3.upload(uploadParams);

                const params = {
                  Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
                  Key: file.type.startsWith("image")
                    ? `organiser_images/${fileName}`
                    : `organiser_videos/${fileName}`,
                  Body: file,
                  ContentType: file.type,
                };
                const UPLOAD_UPDATE_INTERVAL = 1000; // Update progress every second
                try {
                  await handleUpload(params);

                  // Increment the successful uploads counter
                  successfulUploads++;
                  // Update the progress
                  updateProgress(successfulUploads, validFiles.length);
                  setUpload("Uploaded and sent for moderation.");
                  setUploadingMessage(t("upload_and_sent_for_moderation"));
                  setFiles([]);
                  toast.success(
                    ` 
                ${successfulUploads} pictures - ${t(
                      "upload_and_sent_for_moderation"
                    )}`
                  );
                  setLoading(false);
                  setTimeout(() => {
                    setUpload(null);
                    setShowPopup(true);
                  }, 2000);
                } catch (err) {
                  console.error("Error uploading file:", err);
                }

                // // upload.on("httpUploadProgress", function (progress) {
                // //   let percentage = Math.floor((progress.loaded / progress.total) * 100);
                // //   console.log("Upload progress: " + percentage + "%");
                // //   setUploadPercentage(percentage + "%"); // Set the upload progress
                // // });

                // await upload.promise(); // Wait for the upload to complete
              }
            });
        } else {
          const updatePromises = [];
          const newMediaArray = []; // Array to hold newMedia objects for all files

          for (const file of validFiles) {
            const fileName = `${Date.now()}_${file.name}`;
            const newMedia = {
              img_url: fileName,
              moderated: false,
            };

            newMediaArray.push(newMedia);

            let dbMedia = firebase.firestore().collection("event_media");

            updatePromises.push(
              dbMedia.add({
                created_by: "other",
                state: location?.state.toUpperCase(),
                name_of_ulb: location?.ulb.toUpperCase(),
                timestamp: new Date(),
                id: querySnapshot.docs[0].id,
                moderated: false,
                category: "other",
                media_name: fileName ? fileName : "",
                fileType: file?.type.startsWith("image/") ? "image" : "video",
              })
            );

            // Upload the file to S3 (adjust S3 upload code accordingly)
            const contentType = file.type.startsWith("video")
              ? "video/mp4"
              : "image/*";

            const uploadParams = {
              Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
              Key: file.type.startsWith("image")
                ? `organiser_images/${fileName}`
                : `organiser_videos/${fileName}`,
              //   Key: `event_media/${fileName}`,
              Body: file, // The actual file object
              ContentType: contentType, // The MIME type of the file
            };

            // const upload = s3.upload(uploadParams);

            const params = {
              Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
              Key: file.type.startsWith("image")
                ? `organiser_images/${fileName}`
                : `organiser_videos/${fileName}`,
              Body: file,
              ContentType: file.type,
            };
            const UPLOAD_UPDATE_INTERVAL = 1000; // Update progress every second
            try {
              await handleUpload(params);
              successfulUploads++;
              // Update the progress
              updateProgress(successfulUploads, validFiles.length);
              console.log("image uploaded");
              setUpload("Uploaded and sent for moderation.");
              setUploadingMessage(t("upload_and_sent_for_moderation"));
              setFiles([]);
              toast.success(
                ` 
                ${successfulUploads} pictures - ${t(
                  "upload_and_sent_for_moderation"
                )}`
              );
              setLoading(false);
              setTimeout(() => {
                setUpload(null);
                setShowPopup(true);
              }, 2000);
            } catch (err) {
              console.error("Error uploading file:", err);
            }

            // // upload.on("httpUploadProgress", function (progress) {
            // //   let percentage = Math.floor((progress.loaded / progress.total) * 100);
            // //   console.log("Upload progress: " + percentage + "%");
            // //   setUploadPercentage(percentage + "%"); // Set the upload progress
            // // });

            // await upload.promise(); // Wait for the upload to complete
          }
        }
      } else {
        const querySnapshot = await eventsRef
          .where("district", "==", districtName.toUpperCase())
          .where("panchayat", "==", panchayatName.toUpperCase())
          .where("state", "==", location.state.toUpperCase())
          .where("block", "==", location?.block.toUpperCase())
          .limit(1)
          .get();
        // Check if no documents were found
        if (querySnapshot.empty) {
          console.log("heyy");
          const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;
          eventsRef
            .add(
              {
                created_by: "other",
                state: location?.state.toUpperCase(),
                district: districtName.toUpperCase(),
                panchayat: panchayatName.toUpperCase(),
                block: location?.block.toUpperCase(),
                timestamp: new Date(),
                event_date: "",
              },
              { merge: true }
            )
            .then(async (res) => {
              console.log(res);
              const updatePromises = [];
              const newMediaArray = []; // Array to hold newMedia objects for all files

              for (const file of validFiles) {
                const fileName = `${Date.now()}_${file.name}`;
                const newMedia = {
                  img_url: fileName,
                  moderated: false,
                };

                newMediaArray.push(newMedia);

                let dbMedia = firebase.firestore().collection("event_media");

                updatePromises.push(
                  dbMedia.add({
                    created_by: "other",
                    state: location?.state.toUpperCase(),
                    district: districtName.toUpperCase(),
                    panchayat: panchayatName.toUpperCase(),
                    block: location?.block.toUpperCase(),
                    timestamp: new Date(),
                    id: res.id,
                    moderated: false,
                    category: "other",
                    media_name: fileName ? fileName : "",
                    fileType: file?.type.startsWith("image/")
                      ? "image"
                      : "video",
                  })
                );

                // Upload the file to S3 (adjust S3 upload code accordingly)
                const contentType = file.type.startsWith("video")
                  ? "video/mp4"
                  : "image/*";

                const uploadParams = {
                  Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
                  Key: file.type.startsWith("image")
                    ? `organiser_images/${fileName}`
                    : `organiser_videos/${fileName}`,
                  //   Key: `event_media/${fileName}`,
                  Body: file, // The actual file object
                  ContentType: contentType, // The MIME type of the file
                };

                // const upload = s3.upload(uploadParams);

                const params = {
                  Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
                  Key: file.type.startsWith("image")
                    ? `organiser_images/${fileName}`
                    : `organiser_videos/${fileName}`,

                  Body: file,
                  ContentType: file.type,
                };
                const UPLOAD_UPDATE_INTERVAL = 1000; // Update progress every second
                try {
                  await handleUpload(params);
                  successfulUploads++;
                  // Update the progress
                  updateProgress(successfulUploads, validFiles.length);
                  setUpload("Uploaded and sent for moderation.");
                  setUploadingMessage(t("upload_and_sent_for_moderation"));
                  setFiles([]);
                  toast.success(
                    ` 
                ${successfulUploads} pictures - ${t(
                      "upload_and_sent_for_moderation"
                    )}`
                  );
                  setLoading(false);
                  setTimeout(() => {
                    setUpload(null);
                    setShowPopup(true);
                  }, 2000);
                } catch (err) {
                  console.error("Error uploading file:", err);
                }

                // // upload.on("httpUploadProgress", function (progress) {
                // //   let percentage = Math.floor((progress.loaded / progress.total) * 100);
                // //   console.log("Upload progress: " + percentage + "%");
                // //   setUploadPercentage(percentage + "%"); // Set the upload progress
                // // });

                // await upload.promise(); // Wait for the upload to complete
              }
            });
        } else {
          const updatePromises = [];
          const newMediaArray = []; // Array to hold newMedia objects for all files

          for (const file of validFiles) {
            const fileName = `${Date.now()}_${file.name}`;
            const newMedia = {
              img_url: fileName,
              moderated: false,
            };
            console.log(fileName);
            newMediaArray.push(newMedia);

            let dbMedia = firebase.firestore().collection("event_media");

            updatePromises.push(
              dbMedia.add({
                created_by: "other",
                state: location?.state.toUpperCase(),
                district: districtName.toUpperCase(),
                panchayat: panchayatName.toUpperCase(),
                block: location?.block.toUpperCase(),
                timestamp: new Date(),
                id: querySnapshot.docs[0].id,
                moderated: false,
                category: "other",
                media_name: fileName ? fileName : "",
                fileType: file?.type.startsWith("image/") ? "image" : "video",
              })
            );

            // Upload the file to S3 (adjust S3 upload code accordingly)
            const contentType = file.type.startsWith("video")
              ? "video/mp4"
              : "image/*";

            const uploadParams = {
              Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
              Key: file.type.startsWith("image")
                ? `organiser_images/${fileName}`
                : `organiser_videos/${fileName}`,
              //   Key: `event_media/${fileName}`,
              Body: file, // The actual file object
              ContentType: contentType, // The MIME type of the file
            };

            // const upload = s3.upload(uploadParams);

            const params = {
              Bucket: "static.mmmd.gov.in", // Replace with your S3 bucket name
              Key: file.type.startsWith("image")
                ? `organiser_images/${fileName}`
                : `organiser_videos/${fileName}`,

              Body: file,
              ContentType: file.type,
            };
            const UPLOAD_UPDATE_INTERVAL = 1000; // Update progress every second
            try {
              await handleUpload(params);
              successfulUploads++;
              // Update the progress
              updateProgress(successfulUploads, validFiles.length);
              setUpload("Uploaded and sent for moderation.");
              setUploadingMessage(t("upload_and_sent_for_moderation"));
              setFiles([]);
              toast.success(
                `${successfulUploads} pictures - ${t(
                  "upload_and_sent_for_moderation"
                )}`
              );
              setLoading(false);
              setTimeout(() => {
                setUpload(null);
                setShowPopup(true);
              }, 2000);
            } catch (err) {
              console.error("Error uploading file:", err);
            }

            // // upload.on("httpUploadProgress", function (progress) {
            // //   let percentage = Math.floor((progress.loaded / progress.total) * 100);
            // //   console.log("Upload progress: " + percentage + "%");
            // //   setUploadPercentage(percentage + "%"); // Set the upload progress
            // // });

            // await upload.promise(); // Wait for the upload to complete
          }
        }
      }

      console.log(`${successfulUploads} photos uploaded successfully`);
    } catch (error) {
      console.error("Error during file upload: ", error);
      toast.error(t("error_during_file_upload") + error);
      setUpload("Error during file upload: " + error);
      setLoading(false);
      setTimeout(() => {
        setUpload(null);
      }, 2000);
    } finally {
      // Hide the progress bar when the upload process finishes
      setUploading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("location", location);
  }, [location]);

  console.log(uploadPercentage);
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="container" style={{}}>
        <div className="d-flex justify-content-center align-items-center my-3">
          <p className="step_title py-2">
            {t(
              "Upload your image or a video of you doing an activity dedicated to Meri Maati Mera Desh"
            )}
          </p>
        </div>
        <StateDistrictBlockGP
          location={location}
          onUpdateLocation={updateLocation}
        />
        <Tabs defaultActiveKey="home" id="justify-tab-example" className="mb-3">
          <Tab eventKey="home" title="Add Links">
            <AddLinks location={location} />
          </Tab>
          <Tab eventKey="profile" title="Upload Media">
            <div className="row mt-5">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  opacity: location.gp ? 1 : location?.ulb ? 1 : 0.4,
                  pointerEvents: location.gp ? "" : location?.ulb ? "" : "none",
                }}
              >
                <div>
                  <div
                    className="d-flex justify-content-center"
                    style={{ height: "250px", minWidth: "200px" }}
                  >
                    <div className="img_box">
                      <div className="pt-3">
                        <img
                          src="./asset/images/upload.svg"
                          style={{ height: "6vh" }}
                        />
                        <div className="my-5 ">
                          <input
                            type="file"
                            id="file"
                            accept="image/*,video/*"
                            multiple
                            onChange={(e) => {
                              if (e.target.files.length > 20) {
                                toast.error(t("select_20"));
                                e.target.value = "";
                              } else {
                                setFiles([...files, ...e.target.files]);
                              }
                            }}
                            style={{
                              display: "none", // Hide the default input appearance
                            }}
                          />
                          <div className="file_name">
                            {files.length > 0 && (
                              <p>{files.map((file) => file.name).join(", ")}</p>
                            )}
                          </div>
                          <label htmlFor="file" className="custom-input-button">
                            {t("choose_file")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="d-flex justify-content-center py-3">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <button
                        disabled={loading}
                        className="pledge_btn mt-4"
                        onClick={fileUpload}
                      >
                        {t("submit")}
                      </button>
                    </div>
                  )}

                  {uploadingMessage && (
                    <div className="d-flex justify-content-center align-items-center">
                      <p className="step_title py-2">{uploadingMessage}</p>
                    </div>
                  )}

                  {uploading && (
                    <div>
                      <progress value={progress} max={100}></progress>
                      <span>{progress}% Complete</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="py-3">
              <p className="note_text text-center">
                {t("File Size 10 Mb for Image & 200 Mb for Video")}
              </p>
              <p className="note_text text-center">{t("select_20")}</p>
              <p className="note_text text-center">
                {t("File type  Image & Video only")}
              </p>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
