import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
const DropdownComponent = ({
  // setSelectedState,
  // setSelectedBlock,
  // setSelectedDistrict,
  // setSelectedPanchayat,
  // setSelectedVillage,
  // setSelectedULB,
  onUpdateLocation,
  showVillage = null,
}) => {
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [state_name, setStateName] = useState();
  const [district_name, setDistrictName] = useState();
  const [block_name, setBlockName] = useState();
  const [ulb_name, setULBName] = useState();
  const [ulbs, setUlbs] = useState([]);
  const [locationType, setLocationtype] = useState("RURAL");
  const [panchayat_name, setPanchayatName] = useState();
  const [village_name, setVillageName] = useState();
  const [villages, setVillages] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedPanchayat, setSelectedPanchayat] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [selectedULB, setSelectedULB] = useState("");
  const handleLocationChange = (field, value) => {
    // Update the parent's location state
    onUpdateLocation(field, value);
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("state_lgd_mapping")
      .orderBy("state")
      .get()
      .then((res) => {
        let array = [];
        res?.docs?.forEach((e) => {
          array.push({
            ...e?.data(),
          });
        });
        setStates(array);
      });
  }, []);
  useEffect(() => {
    if (state_name) {
      handleLocationChange("state", JSON.parse(state_name)?.state);
      handleLocationChange("district", "");
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
      handleLocationChange("ulb", "");
      setDistrictName("");
      setBlockName("");
      setPanchayatName("");
      setVillageName("");
      firebase
        .firestore()
        .collection("state_district_lgd_mapping")
        .where("state_code", "==", JSON.parse(state_name)?.state_code)
        .get()
        .then((res) => {
          let array = [];
          res?.docs?.forEach((e) => {
            array.push({
              ...e?.data(),
            });
          });
          setDistrict(array);
        });
    }
  }, [state_name]);
  useEffect(() => {
    if (district_name) {
      handleLocationChange("district", JSON.parse(district_name)?.district);
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
      handleLocationChange("ulb", "");
      setBlockName("");
      setPanchayatName("");
      setVillageName("");
      LGDSelector(
        "district_code",
        JSON.parse(district_name).district_code,
        setBlocks
      );
      firebase
        .firestore()
        .collection("ulb_lgd_mapping")
        .where("district_code", "==", JSON.parse(district_name)?.district_code)
        .get()
        .then((res) => {
          let array = [];
          res?.docs?.forEach((e) => {
            array.push({
              ...e?.data(),
            });
          });
          setUlbs(array);
        });
    }
  }, [district_name]);
  useEffect(() => {
    if (ulb_name) {
      handleLocationChange("ulb", JSON.parse(ulb_name)?.ulb);
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
      setPanchayatName("");
      setVillageName("");
      LGDSelector("ulb_code", JSON.parse(ulb_name).ulb_code, setULBName);
    }
  }, [ulb_name]);

  useEffect(() => {
    if (block_name) {
      handleLocationChange("block", JSON.parse(block_name)?.block_name);
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
      handleLocationChange("ulb", "");
      setPanchayatName("");
      setVillageName("");
      LGDSelector(
        "block_code",
        JSON.parse(block_name).block_code,
        setPanchayats
      );
    }
  }, [block_name]);
  useEffect(() => {
    if (panchayat_name) {
      handleLocationChange("gp", JSON.parse(panchayat_name)?.local_body_name);
      LGDSelector(
        "local_body_code",
        JSON.parse(panchayat_name).local_body_code,
        setVillages
      );
    }
  }, [panchayat_name]);
  function removeDuplicatesByKey(arr, key) {
    const uniqueValues = new Set();
    const deduplicatedArray = [];
    for (const obj of arr) {
      const keyValue = obj[key];
      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        deduplicatedArray.push(obj);
      }
    }
    // Sort the deduplicated array alphabetically based on the specified key
    deduplicatedArray.sort((a, b) => {
      const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const valueB = b[key].toUpperCase();
      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });
    return deduplicatedArray;
  }
  const LGDSelector = (keyToSearch, valueToSearch, setReturnData) => {
    // console.log(keyToSearch, valueToSearch);
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("lgd_data_mapped"); // Replace with your Firestore collection name
    // Use Firestore queries to filter data based on key and value
    collectionRef
      .where(keyToSearch, "==", valueToSearch)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        // console.log(keyToSearch.split("_")[0] + "_name");
        if (keyToSearch == "district_code") {
          setReturnData(removeDuplicatesByKey(data, "block_code"));
        }
        if (keyToSearch == "block_code") {
          setReturnData(removeDuplicatesByKey(data, "local_body_code"));
        }
        if (keyToSearch == "local_body_code") {
          setReturnData(removeDuplicatesByKey(data, "village_code"));
        }
        // setReturnData(data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  };
  return (
    <div className="container">
      <>
        <div className="row">
          <div className="col-md-3">
            <h6>Select Type</h6>
            <select
              className="select-input"
              style={{ width: "100%" }}
              name="state"
              onChange={(e) => {
                setLocationtype(e.target.value);
              }}
              value={locationType}
              required
            >
              <option value="">Select Options</option>
              {["RURAL", "URBAN"].map((institute) => (
                <option key={institute} value={institute}>
                  {institute}
                </option>
              ))}
            </select>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-3">
            <h6>Select State * :</h6>
            <select
              className="select-input"
              style={{ width: "100%" }}
              name="state"
              onChange={(e) => {
                setStateName(e.target.value);
                setSelectedState(e.target.value);
              }}
              value={state_name}
              required
            >
              <option value="">Select Options</option>
              {states.map((institute) => (
                <option
                  key={institute.state_code}
                  value={JSON.stringify(institute)}
                >
                  {institute.state}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <h6>Select District * :</h6>
            <select
              className="select-input"
              style={{ width: "100%" }}
              name="district"
              onChange={(e) => {
                // console.log(e.target.value);
                setSelectedDistrict(e.target.value);
                setDistrictName(e.target.value);
              }}
              value={district_name}
              required
            >
              <option value="">Select Options</option>
              {districts.map((institute) => (
                <option
                  key={institute.district_code}
                  value={JSON.stringify(institute)}
                >
                  {institute.district}
                </option>
              ))}
            </select>
          </div>
          {locationType == "RURAL" && (
            <div className="col-md-3">
              <h6>Select Block * :</h6>
              <select
                className="select-input"
                style={{ width: "100%" }}
                name="block"
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSelectedBlock(e.target.value);
                  setBlockName(e.target.value);
                }}
                value={block_name}
                required
              >
                <option value="">Select Options</option>
                {blocks.map((institute) => (
                  <option
                    key={institute.block_code}
                    value={JSON.stringify(institute)}
                  >
                    {institute.block_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {locationType == "RURAL" && (
            <div className="col-md-3">
              <h6>Select Panchayat * :</h6>
              <select
                className="select-input"
                style={{ width: "100%" }}
                name="panchayat"
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSelectedPanchayat(e.target.value);
                  setPanchayatName(e.target.value);
                }}
                value={panchayat_name}
                required
              >
                <option value="">Select Options</option>
                {panchayats.map((institute) => (
                  <option
                    key={institute.local_body_code}
                    value={JSON.stringify(institute)}
                  >
                    {institute.local_body_name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {locationType == "URBAN" && (
            <div className="col-md-3">
              <h6>Select ULB * :</h6>
              <select
                className="select-input"
                style={{ width: "100%" }}
                name="state"
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSelectedULB(e.target.value);
                  setULBName(e.target.value);
                }}
                value={ulb_name}
                required
              >
                <option value="">Select Options</option>
                {ulbs.map((institute) => (
                  <option
                    key={institute.ulb_code}
                    value={JSON.stringify(institute)}
                  >
                    {institute.ulb}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
        <br />
        {showVillage && (
          <div className="row">
            {locationType == "RURAL" && (
              <div className="col-md-3">
                <h6>Select Village * :</h6>
                <select
                  className="select-input"
                  style={{ width: "100%" }}
                  name="panachayat"
                  onChange={(e) => {
                    setSelectedVillage(e.target.value);
                    setVillageName(e.target.value);
                  }}
                  value={village_name}
                  required
                >
                  <option value="">Select Options</option>
                  {villages.map((institute) => (
                    <option
                      key={institute.village_name}
                      value={JSON.stringify(institute)}
                    >
                      {institute.village_name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};
export default DropdownComponent;
