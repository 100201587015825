import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function Step() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="container">
        <div className="d-flex justify-content-center align-items-center">
          <p className="step_title py-2">
            {t("mitti_of_the_motherland_binds_us_all_together")}
          </p>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="step">
              <div className="step_inner">
                <p className="step_title">{t("step")} 1</p>
                <div className="d-flex justify-content-center align-items-center">
                  <img className="step_img" src={t("step_form")} />
                </div>
                <p className="step_text mx-2">
                  {t("enter_name_and_enter_number")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="step">
              <div className="step_inner">
                <p className="step_title">{t("step")} 2</p>
                <div className="d-flex justify-content-center align-items-center">
                  <button className="pledge_btn mx-2">
                    {t("take_pledge")}
                  </button>
                </div>
                <p className="step_text">
                  {t("Read the pledge on the screen and click Take Pledge")}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="step">
              <div className="step_inner">
                <p className="step_title">{t("step")} 3</p>
                <div className="d-flex justify-content-center align-items-center mx-2 my-2">
                  <Carousel fade interval={3000}>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./asset/images/slider_img/sample1.jpg"
                        alt=""
                      />
                      <Carousel.Caption>
                        <p>{t("sample_caption1")}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./asset/images/slider_img/sample2.jpg"
                        alt=""
                      />
                      <Carousel.Caption>
                        <p>{t("sample_caption2")}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./asset/images/slider_img/sample3.jpg"
                        alt=""
                      />
                      <Carousel.Caption>
                        <p>{t("sample_caption3")}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./asset/images/slider_img/sample4.jpg"
                        alt=""
                      />
                      <Carousel.Caption>
                        <p>{t("sample_caption4")}</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </div>
                <p className="step_text mx-2">{t("step3")}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div className="step">
              <div className="step_inner">
                <p className="step_title">{t("step")} 4</p>
                <div className="d-flex justify-content-center align-items-center">
                  <img className="step_img" src={t("step_upload")} />
                </div>
                <div className="drop_box d-flex justify-content-center align-items-center">
                  <div className="text-center">
                    <p className="step_text">{t("upload_media")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Link to="/pledge">
          <button className="pledge_btn">{t("take_pledge")}</button>
        </Link>
      </div>
    </div>
  );
}
