import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import "./Home.css";
import { useTranslation } from "react-i18next";
import firebase from "../../firebase";
import { Col, Tab, Tabs } from "react-bootstrap";
import info_data from "../Assets/data/info_data.js";
import axios from "axios";
import "react-image-lightbox/style.css";
import styled from "styled-components";

export default function GrandFinale() {
  const { t } = useTranslation();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [activeKey, setActiveKey] = useState("information_center");
  const [view, setView] = useState("29");
  const [selectedState, setSelectedState] = useState("");
  const [LGDState, setLGDState] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [result, setResult] = useState(null);
  const [eventMedia, setEventMedia] = React.useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsPoster, setImageUrlsPoster] = useState([]);
  const [isPopupLoading, setIsPopupLoading] = useState(false); // Step 2
  const [imageUrl, setImageUrl] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState("");

  // Get Signed URL
  const getSignedURLFromCF = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/400x400/filters:format(webp)/filters:quality(70)/phase3_images/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    return data;
  };

  // Get Signed URL
  const getSignedURLFromCFPoster = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/800x800/filters:format(webp)/filters:quality(70)/phase3_images/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    setSelectedImageIndex(response?.data?.data);
    // console.log(data, "datra");
    return data;
  };

  // Fetch all user Images Asynchronously
  useEffect(() => {
    // console.log(eventMedia);
    // if (!eventMedia?.media_name) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      const urls2 = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      setImageUrlsPoster(urls2);
      setImageUrls(urls);
    };
    fetchImageUrls();
  }, [eventMedia]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let db = firebase.firestore();
    db.collection("phase3_media")
      .where("phoneNumber", "==", phoneNumber)
      .get()
      .then((querySnapshot) => {
        const dataArray = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        if (dataArray.length === 0) {
          setNoDataMessage("No data found for the provided mobile number.");
        } else {
          setNoDataMessage(""); // Clear the message if there is data.
          setEventMedia([...eventMedia, ...dataArray]);
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  };

  useEffect(() => {
    setFilteredData();
  }, []);

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("ak_lgd_state")
      .get()
      .then((res) => {
        // console.log("res", res);
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data());
        });
        array = array.slice().sort((a, b) => a.state.localeCompare(b.state));
        // console.log(array.sort());
        setLGDState(array);
      });
  }, []);

  function downloadImageAsBase64() {
    // Create a new Image object
    var img = new Image();
    // Set the crossorigin attribute and property
    img.crossOrigin = "Anonymous";
    // Set a callback to handle the image load event
    img.onload = function () {
      // Create a canvas element to draw the image
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);

      // Convert the image to a base64 encoded string
      var base64Data = canvas.toDataURL("image/png");

      // Create a temporary anchor element to trigger the download
      var a = document.createElement("a");
      a.href = base64Data;
      a.download = "image.png";

      // Trigger a click event on the anchor element to initiate the download
      a.click();
    };

    // Set the source of the image to the provided URL
    img.src = imageUrl;
  }

  const handleClickImage = async (index) => {
    console.log(index, "index");
    setIsPopupLoading(true); // Set loading state to true (Step 3)
    setSelectedImageIndex(index);
    try {
      await getSignedURLFromCFPoster(eventMedia[index]?.media_name);
    } catch (error) {
      console.error("Error loading image: ", error);
    }
    setIsPopupLoading(false); // Set loading state back to false
  };

  const handleClosePopup = () => {
    setSelectedImageIndex(null);
    setImageUrl(null);
  };

  return (
    <>
      <video
        id="background-video"
        autoPlay
        muted
        playsinline
        poster="./asset/images/default.jpg"
        style={{ opacity: isVideoLoaded ? 1 : 0 }}
      >
        <source src="./asset/video/mb_vid.mp4" type="video/mp4" />
      </video>

      <div className="main_body ease-in-anim">
        <Header />
        <div className="content-body-nodal">
          <Tabs
            id="justify-tab-example"
            className="mb-3"
            style={{
              padding: "24px",

              border: "none",
              gap: "20px",
              color: "brown",
            }}
            justify
            onSelect={(e) => {
              setActiveKey(e);
            }}
          >
            <Tab eventKey="information_center" title="Information Center">
              <Col md={2} className="px-2">
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  State:
                </h6>

                <select
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "100%",
                  }}
                  className="mx-2"
                  tabIndex="2"
                  name="state"
                  onChange={(e) => {
                    const selectedIndex = e.target.value;
                    setSelectedState(LGDState[selectedIndex]);
                    // Filter the data based on the selected state
                    const filteredData = info_data.filter(
                      (item) =>
                        item.state_code === LGDState[selectedIndex].state_code
                    );
                    setFilteredData(filteredData);
                  }}
                >
                  <option value="" disabled>
                    Select State
                  </option>
                  {/* Map over your states data */}
                  {LGDState?.sort().map((state, index) => (
                    <option key={state + index} value={index}>
                      {state.state}
                    </option>
                  ))}
                </select>
              </Col>

              {selectedState && filteredData && (
                <div className="table-container">
                  <table className="report-table">
                    <thead>
                      <tr>
                        <th>Accommodation</th>
                        <th>Transportation Details</th>
                        <th>Food</th>
                        <th>Help Center</th>
                        <th>NCC Details Guide</th>
                        <th>How to reach</th>
                        <th>Camp 1</th>
                        <th>Camp 2</th>
                        <th>30th Event Venue</th>
                        <th>31st Event Venue</th>
                        <th>Medical Emergency</th>
                        <th>FAQ</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData?.map((item, index) => (
                        <tr key={index}>
                          <td>{item.details.accomodation_on}</td>
                          <td>{item.details.transportation_details}</td>
                          <td>{item.details.food}</td>
                          <td>{item.details.help_center}</td>
                          <td>{item.details.ncc_detail_guide}</td>
                          <td>{item.details.how_to_reach}</td>
                          <td>{item.details.camp1}</td>
                          <td>{item.details.camp2}</td>
                          <td>{item.details.event_venue_30}</td>
                          <td>{item.details.event_venue_31}</td>
                          <td>{item.details.medical_emergency}</td>
                          <td>{item.details.faq}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </Tab>
            <Tab eventKey="programme_details" title="Programme Details">
              <div className="d-flex justify-content-center align-items-center mt-3">
                <div>
                  <button
                    className={
                      view == "29"
                        ? "pledge_btn_org mx-2"
                        : "pledge_btn_white mx-2"
                    }
                    onClick={() => setView("29")}
                  >
                    29th Programme
                  </button>
                </div>
                <div>
                  <button
                    className={
                      view == "30"
                        ? "pledge_btn_org m-2"
                        : "pledge_btn_white m-2"
                    }
                    onClick={() => setView("30")}
                  >
                    30th Programme
                  </button>
                </div>

                <div>
                  <button
                    className={
                      view == "31"
                        ? "pledge_btn_org mx-2"
                        : "pledge_btn_white mx-2"
                    }
                    onClick={() => setView("31")}
                  >
                    31th Programme
                  </button>
                </div>
              </div>
            </Tab>
            <Tab eventKey="download_media" title="Download Media">
              <div>
                <form onSubmit={handleSubmit}>
                  <label>
                    Enter Mobile number:
                    <input
                      className="login-input"
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </label>
                  <div className="">
                    <button className="submit_btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
                {noDataMessage && <p>{noDataMessage}</p>}
                {!eventMedia?.length == 0 && (
                  <div className="row">
                    {eventMedia?.map((item, index) => (
                      <div
                        className="col-lg-4 col-md-4 col-sm-12 my-2"
                        key={index}
                      >
                        <div
                          style={{
                            borderRadius: "15px",
                            position: "relative",
                            overflow: "hidden",
                          }}
                        >
                          {/* Replace your existing rendering code for images with this */}
                          {imageUrls?.length > 0 && (
                            <div
                              className="pledge_item"
                              style={{
                                borderRadius: "15px",
                                position: "relative",
                                overflow: "hidden",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleClickImage(index);
                                setImageUrl(imageUrls[index]);
                              }}
                            >
                              <VillageImageDiv
                                style={{
                                  backgroundImage: `url('${imageUrls[index]}')`,
                                  backgroundColor: "#fff",
                                  borderRadius: "15px",
                                  backgroundSize: "cover",
                                  filter: "blur(40px)",
                                  height: "18vh",
                                  overflow: "hidden",
                                  cursor: "pointer",
                                }}
                              />
                              <VillageImageDiv
                                className="th-border-top th-border-bottom"
                                style={{
                                  backgroundImage: `url('${imageUrls[index]}')`,
                                  borderRadius: "15px",
                                  position: "absolute",
                                  top: "0%",
                                  right: "0%",
                                  bottom: "0%",
                                  backgroundSize: "contain",
                                  cursor: "pointer",
                                }}
                              />
                              {/* {console.log(
                        item?.timestamp,
                        "timestamp",
                        item?.created_at,
                        "created_at"
                      )} */}
                              {/* {item?.timestamp ||
                        (item?.created_at && (
                          <div
                            className="p-2 name_text"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#1ca63b",
                              color: "#fff",
                              fontSize: "2vh",
                            }}
                          >
                            Uploaded on -
                            {item?.timestamp && (
                              <FormatDate date={item?.timestamp} />
                            )}
                            {item?.created_at && (
                              <FormatDate date={item?.created_at} />
                            )}
                          </div>
                        ))}  */}
                            </div>
                          )}

                          {selectedImageIndex !== null && (
                            <div
                              className="image-popup-overlay"
                              onClick={handleClosePopup}
                            >
                              <div className="image-popup">
                                {!isPopupLoading && (
                                  <>
                                    <button
                                      className="close-button"
                                      onClick={handleClosePopup}
                                    >
                                      <img
                                        src="https://merimaatimeradesh.web.app/close.svg"
                                        alt="Close"
                                        style={{ height: "20px" }}
                                      />
                                    </button>
                                  </>
                                )}
                                {isPopupLoading ? ( // Conditionally render loader (Step 4)
                                  <div className="loader">
                                    <span></span>
                                  </div>
                                ) : (
                                  <div>
                                    <img src={selectedImageIndex} alt="Popup" />
                                    <button
                                      className="download-button"
                                      onClick={downloadImageAsBase64}
                                    >
                                      <img
                                        src="/download.svg"
                                        alt="Download"
                                        style={{ height: "20px" }}
                                      />
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}
export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;
