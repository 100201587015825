import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

export default function TermsAndConditions() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_body">
      <Header />
      <br />
      <div>
        <h1 className="main_head text-center">TERMS OF USE</h1>
      </div>

      <div className="container mt-5 px-4">
        <h6>1. GENERAL:</h6>
        <p>
          a) This document is an electronic record in terms of Information
          Technology Act, 2000 and rules there under as applicable and the
          amended provisions pertaining to electronic records in various
          statutes as amended by the Information Technology Act, 2000. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>
        <p>
          b) This document is published in accordance with the provisions of
          Rule 3 (1) of the Information Technology (Intermediaries guidelines)
          Rules, 2011 that require publishing the rules and regulations, privacy
          policy and Terms of Use for access or usage of
          merimaatimeradesh.gov.in.
        </p>
        <p>
          c) The "Meri Maati Mera Desh" ("the project") is an initiative of the
          Ministry of Youth Affairs & Sports (Ministry) which will be organized
          in both Rural and Urban Areas to ensure inclusivity and widespread
          participation in commemorating 75 years of India's independence and
          celebrating the rich history, culture, and achievements of its people.
          By involving both rural and urban communities, the campaign aims to
          unite the entire nation in expressing gratitude to the brave Veers who
          made the supreme sacrifice for the country.
        </p>
        <p>
          d) The domain name merimaatimeradesh.gov.in ("Website"), including its
          online services, such as use of software on website and information,
          articles , news, videos, blogs and text, graphics, images and
          information obtained from service providers and any other material
          contained on the website is owned and operated by the Ministry, where
          such expression shall, unless repugnant to the context thereof,
          officers, deemed to include its respective representatives,
          administrators and officers.
        </p>
        <p>
          e) For the purpose of these Terms of Use ("Terms"), wherever the
          context so requires, i) The term 'You' or "User" shall mean any legal
          person or entity accessing using this Website to services provided or
          registered on this Mobile Application/Website, who is competent to
          enter into binding contracts, as per the provisions of the Indian
          Contract Act, 1872; ii) The terms 'We', 'Us' & 'Our' shall mean the
          website or Ministry as the context so requires. iii) The terms
          'Party'& 'Parties' shall respectively be used to refer to the User and
          the Ministry individually and collectively, as the context so
          requires.
        </p>
        <p>
          f) The headings of each section in these Terms are only for the
          purpose of organizing the various provisions under these Terms in an
          orderly manner, and shall not be used by either Party to interpret the
          provisions contained herein in any manner. Further, it is specifically
          agreed to by the Parties that the headings shall have no legal or
          contractual value.
        </p>

        <p>
          g) The use of the website by the user is solely governed by these
          Terms as well as the Privacy Policy ("Policy"), available on Website
          and any modifications or amendments made thereto by the Ministry from
          time to time, at its sole discretion. Visiting the home page of the
          Website and/or using any of the services provided on the Website shall
          be deemed to signify the User's unequivocal acceptance of these Terms
          and the aforementioned Policy, and the User expressly agrees to be
          bound by the same. The User expressly agrees and acknowledges that the
          Terms and Policy are co-terminus, and that expiry / termination of
          either one will lead to the termination of the other, save as provided
          in Clause 4 hereunder.
        </p>
        <p>
          h) The User unequivocally agrees that these Terms and the
          aforementioned Policy constitute a legally binding agreement between
          the User and the Ministry, and that the User shall be subject to the
          rules, guidelines, policies, terms, and conditions applicable to any
          service that is provided by the Website, and that the same shall be
          deemed to be incorporated into these Terms, and shall be treated as
          part and parcel of the same. The User acknowledges and agrees that no
          signature or express act is required to make these Terms and the
          Policy binding on the User, and that the User's act of visiting any
          part of the Website constitutes the User's full and final acceptance
          of these Terms and the aforementioned Policy.
        </p>
        <p>
          i) The Ministry reserves the sole and exclusive right to amend or
          modify these Terms without any prior permission or intimation to the
          User, and the User expressly agrees that any such amendments or
          modifications shall come into effect immediately. The User has a duty
          to periodically check the terms and stay updated on its requirements.
          In so far as the User complies with these Terms, he/she is granted a
          personal, non-exclusive, non-transferable, revocable, limited
          privilege to enter and use the Website.
        </p>

        <h6>2. ABOUT MERI MAATI MERA DESH (MMMD) PROJECT:</h6>
        <p>
          "Meri Maati Mera Desh" campaign is envisaged as a culminating event of
          'Azadi Ka Amrit Mahotsav'. It involves paying tribute to the 'Veers',
          who have made the supreme sacrifice for the country, by conducting
          ceremonies at Panchayat/Village, Block, Urban Local Bodies, State and
          National level. The programme is being organized by the Ministry of
          Youth Affairs & Sports. The key objectives of the "Meri Maati Mera
          Desh" campaign are:
        </p>
        <p>
          a. Tribute to the Veers: The campaign aims to pay a heartfelt tribute
          to the Veers who have made the supreme sacrifice for India's freedom
          and safeguarding its integrity.
        </p>
        <p>
          b. Commemoration at Various Levels: The campaign endeavours to
          organize ceremonies at multiple levels, starting from the grassroots
          level in Panchayats/Villages, extending to Blocks, Urban Local Bodies,
          and States, and culminating at the National level.{" "}
        </p>
        <p>
          c. Renewing Commitment to the Nation: Through the Panch Pran Pledge,
          the campaign aims to foster a renewed sense of commitment among the
          citizens towards building a developed India and removing any traces of
          colonial mindset and taking pride in promoting and preserving India's
          rich cultural heritage.
        </p>

        <p>
          d. Environmental Conservation: The campaign promotes environmental
          conservation through the Vasudha Vandhan initiative, which involves
          planting 75 saplings of indigenous species in each Gram
          Panchayat/Village.{" "}
        </p>
        <p>
          e. Felicitation of Freedom Fighters and Defence Personnel: The
          campaign seeks to honour freedom fighters, their families, and retired
          personnel from Defence, Central Armed Police Forces (CAPF), and State
          Police, acknowledging their invaluable contributions and sacrifices in
          service to the nation.
        </p>

        <h6>3. ELIGIBILITY:</h6>
        <p>
          {" "}
          The User represents and warrants that he/she is competent and eligible
          to enter into legally binding agreements and that he/she has the
          requisite authority to bind himself/herself to these Terms, as
          determined solely by the provisions of the Indian Contract Act, 1872.
          The User may not use this website if he/she is not competent to
          contract under the Indian Contract Act, 1872, or is disqualified from
          doing so by any other applicable law, rule or regulation currently in
          force.
        </p>
        <h6>4. TERM:</h6>
        <p>
          These Terms shall continue to form a valid and binding contract
          between the Parties, and shall continue to be in full force and effect
          until:{" "}
        </p>
        <p>
          a) The User continues to access and use the Mobile
          Application/Website; or
        </p>
        <p>
          b) The Transaction between the Parties, if any, concludes to the
          satisfaction of both Parties;
        </p>
        <h6>5. TERMINATION:</h6>
        <p>
          The Ministry reserves the right, in its sole discretion, to
          unilaterally terminate the User's access to the services offered on
          the Website, or any portion thereof, at any time, without notice or
          cause. The User shall continue to be bound by these Terms, and it is
          expressly agreed to by the Parties that the User shall not have the
          right to terminate these Terms till the expiry of the same, as
          described in Section 3 herein above.{" "}
        </p>
        <h6>6. TERMS, CONDITIONS AND DUTIES OF THE USER:</h6>
        <p>
          a. It is the duty of the User to provide one's true/accurate/complete
          information, and all the details relating thereof on the Website. If
          any Use detail found to be false then Ministry shall reserve the right
          on its sole discretion to reject the registration and debar the User
          from using the Services available on its Website, without prior
          intimation whatsoever.
        </p>
        <p>
          b. The User agrees to comply with all notices or instructions given by
          the Ministry from time to time to enable the use of the Services.
        </p>
        <p>
          c. User shall indemnify and hold the Ministry, harmless for any loss
          or damage arising out of User's to comply with any applicable laws or
          regulations and for breach of the following warranties and
          representations.
        </p>
        <p>
          d. The User shall at all times during the pendency of this agreement
          endeavor to protect and promote the interest of the Ministry and
          ensure that there will be no damage to third party due to act/
          omission on the part of the User.
        </p>
        <p>
          e. Users are bound not to cut, copy, distribute, modify, recreate,
          reverse engineer, distribute, disseminate, post, publish or create
          derivative works from, transfer, or sell any information or software
          obtained from the Website. Any such use / limited use of the Website
          will only be allowed with the prior express written permission of the
          Ministry. For the removal of doubt, it is clarified that unlimited or
          wholesale reproduction, copying of the content for commercial or
          non-commercial purposes and unwarranted modification of data and
          information contained on the Website is expressly prohibited.
        </p>
        <p>
          f. The user will be responsible for maintaining the confidentiality of
          the account and password for restricting access to your computer to
          prevent unauthorized access to the account.
        </p>
        <p>
          g. The User further undertakes not to: i. Engage in any activity that
          interferes with or disrupts access to the Website or the services
          provided therein (or the servers and networks which are connected to
          the Website); ii. Publish, post, disseminate, any information which is
          grossly harmful, harassing, blasphemous, defamatory, obscene,
          pornographic, paedophilic, libellous, invasive of another's privacy,
          hateful, or racially, ethnically objectionable, disparaging, relating
          or encouraging money laundering or gambling, or otherwise unlawful in
          any manner whatever under any law, rule or regulation currently in
          force; or unlawfully threatening or unlawfully harassing including but
          not limited to "indecent representation of women" within the meaning
          of the Indecent Representation of Women (Prohibition) Act, 1986; iii.
          Post any image/file/data that infringes the copyright, patent or
          trademark of another person or legal entity; iv. Upload or distribute
          files that contain viruses, corrupted files, or any other similar
          software or programs that may damage the operation of the Website; v.
          Download any file posted/uploaded by another User of the Website that
          the User is aware, or should reasonably be aware, cannot be legally
          distributed in such a manner; vi. Probe, scan or test the
          vulnerability of the Website or any network connected to the Website,
          nor breach the security or authentication measures on the Website or
          any network connected to the Website. The User may not reverse
          look-up, trace or seek to trace any information relating to any other
          User of, or visitor to, the Website, or any other customer of the
          Website, including any User account maintained on the Website not
          operated/managed by the User, or exploit the Website or information
          made available or offered by or through the Website, in any manner;
          vii. Disrupt or interfere with the security of, or otherwise cause
          harm to, the Website, systems resources, accounts, passwords, servers
          or networks connected to or accessible through the Mobile Websites or
          any affiliated or linked Mobile Websites; viii. Use the Website or any
          material or content therein for any purpose that is unlawful or
          prohibited by these Terms, or to solicit the performance of any
          illegal activity or other activity which infringes the rights of this
          Website or any other third party/ies; ix. Violate any code of conduct
          or guideline which may be applicable for or to any particular service
          offered on the Website; x. Violate any applicable laws, rules or
          regulations currently in force within or outside India; xi. Violate
          any portion of these Terms or the Policy, including but not limited to
          any applicable additional terms of the Website contained herein or
          elsewhere, whether made by amendment, modification, or otherwise; xii.
          Threaten the unity, integrity, defence, security or sovereignty of
          India, friendly relations with foreign states, or public order, or
          cause incitement to the commission of any cognizable offence, or
          prevent the investigation of any offence, or insult any other nation.
          xiii. Publish, post, or disseminate information that is false,
          inaccurate or misleading; xiv. Commit any act that causes the Ministry
          to lose (in whole or in part) the services of its internet service
          provider ("ISP") or in any manner disrupts the services of any other
          supplier/service provider of the Ministry/Website. xv. The User
          expressly agrees and acknowledges that the Ministry/Website has no
          obligation to monitor the materials posted on the Website, but that it
          has the right to remove or edit any content that in its sole
          discretion violates, or is alleged to violate, any applicable law or
          either the spirit or letter of these Terms.
        </p>
        <h6>
          7. NON- DEFAMATION AND CONTENT/IMAGE/VIDEO AND OTHER DATA
          RESPONSIBILITY:
        </h6>
        <p>
          The Ministry, along with its associated company involved in the "Meri
          Maati Mera Desh" project, cannot be defamed or subjected to any form
          of defamatory actions on any platform including all social media
          platforms or any other way. Users are encouraged to contribute content
          and data while adhering to accuracy and integrity. It is strictly
          prohibited to use the platform for malicious or defamatory purposes
          that harm the reputation or dignity of the Ministry, associated
          company, officials, or any other stakeholders.{" "}
        </p>
        <p>
          The Ministry and associated company reserve the right to take
          appropriate legal action against individuals or entities found guilty
          of defaming or maligning their image. By ensuring responsible
          content/image/video contribution and respecting the reputation of the
          Ministry and associated company, the platform can continue to meet the
          programme objective as defined above.
        </p>
        <h6>8. SUSPENSION OF USER ACCESS AND ACTIVITY:</h6>
        <p>
          Notwithstanding other legal remedies that may be available to it,
          Ministry may in its sole discretion limit the User's access and/ or
          activity by immediately removing the User's access credentials either
          temporarily or indefinitely, or suspend / terminate the User's
          membership, and/or refuse to provide User with access to the Website,
          without being required to provide the User with notice or cause:
        </p>
        <p>a) If the User is in breach any of these Terms or the Policy;</p>
        <p>
          b) If the User has provided wrong, inaccurate, incomplete or incorrect
          information;
        </p>
        <p>
          c) If the User's actions may cause any harm, damage or loss to the
          other Users or to the Website/Ministry, at the sole discretion of the
          Ministry.
        </p>
        <p>
          d) If User' action copying or duplicating in any manner any of content
          or other information available from the Website.
        </p>
        <h6>9. INDEMNITY AND LIMITATIONS:</h6>
        <p>
          The User hereby expressly agrees to defend, indemnify and hold
          harmless the Website and the Ministry, its officials, affiliate Users,
          employees, against any and all claims, liabilities, damages, losses,
          costs and expenses, including attorney's fees, caused by or arising
          out of claims based upon the User's actions or inactions, including
          but not limited to any warranties, representations or undertakings, or
          in relation to the non-fulfilment of any of the User's obligations
          under this Agreement, or arising out of the User's infringement of any
          applicable laws, rules and regulations, including but not limited to
          infringement of intellectual property rights, payment of statutory
          dues and taxes, claims of libel, defamation, violation of rights of
          privacy or publicity, loss of service by other subscribers, or the
          infringement of any other rights of a third party.{" "}
        </p>
        <h6>10. INTELLECTUAL PROPERTY RIGHTS: </h6>
        <p>
          Unless expressly agreed to in writing, nothing contained herein shall
          give the User a right to use any of the Website's trade names,
          trademarks, service marks, logos, domain names, information,
          questions, answers, solutions, reports, images, videos and other
          distinctive features, save according to the provisions of these Terms.
          All logos, trademarks, brand names, service marks, domain names,
          including material, designs, and graphics created by and developed by
          the Website and other distinctive brand features of the Website are
          the property of the Ministry. Furthermore, with respect to the Website
          created by the Ministry the Ministry shall be the exclusive owner of
          all the designs, graphics and the like, related to the Mobile
          Application/Website.{" "}
        </p>
        <p>
          The User may not use any of the intellectual property displayed on the
          Website in any manner that is likely to cause confusion among existing
          or prospective Users of the Website, or that in any manner disparages
          or discredits the Ministry/Website, to be determined in the sole
          discretion of the Ministry.
        </p>
        <p>
          The User is further aware that any reproduction or infringement of the
          intellectual property of the aforementioned owners by the User will
          result in legal action being initiated against the User by the
          respective owners of the intellectual property so reproduced /
          infringed upon. It is agreed to by the Parties that the contents of
          this clause shall survive even after the termination or expiry of the
          Terms and/or Policy.
        </p>

        <h6>11. DISCLAIMER OF WARRANTIES AND LIABILITIES</h6>
        <p>
          a) Except as otherwise expressly stated on the Website, all services
          offered on the Website are offered on an "as is" basis without any
          warranty whatsoever, either express or implied.
        </p>
        <p>
          b) Information accessed through the Ministry Website and other touch
          points is presented in summary form and is not exhaustive. The
          Ministry makes no warranties or representations as to its accuracy or
          completeness. This information is provided "as is" without warranty of
          any kind, either express or implied, including but not limited to
          implied warranties of merchantability, fitness for particular purpose,
          or non-infringement.{" "}
        </p>
        <p>
          c) The Ministry/Website does not guarantee that the functions and
          services contained in the Website will be uninterrupted or error-free,
          or that the Website or its server will be free of viruses or other
          harmful components, and the User hereby expressly accepts any and all
          associated risks involved with the User's use of the Website.
        </p>
        <p>
          d) It is further agreed to by the Parties that the contents of this
          Clause shall survive even after the termination or expiry of the Terms
          and/or Policy.
        </p>

        <h6>12. SUBMISSIONS:</h6>
        <p>
          Any comments, feedback, ideas, suggestions, initiation, or any other
          content contributed by the User to the Ministry or this Website will
          be deemed to include a royalty-free, perpetual, irrevocable,
          nonexclusive right and license for the Ministry to adopt, publish,
          reproduce, disseminate, transmit, distribute, copy, use, create
          derivative works, display worldwide, or act on such content, without
          additional approval or consideration, in any media, or technology now
          known or later developed, for the full term of any rights that may
          exist in such content, and the User hereby waives any claim to the
          contrary. The User hereby represents and warrants that he/she owns or
          otherwise controls all of the rights to the content contributed to the
          Website, and that use of such content by the Ministry/Website does not
          infringe upon or violate the rights of any third party. In the event
          of any action initiated against the Ministry/Website by any such
          affected third party, the User hereby expressly agrees to indemnify
          and hold harmless the Ministry/Website, for its use of any such
          information provided to it by the User. The Ministry reserves its
          right to defend itself in any such legal disputes that may arise, and
          recover the costs incurred in such proceedings from the User.
        </p>

        <h6>
          13. NOTICES, JURISDICTION AND GOVERNING LAW FOR DISPUTE RESOLUTION:
        </h6>
        <p>
          a) Any notices or communications required to be given or served by any
          of the Parties on the others in respect of this Agreement shall be
          given in writing in English, and shall be deemed to have been duly
          served, if sent by prepaid registered mail with acknowledgement due at
          the address specified in this Agreement or at such other address as
          may have been notified by the Parties to the other. Any notice under
          this Agreement shall be deemed to be received: i. if delivered
          personally, at the time of delivery; ii. in the case of courier, 72
          hours from the date of posting;
        </p>
        <p>
          b) Any Disputes / Claims arising out of this Agreement are subject to
          Arbitration and the Jurisdiction of Delhi Courts. Further, in case the
          parties failed to resolve the dispute; this Agreement shall be
          governed by and construed in accordance with the law and Jurisdiction
          within Delhi, India only.
        </p>
        <p>
          c) Any amendments in the clauses of the Agreement can be effected as
          an addendum, after written approval from both parties.
        </p>

        <h6>14. MISCELLANEOUS PROVISIONS:</h6>
        <p>
          a) Entire Agreement: These Terms, read with the Policy form the
          complete and final contract between the User and the Ministry with
          respect to the subject matter hereof and supersedes all other
          communications, representations and agreements (whether oral, written
          or otherwise) relating thereto.
        </p>
        <p>
          b) Waiver: The failure of either Party at any time to require
          performance of any provision of these Terms shall in no manner affect
          such Party's right at a later time to enforce the same. No waiver by
          either Party of any breach of these Terms, whether by conduct or
          otherwise, in any one or more instances, shall be deemed to be or
          construed as a further or continuing waiver of any such breach, or a
          waiver of any other breach of these Terms.{" "}
        </p>
        <p>
          c) Severability: If any provision/clause of these Terms is held to be
          invalid, illegal or unenforceable by any court or authority of
          competent jurisdiction, the validity, legality and enforceability of
          the remaining provisions/clauses of these Terms shall in no way be
          affected or impaired thereby, and each such provision /clause of these
          Terms shall be valid and enforceable to the fullest extent permitted
          by law. In such case, these Terms shall be reformed to the minimum
          extent necessary to correct any invalidity, illegality or
          unenforceability, while preserving to the maximum extent the original
          rights, intentions and commercial expectations of the Parties hereto,
          as expressed herein.
        </p>
      </div>
    </div>
  );
}
