import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import Header from "../Components/Header/Header";
import "./Home.css";
import firebase from "../../firebase";
import axios from "axios";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import Lightbox from "react-image-lightbox";

import "react-image-lightbox/style.css";

export default function ViewPledges() {
  const { t } = useTranslation();
  const [eventMedia, setEventMedia] = React.useState([]);
  const [eventMedia2, setEventMedia2] = React.useState([]);
  const [initialEventMedia, setInitialEventMedia] = React.useState([]);
  const [search, setSearch] = React.useState(false);
  const [searchKeyWord, setKeyword] = React.useState("");
  const [lastId, setLastDocId] = React.useState("");
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imageUrlsPoster, setImageUrlsPoster] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isPopupLoading, setIsPopupLoading] = useState(false); // Step 2

  const handleClickImage = async (index) => {
    setIsPopupLoading(true); // Set loading state to true (Step 3)
    setSelectedImageIndex(index);
    try {
      await getSignedURLFromCFPoster(eventMedia[index]?.media_name);
    } catch (error) {
      console.error("Error loading image: ", error);
    }
    setIsPopupLoading(false); // Set loading state back to false
  };

  const handleClosePopup = () => {
    setSelectedImageIndex(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNext = () => {
    //Fetch next Pledges data
    let db = firebase.firestore();
    const lastDocRef = db.collection("moderated_pledges").doc(lastId);
    lastDocRef.get().then((lastDocFetched) => {
      //making a ref and query to db
      const docRef = db
        .collection("moderated_pledges")
        .startAfter(lastDocFetched)
        .limit(16);
      docRef
        .get()
        .then((querySnapshot) => {
          // console.log(querySnapshot.docs);
          const dataArray = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          setLastDocId(dataArray[dataArray.length - 1].id);
          setEventMedia([...eventMedia, ...dataArray]);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    });
  };
  const [upref, upView] = useInView({
    /* Optional options */
    threshold: 0.2,
  });
  useEffect(() => {
    if (upView) {
      getNext();
    }
  }, [upView]);
  useEffect(() => {
    //Fetch top 50 Pledges data
    let db = firebase.firestore();
    db.collection("moderated_pledges")
      .limit(48)
      .get()
      .then((querySnapshot) => {
        console.log(querySnapshot.docs);
        const dataArray = querySnapshot.docs.map((doc) => {
          return {
            id: doc.id,
            ...doc.data(),
          };
        });
        setLastDocId(dataArray[dataArray.length - 1].id);
        setEventMedia(dataArray);
        setInitialEventMedia(dataArray);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }, []);
  // Fetch all user Images Asynchronously
  useEffect(() => {
    // console.log(eventMedia);
    // if (!eventMedia?.media_name) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      const urls2 = await Promise.all(
        eventMedia?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      setImageUrlsPoster(urls2);
      setImageUrls(urls);
    };
    fetchImageUrls();
  }, [eventMedia]);

  useEffect(() => {
    // console.log(eventMedia);
    // if (!eventMedia?.media_name) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        eventMedia2?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      const urls2 = await Promise.all(
        eventMedia2?.map((item) => getSignedURLFromCF(item?.media_name))
      );
      setImageUrlsPoster(urls2);
      setImageUrls(urls);
    };
    fetchImageUrls();
  }, [eventMedia2]);

  // Get Signed URL
  const getSignedURLFromCF = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/400x400/filters:format(webp)/filters:quality(70)/pledge_images/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    return data;
  };

  // Get Signed URL
  const getSignedURLFromCFPoster = async (media) => {
    const url = `https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLFromCF-dic?url=https://d6tqjj2flimrm.cloudfront.net/fit-in/800x800/filters:format(webp)/filters:quality(70)/pledge_images/${media}`;
    const response = await axios.get(url);
    const data = response?.data?.data;
    setSelectedImageIndex(response?.data?.data);
    // console.log(data, "datra");
    return data;
  };
  useEffect(() => {
    if (searchKeyWord.length == 10) {
      let db = firebase.firestore();
      db.collection("moderated_pledges")
        .where("mobile", "==", searchKeyWord)
        .limit(50)
        .get()
        .then((querySnapshot) => {
          // console.log(querySnapshot.docs);
          const dataArray = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          console.log(dataArray);
          setSearch(true);
          setEventMedia2(dataArray);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    } else if (searchKeyWord.length < 10) {
      // setSearch(false);
      // setEventMedia();
      setEventMedia2([]);
    } else {
      setSearch(false);
      setEventMedia(initialEventMedia); // Reset eventMedia to initial content
      setEventMedia2([]);
    }
  }, [searchKeyWord]);

  const handleClickScroll = () => {
    const element = document.getElementById("header-nav");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // console.log(imageUrls.length);
  }, [imageUrls]);

  return (
    <div className="main_body_withoutbg">
      <Header />
      <div className="mt-5">
        <div style={{ width: "100vw", textAlign: "center" }}>
          {" "}
          <input
            id="searhBox"
            value={searchKeyWord}
            placeholder="Search by your 10 digit number"
            onChange={(e) => {
              const inputValue = e.target.value;
              // Limit input to exactly 10 digits
              if (/^\d{0,10}$/.test(inputValue)) {
                setKeyword(inputValue);
              }
            }}
            type="text"
          />
        </div>
        {eventMedia?.state && (
          <p className="sub_head text-center">
            {eventMedia?.gram_panchayat}, {eventMedia?.block},{" "}
            {eventMedia?.district}, {eventMedia?.state}
          </p>
        )}
        <h1 className="main_head text-center">{t("pledge_media")}</h1>
      </div>
      <div className="container mt-4">
        {search ? (
          eventMedia2?.length == 0 ? (
            <div className="row">
              <h1 className="main_head text-center">No Result Found !</h1>
            </div>
          ) : (
            <div className="row">
              {eventMedia2?.map((item, index) => (
                <div className="col-lg-3 col-md-4 col-sm-12 my-2" key={index}>
                  <div
                    style={{
                      borderRadius: "15px",
                      position: "relative",
                      overflow: "hidden",
                    }}
                  >
                    {/* Replace your existing rendering code for images with this */}
                    {imageUrls.length > 0 && (
                      <div
                        className="pledge_item"
                        style={{
                          borderRadius: "15px",
                          position: "relative",
                          overflow: "hidden",
                          cursor: "pointer",
                        }}
                        onClick={() => handleClickImage(index)}
                      >
                        <VillageImageDiv
                          style={{
                            backgroundImage: `url('${imageUrls[index]}')`,
                            backgroundColor: "#fff",
                            borderRadius: "15px",
                            backgroundSize: "cover",
                            filter: "blur(40px)",
                            height: "18vh",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                        />
                        <VillageImageDiv
                          className="th-border-top th-border-bottom"
                          style={{
                            backgroundImage: `url('${imageUrls[index]}')`,
                            borderRadius: "15px",
                            position: "absolute",
                            top: "0%",
                            right: "0%",
                            bottom: "0%",
                            backgroundSize: "contain",
                            cursor: "pointer",
                          }}
                        />
                        <div
                          className="p-2 name_text"
                          style={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#1ca63b",
                            color: "#fff",
                            fontSize: "2vh",
                          }}
                        >
                          {item?.name.length > 20
                            ? `${item?.name.toUpperCase().slice(0, 20)}...`
                            : item?.name.toUpperCase()}
                        </div>
                      </div>
                    )}

                    {selectedImageIndex !== null && (
                      <div
                        className="image-popup-overlay"
                        onClick={handleClosePopup}
                      >
                        <div className="image-popup">
                          {!isPopupLoading && (
                            <button
                              className="close-button"
                              onClick={handleClosePopup}
                            >
                              <img
                                src="./close.svg"
                                alt="Close"
                                style={{ height: "20px" }}
                              />
                            </button>
                          )}
                          {isPopupLoading ? ( // Conditionally render loader (Step 4)
                            <div className="loader">
                              <span></span>
                            </div>
                          ) : (
                            <img src={selectedImageIndex} alt="Popup" />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )
        ) : (
          <div className="row">
            {eventMedia?.map(
              (item, index) =>
                imageUrls.length > 0 && (
                  <div className="col-lg-3 col-md-4 col-sm-12 my-2" key={index}>
                    <div
                      style={{
                        borderRadius: "15px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      {/* Replace your existing rendering code for images with this */}
                      {imageUrls.length > 0 && (
                        <div
                          className="pledge_item"
                          style={{
                            borderRadius: "15px",
                            position: "relative",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          // onClick={() => handleClickImage(index)}
                          onClick={() => {
                            handleClickImage(index);
                            getSignedURLFromCFPoster(item?.media_name);
                          }}
                        >
                          <VillageImageDiv
                            style={{
                              backgroundImage: `url('${imageUrls[index]}')`,
                              backgroundColor: "#fff",
                              borderRadius: "15px",
                              backgroundSize: "cover",
                              filter: "blur(40px)",
                              height: "18vh",
                              overflow: "hidden",
                              cursor: "pointer",
                            }}
                          />
                          <VillageImageDiv
                            className="th-border-top th-border-bottom"
                            style={{
                              backgroundImage: `url('${imageUrls[index]}')`,
                              borderRadius: "15px",
                              position: "absolute",
                              top: "0%",
                              right: "0%",
                              bottom: "0%",
                              backgroundSize: "contain",
                              cursor: "pointer",
                            }}
                          />
                          <div
                            className="p-2 name_text"
                            style={{
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              backgroundColor: "#1ca63b",
                              color: "#fff",
                              fontSize: "2vh",
                            }}
                          >
                            {item?.name.length > 20
                              ? `${item?.name.toUpperCase().slice(0, 20)}...`
                              : item?.name.toUpperCase()}
                          </div>
                        </div>
                      )}

                      {selectedImageIndex !== null && (
                        <div
                          className="image-popup-overlay"
                          onClick={handleClosePopup}
                        >
                          <div className="image-popup">
                            {!isPopupLoading && (
                              <button
                                className="close-button"
                                onClick={handleClosePopup}
                              >
                                <img
                                  src="./close.svg"
                                  alt="Close"
                                  style={{ height: "20px" }}
                                />
                              </button>
                            )}
                            {isPopupLoading ? ( // Conditionally render loader (Step 4)
                              <div className="loader">
                                <span></span>
                              </div>
                            ) : (
                              <img src={selectedImageIndex} alt="Popup" />
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
            )}
            {eventMedia.length > 0 && <div ref={upref}></div>}
          </div>
        )}
      </div>

      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "30px",
          zIndex: "99",
          cursor: "pointer",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "#f5ead6",
          boxShadow: "#885d1c 0px 0px 7px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", // Aligns the image vertically in the center
        }}
        onClick={handleClickScroll}
      >
        <img src="./asset/images/arrow.png" alt="Scroll To Top" />
      </div>
    </div>
  );
}

export const VillageImageDiv = styled.div`
  background: url(image.png);
  width: 100%;
  padding-bottom: 50%;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-color: "#555";
  background-position: center;
  border-radius: 20px;
`;
