import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import "./Report.css";
import "./Home.css";
import firebase from "../../firebase";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";
import Report from "./Report";
import { Col } from "react-bootstrap";
import LGD_mapping from "../Components/LGD_mapping";
import DateRangePicker from "../Components/DateRangePicker";

export default function KalashReport() {
  const [loader, setLoader] = useState(true);
  const [nodalData, setNodalData] = useState([]);
  const history = useHistory();
  const [downloading, setDownlaoding] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [selectedlocation, setSelectedlocation] = useState({});
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [type, setType] = useState("");
  const [phase, setPhase] = useState(true);
  const [districts, setDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [reportType, setReportType] = useState("");
  const [loading, setLoading] = useState(false);
  const [stateSelected, setStateSelected] = useState("");
  const [districtSelected, setDistrictSelected] = useState("");
  const [blockSelected, setBlockSelected] = useState("");
  const [panchayatSelected, setPanchayatSelected] = useState("");
  const [villageSelected, setVillageSelected] = useState("");
  const [stateCode, setStateCode] = useState();
  const [districtCode, setDistrictCode] = useState();

  const [sortColumn, setSortColumn] = useState("state");
  const [sortOrder, setSortOrder] = useState("asc"); // "asc" or "desc"

  const [location, setLocation] = useState({
    state: "",
    district: "",
    block: "",
    gp: "",
    village: "",
  });

  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });

  const formatDateString = (date) => {
    if (date instanceof Date) {
      // Convert the date to the local timezone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().slice(0, 19).replace("T", " ");
    }
    return null;
  };

  const handleDatesSelected = (dates) => {
    setSelectedDates(dates);
  };

  const formattedStartDate = formatDateString(selectedDates.startDate);
  const formattedEndDate = formatDateString(selectedDates.endDate);

  const minDate = new Date("2023-09-01");
  const maxDate = new Date();

  const updateLocation = (field, value) => {
    console.log(field, value);
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });
        array.sort();
        setStates(array);
      });
  }, []);

  const handleStateClick = (stateName) => {
    setSelectedlocation(stateName);
    console.log(stateName);
  };
  const handleType = (typeName) => {
    setType(typeName);
  };
  const handleReportType = (type) => {
    setLoader(true);
    setReportType(type);
    console.log(loader);
    setSelectedState("");
    console.log(type);
  };

  const customSort = (a, b) => {
    const stateComparison = a["state"].localeCompare(b["state"]);
    if (stateComparison !== 0) return stateComparison;

    const districtComparison = (a["district"] || "").localeCompare(
      b["district"] || ""
    );
    if (districtComparison !== 0) return districtComparison;

    const blockComparison = (a["block"] || "").localeCompare(b["block"] || "");
    if (blockComparison !== 0) return blockComparison;

    const panchayatComparison = (
      a["Name of Gram Panchayats"] || ""
    ).localeCompare(b["Name of Gram Panchayats"] || "");
    if (panchayatComparison !== 0) return panchayatComparison;

    const ulbComparison = (a["Name of ULB"] || "").localeCompare(
      b["Name of ULB"] || ""
    );
    if (ulbComparison !== 0) return ulbComparison;

    return 0;
  };

  useEffect(() => {
    // setLoader(true);
    setReportData([]);
    // handle Login/logout
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.
        const userJSON = JSON.parse(localStorage.getItem("users"))?.users;

        // Check the nodal_officer_data collection for type of user based on state district_name & block_name
        firebase
          .firestore()
          .collection("ak_nyv_officers")
          .where("phoneNumber", "==", userJSON?.phoneNumber)
          .get()
          .then((res) => {
            if (res?.docs?.length > 0) {
              // console.log("res", res.docs?.[0].data());
              setNodalData(res.docs?.[0].data());
              setStateCode(res.docs?.[0].data()?.state_code);
              setDistrictCode(res.docs?.[0].data()?.district_code);
              setLoader(true);
              let data = [];
              res?.docs.forEach((e) => {
                data.push(e.data());
              });
              const user = data;

              const fetchedDistricts = [];

              user?.forEach((e) => {
                fetchedDistricts.push({
                  district: e.district,
                  district_code: e?.district_code,
                });
              });
              setDistrict(fetchedDistricts);
              setSelectedDistrict(fetchedDistricts[0]?.district_code);
              if (!res.docs?.[0].data()?.super_admin) {
                firebase.auth().onAuthStateChanged(async (user) => {
                  const token = await user.getIdToken();
                  if (token) {
                    axios
                      .get(
                        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${
                          res.docs?.[0].data()?.state_code
                        }&district=${res.docs?.[0].data()?.district_code}`
                      )
                      .then((res) => {
                        setReportData(res?.data);
                        setLoader(false);
                        // console.log("report data", res?.data);
                      });
                  }
                });
              } else {
                setLoader(false);
              }
            }
          });
      } else {
        setLoader(false);
        // No user is signed in.
        console.log("no user");
        history.push("/");
      }
    });
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (stateCode && districtCode) {
          if (formattedStartDate && formattedEndDate) {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${stateCode}&district=${districtCode}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
              )
              .then((res) => {
                setReportData(res?.data);
                setLoader(false);
                // console.log("report data", res?.data);
              });
          } else {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${stateCode}&district=${districtCode}`
              )
              .then((res) => {
                setReportData(res?.data);
                setLoader(false);
                // console.log("report data", res?.data);
              });
          }
        }
      }
    });
  }, [formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (!loader && !nodalData?.super_admin) {
      // console.log("hey", nodalData);
      firebase.auth().onAuthStateChanged(async (user) => {
        const token = await user.getIdToken();
        if (token) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${nodalData?.state_code}&district=${selectedDistrict}`
            )
            .then((res) => {
              setReportData(res?.data);
              setLoader(false);

              console.log("report data", res?.data);
            });
        }
      });
    }
  }, [selectedDistrict]);

  useEffect(() => {
    // Fetch data for states ordered by state_name
    if (reportType) {
      setLoader(true);
      console.log(reportType);
      firebase.auth().onAuthStateChanged(async (user) => {
        const token = await user.getIdToken();
        if (token) {
          if (formattedStartDate && formattedEndDate) {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=${reportType}&token=${token}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
              )
              .then((res) => {
                if (reportType == "district_all") {
                  setReportData(
                    res?.data.map((item, index) =>
                      item.sort((a, b) =>
                        a?.district?.localeCompare(b?.district)
                      )
                    )
                  );
                } else if (reportType == "block_all") {
                  setReportData(
                    res?.data.map((item, index) =>
                      item.sort((a, b) => a?.block?.localeCompare(b?.block))
                    )
                  );
                } else {
                  setReportData(res?.data);
                  console.log(res?.data);
                }

                setLoader(false);
                // console.log("report data", res?.data);
              });
          } else {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=${reportType}&token=${token}`
              )
              .then((res) => {
                if (reportType == "district_all") {
                  setReportData(
                    res?.data.map((item, index) =>
                      item.sort((a, b) =>
                        a?.district?.localeCompare(b?.district)
                      )
                    )
                  );
                } else if (reportType == "block_all") {
                  setReportData(
                    res?.data.map((item, index) =>
                      item.sort((a, b) => a?.block?.localeCompare(b?.block))
                    )
                  );
                  console.log(
                    res?.data.map((item, index) =>
                      item.sort((a, b) => a?.block?.localeCompare(b?.block))
                    )
                  );
                } else {
                  setReportData(res?.data);
                  console.log(res?.data);
                }

                setLoader(false);
                // console.log("report data", res?.data);
              });
          }
        }
      });
      // console.log(loader);
    }
  }, [reportType]);

  const getIndividualStateReport = (item) => {
    // console.log(item, "state clicked");
    // console.log(item["state"], "state clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        // console.log("item", item);

        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=state_filter&token=${token}&state=${item["state"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.district?.localeCompare(b?.district))
                )
              );
              setLoader(false);
              // console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=state_filter&token=${token}&state=${item["state"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.district?.localeCompare(b?.district))
                )
              );
              setLoader(false);
              // console.log(
              //   res?.data.map((item, index) =>
              //     item.sort((a, b) => a?.district?.localeCompare(b?.district))
              //   )
              // );
            });
        }
      }
    });
  };

  const getIndividualDistrictReport = (item) => {
    // console.log(item, "district clicked");
    // console.log(item["district"], item["state"], "state clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.block?.localeCompare(b?.block))
                )
              );
              setLoader(false);
              // console.log("report data", res?.data);
            });
        }
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=district_filter&token=${token}&state=${item["state"]}&district=${item["district"]}`
          )
          .then((res) => {
            setReportData(
              res?.data.map((item, index) =>
                item.sort((a, b) => a?.block?.localeCompare(b?.block))
              )
            );
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const getIndividualBlockReport = (item) => {
    // console.log(item, "block clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=block_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.panchayat?.localeCompare(b?.panchayat))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=block_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.panchayat?.localeCompare(b?.panchayat))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      }
    });
  };

  const getIndividualPanchayatReport = (item) => {
    // console.log(item, "panchayat clicked");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (formattedStartDate && formattedEndDate) {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=panchayat_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&panchayat=${item["panchayat"]}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.village?.localeCompare(b?.village))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        } else {
          axios
            .get(
              `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=panchayat_filter&token=${token}&state=${item["state"]}&district=${item["district"]}&block=${item["block"]}&panchayat=${item["panchayat"]}`
            )
            .then((res) => {
              setReportData(
                res?.data.map((item, index) =>
                  item.sort((a, b) => a?.village?.localeCompare(b?.village))
                )
              );
              setLoader(false);
              console.log("report data", res?.data);
            });
        }
      }
    });
  };

  const getIndividualVillageReport = (item) => {
    // console.log(item, "village clicked");
    // console.log(item["state"], "VillageReport");
    // console.log(item["district"], "VillageReport");
    // console.log(item["block"], "VillageReport");
    // console.log(item["panchayat"], "VillageReport");
    // console.log(item["village"], "VillageReport");
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getNewReport?type=village_filter&token=${token}&state=${item[
              "state"
            ].toUpperCase()}&district=${item[
              "district"
            ].toUpperCase()}&block=${item[
              "block"
            ].toUpperCase()}&panchayat=${item[
              "panchayat"
            ].toUpperCase()}&village=${item["village"].toUpperCase()}`
          )
          .then((res) => {
            setReportData(res?.data);
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const getIndividualULBReport = (item) => {
    setLoader(true);
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        axios
          .get(
            `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getTestingReport?type=report_ulb&state=${item}&token=${token}`
          )
          .then((res) => {
            setReportData(res?.data);
            setLoader(false);
            // console.log("report data", res?.data);
          });
      }
    });
  };

  const handleStateChange = (event) => {
    const { name, value } = event.target;
    // console.log(name, value);
    setSelectedState(value);
    getIndividualULBReport(value);
  };

  const handleDownload = () => {
    // Create a new blank workbook
    const workbook = XLSX.utils.book_new();
    const worksheets = [{ name: "Report", data: reportData[0] }];

    // Convert each data array to a worksheet
    worksheets.forEach((sheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    });

    // Create a Blob and download the Excel workbook
    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Overall_Summary.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownlaoding(false);
    console.log("Data has been downloaded as nodal_officer_summary.xlsx");
  };
  // useEffect(() => {
  //   if (
  //     location?.state &&
  //     location?.district &&
  //     location?.block &&
  //     location?.panchayat
  //   ) {
  //     setReportData([]);
  //     getIndividualPanchayatReport({
  //       state: location?.state,
  //       district: location?.district,
  //       block: location?.block,
  //       panchayat: location?.panchayat,
  //     });
  //   } else if (location?.state && location?.block && location?.district) {
  //     setReportData([]);
  //     getIndividualBlockReport({
  //       state: location?.state,
  //       district: location?.district,
  //       block: location?.block,
  //     });
  //   } else if (location?.district && location?.state) {
  //     setReportData([]);
  //     getIndividualDistrictReport({
  //       state: location?.state,
  //       district: location?.district,
  //     });
  //   } else if (location?.state) {
  //     setReportData([]);
  //     console.log("stateSelected from kalas", location?.state);
  //     getIndividualStateReport({ state: location?.state });
  //   }
  // }, [location]);

  useEffect(() => {
    if (location?.state) {
      setReportData([]);
      // console.log("stateSelected from kalas", location?.state);
      getIndividualStateReport({ state: location?.state });
    }
  }, [location.state, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (location.district) {
      setReportData([]);
      getIndividualDistrictReport({
        state: location?.state,
        district: location?.district,
      });
    }
  }, [location.district, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (location.block) {
      setReportData([]);
      getIndividualBlockReport({
        state: location?.state,
        district: location?.district,
        block: location?.block,
      });
    }
  }, [location.block, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (location.gp) {
      setReportData([]);
      getIndividualPanchayatReport({
        state: location?.state,
        district: location?.district,
        block: location?.block,
        panchayat: location?.gp,
      });
    }
  }, [location.gp, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    // console.log("village coming", location.village);
    // console.log(villageSelected, "villageSelected");
    if (location.village) {
      setReportData([]);
      getIndividualVillageReport({
        state: location?.state,
        district: location?.district,
        block: location?.block,
        panchayat: location?.gp,
        village: location?.village,
      });
    }
  }, [location.village, formattedStartDate, formattedEndDate]);

  // Convert data to CSV format
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };
  const sortData = (data, column, order) => {
    if (data[0]) {
      return data[0].sort((a, b) => {
        const valA = a[column];
        const valB = b[column];

        if (order === "asc") {
          if (valA < valB) return -1;
          if (valA > valB) return 1;
        } else {
          if (valA < valB) return 1;
          if (valA > valB) return -1;
        }

        return 0;
      });
    } else {
      return [];
    }
  };
  const sortedData = sortData(reportData, sortColumn, sortOrder);

  const handleSort = (column) => {
    if (column === sortColumn) {
      console.log("sort called if");
      // Toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      console.log("sort called else");
      // If sorting a different column, set the new column and default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  console.log(location);
  return (
    <>
      <div className="main_body ease-in-anim">
        <Header />
        {nodalData?.super_admin && (
          <div className="d-flex justify-content-center py-3">
            <button
              className={"phase_btn mx-2"}
              style={{
                fontSize: "13px",
                width: "auto",
                pointerEvents: loader ? "none" : "auto",
              }}
              onClick={() => {
                setPhase(!phase);
              }}
              name="gp"
            >
              {phase == true ? "Change to Phase 1" : "Change to Phase 2"}
            </button>
          </div>
        )}
        <div className="d-flex justify-content-center py-3">
          <a href="/summary-report">
            <button
              className="submit_btn"
              style={{ fontSize: "13px", width: "auto" }}
            >
              Summary Reports
            </button>
          </a>
        </div>
        {phase ? (
          <>
            {nodalData?.super_admin && (
              <LGD_mapping
                setStateSelected={setStateSelected}
                setDistrictSelected={setDistrictSelected}
                setBlockSelected={setBlockSelected}
                setPanchayatSelected={setPanchayatSelected}
                setVillageSelected={setVillageSelected}
                location={location}
                onUpdateLocation={updateLocation}
              />
            )}
            {nodalData?.super_admin ? (
              <>
                <div className="container d-flex justify-content-center text-center form_login my-4">
                  <span
                    className="report_active"
                    style={{
                      width: "auto",
                      pointerEvents: loader ? "none" : "auto",
                    }}
                    name="gp"
                    onClick={() => {
                      handleReportType("state_all");
                      handleType("report");
                    }}
                  >
                    Total State wise
                  </span>
                  <span
                    className="report_active"
                    style={{
                      width: "auto",
                      pointerEvents: loader ? "none" : "auto",
                    }}
                    onClick={() => {
                      handleReportType("district_all");
                      handleType("report_district");
                    }}
                  >
                    Total District wise
                  </span>
                  <span
                    className="report_active"
                    style={{
                      width: "auto",
                      pointerEvents: loader ? "none" : "auto",
                    }}
                    onClick={() => {
                      handleReportType("block_all");
                      handleType("report_block");
                    }}
                  >
                    Total Block wise
                  </span>
                  {/* <button
                className={
                  reportType == "report_ulb"
                    ? "report_btn_active mx-2"
                    : "report_btn_inactive mx-2"
                }
                style={{
                  fontSize: "13px",
                  width: "auto",
                  pointerEvents: loader ? "none" : "auto",
                }}
                onClick={() => {
                  handleReportType("report_ulb");
                  handleType("report_ulb");
                }}
              >
                Total ULB wise
              </button> */}

                  {reportType == "report_ulb" && (
                    <label className="mt-1 mb-1 ">
                      <select
                        className="select-input"
                        tabIndex="2"
                        name="state"
                        value={selectedState}
                        onChange={handleStateChange}
                      >
                        <option value="">India</option>
                        {/* Map over your states data */}
                        {states?.sort().map((state, index) => (
                          <option key={state + index} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </label>
                  )}
                </div>
                <div className="justify-content-center d-flex">
                  Please select a tab to see the report &nbsp;&nbsp;
                  {loader && (
                    <div class="spinner-border" role="status">
                      <span class="sr-only">Loading Report...Please wait</span>
                    </div>
                  )}
                </div>

                <DateRangePicker
                  onDatesSelected={handleDatesSelected}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                {/* <p>Start Date: {formattedStartDate}</p>
                <p>End Date: {formattedEndDate}</p> */}
                {reportData?.length > 0 ? (
                  <>
                    <div className="report-container">
                      <h1 className="report-title">
                        {type == "report"
                          ? "State wise Report"
                          : type == "report_district"
                          ? "District wise Report"
                          : type == "report_block"
                          ? "Block wise Report"
                          : type == "report_panchayat"
                          ? "Panchayat wise Report"
                          : type == "report_ulb" && "ULB wise Report"}
                      </h1>
                      <div className="d-flex justify-content-end my-3">
                        <button
                          className="pledge_btn mx-2"
                          style={{
                            fontSize: "13px",
                            width: "auto",
                            opacity: downloading ? "0.5" : "1",
                          }}
                          onClick={() => {
                            handleDownload();
                          }}
                          disabled={downloading}
                        >
                          {downloading ? "Downloading" : "Download Report"}
                        </button>
                      </div>

                      {reportData[0].length >= 1 && (
                        <div className="table-container">
                          <table className="report-table">
                            <thead>
                              <tr>
                                <th onClick={() => handleSort("state")}>
                                  State
                                </th>

                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "district"
                                  ) && "District"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "block"
                                  ) && "Block"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "panchayat"
                                  ) && "Panchayat"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "village"
                                  ) && "Village"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "distinct_districts"
                                  ) && "Distinct Districts"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "distinct_blocks"
                                  ) && "Distinct Blocks"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "distinct_panchayats"
                                  ) && "Distinct Panchayats"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "distinct_villages"
                                  ) && "Distinct Villages"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "images"
                                  ) && "Images"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "videos"
                                  ) && "Videos"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "total_household_number"
                                  ) && "Total Household Number"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "block_level_event_date"
                                  ) && "block_level_event_date"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "kalash_date"
                                  ) && "kalash_date"}
                                </th>
                                <th>
                                  {Object.keys(reportData?.[0]?.[0])?.includes(
                                    "dignitary_name_count"
                                  ) && "Dignitary name count"}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sortedData?.map((item, index) => (
                                <tr key={index}>
                                  {
                                    // item["Name of ULB"] ? (
                                    //   <td>{item["Name of State"]}</td>
                                    // ) : (
                                    <td>
                                      {item["state"] &&
                                      !item["districts"] &&
                                      !item["blocks"] ? (
                                        <a
                                          className="state-link"
                                          style={{
                                            pointerEvents: loader
                                              ? "none"
                                              : "auto",
                                          }}
                                          onClick={() => {
                                            handleType("report_district");
                                            handleStateClick(item);
                                            getIndividualStateReport(item);
                                          }}
                                        >
                                          {item["state"]}
                                        </a>
                                      ) : (
                                        item["state"]
                                      )}
                                    </td>
                                    // )
                                  }
                                  <td>
                                    {!item["block"] ? (
                                      <a
                                        className="state-link"
                                        style={{
                                          pointerEvents: loader
                                            ? "none"
                                            : "auto",
                                        }}
                                        onClick={() => {
                                          handleType("report_block");
                                          handleStateClick(item);
                                          getIndividualDistrictReport(item);
                                        }}
                                      >
                                        {item["district"]}
                                      </a>
                                    ) : (
                                      item["district"]
                                    )}
                                  </td>
                                  <td>
                                    {item["block"] ? (
                                      <a
                                        className="state-link"
                                        style={{
                                          pointerEvents: loader
                                            ? "none"
                                            : "auto",
                                        }}
                                        onClick={() => {
                                          handleType("report_panchayat");
                                          handleStateClick(item);
                                          getIndividualBlockReport(item);
                                        }}
                                      >
                                        {item["block"]}
                                      </a>
                                    ) : (
                                      item["block"]
                                    )}
                                  </td>
                                  <td>
                                    {item["panchayat"] ? (
                                      <a
                                        className="state-link"
                                        style={{
                                          pointerEvents: loader
                                            ? "none"
                                            : "auto",
                                        }}
                                        onClick={() => {
                                          handleType("report_village");
                                          handleStateClick(item);
                                          getIndividualPanchayatReport(item);
                                        }}
                                      >
                                        {item["panchayat"]}
                                      </a>
                                    ) : (
                                      item["panchayat"]
                                    )}
                                  </td>
                                  {/* <td >
                                    {item["panchayat"] && item["panchayat"]}
                                  </td> */}
                                  <td>{item["village"] && item["village"]}</td>
                                  <td>
                                    {item["distinct_districts"] &&
                                      item["distinct_districts"]}
                                  </td>
                                  <td>
                                    {item["distinct_blocks"] &&
                                      item["distinct_blocks"]}
                                  </td>
                                  <td>
                                    {item["distinct_panchayats"] &&
                                      item["distinct_panchayats"]}
                                  </td>
                                  <td>
                                    {item["distinct_villages"] &&
                                      item["distinct_villages"]}
                                  </td>
                                  <td>{item["images"] && item["images"]}</td>
                                  <td>{item["videos"] && item["videos"]}</td>
                                  <td>
                                    {item["total_household_number"] &&
                                      item["total_household_number"]}
                                  </td>
                                  <td>
                                    {item["block_level_event_date"]
                                      ? item["block_level_event_date"]
                                      : null}
                                  </td>
                                  <td>
                                    {item["kalash_date"] && item["kalash_date"]}
                                  </td>
                                  <td>
                                    {item["dignitary_name_count"] &&
                                      item["dignitary_name_count"]}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      <br />
                      <div>Others Count : 416180</div>
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              !loader &&
              (reportData[0]?.length > 0 ? (
                <div>
                  <Col
                    my={12}
                    md={12}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h6
                      style={{
                        fontSize: "18px",
                        marginLeft: "0.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      District :
                    </h6>
                    <select
                      style={{
                        fontSize: "15px",
                        color: "#543c00",
                        padding: "8px",
                        height: "40px",
                        marginLeft: "1rem",
                        marginTop: "1rem",
                        borderRadius: "25px",
                        border: "1px solid #543c00",
                        background: "#e7d3a9",
                        width: "200px",
                      }}
                      value={selectedDistrict}
                      name="gramPanchayat"
                      onChange={(e) => {
                        setSelectedDistrict(e.target.value);
                      }}
                      // value={selectedGramPanchayat}
                      required // Make this field mandatory
                    >
                      <option value="">Select District</option>
                      {districts?.sort()?.map((dis, index) => (
                        <option key={dis + index} value={dis.district_code}>
                          {dis.district}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <div className="py-4">
                    <DateRangePicker
                      onDatesSelected={handleDatesSelected}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                    {formattedStartDate && formattedEndDate && (
                      <div className="text-center">
                        <h6>
                          Date Selected :
                          <span className="date">{formattedStartDate}</span> to{" "}
                          <span className="date">{formattedEndDate}</span>
                        </h6>
                      </div>
                    )}
                  </div>

                  <br />

                  <div className="d-flex justify-content-end my-3">
                    <button
                      className="pledge_btn mx-2"
                      style={{
                        fontSize: "13px",
                        width: "auto",
                        opacity: downloading ? "0.5" : "1",
                      }}
                      onClick={() => {
                        handleDownload();
                      }}
                      disabled={downloading}
                    >
                      {downloading ? "Downloading" : "Download Report"}
                    </button>
                  </div>
                  <div style={{ padding: "12px" }} className="table-container">
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th onClick={() => handleSort("state")}>State</th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "district"
                            ) && "District"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "block"
                            ) && "Block"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "panchayat"
                            ) && "Panchayat"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "village"
                            ) && "Village"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "distinct_districts"
                            ) && "Distinct Districts"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "distinct_blocks"
                            ) && "Distinct Blocks"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "distinct_panchayats"
                            ) && "Distinct Panchayats"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "distinct_villages"
                            ) && "Distinct Villages"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "images"
                            ) && "Images"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "videos"
                            ) && "Videos"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "total_household_number"
                            ) && "Total Household Number"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "block_level_event_date"
                            ) && "Block level event date"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0]).includes(
                              "kalash_date"
                            ) && "Kalash date"}
                          </th>
                          <th>
                            {Object.keys(reportData?.[0]?.[0])?.includes(
                              "dignitary_name_count"
                            ) && "Dignitary name count"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData?.map((item, index) => (
                          <tr key={index}>
                            <td>{item["state"]}</td>

                            <td>{item["district"]}</td>
                            <td>{item["block"]}</td>
                            <td>{item["panchayat"] && item["panchayat"]}</td>
                            <td>{item["village"] && item["village"]}</td>
                            <td>
                              {item["distinct_districts"] &&
                                item["distinct_districts"]}
                            </td>
                            <td>
                              {item["distinct_blocks"] &&
                                item["distinct_blocks"]}
                            </td>
                            <td>
                              {item["distinct_panchayats"] &&
                                item["distinct_panchayats"]}
                            </td>
                            <td>
                              {item["distinct_villages"] &&
                                item["distinct_villages"]}
                            </td>
                            <td>{item["images"] && item["images"]}</td>
                            <td>{item["videos"] && item["videos"]}</td>
                            <td>
                              {item["total_household_number"] &&
                                item["total_household_number"]}
                            </td>
                            <td>
                              {item["block_level_event_date"] &&
                                item["block_level_event_date"]}
                            </td>
                            <td>
                              {item["kalash_date"] && item["kalash_date"]}
                            </td>
                            <td>
                              {item["dignitary_name_count"] &&
                                item["dignitary_name_count"]}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <>
                  <div className="container d-flex justify-content-center text-center form_login my-4">
                    <Col
                      my={12}
                      md={12}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <h6
                        style={{
                          fontSize: "18px",
                          marginLeft: "0.5rem",
                          marginTop: "1rem",
                        }}
                      >
                        District :
                      </h6>
                      <select
                        style={{
                          fontSize: "15px",
                          color: "#543c00",
                          padding: "8px",
                          height: "40px",
                          marginLeft: "1rem",
                          marginTop: "1rem",
                          borderRadius: "25px",
                          border: "1px solid #543c00",
                          background: "#e7d3a9",
                          width: "200px",
                        }}
                        value={selectedDistrict}
                        name="gramPanchayat"
                        onChange={(e) => {
                          setSelectedDistrict(e.target.value);
                        }}
                        // value={selectedGramPanchayat}
                        required // Make this field mandatory
                      >
                        <option value="">Select District</option>
                        {districts?.sort()?.map((dis, index) => (
                          <option key={dis + index} value={dis.district_code}>
                            {dis.district}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </div>
                  <div className="py-4">
                    <DateRangePicker
                      onDatesSelected={handleDatesSelected}
                      minDate={minDate}
                      maxDate={maxDate}
                    />
                    {formattedStartDate && formattedEndDate && (
                      <div className="text-center">
                        <h6>
                          Date Selected :
                          <span className="date">{formattedStartDate}</span> to{" "}
                          <span className="date">{formattedEndDate}</span>
                        </h6>
                      </div>
                    )}
                  </div>
                </>
              ))
            )}
          </>
        ) : (
          <>
            <Report headerHid={true} />
          </>
        )}
        <div>
          <p className="note mx-2 text-center">
            {loader
              ? "Please Wait.."
              : "Disclaimer: Data is still being collected and is not final."}
          </p>
        </div>
      </div>
    </>
  );
}
