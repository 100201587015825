import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Certificate from "../Components/Certificate/Certificate";
import Header from "../Components/Header/Header";
import Popup from "../Components/Popup/Popup";
import AWS from "aws-sdk";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleUpload } from "../../services/upload";

export default function Upload() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [upload, setUpload] = useState(null);
  const [file, setFile] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [uploadingMessage, setUploadingMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState("");

  const fileUpload = async () => {
    if (file && localStorage.getItem("pledge_id")) {
      const maxSize = 10 * 1024 * 1024; // in bytes
      if (file[0].size > maxSize) {
        setUploadingMessage(
          t("file_is_too_large_please_select_a_file_smaller_than_10mb")
        );
        setTimeout(() => {
          setUploadingMessage("");
        }, 2000);
        return;
      }

      // Check the file type (must be an image)
      if (!file[0].type.startsWith("image/")) {
        setUploadingMessage(
          t("incorrect_file_type_please_select_an_image_file")
        );
        setTimeout(() => {
          setUploadingMessage("");
        }, 2000);
        return;
      }

      setUpload("Uploading...");
      setUploadingMessage(t("uploading"));
      const fileName = `${Date.now()}.${file[0].name.split(".").pop()}`;
      const params = {
        Bucket: "static.mmmd.gov.in",
        Key: `pledge_images/${fileName}`,
        Body: file[0],
        ContentType: "image/jpg",
      };

      await handleUpload(params);

      setUpload("Uploaded and sent for moderation.");

      setUploadPercentage("100" + "%");

      // upload.on("httpUploadProgress", function (progress) {
      //   let percentage = Math.floor((progress.loaded / progress.total) * 100);
      //   setUploadPercentage(percentage + "%");
      //   console.log("Upload progress: " + percentage + "%");
      // });
    } else {
      toast.error("Please select a file to upload");
    }
  };
  useEffect(() => {
    if (upload === "Uploaded and sent for moderation.") {
      console.log("upload", upload);
      setTimeout(() => {
        setShowPopup(true);
        // history.push("/");
      }, 2000);
    }
  }, [upload]);
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="container" style={{ marginTop: "70px" }}>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <Carousel fade interval={3000}>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="./asset/images/slider_img/sample1.jpg"
                  alt=""
                />
                <Carousel.Caption>
                  <p>{t("sample_caption1")}</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="./asset/images/slider_img/sample2.jpg"
                  alt=""
                />
                <Carousel.Caption>
                  <p>{t("sample_caption2")}</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="./asset/images/slider_img/sample3.jpg"
                  alt=""
                />
                <Carousel.Caption>
                  <p>{t("sample_caption3")}</p>
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src="./asset/images/slider_img/sample4.jpg"
                  alt=""
                />
                <Carousel.Caption>
                  <p>{t("sample_caption4")}</p>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-lg-6 col-sm-12 d-flex justify-content-center align-items-center">
            <div>
              <div className="d-flex justify-content-center align-items-center">
                <p className="step_title py-2">
                  {t(
                    "upload_selfie_or_picture_and_share_it_with_your_loved_ones"
                  )}
                </p>
              </div>

              <div
                className="d-flex justify-content-center"
                style={{ height: "250px", minWidth: "200px" }}
              >
                <div className="img_box">
                  <div className="pt-3">
                    <img
                      src="./asset/images/upload.svg"
                      style={{ height: "6vh" }}
                    />
                    <div className="my-5 ">
                      <input
                        type="file"
                        id="file"
                        accept="image/*"
                        onChange={(e) => {
                          setFile(e.target.files);
                        }}
                        style={{
                          display: "none", // Hide the default input appearance
                        }}
                      />
                      {file && <p>{file[0].name}</p>}
                      <label htmlFor="file" className="custom-input-button">
                        {t("choose_file")}
                      </label>
                    </div>
                    {/* <div>
                <button className="pledge_btn mt-4">Upload</button>
              </div> */}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  className="pledge_btn mt-4"
                  onClick={() => {
                    fileUpload();
                  }}
                >
                  {t("submit")}
                </button>
              </div>
              {uploadingMessage && (
                <div className="d-flex justify-content-center align-items-center">
                  <p className="step_title py-2">{uploadingMessage}</p>
                </div>
              )}

              {uploadPercentage && (
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: uploadPercentage }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {uploadPercentage}
                  </div>
                </div>
              )}
              <div className="py-3">
                <p className="note_text text-center">File Size 10 Mb.</p>
                <p className="note_text text-center">File type Image only.</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="d-flex justify-content-center">
          <button
            className="pledge_btn mt-4"
            onClick={() => {
              fileUpload();
            }}
          >
            {t("submit")}
          </button>
        </div> */}

        {showPopup && (
          <Certificate name={localStorage.getItem("pledge_name")} />
        )}
      </div>
    </div>
  );
}
