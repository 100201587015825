import { Telephone } from "react-bootstrap-icons";

function Footer() {
  return (
    <footer
      className="footer mt-auto py-3 "
      style={{ backgroundColor: "#fff" }}
    >
      <div className="container text-center">
        <a href="/terms-and-conditions" className="text-muted">
          T&C ||
        </a>
        <a href="/privacy-policy" className="text-muted">
          &nbsp;Privacy Policy ||
        </a>
        <a href="/disclaimer" className="text-muted">
          &nbsp;Disclaimer
        </a>
      </div>

      <div className="container-fluid">
        <div className="row d-flex align-items-center">
          <div className="col-4">
            <hr className="org" />
            <hr className="grn" />
          </div>
          <div
            className="col-4 text-center text-nowrap"
            style={{ padding: "0px" }}
          >
            <div className="row">
              <div className="p-2 text-break footer_text d-flex align-items-center text-bold justify-content-center col">
                <Telephone color="currentColor" size={16} />
                &nbsp; 1800-203-7499
              </div>
            </div>
          </div>
          <div className="col-4">
            <hr className="org" />
            <hr className="grn" />
          </div>
        </div>
      </div>

      <div className="container text-center">
        <a
          href="https://twitter.com/MMMD_2023"
          target="_blank"
          className="text-muted"
        >
          <img
            style={{ height: "3.8vh" }}
            src="./asset/images/logos/twitter.png"
            alt="Twitter"
          />
        </a>
        <a
          href="https://instagram.com/merimaatimeradesh_official?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          className="text-muted"
        >
          &nbsp;
          <img
            style={{ height: "5vh" }}
            src="./asset/images/logos/instagram.svg"
            alt="Instagram"
          />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=100095736453687"
          target="_blank"
          className="text-muted"
        >
          &nbsp;
          <img
            style={{ height: "3.8vh" }}
            src="./asset/images/logos/facebook.svg"
            alt="Facebook"
          />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
