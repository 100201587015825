import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App/Assets/css/general.css";
import "./App/Assets/css/style.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import "./services/i18n";
import firebase from "./firebase";

const history = createBrowserHistory();

console.clear();

firebase.auth().onAuthStateChanged((user) => {
  ReactDOM.render(
    <BrowserRouter>
      <Router />
      <ToastContainer />
    </BrowserRouter>,
    document.getElementById("root")
  );
});

export { history };
