import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import { Card, Col } from "react-bootstrap";
import shiplakalam_img from "../Assets/img/shilphakalam.png";
import pran_img from "../Assets/img/pran1.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
export default function Downloads() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const sections = ["artwork_for_scroll_for_felicitation"];
  return (
    <div className="main_body">
      <Header />
      <div className="mt-5">
        <h1 className="main_head text-center">{t("downloads")}</h1>
      </div>
      <div className="container">
        <div className="main_head ">Phase 3 - Grand Finale</div>
        <div className="row">
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">Brochure</p>
                  <div className="text-center pt-1">
                    {/* <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase3/brochure.pdf"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a> */}
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase3/brochure.pdf"
                      target="_blank"
                    >
                      <button className="download-btn mx-2">View</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Nodal Officer for Designated Locations (Airport/Railway
                    Station/Bus Terminals)
                  </p>
                  <div className="text-center pt-1">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase3/locations.pdf"
                      target="_blank"
                    >
                      <button className="download-btn mx-2">View</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      <div className="container">
        <div className="main_head ">Phase 2</div>
        <div className="row">
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              {/* <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              /> */}
              <video
                controls
                src="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/explainer_video.mp4"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">Explainer Video</p>
                  <div className="text-center pt-1">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/explainer_video.mp4"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">Banner 1</p>
                  <div className="text-center pt-1">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/banner1.jpg"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
                <div className="my-2">
                  <p className="card-title text-center">Banner 2</p>
                  <div className="text-center pt-1">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/banner2.jpg"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    States with District Level Nodal Agency
                  </p>
                  <div className="text-center pt-1">
                    <a
                      href="./asset/files/report/States with District Level Nodal Agency.xlsx"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    SoP Amrit Kalash Campaign 12 Sept 2023
                  </p>
                  <div className="text-center pt-1">
                    <a
                      href="./asset/files/report/SoP Amrit Kalash Campaign 12 Sept 2023.docx"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("backdrop")}</p>
                  <div className="text-center pt-3">
                    <a href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/kalash_yatra_bd.jpg">
                      <button className="download-btn mx-2">
                        JPG
                        {/* {t("kalash_yatra")} */}
                      </button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/kalash_yatra_bd.psd"
                      download
                    >
                      <button className="download-btn mx-2">
                        PSD
                        {/* {t("kalash_yatra_psd")} */}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Anthem
                    {/* {t("text_for_pledge")} */}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/anthem.mp3"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Hoarding English
                    {/* {t("text_for_pledge")} */}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/hoarding_english/v1.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/hoarding_english/v2.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Hoarding Hindi
                    {/* {t("text_for_pledge")} */}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/hoarding_hindi/v1.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>

                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/hoarding_hindi/v2.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Standee English
                    {/* {t("text_for_pledge")} */}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/standee_english/v1.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>

                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/standee_english/v2.jpg"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/kalash.jpg"
              />

              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    Standee Hindi
                    {/* {t("text_for_pledge")} */}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/anthem.mp3"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/phase2/anthem.mp3"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </div>
      </div>
      <div class="container">
        <div className="main_head ">Phase 1</div>
        <div class="row">
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <div className="card_img d-flex align-items-center">
                <Card.Img
                  className="img-fluid"
                  variant="top"
                  // src={shiplakalam_img}
                  src="/asset/files/shilapalakam_sample.jpg"
                />
              </div>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("shilaphalakam")}</p>
                  <div className="text-center pt-3">
                    <a href="/generate-shilaphalakam">
                      <button className="download-btn mx-2">
                        {t("generate_shilaphalakam")}
                      </button>
                    </a>
                    <a href="/asset/files/shilapalakam_sample.jpg" download>
                      <button className="download-btn mx-2">
                        {t("sample_shila")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <div className="card_img d-flex align-items-center">
                <Card.Img className="img-fluid" variant="top" src={pran_img} />
              </div>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("text_for_pledge")}
                  </p>
                  <div className="text-center pt-3">
                    <a href="./asset/files/Pledge.pdf" download>
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("translation_of_campaign_name_and_tagline")}
                  </p>
                  <div className="text-center">
                    <a href="./asset/files/meri_maati_mera_desh.pdf" download>
                      {/* <p className="mt-2" style={{ color: "white" }}> {t("sample_shila")}</p> */}
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <div className="card_img d-flex align-items-center justify-content-center">
                <Card.Img
                  style={{ height: "200px", width: "auto" }}
                  className="img-fluid"
                  variant="top"
                  src="./asset/files/English.jpg"
                />
              </div>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("english_logo")}</p>
                  <div className="text-center pt-3">
                    <a href="./asset/files/English.jpg" download>
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a href="./asset/files/English.pdf" download>
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a href="./asset/files/English.png" download>
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <div className="card_img d-flex align-items-center justify-content-center">
                <Card.Img
                  style={{ height: "200px", width: "auto" }}
                  className="img-fluid"
                  variant="top"
                  src="./asset/files/Hindi.jpg"
                />
              </div>
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("hindi_logo")}</p>
                  <div className="text-center pt-3">
                    <a href="./asset/files/Hindi.jpg" download>
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a href="./asset/files/Hindi.pdf" download>
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a href="./asset/files/Hindi.png" download>
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("english")}
                    {t("standees")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeEnglish.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeEnglish.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeEnglish.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("hindi")}
                    {t("standees")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeHindi.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeHindi.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/standees/StandeeHindi.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("english")}
                    {t("hoarding")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingEnglish.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingEnglish.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingEnglish.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("hindi")}
                    {t("hoarding")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingHindi.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingHindi.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/hoarding/HoardingHindi.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("english")}
                    {t("backdrop")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropEnglish.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropEnglish.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropEnglish.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">
                    {t("hindi")}
                    {t("backdrop")}
                  </p>
                  <div className="text-center pt-3">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropHindi.jpg"
                      download
                    >
                      <button className="download-btn mx-2">JPG</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropHindi.pdf"
                      download
                    >
                      <button className="download-btn mx-2">PDF</button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/files/backdrop/BackdropHindi.png"
                      download
                    >
                      <button className="download-btn mx-2">PNG</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("social_media")}</p>
                  <div className="text-center pt-3">
                    <a href="./asset/files/social/Twitter.jpg" download>
                      <button className="download-btn mx-2">
                        {t("twitter")}
                      </button>
                    </a>
                    <a href="./asset/files/social/Facebook.jpg" download>
                      <button className="download-btn mx-2">
                        {t("facebook")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          {/* Flag Code */}
          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("flag_code")}</p>
                  <div className="text-center pt-3">
                    <Link to="/flag-code">
                      <button className="download-btn mx-2">{t("view")}</button>
                    </Link>
                    <a href="./asset/files/FlagCode.pdf" download>
                      <button className="download-btn mx-2">
                        {t("download")}
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">{t("audio")}</p>
                  <div className="text-center pt-1">
                    <a href="./asset/files/audio/Jingle1.mp3" download>
                      <button className="download-btn mx-2">
                        {t("jingle")}-1
                      </button>
                    </a>
                    <a href="./asset/files/audio/Jingle2.mp3" download>
                      <button className="download-btn mx-2">
                        {t("jingle")}-2
                      </button>
                    </a>
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/audio/audio_new.wav"
                      download
                    >
                      <button className="download-btn mx-2">
                        {t("jingle")}-3
                      </button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col
            xs={12}
            md={6}
            lg={3}
            className="d-flex justify-content-center align-items-center"
          >
            <Card className="download-cards" style={{ marginTop: "5%" }}>
              {/* <Card.Img
                className="img-fluid"
                variant="top"
                src="./asset/images/gallery.jpg"
              /> */}
              <video
                controls
                src="https://shilafalkam.s3.ap-south-1.amazonaws.com/video/anthem.mp4"
              />
              <Card.Body className="d-flex justify-content-center align-items-center">
                <div className="my-2">
                  <p className="card-title text-center">Video</p>
                  <div className="text-center pt-1">
                    <a
                      href="https://shilafalkam.s3.ap-south-1.amazonaws.com/video/anthem.mp4"
                      download
                    >
                      <button className="download-btn mx-2">Download</button>
                    </a>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          {sections.map((sectionName, index) => (
            <Col
              xs={12}
              md={6}
              lg={3}
              className="d-flex justify-content-center align-items-center"
              key={index}
            >
              <Card className="download-cards" style={{ marginTop: "5%" }}>
                <Card.Img
                  className="img-fluid"
                  variant="top"
                  src="./asset/images/gallery.jpg"
                />
                <Card.Body className="d-flex justify-content-center align-items-center">
                  <div className="my-2">
                    <h6 className="card-title text-center">{t(sectionName)}</h6>
                    <div className="text-center">
                      <button className="download-btn mx-2">
                        {t("coming_soon")}
                      </button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </div>
      </div>
    </div>
  );
}
