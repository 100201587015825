import React, { useEffect, useState } from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import "./Home.css";
import firebase from "../../firebase";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";

const DashboardCounterCard = ({
  selectedState,
  selectedDistrict,
  selectedBlock,
  collectionName,
  cardImage,
  cardName,
  category = null,
  fileType = null,
  eventDate = null,
  returnOnlyCounter = false,
  districtKey = null,
  blockKey = null,
  ulbKey = null,
  selectedULB,
  dignitary_name = null,
  date = null,
  phase = null,
  kalashData = null,
  setKalashData = null,
  type = null,
}) => {
  const [counter, updateCounter] = useState(0);
  let whereClauses = [];

  if (category) {
    whereClauses.push(["category", "==", category]);
  }
  if (fileType) {
    whereClauses.push(["fileType", "==", fileType]);
    // whereClauses.push(["file_type", "==", fileType]);
  }
  if (eventDate) {
    whereClauses.push(["event_date", "!=", ""]);
  }
  if (dignitary_name) {
    whereClauses.push(["dignitary_name", "!=", ""]);
  }
  if (phase == "phase1") {
    const startDate = new Date(date);
    whereClauses.push(["timestamp", "<", startDate]);
  }
  if (phase == "phase2") {
    const startDate = new Date(date);
    whereClauses.push(["timestamp", ">=", startDate]);
  }
  if (type) {
    whereClauses.push(["type", "==", type]);
  }

  // Get total ELEMENT Count
  useEffect(async () => {
    if (selectedState == "") {
      updateCounter(0);

      const db = firebase.firestore();
      const coll = collection(db, collectionName);

      // // console.log(collectionName + 'whereClauses: ', whereClauses);

      // If there is any Where Clause, add it to the query
      let q = query(coll);
      for (let clause of whereClauses) {
        q = query(q, where(...clause));
      }
      const snapshot = await getCountFromServer(q);
      updateCounter(snapshot.data().count);
    }
    // console.log(collectionName + ' count: ', snapshot.data().count);
  }, [selectedState]);

  // Get total ELEMENT for selected state
  useEffect(async () => {
    if (selectedState) {
      updateCounter(0);

      const formattedState = selectedState.toLowerCase();
      const db = firebase.firestore();
      const coll = collection(db, collectionName);
      const selectedStateVarieties = [
        formattedState,
        formattedState.toUpperCase(),
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
        selectedState
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];

      // console.log(selectedStateVarieties)
      whereClauses.push(["state", "in", selectedStateVarieties]);

      // If there is any Where Clause, add it to the query
      let q = query(coll);
      for (let clause of whereClauses) {
        q = query(q, where(...clause));
      }

      const snapshot = await getCountFromServer(q);
      updateCounter(snapshot.data().count);

      // console.log('count: ', snapshot.data().count);
    }
  }, [selectedState]);

  // Get total ELEMENT for selected state, district
  useEffect(async () => {
    if (selectedDistrict && selectedState) {
      updateCounter(0);

      const formattedState = selectedState.toLowerCase();
      const formattedDistrict = selectedDistrict.toLowerCase();
      const db = firebase.firestore();
      const coll = collection(db, collectionName);
      const selectedStateVarieties = [
        formattedState,
        formattedState.toUpperCase(),
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
        selectedState
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      whereClauses.push(["state", "in", selectedStateVarieties]);

      const selectedDistrictVarieties = [
        formattedDistrict,
        formattedDistrict.toUpperCase(),
        formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
        formattedDistrict
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      // Id any collection has a different districtkey
      whereClauses.push([
        districtKey ? districtKey : "district",
        "in",
        selectedDistrictVarieties,
      ]);

      // If there is any Where Clause, add it to the query
      let q = query(coll);
      for (let clause of whereClauses) {
        q = query(q, where(...clause));
      }
      const snapshot = await getCountFromServer(q);
      updateCounter(snapshot.data().count);

      // console.log("count: ", snapshot.data().count);
    }
  }, [selectedDistrict]);

  // Get total ELEMENT for selected state, district block
  useEffect(async () => {
    if (selectedDistrict && selectedState && selectedBlock) {
      updateCounter(0);

      const formattedState = selectedState.toLowerCase();
      const formattedDistrict = selectedDistrict.toLowerCase();
      const formattedBlock = selectedBlock.toLowerCase();
      const db = firebase.firestore();
      const coll = collection(db, collectionName);
      const selectedStateVarieties = [
        formattedState,
        formattedState.toUpperCase(),
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
        selectedState
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      whereClauses.push(["state", "in", selectedStateVarieties]);

      const selectedDistrictVarieties = [
        formattedDistrict,
        formattedDistrict.toUpperCase(),
        formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
        formattedDistrict
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      whereClauses.push([
        districtKey ? districtKey : "district",
        "in",
        selectedDistrictVarieties,
      ]);

      const selectedBlockVarieties = [
        formattedBlock,
        formattedBlock.toUpperCase(),
        formattedBlock.replace(/\b\w/g, (c) => c.toUpperCase()),
        formattedBlock
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      whereClauses.push([
        blockKey ? blockKey : "block",
        "in",
        selectedBlockVarieties,
      ]);

      // If there is any Where Clause, add it to the query
      let q = query(coll);
      for (let clause of whereClauses) {
        q = query(q, where(...clause));
      }
      const snapshot = await getCountFromServer(q);
      updateCounter(snapshot.data().count);

      // console.log('count: ', snapshot.data().count);
    }
  }, [selectedBlock]);

  // Get total ELEMENT for selected state, ULB
  useEffect(async () => {
    if (selectedULB && selectedState) {
      updateCounter(0);

      const formattedState = selectedState.toLowerCase();
      const formattedDistrict = selectedULB.toLowerCase();
      const db = firebase.firestore();
      const coll = collection(db, collectionName);
      const selectedStateVarieties = [
        formattedState,
        formattedState.toUpperCase(),
        formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
        selectedState
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      whereClauses.push(["state", "in", selectedStateVarieties]);

      const selectedDistrictVarieties = [
        formattedDistrict,
        formattedDistrict.toUpperCase(),
        formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
        formattedDistrict
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      ];
      // Id any collection has a different districtkey
      whereClauses.push(["name_of_ulb", "in", selectedDistrictVarieties]);

      // If there is any Where Clause, add it to the query
      let q = query(coll);
      for (let clause of whereClauses) {
        q = query(q, where(...clause));
      }
      const snapshot = await getCountFromServer(q);
      updateCounter(snapshot.data().count);

      console.log("count: ", snapshot.data().count);
    }
  }, [selectedULB]);

  if (returnOnlyCounter) {
    // console.log(cardName, "counter: ", counter);
    if (cardName == "Amrit Kalash Yatra Pictures2") {
      setKalashData(counter);
      return kalashData;
    }
    return counter;

    // return counter?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return (
      <Col xs={12} lg={3} className="mt-1">
        <Card className="custom-card">
          <Row noGutters>
            <Col
              xs={4}
              className="d-flex align-items-center justify-content-cente"
            >
              <Image
                className="m-2"
                src={cardImage}
                alt="Card Image"
                fluid
                style={{ style: "100%", height: "100px", width: "auto" }}
              />
            </Col>
            <Col xs={8}>
              <Card.Body>
                <Card.Title className="count">
                  {" "}
                  {counter
                    ?.toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}{" "}
                </Card.Title>
                <Card.Text className="topic">{cardName}</Card.Text>
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </Col>
    );
  }
};

export default DashboardCounterCard;
