import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

export default function Disclaimer() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_body">
      <Header />
      <br />
      <div>
        <h1 className="main_head text-center">Disclaimer</h1>
      </div>

      <div className="container mt-5 px-4">
        <p>
          Please read these Terms of Use & Privacy Policy (hereinafter referred
          to as "Agreements",) carefully before using our website
          merimaatimeradesh.gov.in (hereinafter referred to as "us", "we", or
          "our" "the Site") operated by the Ministry of Youth Affairs & Sports
          ("Ministry"). These Agreements sets forth the legally binding terms
          and conditions for your use of the services or website at
          merimaatimeradesh.gov.in
        </p>
        <p>
          The use of this website by the User including prospective registered
          Users is solely governed by this policy and any policy so mentioned by
          terms of reference. Moving past home page, or using any of the
          services shall be taken to mean that User have read and agreed to all
          of the agreements or policies so binding upon User and that User are
          contracting with the Ministry and have undertaken binding obligations
          with the Ministry.
        </p>
        <p>
          User will be subject to the rules, guidelines, policies, terms, and
          conditions applicable to any service that is provided by this website,
          and they shall be deemed to be incorporated into this Terms of Use &
          Privacy Policy and shall be considered as part and parcel of these
          Agreements.{" "}
        </p>
        <p>
          Ministry's hold the sole right to modify these Agreements without
          prior permission from User or informing User. The relationship creates
          on User a duty to periodically check the terms and stay updated on its
          requirements. If User continues to use the website following such a
          change, this is deemed as consent by User to the so amended Agreement.
          As long as User complies with these Agreements, we grant User a
          personal, non-exclusive, non-transferable, limited privilege to enter
          and use the website.
        </p>
        <p>
          Ministry or its officials, managers, employees or affiliates will not
          be liable for any loss, damage, injury, inaccessibility, failure,
          interruption, alteration or damage of any data, third party
          information or other information caused to a User arising out of use
          of the Website , its Online services or materials.
        </p>
        <p>
          By impliedly or expressly accepting these Terms of Service, User also
          accept and agree to be bound by other Company Policies, inter alia
          Privacy Policy, which would be amended from time to time. In case User
          finds any information objectionable or beyond scope of such purpose,
          please feel free to contact Grievance Redressed officer. If you are
          not satisfied with resolution of your query/complaint, you have the
          option to discontinue use of the website or cancel your account with
          merimaatimeradesh.gov.in.
        </p>
      </div>
    </div>
  );
}
