import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import { useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
import firebase from "../../firebase";
import { Col, Row } from "react-bootstrap";
import DateRangePicker from "../Components/DateRangePicker";

export default function BlockNyvReport() {
  const location = useLocation();
  const [loader, setLoader] = useState(true);
  // Extract the state and district parameters from the location search
  const searchParams = new URLSearchParams(location.search);
  const selectedState = searchParams.get("state");
  const selectedDistrict = searchParams.get("district");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [downloading, setDownlaoding] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [LGDBlocks, setLGDBlocks] = useState([]);
  const [stateCode, setStateCode] = useState();
  const [districtCode, setDistrictCode] = useState();
  const [blockCode, setBlockCode] = useState();

  const [selectedDates, setSelectedDates] = useState({
    startDate: null,
    endDate: null,
  });
  const minDate = new Date("2023-09-01");
  const maxDate = new Date();

  const formatDateString = (date) => {
    if (date instanceof Date) {
      // Convert the date to the local timezone
      const localDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      return localDate.toISOString().slice(0, 19).replace("T", " ");
    }
    return null;
  };

  const handleDatesSelected = (dates) => {
    setSelectedDates(dates);
  };

  const formattedStartDate = formatDateString(selectedDates.startDate);
  const formattedEndDate = formatDateString(selectedDates.endDate);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("nyv_user"));
    const fetchedStates = [];
    const fetchedBlocks = [];
    const fetchedDistricts = [];

    user.forEach((e) => {
      fetchedStates.push(e.state);
      fetchedBlocks.push({ block: e.block, block_code: e.block_code });

      fetchedDistricts.push(e.district);
    });

    setBlocks(fetchedBlocks);
    setSelectedBlock(fetchedBlocks[0]);

    // Fetch all the Blocks of the District that the logged in user belong to
    LGDSelector("district_code", user[0].district_code, setLGDBlocks);
    setStateCode(user[0].state_code);
    setDistrictCode(user[0].district_code);
    console.log(user);
  }, []);
  useEffect(() => {
    // if (!loader) {
    firebase.auth().onAuthStateChanged(async (user) => {
      const token = await user.getIdToken();
      if (token) {
        if (stateCode && districtCode && blockCode) {
          //!user[0]?.super_admin &&

          if (formattedStartDate && formattedEndDate) {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?type=block_filter&token=${token}&state=${stateCode}&district=${districtCode}&block=${blockCode}&date=true&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
              )
              .then((res) => {
                setReportData(res?.data);
                setLoader(false);
                console.log("report data", res?.data);
              });
          } else {
            axios
              .get(
                `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?type=block_filter&token=${token}&state=${stateCode}&district=${districtCode}&block=${blockCode}`
              )
              .then((res) => {
                setReportData(res?.data);
                setLoader(false);
                console.log("report data", res?.data);
              });
          }
        }
        // else {
        //   axios
        //     .get(
        //       `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/getReports?type=block_filter&token=${token}&state=${selectedState}&district=${selectedDistrict}&block=${selectedBlock}`
        //     )
        //     .then((res) => {
        //       setReportData(res?.data);
        //       setLoader(false);
        //       console.log("report data", res?.data);
        //     });
        // }
      }
    });
    // }
  }, [blockCode, formattedEndDate, formattedStartDate]);

  useEffect(() => {
    selectedBlock
      ? setSelectedBlock(selectedBlock)
      : setSelectedBlock(LGDBlocks[0]);
  }, [LGDBlocks]);

  // Convert data to CSV format
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const handleDownload = () => {
    // Create a new blank workbook
    const workbook = XLSX.utils.book_new();
    const worksheets = [{ name: "Report", data: reportData[0] }];

    // Convert each data array to a worksheet
    worksheets.forEach((sheet) => {
      const worksheet = XLSX.utils.json_to_sheet(sheet.data);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheet.name);
    });

    // Create a Blob and download the Excel workbook
    const workbookBlob = new Blob(
      [s2ab(XLSX.write(workbook, { type: "binary" }))],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(workbookBlob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Block Report.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setDownlaoding(false);
    console.log("Data has been downloaded as nodal_officer_summary.xlsx");
  };

  // Function to remove duplicates based on entire objects
  function removeDuplicatesByKey(arr, key) {
    const uniqueValues = new Set();
    const deduplicatedArray = [];

    for (const obj of arr) {
      const keyValue = obj[key];

      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        deduplicatedArray.push(obj);
      }
    }

    // Sort the deduplicated array alphabetically based on the specified key
    deduplicatedArray.sort((a, b) => {
      const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const valueB = b[key].toUpperCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    return deduplicatedArray;
  }

  const LGDSelector = (keyToSearch, valueToSearch, setReturnData) => {
    console.log(keyToSearch, valueToSearch);
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("lgd_data_mapped"); // Replace with your Firestore collection name

    // Use Firestore queries to filter data based on key and value
    collectionRef
      .where(keyToSearch, "==", valueToSearch)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        console.log(keyToSearch.split("_")[0] + "_name");
        if (keyToSearch == "district_code") {
          setReturnData(removeDuplicatesByKey(data, "block_code"));
        }

        if (keyToSearch == "block_code") {
          setReturnData(removeDuplicatesByKey(data, "local_body_code"));
        }

        if (keyToSearch == "local_body_code") {
          setReturnData(removeDuplicatesByKey(data, "village_code"));
        }

        // setReturnData(data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  };

  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="pt-5">
        <Row className="d-flex justify-content-center">
          <Col md={2}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                  State: {selectedState}
                </h6>

                <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                  District: {selectedDistrict}
                </h6>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Block:</h6>
            <select
              style={{
                fontSize: "15px",
                color: "#543c00",
                padding: "8px",
                height: "40px",
                borderRadius: "25px",
                border: "1px solid #543c00",
                background: "#e7d3a9",
                width: "100%",
              }}
              name="block"
              onChange={(e) => {
                setSelectedBlock(LGDBlocks[e.target.value]);
                setBlockCode(LGDBlocks[e.target.value]?.block_code);
                setLoader(true);
                console.log(LGDBlocks[e.target.value]);
              }}
              // value={selectedBlock}
              required // Make this field mandatory
            >
              <option value="">Select Block</option>
              {LGDBlocks?.map((block, index) => (
                <option key={block.block_name + index} value={index}>
                  {block.block_name}
                </option>
              ))}
            </select>
          </Col>
        </Row>

        <div className="py-3">
          <DateRangePicker
            onDatesSelected={handleDatesSelected}
            minDate={minDate}
            maxDate={maxDate}
          />
          {formattedStartDate && formattedEndDate && (
            <div className="text-center">
              <h6>
                Date Selected :
                <span className="date">{formattedStartDate}</span> to{" "}
                <span className="date">{formattedEndDate}</span>
              </h6>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-end my-3">
          <button
            className="pledge_btn mx-2"
            style={{
              fontSize: "13px",
              width: "auto",
              opacity: downloading ? "0.5" : "1",
            }}
            onClick={() => {
              handleDownload();
            }}
            disabled={downloading}
          >
            {downloading ? "Downloading" : "Download Report"}
          </button>
        </div>
      </div>
      {selectedBlock && reportData?.[0]?.[0] ? (
        <div className="table-container mx-2 mt-4">
          <table className="report-table">
            <thead>
              <tr>
                <th>State</th>

                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("district") &&
                    "District"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("block") &&
                    "Block"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("panchayat") &&
                    "Panchayat"}
                </th>

                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes(
                    "distinct_villages"
                  ) && "Distinct Villages"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("images") &&
                    "Images"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("videos") &&
                    "Videos"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes(
                    "total_household_number"
                  ) && "Total Household Number"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes(
                    "block_level_event_date"
                  ) && "Block Level Event Date"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes("kalash_date") &&
                    "Kalash Date"}
                </th>
                <th>
                  {Object.keys(reportData?.[0]?.[0])?.includes(
                    "dignitary_name_count"
                  ) && "Dignitary name count"}
                </th>
              </tr>
            </thead>
            <tbody>
              {reportData?.[0]?.map((item, index) => (
                <tr key={index}>
                  <td>{item["state"] && item["state"]}</td>

                  <td>{item["district"] && item["district"]}</td>
                  <td>{item["block"] && item["block"]}</td>
                  <td>{item["panchayat"] && item["panchayat"]}</td>

                  <td>
                    {item["distinct_villages"] && item["distinct_villages"]}
                  </td>
                  <td>{item["images"] && item["images"]}</td>
                  <td>{item["videos"] && item["videos"]}</td>
                  <td>
                    {item["total_household_number"] &&
                      item["total_household_number"]}
                  </td>
                  <td>
                    {item["block_level_event_date"]
                      ? item["block_level_event_date"]
                      : null}
                  </td>
                  <td>{item["kalash_date"] && item["kalash_date"]}</td>
                  <td>
                    {item["dignitary_name_count"] &&
                      item["dignitary_name_count"]}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "30vh" }}
        >
          <h1>No Data Found</h1>
        </div>
      )}
    </div>
  );
}
