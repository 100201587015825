import React, { useEffect, useState } from "react";
import { Tab, Tabs, Row, Col, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import firebase from "../../firebase";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import Header from "../Components/Header/Header";
import axios from "axios";

export default function AddNYV() {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [blocks, setBlocks] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGramPanchayat, setSelectedGramPanchayat] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [searchResults, setResults] = useState([]);
  const [dummySearchResults, setDummyResults] = useState([]);
  const [inputValue, setInputValue] = useState();
  const [modalShow, setModal] = useState();
  const [isValid, setValid] = useState(true);
  const [phone, setPhone] = useState("+91");
  const [docId, setDocId] = useState();
  const [LGDVillages, setLGDVillages] = useState([]);

  useEffect(() => {
    let db = firebase.firestore().collection("ak_nyv_officers");
    let phone = JSON.parse(localStorage.getItem("nyv_user"))[0]?.phoneNumber;
    db.where("phoneNumber", "==", phone)
      .where("type", "==", "dyo")
      .get()
      .then(async (querySnapshot) => {
        if (!querySnapshot.empty) {
          let data = [];
          querySnapshot.forEach((e) => {
            data.push(e.data());
          });
          const user = data;
          const fetchedStates = [];
          const fetchedBlocks = [];
          const fetchedDistricts = [];
          console.log(user);
          user.forEach((e) => {
            fetchedStates.push(e.state);
            fetchedBlocks.push({ block: e.block, block_code: e.block_code });

            fetchedDistricts.push({
              district: e.district,
              district_code: e?.district_code,
            });
          });

          setStates(fetchedStates);
          setBlocks(fetchedBlocks);
          setDistricts(fetchedDistricts);
          setSelectedState(fetchedStates[0]);
          setSelectedDistrict(fetchedDistricts[0]?.district_code);
          setSelectedBlock(fetchedBlocks[0]);
        }
      });
  }, []);

  const [NYVMobile, setNYVMobile] = useState("");
  const [NYVName, setNYVName] = useState("");

  const handleUpdate = (e) => {
    console.log(docId);
    if (phone.length != 13) {
      toast.error("Invalid Mobile Number");
      return;
    }
    firebase
      .firestore()
      .collection("ak_nyv_officers")
      .doc(docId)
      .get()
      .then(async (res) => {
        await res.ref.update({
          phoneNumber: phone,
        });
        toast.success("Phone Number Updated");
        setResults([]);
        setModal();
        setValid();
        setDocId();
        setInputValue();
      });

    return;
  };
  useEffect(async () => {
    let results = [];
    if (selectedDistrict) {
      const querySnapshot1 = await firebase
        .firestore()
        .collection("ak_nyv_officers")
        .where("district_code", "==", selectedDistrict)
        .get();

      if (!querySnapshot1.empty) {
        querySnapshot1.forEach((doc) => {
          let data = {};
          data = doc.data();
          data.doc_id = doc.id;
          results.push(data);
        });
      }

      setResults(results);
      setDummyResults(results);
    }
  }, [selectedDistrict]);
  function searchByNameOrPhoneNumber(query) {
    query = query.toLowerCase(); // Convert the query to lowercase for case-insensitive search
    const results = [];

    for (const item of dummySearchResults) {
      const name = item.name.toLowerCase();
      const phoneNumber = item.phoneNumber;

      // Check if the query matches the name or phone number
      if (name.includes(query) || phoneNumber.includes(query)) {
        results.push(item);
      }
    }

    return results;
  }

  const onInputChange = async (query) => {
    if (query.length == 0) {
      setResults(dummySearchResults);
    } else {
      const searchQuery = query; // Replace with the name or phone number you want to search
      const searchResults = searchByNameOrPhoneNumber(searchQuery);

      setResults(searchResults);
    }
  };
  const handleCheck = (e) => {
    setLoading(true);
    const email = e.slice(3) + "@gmail.com";

    axios
      .get(
        `https://us-central1-ekta-shrinkhila-59ad9.cloudfunctions.net/checkSignedIn?email=${email}`
      )
      .then((res) => {
        if (res?.data.code == 500) {
          setValid(true);
        } else {
          if (res?.data?.lastSignInTime == null) {
            setValid(true);
          }
        }
        setLoading(false);
      });
  };

  console.log(searchResults);
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="d-flex justify-content-end px-2">
        <div className="d-flex justify-content-end px-2">
          <a href="/kalash-report">
            <button
              className="submit_btn"
              style={{ fontSize: "13px", width: "auto" }}
            >
              Kalash Reports
            </button>
          </a>
        </div>
        <div className="d-flex justify-content-end px-2">
          <a href="/moderation-report">
            <button
              className="submit_btn"
              style={{ fontSize: "13px", width: "auto" }}
            >
              Moderation Reports
            </button>
          </a>
        </div>
      </div>
      <div>
        <Row className="d-flex justify-content-center align-items-center ">
          <Col md={12}>
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                  State: {selectedState}
                </h6>
              </div>
            </div>
          </Col>
          <br />
          <Col
            my={12}
            md={12}
            className="d-flex justify-content-center align-items-center"
          >
            <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
              District :
            </h6>
            <select
              style={{
                fontSize: "15px",
                color: "#543c00",
                padding: "8px",
                height: "40px",
                marginLeft: "1rem",
                marginTop: "1rem",
                borderRadius: "25px",
                border: "1px solid #543c00",
                background: "#e7d3a9",
                width: "200px",
              }}
              value={selectedDistrict}
              name="gramPanchayat"
              onChange={(e) => {
                setSelectedDistrict(e.target.value);
              }}
              // value={selectedGramPanchayat}
              required // Make this field mandatory
            >
              <option value="">Select District</option>
              {districts?.map((dis, index) => (
                <option key={dis + index} value={dis.district_code}>
                  {dis.district}
                </option>
              ))}
            </select>
          </Col>
          {/* <Col md={2}>
              <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                Block:
              </h6>
              <select
                style={{
                  fontSize: "15px",
                  color: "#543c00",
                  padding: "8px",
                  height: "40px",
                  borderRadius: "25px",
                  border: "1px solid #543c00",
                  background: "#e7d3a9",
                  width: "100%",
                }}
                name="block"
                onChange={(e) => {
                  setSelectedBlock(e.target.value);
                }}
                value={selectedBlock}
                required // Make this field mandatory
              >
                <option value="">Select Block</option>
                {blocks?.map((block, index) => (
                  <option key={block + index} value={block}>
                    {block}
                  </option>
                ))}
              </select>
            </Col> */}
        </Row>
        <br />

        <hr />
        <br />
        <Row>
          <div className="container d-flex justify-content-center text-center ">
            <div className="row d-flex justify-content-center align-items-center form_card ">
              <div className="text-center">
                <h3 className="household_title">Edit NYV </h3>
              </div>

              <Col my={10} md={10}>
                <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                  Search:
                </h6>
                <input
                  style={{
                    fontSize: "15px",
                    color: "#543c00",
                    padding: "8px",
                    height: "40px",
                    borderRadius: "25px",
                    border: "1px solid #543c00",
                    background: "#e7d3a9",
                    width: "50%",
                  }}
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    onInputChange(e.target.value);
                  }}
                />
              </Col>
              {searchResults.length !== 0 && (
                <Row
                  style={{
                    marginTop: "2rem",
                    marginLeft: "1.5rem",
                    marginRight: "1.5rem",
                    padding: "8px",
                    border: "1px solid rgb(84, 60, 0)",
                    background: "rgb(231, 211, 169)",
                    color: "rgb(84, 60, 0)",
                    borderRadius: "12px",
                  }}
                >
                  <Col my={2} md={2}>
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      Name :
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      Phone Number :
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      State :
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      District :
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      Block :
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "18px", marginLeft: "0.5rem" }}>
                      Edit :
                    </h6>
                  </Col>
                </Row>
              )}
              {searchResults?.map((e) => (
                <Row
                  style={{
                    marginTop: "2rem",
                    marginLeft: "1.5rem",
                    marginRight: "1.5rem",
                    padding: "8px",
                    border: "1px solid rgb(84, 60, 0)",
                    background: "rgb(231, 211, 169)",
                    color: "rgb(84, 60, 0)",
                    borderRadius: "12px",
                  }}
                >
                  <Col my={2} md={2}>
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      {e?.name}
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      {e?.phoneNumber}
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      {e?.state}
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      {e?.district}
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      {e?.block}
                    </h6>
                  </Col>
                  <Col my={2} md={2}>
                    {" "}
                    <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
                      <svg
                        width={18}
                        onClick={() => {
                          handleCheck(e?.phoneNumber);
                          setModal(e?.phoneNumber);
                          setDocId(e?.doc_id);
                        }}
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="EditIcon"
                        tabindex="-1"
                        title="Edit"
                      >
                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                      </svg>
                    </h6>
                  </Col>
                </Row>
              ))}
            </div>
          </div>
        </Row>
        <Modal show={modalShow}>
          {loading ? (
            <div
              style={{
                width: "500px",
                textAlign: "center",
                padding: "12px",
                color: "rgb(84, 60, 0)",
              }}
            >
              Please Wait...
            </div>
          ) : (
            <>
              <div
                style={{
                  width: "500px",
                  textAlign: "center",
                  padding: "12px",
                  color: "rgb(84, 60, 0)",
                }}
              >
                Phone : {modalShow}
              </div>
              <br />
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "12px",
                  color: "rgb(84, 60, 0)",
                }}
              >
                {isValid ? (
                  <>
                    <h6>New Phone Number :</h6>
                    <input
                      style={{
                        fontSize: "15px",
                        color: "#543c00",
                        padding: "8px",
                        height: "40px",
                        borderRadius: "25px",
                        border: "1px solid #543c00",
                        background: "#e7d3a9",
                        marginTop: "0.5rem",
                        width: "50%",
                      }}
                      autoComplete="off"
                      label="Phone Number"
                      value={phone}
                      maxLength={13}
                      onChange={(event) => setPhone(event.target.value)}
                    />
                  </>
                ) : (
                  "Phone number already signed in !"
                )}
              </div>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  padding: "12px",
                  color: "rgb(84, 60, 0)",
                }}
              >
                {isValid && (
                  <button
                    className="pledge_btn mt-4 "
                    style={{ marginRight: "1rem", border: "1px solid red" }}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update
                  </button>
                )}
                <button
                  onClick={() => {
                    setResults([]);
                    setModal();
                    setValid();
                    setDocId();
                    setInputValue();
                  }}
                  className="pledge_btn mt-4"
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}
