import React, { useState, useEffect } from "react";
import firebase from "../../firebase";

export default function StateDistrictBlockGP({
  location,
  onUpdateLocation,
  showGp = true,
  showType = true,
}) {
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGP, setSelectedGP] = useState("");
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [gps, setGps] = useState([]);

  // const handleLocationChange = (field, value) => {
  //   const updatedLocation = { ...location, [field]: value };

  //   console.log(updatedLocation, "updatedLocation");
  //   onUpdateLocation(updatedLocation);
  // };

  const [selectedUlb, setSelectedUlb] = useState("");
  const [ulbs, setUlbs] = useState([]);
  const [type, setType] = useState("Rural");
  const handleLocationChange = (field, value) => {
    // Update the parent's location state
    onUpdateLocation(field, value);
  };

  // function getDistrictsForState(stateName) {
  //     return stateDistrictMap[stateName] || [];
  //   }
  //   const indiaStates = Object.keys(stateDistrictMap);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedDistrict("");
    setSelectedBlock("");
    setSelectedGP("");
    setDistrict([]);
    setBlocks([]);
    setGps([]);

    handleLocationChange("state", event.target.value);
    handleLocationChange("district", "");
    handleLocationChange("block", "");
    handleLocationChange("gp", "");
  };

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });
        array.sort();
        setStates(array);
      });
  }, []);

  //Get District of Slecetd States
  useEffect(() => {
    handleLocationChange("district", "");
    handleLocationChange("block", "");
    handleLocationChange("gp", "");

    if (selectedState) {
      if (type == "Rural") {
        handleLocationChange("state", selectedState);
        const formattedState = selectedState.toLowerCase();
        firebase
          .firestore()
          .collection("state_district_mapping")
          .where("state_name", "in", [
            formattedState,
            formattedState.toUpperCase(),
            formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          ])
          .get()
          .then((res) => {
            let array = [];
            res.forEach((doc) => {
              // console.log("district array", doc.data().district_mapping);
              array.push(doc.data()?.district_mapping?.toUpperCase());
            });
            array.sort();
            setDistrict(array);
          });
      } else {
        handleLocationChange("state", selectedState);
        const formattedState = selectedState.toLowerCase();
        firebase
          .firestore()
          .collection("state_ulb_mapping")
          .where("state_name", "in", [
            formattedState,
            formattedState.toUpperCase(),
            formattedState.replace(/\b\w/g, (c) => c.toUpperCase()),
          ])
          .get()
          .then((res) => {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().name_of_ulb?.toUpperCase());
            });
            array.sort();
            setUlbs(array);
          });
      }
    }
  }, [selectedState]);

  //Get Block of Slecetd States
  useEffect(() => {
    handleLocationChange("block", "");
    handleLocationChange("gp", "");

    if (selectedDistrict) {
      handleLocationChange("district", selectedDistrict);
      const formattedDistrict = selectedDistrict.toLowerCase();

      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "in", [
          formattedDistrict,
          formattedDistrict.toUpperCase(),
          formattedDistrict.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            // console.log("block array", doc.data());
            array.push(doc.data()?.block_name?.toUpperCase());
          });

          setBlocks(array);
        });
    }
  }, [selectedDistrict]);

  //Get GP of Slecetd States
  useEffect(() => {
    handleLocationChange("gp", "");

    if (selectedBlock) {
      handleLocationChange("block", selectedBlock);
      const formattedBlock = selectedBlock.toLowerCase();
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "in", [
          formattedBlock,
          formattedBlock.toUpperCase(),
          formattedBlock.replace(/\b\w/g, (c) => c.toUpperCase()),
        ])
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            // console.log("gp array", doc.data());
            array.push(doc.data()?.gp_name?.toUpperCase());
          });
          array.sort();
          setGps(array);
        });
    }
  }, [selectedBlock]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // console.log(name);
    // setFormInput((prev) => ({ ...prev, [name]: value }));

    if (name === "state") {
      setSelectedState(value);
      setFilteredDistricts(districts);

      //Nullify selected District
      setSelectedDistrict("");
      setSelectedBlock("");
      setSelectedGP("");

      handleLocationChange("district", "");
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
    }
    if (name === "district") {
      // console.log("district", value);
      setSelectedDistrict(value);
      setSelectedBlock("");
      setSelectedGP("");
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
    }

    if (name === "block") {
      // console.log("block", value);
      setSelectedBlock(value);
      setSelectedGP("");
      handleLocationChange("gp", "");
    }
    if (name === "gp") {
      // console.log("gp", value);
      setSelectedGP(value);
      handleLocationChange("gp", value);
    }
    if (name === "ulb") {
      console.log("ulb", value);
      setSelectedUlb(value);
      handleLocationChange("ulb", value);
    }
  };

  return (
    <div>
      {showType && (
        <div className="row mx-4">
          <div className="col-lg-3  col-md-6 my-2 d-flex justify-content-center">
            <label className="mt-1 mb-1 ">
              <select
                className="select-input"
                tabIndex="2"
                name="state"
                onChange={(e) => {
                  handleLocationChange("type", e.target.value);
                  setSelectedDistrict("");
                  setSelectedState("");
                  setSelectedDistrict("");
                  setSelectedBlock("");
                  setSelectedGP("");
                  setDistrict([]);
                  setBlocks([]);
                  setGps([]);
                  handleLocationChange("state", "");
                  handleLocationChange("district", "");
                  handleLocationChange("block", "");
                  handleLocationChange("gp", "");
                  handleLocationChange("ulb", "");
                  setType(e.target.value);
                }}
                value={type}
              >
                <option value="">Select Type</option>
                {/* Map over your states data */}
                {["Urban", "Rural"].map((state, index) => (
                  <option key={state + index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      )}
      {type == "Rural" ? (
        <div className="row mx-4  d-flex justify-content-center">
          <div className="col-lg-3  col-md-6 my-2 d-flex justify-content-center">
            <label className="mt-1 mb-1 ">
              <select
                className="select-input"
                tabIndex="2"
                name="state"
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {/* Map over your states data */}
                {states?.sort()?.map((state, index) => (
                  <option key={state + index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-lg-3  col-md-6  my-2  d-flex justify-content-center">
            <label className="mx-1">
              <select
                className="select-input"
                name="district"
                onChange={handleInputChange}
                disabled={!selectedState}
              >
                <option value="">Select District</option>
                {districts?.sort()?.map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-lg-3 col-md-6  my-2  d-flex justify-content-center">
            <label className="mx-1">
              <select
                className="select-input"
                name="block"
                onChange={handleInputChange}
                disabled={!selectedDistrict}
              >
                <option value="">Select Block</option>
                {blocks?.sort()?.map((block) => (
                  <option key={block} value={block}>
                    {block}
                  </option>
                ))}
              </select>
            </label>
          </div>
          {showGp && (
            <div className="col-lg-3 col-md-6  my-2  d-flex justify-content-center">
              <label className="mx-1">
                <select
                  className="select-input"
                  name="gp"
                  onChange={handleInputChange}
                  disabled={!selectedBlock}
                >
                  <option value="">Select Gram Panchayat</option>
                  {gps?.sort()?.map((gp) => (
                    <option key={gp} value={gp}>
                      {gp}
                    </option>
                  ))}
                </select>
              </label>
            </div>
          )}
        </div>
      ) : (
        <div className="row mx-4">
          <div className="col-lg-3  col-md-6 my-2 d-flex justify-content-center">
            <label className="mt-1 mb-1 ">
              <select
                className="select-input"
                tabIndex="2"
                name="state"
                onChange={handleStateChange}
              >
                <option value="">Select State</option>
                {/* Map over your states data */}
                {states?.sort()?.map((state, index) => (
                  <option key={state + index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </label>
          </div>
          <div className="col-lg-3  col-md-6  my-2  d-flex justify-content-center">
            <label className="mx-1">
              <select
                className="select-input"
                name="ulb"
                onChange={handleInputChange}
                disabled={!selectedState}
              >
                <option value="">Select ULB</option>
                {ulbs?.sort()?.map((district) => (
                  <option key={district} value={district}>
                    {district}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}
