import React, { useEffect } from "react";
import Header from "../../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import Shilpimage from "../../Assets/img/Shilpalakham1.png";
import Amritvatika from "../../Assets/img/Amritvatika.png";
import PranImage from "../../Assets/img/pran.png";
import HoistImage from "../../Assets/img/hoist.png";
import HonorImage from "../../Assets/img/soldier.png";

export default function About() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_body">
      <Header />
      <br />
      <div>
        <h1 className="main_head text-center"> {t("about_heading")}</h1>
      </div>

      <div className="container mt-5 px-4">
        <p>{t("about_line_1")}</p>

        <p>{t("about_line_2")}</p>
        <p> {t("about_line_3")} </p>
        <p> {t("about_line_4")}</p>
        <p> {t("about_line_5")}</p>
        <p> {t("about_line_6")}</p>
        <p> {t("about_line_7")}</p>
        <div className="container mt-5">
          <Row className="justify-content-center text-center">
            <Col xs={12} md={6} lg={3} className="about-profile-block">
              <div className="organiser_box">
                <div className="text-center">
                  <img
                    className="img-fluid pt-4"
                    src="/asset/files/shilapalakam_sample.jpg"
                    alt="Shilpalakam"
                    style={{ width: "70%" }}
                  />
                </div>
                <h6>
                  {t(
                    "design_and_errect_shilaphalakam_in_remembrance_of_bravehearts"
                  )}
                </h6>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="about-profile-block">
              <div className="organiser_box">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={Amritvatika}
                    alt="Amritvatika"
                  />
                </div>
                <h6>{t("creation_of_amrit_vatika")}</h6>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="about-profile-block">
              <div className="organiser_box">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={HonorImage}
                    alt="Amritvatika"
                  />
                </div>
                <h6>{t("honor_bravehearts")}</h6>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="about-profile-block">
              <div className="organiser_box">
                <div className="text-center">
                  <img
                    className="img-fluid pt-4"
                    src={PranImage}
                    alt="Amritvatika"
                    style={{ width: "90%" }}
                  />
                </div>
                <h6>
                  {t(
                    "take_the_panch_pran_pledge_with_a_fistful_of_soil_or_earthen_diya"
                  )}
                </h6>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="about-profile-block">
              <div className="organiser_box">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={HoistImage}
                    alt="Amritvatika"
                    style={{ width: "70%" }}
                  />
                </div>
                <h6> {t("hoist_national_flag_and_sing_national_anthem")}</h6>
              </div>
            </Col>
          </Row>
        </div>
        <p> {t("about_line_8")}</p>
      </div>
    </div>
  );
}
