import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import firebase from "../../firebase";

export default function LGD_mapping({
  setStateSelected,
  setDistrictSelected,
  setBlockSelected,
  setPanchayatSelected,
  setVillageSelected,
  onUpdateLocation,
}) {
  const [LGDPanchayats, setLGDPanchayats] = useState([]);
  const [LGDVillages, setLGDVillages] = useState([]);
  const [LGDBlocks, setLGDBlocks] = useState([]);
  const [LGDDistrict, setLGDDistrict] = useState([]);
  const [LGDState, setLGDState] = useState([]);

  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedBlock, setSelectedBlock] = useState("");
  const [selectedGramPanchayat, setSelectedGramPanchayat] = useState("");
  const [selectedVillage, setSelectedVillage] = useState("");

  const handleLocationChange = (field, value) => {
    // Update the parent's location state
    onUpdateLocation(field, value);
  };

  // Function to remove duplicates based on entire objects
  function removeDuplicatesByKey(arr, key) {
    const uniqueValues = new Set();
    const deduplicatedArray = [];

    for (const obj of arr) {
      const keyValue = obj[key];
      // console.log(obj);
      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        deduplicatedArray.push(obj);
      }
    }

    // Sort the deduplicated array alphabetically based on the specified key
    deduplicatedArray.sort((a, b) => {
      const valueA = a[key].toUpperCase(); // Convert to uppercase for case-insensitive sorting
      const valueB = b[key].toUpperCase();

      if (valueA < valueB) {
        return -1;
      } else if (valueA > valueB) {
        return 1;
      } else {
        return 0;
      }
    });

    return deduplicatedArray;
  }

  const LGDSelector = (keyToSearch, valueToSearch, setReturnData) => {
    // console.log(keyToSearch, valueToSearch);
    const firestore = firebase.firestore();
    const collectionRef = firestore.collection("lgd_data_mapped"); // Replace with your Firestore collection name

    // Use Firestore queries to filter data based on key and value
    collectionRef
      .where(keyToSearch, "==", valueToSearch)
      .get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
          // console.log(doc.data());
        });
        // console.log(data);
        if (keyToSearch == "state_code") {
          setReturnData(removeDuplicatesByKey(data, "district_code"));
        }

        if (keyToSearch == "district_code") {
          setReturnData(removeDuplicatesByKey(data, "block_code"));
        }

        if (keyToSearch == "block_code") {
          setReturnData(removeDuplicatesByKey(data, "local_body_code"));
        }

        if (keyToSearch == "local_body_code") {
          setReturnData(removeDuplicatesByKey(data, "village_code"));
        }

        // setReturnData(data);
      })
      .catch((error) => {
        console.error("Error retrieving data:", error);
      });
  };

  function capitalizeString(inputString) {
    return (
      inputString.charAt(0).toUpperCase() + inputString.slice(1).toLowerCase()
    );
  }

  // poass the value to upper component
  useEffect(() => {
    // console.log(selectedVillage);
    if (
      selectedState &&
      selectedDistrict &&
      selectedBlock &&
      selectedGramPanchayat &&
      selectedVillage
    ) {
      console.log("selectedVillage", selectedVillage.village_name);
      setVillageSelected(selectedVillage.village_name);
      handleLocationChange("village", selectedVillage.village_name);
    } else if (
      selectedState &&
      selectedDistrict &&
      selectedBlock &&
      selectedGramPanchayat
    ) {
      setPanchayatSelected(selectedGramPanchayat.local_body_name);
      handleLocationChange("gp", selectedGramPanchayat.local_body_name);
    } else if (selectedState && selectedDistrict && selectedBlock) {
      //   console.log("selectedBlock", selectedBlock);
      setBlockSelected(selectedBlock.block_name);
      handleLocationChange("block", selectedBlock.block_name);
    } else if (selectedState && selectedDistrict) {
      //   console.log("selectedDistrict", selectedDistrict?.district_name);
      setDistrictSelected(capitalizeString(selectedDistrict.district_name));
      handleLocationChange(
        "district",
        capitalizeString(selectedDistrict.district_name)
      );
    } else if (selectedState) {
      //   console.log("selectedState", selectedState.state);

      setStateSelected(selectedState.state);
      handleLocationChange("state", selectedState?.state);
    }
  }, [
    selectedState,
    selectedDistrict,
    selectedBlock,
    selectedGramPanchayat,
    selectedVillage,
  ]);

  useEffect(() => {
    if (selectedState) {
      handleLocationChange("state", selectedState?.state);
      handleLocationChange("district", "");
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
      setDistrictSelected("");
      setBlockSelected("");
      setPanchayatSelected("");
      setVillageSelected("");
    }
  }, [selectedState]);

  useEffect(() => {
    console.log(selectedDistrict);
    if (selectedDistrict) {
      setBlockSelected("");
      setPanchayatSelected("");
      setVillageSelected("");
      handleLocationChange(
        "district",
        capitalizeString(selectedDistrict?.district_name)
      );
      handleLocationChange("block", "");
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
    }
  }, [selectedDistrict]);

  useEffect(() => {
    if (selectedBlock) {
      handleLocationChange("block", selectedBlock.block_name);
      setPanchayatSelected("");
      setVillageSelected("");
      handleLocationChange("gp", "");
      handleLocationChange("village", "");
    }
  }, [selectedBlock]);

  // Fetch District from LGD data When State is selected
  useEffect(() => {
    // console.log(selectedState);
    if (selectedState) {
      LGDSelector(
        "state_code",
        selectedState?.state_code.toString(),
        setLGDDistrict
      );
    } else {
      setLGDDistrict([]);
    }
  }, [selectedState]);

  // Fetch Block from LGD data When District is selected
  useEffect(() => {
    if (selectedDistrict) {
      LGDSelector(
        "district_code",
        selectedDistrict.district_code,
        setLGDBlocks
      );
    } else {
      setLGDBlocks([]);
    }
  }, [selectedDistrict]);

  // Fetch Panchayat from LGD data When Block is selected
  useEffect(() => {
    // console.log(selectedBlock);
    if (selectedBlock) {
      LGDSelector("block_code", selectedBlock.block_code, setLGDPanchayats);
    } else {
      setLGDPanchayats([]);
    }
  }, [selectedBlock]);

  // Fetch Village from LGD data When Panchayat is selected
  useEffect(() => {
    selectedGramPanchayat &&
      LGDSelector(
        "local_body_code",
        selectedGramPanchayat.local_body_code,
        setLGDVillages
      );
  }, [selectedGramPanchayat]);

  const [states, setStates] = useState([]);

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setStateSelected(event.target.value);
  };

  //Fetch all States
  useEffect(() => {
    firebase
      .firestore()
      .collection("ak_lgd_state")
      .get()
      .then((res) => {
        // console.log("res", res);
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data());
        });
        array = array.slice().sort((a, b) => a.state.localeCompare(b.state));
        // console.log(array.sort());
        setLGDState(array);
        setStates(array);
      });
  }, []);

  return (
    <>
      <div className="d-flex justify-content-center">
        <Col md={2} className="px-2">
          <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>State:</h6>

          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "100%",
            }}
            className="mx-2"
            tabIndex="2"
            name="state"
            onChange={(e) => {
              setSelectedState(LGDState[e.target.value]);
              handleLocationChange("state", selectedState?.state);
              handleLocationChange("district", "");
              handleLocationChange("block", "");
              handleLocationChange("gp", "");
              handleLocationChange("village", "");
              setLGDDistrict([]);
              setLGDBlocks([]);
              setLGDPanchayats([]);
              setLGDVillages([]);
              // console.log(LGDState[e.target.value]);
            }}
          >
            <option value="">Select State</option>
            {/* Map over your states data */}

            {LGDState?.sort().map((state, index) => (
              <option key={state + index} value={index}>
                {state.state}
              </option>
            ))}
          </select>
        </Col>
        <Col md={2} className="px-2">
          <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>District:</h6>
          {/* {console.log(
            LGDDistrict?.slice().sort((a, b) =>
              a.district_name.localeCompare(b.district_name)
            )
          )} */}
          <select
            className="mx-2"
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "100%",
            }}
            name="block"
            onChange={(e) => {
              // console.log(e.target);
              setSelectedDistrict(LGDDistrict[e.target.value]);
              setSelectedBlock("");
              setSelectedGramPanchayat("");
              setSelectedVillage("");
              handleLocationChange("block", "");
              handleLocationChange("gp", "");
              handleLocationChange("village", "");
              setLGDBlocks([]);
              setLGDPanchayats([]);
              setLGDVillages([]);
            }}
            // value={selectedBlock}
            required // Make this field mandatory
          >
            <option value="">Select District</option>
            {/* {LGDDistrict?.map((block, index) => (
              <option key={block.district_name + index} value={index}>
                {block.district_name}
              </option>
            ))} */}
            {LGDDistrict.map((block, index) => (
              <option key={block.district_name + index} value={index}>
                {block.district_name}
              </option>
            ))}
          </select>
        </Col>
        <Col md={2} className="px-2">
          <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Block:</h6>
          <select
            className="mx-2"
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "100%",
            }}
            name="block"
            onChange={(e) => {
              // console.log(e.target);
              setSelectedBlock(LGDBlocks[e.target.value]);
              setSelectedGramPanchayat("");
              handleLocationChange("gp", "");
              setLGDPanchayats([]);
              setSelectedVillage("");
              handleLocationChange("village", "");
              setLGDVillages([]);
            }}
            // value={selectedBlock}
            required // Make this field mandatory
          >
            <option value="">Select Block</option>
            {/* {LGDBlocks?.map((block, index) => (
              <option key={block.block_name + index} value={index}>
                {block.block_name}
              </option>
            ))} */}
            {[...LGDBlocks]
              .sort((a, b) => a.block_name.localeCompare(b.block_name))
              .map((block, index) => (
                <option key={block.block_name + index} value={index}>
                  {block.block_name}
                </option>
              ))}
          </select>
        </Col>
        <Col my={2} md={2} className="px-2">
          <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>
            Gram Panchayat:
          </h6>
          <select
            className="mx-2"
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "100%",
            }}
            name="gramPanchayat"
            onChange={(e) => {
              setSelectedGramPanchayat(LGDPanchayats[e.target.value]);
              setSelectedVillage("");
              handleLocationChange("village", "");
              setLGDVillages([]);
            }}
            // value={selectedGramPanchayat}
            required // Make this field mandatory
          >
            <option value="">Select Gram Panchayat</option>
            {/* {LGDPanchayats?.map((gramPanchayat, index) => (
              <option key={gramPanchayat + index} value={index}>
                {gramPanchayat.local_body_name}
              </option>
            ))} */}
            {[...LGDPanchayats]
              .sort((a, b) =>
                a.local_body_name.localeCompare(b.local_body_name)
              )
              .map((gramPanchayat, index) => (
                <option
                  key={gramPanchayat.local_body_name + index}
                  value={index}
                >
                  {gramPanchayat.local_body_name}
                </option>
              ))}
          </select>
        </Col>
        {/* <Col my={2} md={2} className="px-2">
          <h6 style={{ fontSize: "13px", marginLeft: "0.5rem" }}>Village:</h6>
          <select
            className="mx-2"
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "100%",
            }}
            name="village"
            onChange={(e) => {
              setSelectedVillage(setLGDVillages[e.target.value]);
              // handleLocationChange("village", selectedVillage?.village_name);
            }}
            // value={selectedGramPanchayat}
            required // Make this field mandatory
          >
            <option value="">Select Village</option>
            {LGDVillages?.map((item, index) => (
              <option key={item + index} value={index}>
                {item.village_name}
              </option>
            ))}
          </select>
        </Col> */}
      </div>
    </>
  );
}
