import React, { useEffect } from "react";
import Header from "../Components/Header/Header";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main_body">
      <Header />
      <br />
      <div>
        <h1 className="main_head text-center">Privacy Policy</h1>
      </div>

      <div className="container mt-5 px-4">
        <h6>1. GENERAL</h6>
        <p>
          a) The Ministry of Youth Affairs & Sports ("Ministry") has established
          this Privacy Policy to provide users with information about the
          policies and procedures governing the collection, usage, and
          disclosure of personally identifiable information or data obtained
          from individuals using the website, Mera Mati Mera Desh ("MMMD"). The
          MMMD Platform can be accessed at merimaatimeradesh.gov.in and will be
          referred to as the "MMMD Platform" hereafter. Our intent is to provide
          clear and concise information about how your personal information is
          handled. Therefore, we have set forth this policy to inform you about
          the manner in which we collect, use, and disclose such information.
          This electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </p>
        <p>
          b) This document is published in accordance with the provisions of
          Rule 3 (1) of the Information Technology (Intermediaries guidelines)
          Rules, 2011 that require publishing the Rules and Regulations, Privacy
          Policy and Terms of Use for access or usage merimaatimeradesh.gov.in
        </p>
        <p>
          c) The domain name merimaatimeradesh.gov.in ("Website"), is owned and
          operated by the Ministry of Youth Affairs & Sports ("Ministry") where
          such expression shall, unless repugnant to the context thereof, be
          deemed to include its respective representatives, administrators,
          employees, officers, and their assigns.
        </p>
        <p>
          d) For the purpose of these Terms of Use ("Terms"), wherever the
          context so requires, i. The term 'You' &'User' shall mean any legal
          person or entity accessing or availing the services provided on this
          Website, who is competent to enter into binding contracts, as per the
          provisions of the Indian Contract Act, 1872;
        </p>
        <p>
          ii. The terms 'We', 'Us' & 'Our' shall mean the Website and/or the
          Ministry, as the context so requires.
        </p>
        <p>
          iii. The terms 'Party'& 'Parties' shall respectively be used to refer
          to the User and the Ministry individually and collectively, as the
          context so requires.
        </p>
        <p>
          e) The headings of each section in this Policy are only for the
          purpose of organizing the various provisions under this Policy in an
          orderly manner, and shall not be used by either Party to interpret the
          provisions contained herein in any manner. Further, it is specifically
          agreed to by the Parties that the headings shall have no legal or
          contractual value.
        </p>
        <p>
          f) The use of the Website by the User is solely governed by this
          Policy as well as the Terms of Use of the Website ("Terms of Use"),
          and any modifications or amendments made thereto by the Ministry from
          time to time, at its sole discretion. Visiting the home page of the
          Website and/or using any of the services provided on the Website shall
          be deemed to signify the User's unequivocal acceptance of this Policy
          and the aforementioned Terms, and the User expressly agrees to be
          bound by the same. The User expressly agrees and acknowledges that the
          Terms of Use and Policy are co-terminus, and that expiry / termination
          of either one will lead to the termination of the other.
        </p>
        <p>
          g) The User unequivocally agrees that this Policy and the
          aforementioned Terms constitute a legally binding agreement between
          the User and the Ministry, and that the User shall be subject to the
          rules, guidelines, policies, terms, and conditions applicable to any
          service that is provided by the Website, and that the same shall be
          deemed to be incorporated into the Terms of Use, and shall be treated
          as part and parcel of the same. The User acknowledges and agrees that
          no signature or express act is required to make these Terms and the
          Policy binding on the User, and that the User's act of visiting any
          part of the Website constitutes the User's full and final acceptance
          of the Policy and the aforementioned Terms.
        </p>
        <p>
          h) The Parties expressly agree that the Ministry retains the sole and
          exclusive right to amend or modify the Policy and the aforementioned
          Terms without any prior permission or intimation to the User and the
          User expressly agrees that any such amendments or modifications shall
          come into effect immediately. The User has a duty to periodically
          check the Policy and Terms, and stay updated on their policies.
        </p>
        <p>
          i) If the User continues to use the Website following such a change,
          the User will be deemed to have consented to any and all amendments /
          modifications made to the Policy and Terms. In so far as the User
          complies with the Policy and Terms, he/she is granted a personal,
          non-exclusive, non-transferable, revocable, limited privilege to
          enter, access and use the Website.
        </p>

        <h6>2. COLLECTION OF PERSONAL AND OTHER INFORMATION</h6>

        <p>
          a) The User expressly agrees and acknowledges that the Ministry
          collects and stores the User's personal information, which is provided
          by the User from time to time on the Website, including but no
          including but not limited to the User's username, password, email
          address, name, address, Service address, destination, age, date of
          birth, sex, nationality, browsing history, etc., as well as any images
          or other information uploaded/submitted by the User on the Website.
          The Ministry on a timely basis will evaluate the personal information
          of the User and keep it safe, accurate and up to date.
        </p>
        <p>
          b) The User is aware that the Website may automatically track certain
          information about the User based upon the User's IP address and the
          User's behaviour on the Website and the User expressly consents to the
          same. The User is aware that this information is used to do internal
          research on user demographics, interests, and behaviour, to enable the
          Website to better understand, and cater to the interests of its users.
          The User is expressly made aware that such information may include the
          URL that the User visited prior to accessing the Website, the URL
          which the User subsequently visits (whether or not these URLs form a
          part of the Website), the User's computer & web browser information,
          the User's IP address, etc.{" "}
        </p>
        <p>
          c) If the User chooses to post messages, ads, promotion, reviews,
          feedback anywhere on the Website, including but not limited to message
          boards, chat rooms, other message areas, etc., the User is aware that
          any and all such information provided or uploaded will be collected
          and stored by the Ministry, and that such retained information may be
          used to resolve disputes, provide user support, troubleshoot problems,
          etc., and that such information, if requested, may be provided to
          judicial or other governmental authorities of requisite jurisdiction,
          or otherwise used by the Ministry as permitted by applicable laws.
        </p>
        <p>
          d) The User is aware that any and all information pertaining to the
          User collected by the Ministry, whether or not directly provided by
          the User to the Ministry or Website, including but not limited to
          personal correspondence such as emails or letters, feedback from other
          users or third parties regarding the User's activities or postings on
          the Website, etc., may be collected and compiled by the Ministry or
          Website into a file or folder specifically created for / allotted to
          the User, and the User hereby expressly consents to the same.
        </p>

        <h6>3. USER'S RESPONSIBILITIES</h6>

        <p>
          It is a user's responsibility to provide correct information about its
          contact particulars.. The Ministry will not be liable for any
          incorrect information submitted by a user at any point of time on
          merimaatimeradesh.gov.in.
        </p>

        <h6>4. AGE RESTRICTION AND PARENTAL CONSENT:</h6>
        <p>
          a. Users must be at least 18 years old to access and use the website
          without parental control.
        </p>
        <p>
          b. Users below the age of 18 must obtain explicit consent from their
          parents or legal guardians before using the website.
        </p>
        <p>
          c. The website will implement measures to verify users' age and obtain
          parental consent if necessary.
        </p>

        <h6>5. PROHIBITION OF OBSCENE OR ILLEGAL CONTENT:</h6>

        <p>
          a. Users are strictly prohibited from uploading, sharing, or
          distributing any obscene, pornographic, illegal, controversial
          content, image, video etc.
        </p>
        <p>
          b. The website will employ moderation mechanisms to detect and remove
          inappropriate content where applicable.
        </p>
        <p>
          c. Users found violating this policy will face account suspension,
          termination, and may be reported to appropriate legal authorities.
        </p>
        <h6>6. USER LIABILITY:</h6>
        <p>
          a. Users are personally liable for any content, image, video they
          upload or share on the website.
        </p>
        <p>
          b. Users are responsible for ensuring that their content, image, video
          complies with all applicable laws and regulations.
        </p>

        <p>
          c. The website will cooperate fully with law enforcement agencies in
          case of any illegal activities.
        </p>
        <h6>7. COOKIES</h6>
        <p>
          a) The User is aware that a 'Cookie' is a small piece of information
          stored by a web server on a web browser so it can later be traced back
          from that particular browser, and that cookies are useful for enabling
          the browser to remember information specific to a given user,
          including but not limited to a User's login identification, password,
          etc. The User is aware that the Website places both permanent and
          temporary cookies in the User's computer's hard drive and web browser,
          and does hereby expressly consent to the same.
        </p>
        <p>
          b) The User is further aware that the merimaatimeradesh.gov.in uses or
          might use data collection devices such as cookies on certain pages of
          the merimaatimeradesh.gov.in to help analyse web page flow, measure
          promotional effectiveness, and promote trust and safety, and that
          certain features of the merimaatimeradesh.gov.in are only available
          through the use of such cookies. While the User is free to decline the
          Website's cookies if the User's browser permits, the User may
          consequently be unable to use certain features on the
          merimaatimeradesh.gov.in.
        </p>
        <p>
          c) Additionally, the User is aware that he/she might encounter
          'cookies' or other similar devices on certain pages of the
          merimaatimeradesh.gov.in that are placed by affiliates of the
          Ministry. The User expressly agrees and acknowledges that the Ministry
          or Website does not control the use of such cookies/other devices by
          such third parties, that the Ministry or Website is in no way
          responsible for the same and that the User assumes any and all risks
          in this regard.
        </p>

        <h6>8. DIVULGING/SHARING OF PERSONAL INFORMATION</h6>
        <p>
          a) The User is aware that the merimaatimeradesh.gov.in may disclose
          personal information if required to do so by law or if the
          merimaatimeradesh.gov.in in good faith believes that such disclosure
          is reasonably necessary to respond to subpoenas, court orders, or
          other legal processes. The merimaatimeradesh.gov.in may also disclose
          the User's personal information to law enforcement offices, third
          party rights owners, or other third parties if it believes that such
          disclosure is reasonably necessary to enforce the Terms or Policy;
          respond to claims that an advertisement, posting or other content
          violates the rights of a third party; or protect the rights, property
          or personal safety of its users, or the general public.
        </p>
        <p>
          b) The Ministry may disclose information if legally required to, in
          pursuant to an order from a governmental entity or in good faith. We
          will disclose the information bases to: i. confirm to legal
          requirements or comply with legal process; ii. protect our rights or
          property or our affiliated authorities/ministries; iii. prevent a
          crime or protect national security; or iv. protect the personal safety
          of users or the public.{" "}
        </p>

        <h6>9. USER'S CONSENT</h6>
        <p>
          By using the Website or by providing information to the Ministry
          through the Website, the User consents to the collection and use of
          the information disclosed by the User on the Website in accordance
          with this Policy, including but not limited to the User's consent the
          merimaatimeradesh.gov.in sharing/divulging the User's information, as
          per the terms contained hereinabove in clause 2 of the Policy.
        </p>
        <h6>10. GRIEVANCE OFFICER</h6>
        <p>
          In case User has any grievances in connection with the use of the
          Website, a user may write to the grievance redressal officer. The
          grievance redressal officer is authorized to attend to any user
          complaints or queries and resolve any matter within 30 days from date
          of receipt of complaint. In accordance with Information Technology Act
          2000 and rules made there under, the name and contact details of the
          Grievance Officer are provided below:  Email: Address support-yuva@gov.in
        </p>

        <h6>
          11. NOTICES, JURISDICTION AND GOVERNING LAW FOR DISPUTE RESOLUTION:
        </h6>

        <p>
          a)Any notices or communications required to be given or served by any
          of the Parties on the others in respect of this Agreement shall be
          given in writing in English, and shall be deemed to have been duly
          served, if sent by prepaid registered mail with acknowledgement due at
          the address specified in this Agreement or at such other address as
          may have been notified by the Parties to the other. Any notice under
          this Agreement shall be deemed to be received: i. if delivered
          personally, at the time of delivery; ii. in the case of courier, 72
          hours from the date of posting;
        </p>

        <p>
          b) Any Disputes / Claims arising out of this Agreement are subject to
          Arbitration and the Jurisdiction of Delhi Courts. Further, in case the
          parties failed to resolve the dispute; this Agreement shall be
          governed by and construed in accordance with the law and Jurisdiction
          within Delhi, India only.
        </p>
        <p>
          c) Any amendments in the clauses of the Agreement can be effected as
          an addendum, after written approval from both parties.
        </p>
      </div>
    </div>
  );
}
