import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import { Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import firebase from "../../firebase";
import "./Home.css";
import { Link } from "react-router-dom";
import Dashboard from "./Dashboard";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NodalForm = ({
  formData,
  index,
  handleInputChange,
  states,
  districts,
  blocks,
  panchayat,
  selectedBlock,
  selectedDistrict,
  selectedState,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Name :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            autoComplete="off"
            label="Name"
            value={formData.organiserName || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="organiserName"
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Phone :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            type="tel"
            pattern="[0-9]{10}"
            maxLength="10"
            required
            autoComplete="off"
            label="Phone"
            value={formData.Phone || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="Phone"
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"State :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            disabled={selectedState ? true : false}
            name="state_name"
            onChange={(event) => handleInputChange(event, index)}
            value={selectedState ? selectedState : formData.state_name || ""}
          >
            <option value="">Select State</option>
            {/* Map over your states data */}
            {states?.sort()?.map((institute, index) => (
              <option key={institute + index} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"District :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="district_name"
            disabled={selectedDistrict ? true : false}
            onChange={(event) => handleInputChange(event, index)}
            value={
              selectedDistrict ? selectedDistrict : formData.district_name || ""
            }
          >
            <option value="">Select District</option>
            {/* Map over your states data */}
            {districts?.sort()?.map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Block :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="block_name"
            disabled={selectedBlock ? true : false}
            onChange={(event) => handleInputChange(event, index)}
            value={selectedBlock ? selectedBlock : formData.block_name || ""}
          >
            <option value="">Select Block</option>
            {/* Map over your states data */}
            {blocks?.sort()?.map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const ULBForm = ({
  formData,
  index,
  handleInputChange,
  states,
  districts,
  blocks,
  panchayat,
  selectedULB,
  selectedBlock,
  selectedDistrict,
  selectedState,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Name :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            autoComplete="off"
            label="Name"
            value={formData.organiserName || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="organiserName"
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Phone :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            type="tel"
            pattern="[0-9]{10}"
            maxlength="10"
            required
            autoComplete="off"
            label="Phone"
            value={formData.Phone || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="Phone"
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"State :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            disabled={selectedState ? true : false}
            name="state_name"
            onChange={(event) => handleInputChange(event, index)}
            value={selectedState ? selectedState : formData.state_name || ""}
          >
            <option value="">Select State</option>
            {/* Map over your states data */}
            {states?.sort()?.map((institute, index) => (
              <option key={institute + index} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"ULB :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="ulb_name"
            disabled={selectedULB ? true : false}
            onChange={(event) => handleInputChange(event, index)}
            value={selectedULB ? selectedULB : formData.ulb_name || ""}
          >
            <option value="">Select ULB</option>
            {/* Map over your states data */}
            {[selectedULB].map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};
const OrganiserForm = ({
  formData,
  index,
  handleInputChange,
  states,
  districts,
  blocks,
  panchayat,
  selectedBlock,
  selectedDistrict,
  selectedState,
}) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Name :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            autoComplete="off"
            label="Name"
            value={formData.organiserName || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="organiserName"
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Phone :"}{" "}
          </div>
          <input
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            autoComplete="off"
            label="Phone"
            value={formData.Phone || ""}
            onChange={(event) => handleInputChange(event, index)}
            name="Phone"
            type="tel"
            pattern="[0-9]{10}"
            maxlength="10"
            required
          />
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"State :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            disabled={selectedState ? true : false}
            name="state_name"
            onChange={(event) => handleInputChange(event, index)}
            value={selectedState ? selectedState : formData.state_name || ""}
          >
            <option value="">Select State</option>
            {/* Map over your states data */}
            {states?.sort()?.map((institute, index) => (
              <option key={institute + index} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"District :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="district_name"
            disabled={selectedDistrict ? true : false}
            onChange={(event) => handleInputChange(event, index)}
            value={
              selectedDistrict ? selectedDistrict : formData.district_name || ""
            }
          >
            <option value="">Select District</option>
            {/* Map over your states data */}
            {districts?.sort()?.map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Block :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="block_name"
            disabled={selectedBlock ? true : false}
            onChange={(event) => handleInputChange(event, index)}
            value={selectedBlock ? selectedBlock : formData.block_name || ""}
          >
            <option value="">Select Block</option>
            {/* Map over your states data */}
            {blocks?.sort()?.map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-3">
          <div
            style={{
              fontSize: "17px",
              color: "#543c00",
              fontweight: "500",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            {" "}
            {"Panchayat Name :"}{" "}
          </div>
          <select
            style={{
              fontSize: "15px",
              color: "#543c00",
              padding: "8px",
              height: "40px",
              borderRadius: "25px",
              border: "1px solid #543c00",
              background: "#e7d3a9",
              width: "80%",
            }}
            name="panchayat_name"
            onChange={(event) => handleInputChange(event, index)}
            value={formData.panchayat_name || ""}
          >
            <option value="">Select Panchayat</option>
            {/* Map over your states data */}
            {panchayat?.sort()?.map((institute) => (
              <option key={institute} value={institute}>
                {institute}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default function AddNodal() {
  const [formDataArray, setFormDataArray] = useState([{}]);
  const [formDataArray2, setFormDataArray2] = useState([{}]);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState({ 0: [] });
  const [blocks, setBlocks] = useState({ 0: [] });
  const [panchayats, setPanchayat] = useState({ 0: [] });
  const [districts2, setDistrict2] = useState({ 0: [] });
  const [blocks2, setBlocks2] = useState({ 0: [] });
  const [panchayats2, setPanchayat2] = useState({ 0: [] });
  const [selectedState, setSelectedState] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedBlock, setSelectedBlock] = useState();
  const [removeIndex, setRemoveIndex] = useState([]);
  const [removeIndex2, setRemoveIndex2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedULB, setULB] = useState("");
  const [user, setUser] = useState([]);
  const [activeKey, setActiveKey] = useState("home");
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("nodal_user"));
    setUser(userData);
  }, []);

  const isInsideTab = true;

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("nodal_user"));
    console.log(userData);
    firebase
      .firestore()
      .collection("states")
      .get()
      .then((res) => {
        let array = [];
        res.forEach((doc) => {
          array.push(doc.data()?.state_name?.toUpperCase());
        });

        setStates(array);
      });
    if (userData.name_of_ulb) {
      setSelectedState(userData.state.toUpperCase());
      setULB(userData.name_of_ulb);
    } else if (userData.name_of_block) {
      setSelectedState(userData.state.toUpperCase());
      setSelectedDistrict(userData.district_name);
      setDistrict({ 0: [userData.district_name] });
      setDistrict2({ 0: [userData.district_name] });
      setBlocks({ 0: [userData.name_of_block] });
      setBlocks2({ 0: [userData.name_of_block] });
      setSelectedBlock(userData.name_of_block);
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "==", userData.name_of_block)
        .get()
        .then((res) => {
          console.log(res);
          if (res.empty !== true) {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().gp_name);
            });
            setPanchayat({ 0: array });
            setPanchayat2({ 0: array });
          } else {
            firebase
              .firestore()
              .collection("block_gp_mapping")
              .where("block_name", "==", userData.name_of_block.toUpperCase())
              .get()
              .then((res) => {
                if (res.empty !== true) {
                  let array = [];
                  res.forEach((doc) => {
                    array.push(doc.data().gp_name);
                  });
                  setPanchayat({ 0: array });
                  setPanchayat2({ 0: array });
                } else {
                  firebase
                    .firestore()
                    .collection("block_gp_mapping")
                    .where(
                      "block_name",
                      "==",
                      userData.name_of_block.toLowerCase()
                    )
                    .get()
                    .then((res) => {
                      if (res.empty !== true) {
                        let array = [];
                        res.forEach((doc) => {
                          array.push(doc.data().gp_name);
                        });
                        setPanchayat({ 0: array });
                        setPanchayat2({ 0: array });
                      }
                    });
                }
              });
          }
        });
    } else if (userData.district_name) {
      setSelectedState(userData.state.toUpperCase());
      setSelectedDistrict(userData.district_name);
      setDistrict({ 0: [userData.district_name] });
      setDistrict2({ 0: [userData.district_name] });
      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "==", userData.district_name)
        .get()
        .then((res) => {
          console.log(res);
          if (res.empty !== true) {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().block_name);
            });
            setBlocks({ 0: array });
            setBlocks2({ 0: array });
          } else {
            firebase
              .firestore()
              .collection("district_block_mapping")
              .where(
                "district_name",
                "==",
                userData.district_name.toUpperCase()
              )
              .get()
              .then((res) => {
                if (res.empty !== true) {
                  let array = [];
                  res.forEach((doc) => {
                    array.push(doc.data().block_name);
                  });
                  setBlocks({ 0: array });
                  setBlocks2({ 0: array });
                } else {
                  firebase
                    .firestore()
                    .collection("district_block_mapping")
                    .where(
                      "district_name",
                      "==",
                      userData.district_name.toLowerCase()
                    )
                    .get()
                    .then((res) => {
                      if (res.empty !== true) {
                        let array = [];
                        res.forEach((doc) => {
                          array.push(doc.data().block_name);
                        });
                        setBlocks({ 0: array });
                        setBlocks2({ 0: array });
                      }
                    });
                }
              });
          }
        });
    } else if (userData.state) {
      setSelectedState(userData.state.toUpperCase());
      firebase
        .firestore()
        .collection("state_district_mapping")
        .where("state_name", "==", userData.state)
        .get()
        .then((res) => {
          if (res.empty !== true) {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().district_mapping);
            });
            setDistrict({ 0: array });
            setDistrict2({ 0: array });
          } else {
            firebase
              .firestore()
              .collection("state_district_mapping")
              .where("state_name", "==", userData.state.toUpperCase())
              .get()
              .then((res) => {
                if (res.empty !== true) {
                  let array = [];
                  res.forEach((doc) => {
                    array.push(doc.data().district_mapping);
                  });
                  setDistrict({ 0: array });
                  setDistrict2({ 0: array });
                } else {
                  firebase
                    .firestore()
                    .collection("state_district_mapping")
                    .where("state_name", "==", userData.state)
                    .get()
                    .then((res) => {
                      if (res.empty !== true) {
                        let array = [];
                        res.forEach((doc) => {
                          array.push(doc.data().district_mapping);
                        });
                        setDistrict({ 0: array });
                        setDistrict2({ 0: array });
                      } else {
                        firebase
                          .firestore()
                          .collection("state_district_mapping")
                          .where(
                            "state_name",
                            "==",
                            userData.state.toLowerCase()
                          )
                          .get()
                          .then((res) => {
                            if (res.empty !== true) {
                              let array = [];
                              res.forEach((doc) => {
                                array.push(doc.data().district_mapping);
                              });
                              setDistrict({ 0: array });
                              setDistrict2({ 0: array });
                            }
                          });
                      }
                    });
                }
              });
          }
        });
    }
  }, []);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;

    if (name == "state_name") {
      firebase
        .firestore()
        .collection("state_district_mapping")
        .where("state_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().district_mapping);
          });
          setDistrict({ ...districts, [index]: array });
        });
    } else if (name == "district_name") {
      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().block_name);
          });
          setBlocks({ ...blocks, [index]: array });
        });
    } else if (name == "block_name") {
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().gp_name);
          });
          setPanchayat({ ...panchayats, [index]: array });
        });
    }
    setFormDataArray((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: value,
      };
      return newData;
    });
  };
  const handleInputChange2 = (event, index) => {
    const { name, value } = event.target;
    if (name == "state_name") {
      firebase
        .firestore()
        .collection("state_district_mapping")
        .where("state_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().district_mapping);
          });
          setDistrict2({ ...districts2, [index]: array });
        });
    } else if (name == "district_name") {
      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().block_name);
          });
          setBlocks2({ ...blocks2, [index]: array });
        });
    } else if (name == "block_name") {
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().gp_name);
          });
          setPanchayat2({ ...panchayats2, [index]: array });
        });
    }

    setFormDataArray2((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: value,
      };
      return newData;
    });
  };

  const addOrganiser = async () => {
    setLoading(true);

    if (checkArrayValues(formDataArray2)) {
      try {
        const db = firebase.firestore().collection("organisers");

        for (let i = 0; i < formDataArray2.length; i++) {
          if (!removeIndex2.includes(i)) {
            const e = formDataArray2[i];

            const organizerData = {
              phoneNumber: "+91" + (e.Phone || ""),
              timestamp: new Date(),
              block: e.block_name || selectedBlock || "",
              state: e.state_name || selectedState || "",
              district: e.district_name || selectedDistrict || "",
              panchayat: e.panchayat_name || "",
              name: e.organiserName || "",
            };

            if (checkObjectValues(organizerData)) {
              const querySnapshot = await db
                .where("phoneNumber", "==", "+91" + (e.Phone || ""))
                .limit(1)
                .get();

              if (querySnapshot.empty) {
                await db.add(organizerData);
              } else {
                const docRef = querySnapshot.docs[0].ref;
                await docRef.update(organizerData);
              }

              const eventQuerySnapshot = await firebase
                .firestore()
                .collection("event_new")
                .where(
                  "state",
                  "==",
                  e.state_name?.toUpperCase() ||
                  selectedState?.toUpperCase() ||
                  ""
                )
                .where(
                  "district",
                  "==",
                  e.district_name?.toUpperCase() ||
                  selectedDistrict?.toUpperCase() ||
                  ""
                )
                .where(
                  "panchayat",
                  "==",
                  e?.panchayat_name?.toUpperCase()
                    ? e.panchayat_name.toUpperCase()
                    : ""
                )
                .where(
                  "block",
                  "==",
                  e.block_name?.toUpperCase() ||
                  selectedBlock?.toUpperCase() ||
                  ""
                )
                .limit(1)
                .get();

              if (eventQuerySnapshot.size === 0) {
                await firebase
                  .firestore()
                  .collection("event_new")
                  .add(
                    {
                      phoneNumber: "+91" + (e.Phone || ""),
                      timestamp: new Date(),
                      created_by: "organiser",
                      block:
                        e.block_name?.toUpperCase() ||
                        selectedBlock?.toUpperCase() ||
                        "",
                      state:
                        e.state_name?.toUpperCase() ||
                        selectedState?.toUpperCase() ||
                        "",
                      district:
                        e.district_name?.toUpperCase() ||
                        selectedDistrict?.toUpperCase() ||
                        "",
                      panchayat: e.panchayat_name?.toUpperCase() || "",
                      name: e.organiserName?.toUpperCase() || "",
                      event_date: "",
                    },
                    { merge: true }
                  );
              }
            } else {
              toast.error("Please properly fill all details!");
              setLoading(false);
              return;
            }
          }
        }

        toast.success("Data Added Successfully");

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error adding organizers:", error);
        setLoading(false);
        // Optionally, show an error message to the user.
      }
    } else {
      toast.error("Please properly fill all details!");
      setLoading(false);
    }
  };

  const addULB2 = async () => {
    setLoading(true);
    if (checkArrayValues(formDataArray2)) {
      try {
        let db = firebase.firestore().collection("organisers");

        for (let i = 0; i < formDataArray2.length; i++) {
          if (removeIndex2.includes(i) == false) {
            const e = formDataArray2[i];
            const organizerData = {
              phoneNumber: "+91" + e.Phone || "+91",
              timestamp: new Date(),
              name_of_ulb: e.ulb_name || selectedULB || "",
              state: e.state_name || selectedState || "",
              name: e.organiserName,
            };
            if (checkObjectValues(organizerData)) {
              const querySnapshot = await db
                .where("phoneNumber", "==", "+91" + e.Phone)
                .limit(1)
                .get();
              if (querySnapshot.empty) {
                await db.add(organizerData);
              } else {
                const docRef = querySnapshot.docs[0].ref;
                await docRef.update(organizerData);
              }
              const nameUlb = e.ulb_name || selectedULB || "";
              const nameState = e.state_name || selectedState || "";
              const eventQuerySnapshot = await firebase
                .firestore()
                .collection("event_new")
                .where("state", "==", nameState ? nameState.toUpperCase() : "")
                .where(
                  "name_of_ulb",
                  "==",
                  nameUlb ? nameUlb.toUpperCase() : ""
                )
                .limit(1)
                .get();

              if (eventQuerySnapshot.size === 0) {
                await firebase
                  .firestore()
                  .collection("event_new")
                  .add(
                    {
                      phoneNumber: "+91" + (e.Phone || ""),
                      timestamp: new Date(),
                      created_by: "organiser",

                      state: nameState ? nameState.toUpperCase() : "",
                      name_of_ulb: nameUlb ? nameUlb.toUpperCase() : "",
                      name: e.organiserName || "",
                      event_date: "",
                    },
                    { merge: true }
                  );
              }
            } else {
              toast.error("Please properly fill all details!");
              setLoading(false);
              return;
            }
          }
        }

        toast.success("Data Added Successfully");

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error adding organizers:", error);
        setLoading(false);
        // Optionally, show an error message to the user.
      }
    } else {
      setLoading(false);
      toast.error("Please properly fill all details!");
    }
  };

  const addNodal = async () => {
    setLoading(true);
    if (checkArrayValues(formDataArray)) {
      try {
        const db = firebase.firestore().collection("nodal_officer_data");

        for (let i = 0; i < formDataArray.length; i++) {
          if (!removeIndex.includes(i)) {
            const e = formDataArray[i];
            const nodalData = {
              mobile: e.Phone || "",
              timestamp: new Date(),
              name_of_block: e.block_name || selectedBlock || "",
              state: e.state_name || selectedState || "",
              district_name: e.district_name || selectedDistrict || "",
              nodal_officer_name: e.organiserName,
            };

            if (checkObjectValues(nodalData)) {
              const mobile = nodalData.mobile;

              const querySnapshot = await db
                .where("mobile", "==", e.Phone)
                .limit(1)
                .get();
              if (querySnapshot.empty) {
                await db.add(nodalData);
              } else {
                const docRef = querySnapshot.docs[0].ref;
                await docRef.update(nodalData);
              }
            } else {
              toast.error("Please properly fill all details!");
              setLoading(false);
              return;
            }
          }
        }

        toast.success("Data Added Successfully");

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error adding nodal officer data:", error);
        setLoading(false);
        // Optionally, show an error message to the user.
      }
    } else {
      setLoading(false);
      toast.error("Please properly fill all details!");
    }
  };

  const addULB = async () => {
    setLoading(true);
    if (checkArrayValues(formDataArray)) {
      try {
        const db = firebase.firestore().collection("nodal_officer_data");

        for (let i = 0; i < formDataArray.length; i++) {
          if (!removeIndex.includes(i)) {
            const e = formDataArray[i];
            const nodalData = {
              mobile: e.Phone || "",
              timestamp: new Date(),
              name_of_ulb: e.ulb_name || selectedULB || "",
              state: e.state_name || selectedState || "",
              nodal_officer_name: e.organiserName,
            };

            if (checkObjectValues(nodalData)) {
              const mobile = nodalData.mobile;

              const querySnapshot = await db
                .where("mobile", "==", e.Phone)
                .limit(1)
                .get();
              if (querySnapshot.empty) {
                await db.add(nodalData);
              } else {
                const docRef = querySnapshot.docs[0].ref;
                await docRef.update(nodalData);
              }
            } else {
              toast.error("Please properly fill all details!");
              setLoading(false);
              return;
            }
          }
        }

        toast.success("Data Added Successfully");

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1500);
      } catch (error) {
        console.error("Error adding nodal officer data:", error);
        setLoading(false);
        // Optionally, show an error message to the user.
      }
    } else {
      setLoading(false);
      toast.error("Please properly fill all details!");
    }
  };
  function checkObjectValues(obj) {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
        return false;
      }
    }
    return true;
  }
  function checkArrayValues(arr) {
    for (const obj of arr) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          return false;
        }
      }
    }
    return true;
  }
  const handleAddForm = () => {
    if (removeIndex.includes(formDataArray.length - 1)) {
      setFormDataArray((prevData) => [...prevData, {}]);
    } else {
      formDataArray[formDataArray.length - 1].state_name = formDataArray[
        formDataArray.length - 1
      ]?.state_name
        ? formDataArray[formDataArray.length - 1]?.state_name
        : selectedState;
      formDataArray[formDataArray.length - 1].district_name = formDataArray[
        formDataArray.length - 1
      ]?.district_name
        ? formDataArray[formDataArray.length - 1]?.district_name
        : selectedDistrict;
      formDataArray[formDataArray.length - 1].block_name = formDataArray[
        formDataArray.length - 1
      ]?.block_name
        ? formDataArray[formDataArray.length - 1]?.block_name
        : selectedBlock;

      formDataArray[formDataArray.length - 1].Phone = formDataArray[
        formDataArray.length - 1
      ]?.Phone
        ? formDataArray[formDataArray.length - 1]?.Phone
        : null;
      formDataArray[formDataArray.length - 1].organiserName = formDataArray[
        formDataArray.length - 1
      ]?.organiserName
        ? formDataArray[formDataArray.length - 1]?.organiserName
        : null;

      if (checkObjectValues(formDataArray[formDataArray.length - 1])) {
        setFormDataArray((prevData) => [...prevData, {}]);
      } else {
        toast.error("Please properly fill all details !");
      }
    }
  };

  const handleAddFormULB = () => {
    if (removeIndex.includes(formDataArray.length - 1)) {
      setFormDataArray((prevData) => [...prevData, {}]);
    } else {
      formDataArray[formDataArray.length - 1].state_name = formDataArray[
        formDataArray.length - 1
      ]?.state_name
        ? formDataArray[formDataArray.length - 1]?.state_name
        : selectedState;
      formDataArray[formDataArray.length - 1].ulb_name = formDataArray[
        formDataArray.length - 1
      ]?.ulb_name
        ? formDataArray[formDataArray.length - 1]?.ulb_name
        : selectedULB;

      formDataArray[formDataArray.length - 1].Phone = formDataArray[
        formDataArray.length - 1
      ]?.Phone
        ? formDataArray[formDataArray.length - 1]?.Phone
        : null;
      formDataArray[formDataArray.length - 1].organiserName = formDataArray[
        formDataArray.length - 1
      ]?.organiserName
        ? formDataArray[formDataArray.length - 1]?.organiserName
        : null;

      if (checkObjectValues(formDataArray[formDataArray.length - 1])) {
        setFormDataArray((prevData) => [...prevData, {}]);
      } else {
        toast.error("Please properly fill all details !");
      }
    }
  };
  const handleAddFormULB2 = () => {
    if (removeIndex.includes(formDataArray2.length - 1)) {
      setFormDataArray2((prevData) => [...prevData, {}]);
    } else {
      formDataArray2[formDataArray2.length - 1].state_name = formDataArray2[
        formDataArray2.length - 1
      ]?.state_name
        ? formDataArray2[formDataArray2.length - 1]?.state_name
        : selectedState;
      formDataArray2[formDataArray2.length - 1].ulb_name = formDataArray2[
        formDataArray2.length - 1
      ]?.ulb_name
        ? formDataArray2[formDataArray2.length - 1]?.ulb_name
        : selectedULB;

      formDataArray2[formDataArray2.length - 1].Phone = formDataArray2[
        formDataArray2.length - 1
      ]?.Phone
        ? formDataArray2[formDataArray2.length - 1]?.Phone
        : null;
      formDataArray2[formDataArray2.length - 1].organiserName = formDataArray2[
        formDataArray2.length - 1
      ]?.organiserName
        ? formDataArray2[formDataArray2.length - 1]?.organiserName
        : null;

      if (checkObjectValues(formDataArray2[formDataArray2.length - 1])) {
        setFormDataArray2((prevData) => [...prevData, {}]);
      } else {
        toast.error("Please properly fill all details !");
      }
    }
  };
  const handleAddForm2 = () => {
    if (removeIndex.includes(formDataArray2.length - 1)) {
      setFormDataArray2((prevData) => [...prevData, {}]);
    } else {
      formDataArray2[formDataArray2.length - 1].state_name = formDataArray2[
        formDataArray2.length - 1
      ]?.state_name
        ? formDataArray2[formDataArray2.length - 1]?.state_name
        : selectedState;
      formDataArray2[formDataArray2.length - 1].district_name = formDataArray2[
        formDataArray2.length - 1
      ]?.district_name
        ? formDataArray2[formDataArray2.length - 1]?.district_name
        : selectedDistrict;
      formDataArray2[formDataArray2.length - 1].block_name = formDataArray2[
        formDataArray2.length - 1
      ]?.block_name
        ? formDataArray2[formDataArray2.length - 1]?.block_name
        : selectedBlock;

      formDataArray2[formDataArray2.length - 1].Phone = formDataArray2[
        formDataArray2.length - 1
      ]?.Phone
        ? formDataArray2[formDataArray2.length - 1]?.Phone
        : null;
      formDataArray2[formDataArray2.length - 1].organiserName = formDataArray2[
        formDataArray2.length - 1
      ]?.organiserName
        ? formDataArray2[formDataArray2.length - 1]?.organiserName
        : null;
      formDataArray2[formDataArray2.length - 1].panchayat_name = formDataArray2[
        formDataArray2.length - 1
      ]?.panchayat_name
        ? formDataArray2[formDataArray2.length - 1]?.panchayat_name
        : null;

      if (checkObjectValues(formDataArray2[formDataArray2.length - 1])) {
        setFormDataArray2((prevData) => [...prevData, {}]);
      } else {
        toast.error("Please properly fill all details !");
      }
    }
  };

  const handleRemoveForm = (index) => {
    setRemoveIndex([...removeIndex, index]);
  };
  const handleRemoveForm2 = (index) => {
    setRemoveIndex2([...removeIndex, index]);
  };

  return (
    <div className="main_body ease-in-anim">
      <Header />
      <div className="content-body-nodal">
        <Tabs
          id="justify-tab-example"
          className="mb-3"
          style={{
            padding: "24px",

            border: "none",
            gap: "20px",
            color: "brown",
          }}
          justify
          onSelect={(e) => {
            setActiveKey(e);
          }}
        >
          {user?.name_of_block ? null : user?.name_of_ulb ? null : (
            <Tab eventKey="home" title="Add Nodal Officer">
              {selectedULB ? (
                <div>
                  {formDataArray.map((formData, index) => (
                    <div
                      style={{
                        display: removeIndex.includes(index) ? "none" : "",
                      }}
                      key={index}
                    >
                      <ULBForm
                        formData={formData}
                        states={states}
                        districts={
                          districts[index] ? districts[index] : districts[0]
                        }
                        blocks={blocks[index] ? blocks[index] : blocks[0]}
                        panchayat={
                          panchayats[index] ? panchayats[index] : panchayats[0]
                        }
                        selectedState={selectedState}
                        selectedBlock={selectedBlock}
                        selectedDistrict={selectedDistrict}
                        selectedULB={selectedULB}
                        index={index}
                        handleInputChange={handleInputChange}
                      />
                      {formDataArray.length > 1 && (
                        <svg
                          width={25}
                          style={{
                            float: "right",
                            marginTop: "-5rem",
                            fill: "red",
                          }}
                          onClick={() => {
                            handleRemoveForm(index);
                          }}
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="RemoveCircleOutlineIcon"
                          tabindex="-1"
                          title="RemoveCircleOutline"
                        >
                          <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                        </svg>
                      )}
                    </div>
                  ))}
                  <svg
                    width={25}
                    style={{ float: "right", fill: "#885d1c " }}
                    onClick={handleAddFormULB}
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="AddCircleOutlineIcon"
                  >
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                  </svg>

                  <div style={{ textAlign: "center" }}>
                    <button
                      style={{
                        fontSize: "13px",
                        color: "#ffffff",
                        padding: "8px",
                        height: "40px",
                        borderRadius: "25px",
                        border: "1px solid #885d1c",
                        background: "#885d1c ",
                        width: "200px",
                        marginTop: "3rem",
                      }}
                      onClick={addULB}
                      disabled={loading}
                    >
                      {loading ? "Please Wait..." : " Add Nodal Officer"}
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  {formDataArray.map((formData, index) => (
                    <div
                      style={{
                        display: removeIndex.includes(index) ? "none" : "",
                      }}
                      key={index}
                    >
                      <NodalForm
                        formData={formData}
                        states={states}
                        districts={
                          districts[index] ? districts[index] : districts[0]
                        }
                        blocks={blocks[index] ? blocks[index] : blocks[0]}
                        panchayat={
                          panchayats[index] ? panchayats[index] : panchayats[0]
                        }
                        selectedState={selectedState}
                        selectedBlock={selectedBlock}
                        selectedDistrict={selectedDistrict}
                        index={index}
                        handleInputChange={handleInputChange}
                      />
                      {formDataArray.length > 1 && (
                        <svg
                          width={25}
                          style={{
                            float: "right",
                            marginTop: "-5rem",
                            fill: "red",
                          }}
                          onClick={() => {
                            handleRemoveForm(index);
                          }}
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                          data-testid="RemoveCircleOutlineIcon"
                          tabindex="-1"
                          title="RemoveCircleOutline"
                        >
                          <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                        </svg>
                      )}
                    </div>
                  ))}
                  <svg
                    width={25}
                    style={{ float: "right", fill: "#885d1c " }}
                    onClick={handleAddForm}
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                    focusable="false"
                    aria-hidden="true"
                    viewBox="0 0 24 24"
                    data-testid="AddCircleOutlineIcon"
                  >
                    <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                  </svg>

                  <div style={{ textAlign: "center" }}>
                    <button
                      style={{
                        fontSize: "13px",
                        color: "#ffffff",
                        padding: "8px",
                        height: "40px",
                        borderRadius: "25px",
                        border: "1px solid #885d1c",
                        background: "#885d1c ",
                        width: "200px",
                        marginTop: "3rem",
                      }}
                      onClick={addNodal}
                      disabled={loading}
                    >
                      {loading ? "Please Wait..." : " Add Nodal Officer"}
                    </button>
                  </div>
                </div>
              )}
            </Tab>
          )}
          <Tab eventKey="profile" title="Add Organiser">
            {selectedULB ? (
              <div>
                {formDataArray2.map((formData, index) => (
                  <div
                    style={{
                      display: removeIndex2.includes(index) ? "none" : "",
                    }}
                    key={index}
                  >
                    <ULBForm
                      formData={formData}
                      states={states}
                      districts={
                        districts2[index] ? districts2[index] : districts2[0]
                      }
                      blocks={blocks2[index] ? blocks2[index] : blocks2[0]}
                      panchayat={
                        panchayats2[index] ? panchayats2[index] : panchayats2[0]
                      }
                      selectedState={selectedState}
                      selectedBlock={selectedBlock}
                      selectedDistrict={selectedDistrict}
                      index={index}
                      selectedULB={selectedULB}
                      handleInputChange={handleInputChange2}
                    />
                    {formDataArray2.length > 1 && (
                      <svg
                        width={25}
                        style={{
                          float: "right",
                          marginTop: "-5rem",
                          fill: "red",
                        }}
                        onClick={() => {
                          handleRemoveForm2(index);
                        }}
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="RemoveCircleOutlineIcon"
                        tabindex="-1"
                        title="RemoveCircleOutline"
                      >
                        <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                      </svg>
                    )}
                  </div>
                ))}
                <svg
                  width={25}
                  style={{ float: "right", fill: "#885d1c" }}
                  onClick={handleAddFormULB2}
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="AddCircleOutlineIcon"
                >
                  <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                </svg>
                <div style={{ textAlign: "center" }}>
                  <button
                    style={{
                      fontSize: "13px",
                      color: "#ffffff",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #885d1c",
                      background: "#885d1c ",
                      width: "200px",
                      marginTop: "3rem",
                    }}
                    onClick={addULB2}
                    disabled={loading}
                  >
                    {loading ? "Please Wait..." : "Add Organiser"}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {formDataArray2.map((formData, index) => (
                  <div
                    style={{
                      display: removeIndex2.includes(index) ? "none" : "",
                    }}
                    key={index}
                  >
                    <OrganiserForm
                      formData={formData}
                      states={states}
                      districts={
                        districts2[index] ? districts2[index] : districts2[0]
                      }
                      blocks={blocks2[index] ? blocks2[index] : blocks2[0]}
                      panchayat={
                        panchayats2[index] ? panchayats2[index] : panchayats2[0]
                      }
                      selectedState={selectedState}
                      selectedBlock={selectedBlock}
                      selectedDistrict={selectedDistrict}
                      index={index}
                      handleInputChange={handleInputChange2}
                    />
                    {formDataArray2.length > 1 && (
                      <svg
                        width={25}
                        style={{
                          float: "right",
                          marginTop: "-5rem",
                          fill: "red",
                        }}
                        onClick={() => {
                          handleRemoveForm2(index);
                        }}
                        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-zjt8k"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="RemoveCircleOutlineIcon"
                        tabindex="-1"
                        title="RemoveCircleOutline"
                      >
                        <path d="M7 11v2h10v-2H7zm5-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                      </svg>
                    )}
                  </div>
                ))}
                <svg
                  width={25}
                  style={{ float: "right", fill: "#885d1c" }}
                  onClick={handleAddForm2}
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="AddCircleOutlineIcon"
                >
                  <path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                </svg>
                <div style={{ textAlign: "center" }}>
                  <button
                    style={{
                      fontSize: "13px",
                      color: "#ffffff",
                      padding: "8px",
                      height: "40px",
                      borderRadius: "25px",
                      border: "1px solid #885d1c",
                      background: "#885d1c ",
                      width: "200px",
                      marginTop: "3rem",
                    }}
                    onClick={addOrganiser}
                    disabled={loading}
                  >
                    {loading ? "Please Wait..." : "Add Organiser"}
                  </button>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="dashboard" title="Dashboard">
            {activeKey == "dashboard" && <Dashboard showHeader={false} />}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

//adnodal
