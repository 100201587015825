import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import firebase from "../../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import Header from "../Components/Header/Header";
import { Link, useLocation } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import "./Home.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Login = () => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [screen, setScreen] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isOTPVerified, setOTPVerified] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistrict] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [state_name, setStateName] = useState();
  const [district_name, setDistrictName] = useState();
  const [block_name, setBlockName] = useState();
  const [name_of_ulb, setULBName] = useState();
  const [ulbs, setUlbs] = useState();
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [panchayat_name, setPanchayatName] = useState();
  const [userRecord, setUserRecord] = useState({});
  const [exist, setExist] = useState(false);
  const [form, setForm] = useState(false);
  const [type, setType] = useState("Rural");
  const location = useLocation();
  const [loginLoader, setLoginLoader] = useState(false);
  const checkIfUserExistsWithEmail = async (email) => {
    try {
      const signInMethods = await firebase
        .auth()
        .fetchSignInMethodsForEmail(email);
      // If the email is associated with any sign-in methods, the user exists
      return signInMethods && signInMethods.length > 0;
    } catch (error) {
      console.error("Error checking user:", error);
      throw error;
    }
  };
  const lastSegment = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  console.log(lastSegment);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSend = (event) => {
    event.preventDefault();
    setLoading(true);

    // First Check if user exist on our database, if so then go for OTP verification
    let db = firebase.firestore().collection("organisers");
    db.where("phoneNumber", "==", phone)
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.empty) {
          setForm(true);
          let db2 = firebase.firestore().collection("users");
          db2
            .where("email", "==", phone + "@gmail.com")

            .limit(1)
            .get()
            .then((users) => {
              if (!users.empty) {
                setExist(true);
                setScreen(2);
                setHasFilled(true);
                setLoading(false);
              } else {
                axios
                  .post(
                    "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/sendOTP",
                    { phoneNumber: phone }
                  )
                  .then((res) => {
                    if (res.data.success == true) {
                      setScreen(1);
                      setHasFilled(true);
                      setLoading(false);
                    }
                  });
              }
            });
        } else {
          setUserRecord({
            uid: querySnapshot.docs[0].id,
          });
          localStorage.setItem(
            "event_user",
            JSON.stringify({
              phoneNumber: querySnapshot.docs[0].data().phoneNumber
                ? querySnapshot.docs[0].data().phoneNumber
                : "",
              block: querySnapshot.docs[0].data()?.block
                ? querySnapshot.docs[0].data()?.block
                : "",
              state: querySnapshot.docs[0].data()?.state
                ? querySnapshot.docs[0].data()?.state
                : "",
              district: querySnapshot.docs[0].data()?.district
                ? querySnapshot.docs[0].data()?.district
                : "",
              panchayat: querySnapshot.docs[0].data()?.panchayat
                ? querySnapshot.docs[0].data()?.panchayat
                : "",
              name_of_ulb: querySnapshot.docs[0].data()?.name_of_ulb
                ? querySnapshot.docs[0].data()?.name_of_ulb
                : "",
              name: querySnapshot.docs[0].data()?.name
                ? querySnapshot.docs[0].data()?.name
                : "",
            })
          );

          let db2 = firebase.firestore().collection("users");
          db2
            .where("email", "==", phone + "@gmail.com")

            .limit(1)
            .get()
            .then((users) => {
              if (!users.empty) {
                setExist(true);
                setScreen(2);
                setHasFilled(true);
                setLoading(false);
              } else {
                axios
                  .post(
                    "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/sendOTP",
                    { phoneNumber: phone }
                  )
                  .then((res) => {
                    if (res.data.success == true) {
                      setScreen(1);
                      setHasFilled(true);
                      setLoading(false);
                    }
                  });
              }
            });
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
        return false;
      });
  };

  const verifyOtp = (event) => {
    let otp = event.target.value;
    setOtp(otp);
  };

  const submitOtp = async () => {
    if (otp.length === 6) {
      setLoading(true);
      // verifu otp

      axios
        .post(
          "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/otpHandler/verifyOTP",
          { phoneNumber: phone, enteredOTP: parseInt(otp) }
        )
        .then((results) => {
          if (results.data.success) {
            console.log(results.data.user);
            setUserRecord(results.data.user);
            setScreen(2);
            setLoading(false);
          }

          setOTPVerified(true);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
          alert("User couldn't sign in (bad verification code?)");
          setOtp("");
          setLoading(false);
        });
    }
  };
  const handleLogin = async () => {
    setLoginLoader(true);
    if (password == (exist ? password : repassword)) {
      if (userRecord?.uid) {
        localStorage.setItem(
          "users",
          JSON.stringify({
            users: {
              phoneNumber: phone,
              uid: userRecord?.uid,
              type: "organisers",
            },
          })
        );
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              if (form == false) {
                setTimeout(() => {
                  setLoginLoader(false);
                  window.location.href = "/organiser-profile";
                }, 1500);
              } else {
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              let db = firebase.firestore().collection("users");
              db.doc(res?.user?.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((res) => {
                  if (form == false) {
                    setTimeout(() => {
                      setLoginLoader(false);
                      window.location.href = "/organiser-profile";
                    }, 1500);
                  } else {
                    setScreen(3);
                  }
                });
            });
        }
      } else {
        if (exist) {
          firebase
            .auth()
            .signInWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              console.log(res);
              localStorage.setItem(
                "users",
                JSON.stringify({
                  users: {
                    phoneNumber: phone,
                    uid: res?.user?.uid,
                    type: "organisers",
                  },
                })
              );

              if (form == false) {
                setTimeout(() => {
                  setLoginLoader(false);
                  window.location.href = "/organiser-profile";
                }, 1500);
              } else {
                setScreen(3);
              }
            })
            .catch((err) => {
              alert("Something went wrong ! , Please Check your Password");
              setLoginLoader(false);
            });
        } else {
          firebase
            .auth()
            .createUserWithEmailAndPassword(phone + "@gmail.com", password)
            .then((res) => {
              localStorage.setItem(
                "users",
                JSON.stringify({
                  users: {
                    phoneNumber: phone,
                    uid: res?.user?.uid,
                    type: "organisers",
                  },
                })
              );
              let db = firebase.firestore().collection("users");
              db.doc(res?.user?.uid)
                .set(
                  {
                    email: phone + "@gmail.com",
                  },
                  { merge: true }
                )
                .then((result) => {
                  if (form == false) {
                    setLoginLoader(false);
                    setTimeout(() => {
                      window.location.href = "/organiser-profile";
                    }, 1500);
                  } else {
                    setScreen(3);
                  }
                });
            });
        }
      }
    } else {
      setLoginLoader(false);
      alert("Please enter correct password !");
    }
  };
  useEffect(() => {
    if (screen == 3) {
      firebase
        .firestore()
        .collection("states")
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().state_name);
          });
          setStates(array);
        });
    }
  }, [screen]);
  const [organiserName, setOrganiserName] = useState("");
  const [organiserType, setOrganiserType] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name == "state") {
      setStateName(value);
      if (type == "Rural") {
        firebase
          .firestore()
          .collection("state_district_mapping")
          .where("state_name", "==", value)
          .get()
          .then((res) => {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().district_mapping);
            });
            setDistrict(array);
          });
      } else {
        firebase
          .firestore()
          .collection("state_ulb_mapping")
          .where("state_name", "==", value)
          .get()
          .then((res) => {
            let array = [];
            res.forEach((doc) => {
              array.push(doc.data().name_of_ulb);
            });
            setUlbs(array);
          });
      }
    } else if (name == "district") {
      setDistrictName(value);
      firebase
        .firestore()
        .collection("district_block_mapping")
        .where("district_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().block_name);
          });
          setBlocks(array);
        });
    } else if (name == "block") {
      setBlockName(value);
      firebase
        .firestore()
        .collection("block_gp_mapping")
        .where("block_name", "==", value)
        .get()
        .then((res) => {
          let array = [];
          res.forEach((doc) => {
            array.push(doc.data().gp_name);
          });
          setPanchayats(array);
        });
    } else if (name == "name_of_ulb") {
      setULBName(value);
    } else {
      setPanchayatName(value);
    }
  };

  const handleInstituteOrganiserLogin = (event) => {
    event.preventDefault();
    setLoading(true);
    let db = firebase.firestore().collection("organisers");
    const user = JSON.parse(localStorage.getItem("users")).users;

    // Save it to the Events Collection
    db.doc(user.uid)
      .set(
        {
          phoneNumber: user.phoneNumber ? user.phoneNumber : "",
          timestamp: new Date(),
          block: block_name ? block_name : "",
          state: state_name ? state_name : "",
          district: district_name ? district_name : "",
          panchayat: panchayat_name ? panchayat_name : "",
          name: organiserName ? organiserName : "",
        },
        { merge: true }
      )
      .then((res) => {
        firebase
          .firestore()
          .collection("event_new")
          .where("state", "==", state_name ? state_name?.toUpperCase() : "")
          .where(
            "district",
            "==",
            district_name ? district_name?.toUpperCase() : ""
          )
          .where(
            "panchayat",
            "==",
            panchayat_name ? panchayat_name?.toUpperCase() : ""
          )
          .where("block", "==", block_name ? block_name?.toUpperCase() : "")
          .limit(1)
          .get()
          .then((res) => {
            console.log(res);
            if (res.size == 0) {
              firebase
                .firestore()
                .collection("event_new")
                .add(
                  {
                    created_by: "organiser",
                    phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                    timestamp: new Date(),
                    block: block_name ? block_name?.toUpperCase() : "",
                    state: state_name ? state_name?.toUpperCase() : "",
                    district: district_name ? district_name?.toUpperCase() : "",
                    panchayat: panchayat_name
                      ? panchayat_name?.toUpperCase()
                      : "",
                    name: organiserName ? organiserName?.toUpperCase() : "",
                    event_date: "",
                  },
                  { merge: true }
                )
                .then((res) => {
                  setLoading(false);
                  localStorage.setItem(
                    "event_user",
                    JSON.stringify({
                      phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                      block: block_name ? block_name : "",
                      state: state_name ? state_name : "",
                      district: district_name ? district_name : "",
                      panchayat: panchayat_name ? panchayat_name : "",
                      name: organiserName ? organiserName : "",
                    })
                  );
                  setTimeout(() => {
                    window.location.href = "/organiser-profile";
                  }, 1500);
                });
            } else {
              setLoading(false);
              localStorage.setItem(
                "event_user",
                JSON.stringify({
                  phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                  block: block_name ? block_name : "",
                  state: state_name ? state_name : "",
                  district: district_name ? district_name : "",
                  panchayat: panchayat_name ? panchayat_name : "",
                  name: organiserName ? organiserName : "",
                })
              );
              setTimeout(() => {
                window.location.href = "/organiser-profile";
              }, 1500);
            }
          });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        setLoading(false);
      });
  };
  const handleInstituteOrganiserLogin2 = (event) => {
    event.preventDefault();
    setLoading(true);
    let db = firebase.firestore().collection("organisers");
    const user = JSON.parse(localStorage.getItem("users")).users;

    // Save it to the Events Collection
    db.doc(user.uid)
      .set(
        {
          phoneNumber: user.phoneNumber ? user.phoneNumber : "",
          timestamp: new Date(),
          name_of_ulb: name_of_ulb ? name_of_ulb : "",
          state: state_name ? state_name : "",
          name: organiserName ? organiserName : "",
        },
        { merge: true }
      )
      .then((res) => {
        firebase
          .firestore()
          .collection("event_new")
          .where("state", "==", state_name ? state_name?.toUpperCase() : "")
          .where(
            "name_of_ulb",
            "==",
            name_of_ulb ? name_of_ulb?.toUpperCase() : ""
          )

          .limit(1)
          .get()
          .then((res) => {
            console.log(res);
            if (res.size == 0) {
              firebase
                .firestore()
                .collection("event_new")
                .add(
                  {
                    created_by: "organiser",
                    phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                    timestamp: new Date(),
                    state: state_name ? state_name?.toUpperCase() : "",
                    name_of_ulb: name_of_ulb ? name_of_ulb?.toUpperCase() : "",
                    name: organiserName ? organiserName?.toUpperCase() : "",
                    event_date: "",
                  },
                  { merge: true }
                )
                .then((res) => {
                  setLoading(false);
                  localStorage.setItem(
                    "event_user",
                    JSON.stringify({
                      phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                      timestamp: new Date(),
                      state: state_name ? state_name?.toUpperCase() : "",
                      name_of_ulb: name_of_ulb
                        ? name_of_ulb?.toUpperCase()
                        : "",
                      name: organiserName ? organiserName?.toUpperCase() : "",
                    })
                  );
                  setTimeout(() => {
                    window.location.href = "/organiser-profile";
                  }, 1500);
                });
            } else {
              setLoading(false);
              localStorage.setItem(
                "event_user",
                JSON.stringify({
                  phoneNumber: user.phoneNumber ? user.phoneNumber : "",
                  timestamp: new Date(),
                  state: state_name ? state_name?.toUpperCase() : "",
                  name_of_ulb: res?.docs[0].data?.name_of_ulb
                    ? res?.docs[0].data?.name_of_ulb?.toUpperCase()
                    : "",
                  name: organiserName ? organiserName?.toUpperCase() : "",
                })
              );
              setTimeout(() => {
                window.location.href = "/organiser-profile";
              }, 1500);
            }
          });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        setLoading(false);
      });
  };
  if (!hasFilled) {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        <div
          className="container d-flex justify-content-center text-center align-items-center"
          style={{ height: "70vh" }}
        >
          <div className="app__container">
            <div style={{ width: "300px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ padding: "20px" }} variant="h5" component="div">
                  {t("enter_your_number")}
                </h3>
                <form onSubmit={handleSend}>
                  <input
                    className="login-input"
                    style={{ width: "240px" }}
                    autoComplete="off"
                    label="Phone Number"
                    value={phone}
                    maxLength={13}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                  <button
                    disabled={loading}
                    style={{ opacity: loading ? "0.6" : "1" }}
                    className="pledge_btn mt-5"
                    type="submit"
                  >
                    {loading ? t("pleasewait") : t("send_code")}
                  </button>
                  <br />
                </form>
              </div>
            </div>
            <div id="recaptcha"></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main_body ease-in-anim">
        <Header />
        {screen == 1 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                {!isOTPVerified && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("enter_otp")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "100px" }}
                        label="OTP "
                        value={otp}
                        onChange={verifyOtp}
                        maxLength={6}
                      />
                    </div>
                    <br />
                    <button className="pledge_btn" onClick={submitOtp}>
                      {t("submit")}
                    </button>
                    <br />
                    <a
                      onClick={() => {
                        window.location.href = "/login";
                      }}
                      href="#"
                    >
                      {t("change_mobile_number")}
                    </a>
                  </>
                )}

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 2 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{ padding: "20px" }}
                      variant="h5"
                      component="div"
                    >
                      {t("password")}
                    </h3>
                    <input
                      className="login-input"
                      style={{ width: "auto", height: "40px" }}
                      label="password"
                      type="password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>

                  {!exist && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <h3
                        style={{ padding: "20px" }}
                        variant="h5"
                        component="div"
                      >
                        {t("repassword")}
                      </h3>
                      <input
                        className="login-input"
                        style={{ width: "auto", height: "40px" }}
                        label="password"
                        type="password"
                        value={repassword}
                        onChange={(e) => {
                          setRePassword(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <br />
                  <button
                    className="pledge_btn"
                    style={{ opacity: loginLoader ? "0.6" : "1" }}
                    onClick={() => {
                      handleLogin();
                    }}
                    disabled={loginLoader}
                  >
                    {loginLoader ? t("pleasewait") : t("submit")}
                  </button>
                  <br />
                  <br />
                  <Link style={{ fontWeight: "12px" }} to="/forget-password">
                    {t("forgot_password")} ?
                  </Link>
                </>

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : screen == 3 ? (
          <div
            className="container d-flex justify-content-center text-center align-items-center"
            style={{ height: "70vh" }}
          >
            <div className="app__container">
              <div style={{ width: "300px" }}>
                <label className="mx-1">
                  <div className="form_input_title"> {"Type"} </div>

                  <select
                    className="select-input"
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                    value={type}
                  >
                    <option value="">Select Type</option>
                    {["Urban", "Rural"]?.sort()?.map((institute) => (
                      <option key={institute} value={institute}>
                        {t(institute)}
                      </option>
                    ))}
                  </select>
                </label>
                {type == "Rural" ? (
                  <form onSubmit={handleInstituteOrganiserLogin}>
                    <label className="mx-1">
                      <div className="form_input_title">
                        {" "}
                        {t("enter_your_name")}
                      </div>

                      <input
                        className="login-input"
                        style={{ width: "240px" }}
                        autoComplete="off"
                        label="Name"
                        value={organiserName}
                        onChange={(event) =>
                          setOrganiserName(event.target.value)
                        }
                        required
                      />
                    </label>
                    <br />

                    <label className="mx-1">
                      <div className="form_input_title"> {t("state")} </div>

                      <select
                        className="select-input"
                        name="state"
                        onChange={handleInputChange}
                        value={state_name}
                        required
                      >
                        <option value="">Select State</option>
                        {states?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>

                    <br />
                    <label className="mx-1">
                      <div className="form_input_title"> {t("district")} </div>

                      <select
                        className="select-input"
                        name="district"
                        onChange={handleInputChange}
                        value={district_name}
                        required
                      >
                        <option value="">Select District</option>
                        {districts?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>
                    <br />
                    <label className="mx-1">
                      <div className="form_input_title"> {t("block")} </div>

                      <select
                        className="select-input"
                        name="block"
                        onChange={handleInputChange}
                        value={block_name}
                        required
                      >
                        <option value="">Select Block</option>
                        {blocks?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>
                    <br />
                    <label className="mx-1">
                      <div className="form_input_title"> {t("panchayat")} </div>

                      <select
                        className="select-input"
                        name="panchayat"
                        onChange={handleInputChange}
                        value={panchayat_name}
                        required
                      >
                        <option value="">Select Panchayat</option>
                        {panchayats?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>

                    <button
                      className="pledge_btn mt-5"
                      type="submit" // Changed to "submit" to trigger the form submission
                    >
                      {t("login")}
                    </button>
                    <br />
                    <br />
                  </form>
                ) : (
                  <form onSubmit={handleInstituteOrganiserLogin2}>
                    <label className="mx-1">
                      <div className="form_input_title">
                        {" "}
                        {t("enter_your_name")}
                      </div>

                      <input
                        className="login-input"
                        style={{ width: "240px" }}
                        autoComplete="off"
                        label="Name"
                        value={organiserName}
                        onChange={(event) =>
                          setOrganiserName(event.target.value)
                        }
                        required
                      />
                    </label>
                    <br />

                    <label className="mx-1">
                      <div className="form_input_title"> {t("state")} </div>

                      <select
                        className="select-input"
                        name="state"
                        onChange={handleInputChange}
                        value={state_name}
                        required
                      >
                        <option value="">Select State</option>
                        {states?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>

                    <br />
                    <label className="mx-1">
                      <div className="form_input_title"> {t("ulb")} </div>

                      <select
                        className="select-input"
                        name="name_of_ulb"
                        onChange={handleInputChange}
                        value={name_of_ulb}
                        required
                      >
                        <option value="">Select ULB</option>
                        {ulbs?.sort()?.map((institute) => (
                          <option key={institute} value={institute}>
                            {t(institute)}
                          </option>
                        ))}
                      </select>
                    </label>

                    <button
                      className="pledge_btn mt-5"
                      type="submit" // Changed to "submit" to trigger the form submission
                    >
                      {t("login")}
                    </button>
                    <br />
                    <br />
                  </form>
                )}

                {loading && (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
              <div id="recaptcha"></div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
};

export default Login;
