import { React, useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";
import "../../Components/Certificate/Certificate.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// import ShareModal from "./ShareModal";

function ShilaphalakamPreview({ formInput, initialinput, setShowPopup }) {
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const [imageUri, setUri] = useState(null);

  useEffect(() => {
    // Setup the Canvas
    const canvas = document.getElementById("canvas");
    const ctx = canvas.getContext("2d");

    const img = new Image();
    const laguage = localStorage.getItem("i18nextLng").startsWith("en")
      ? "en"
      : localStorage.getItem("i18nextLng");
    console.log(laguage);
    // img.src = `./asset/images/certificate/${laguage}.png`;
    if (formInput.type == "without_name") {
      img.src = `./asset/images/shilaphalakam/${formInput.language}_without_name.png`;

      img.onload = function () {
        // Set Canvas resolution as per image
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.crossOrigin = "Anonymous";

        // Draw Image & Add Name
        ctx.font = "bold 35pt Arial";

        const panchayat = formInput.panchayat;
        const stateName = formInput.state;
        const date = formInput.date;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#fff";
        ctx.textAlign = "center";
        ctx.fillText(panchayat, canvas.width / 6.9, canvas.height / 2);

        if (stateName == "The Dadra and Nagar Haveli and Daman and Diu") {
          ctx.font = "bold 28pt Arial";
          ctx.fillText(
            "The Dadra and Nagar Haveli",
            canvas.width / 6.7,
            canvas.height / 1.85
          );
          ctx.fillText(
            " and Daman and Diu",
            canvas.width / 7,
            canvas.height / 1.75
          );
        } else {
          if (stateName.length > 15) {
            ctx.font = "bold 35pt Arial";
            ctx.fillText(stateName, canvas.width / 7, canvas.height / 1.8);
          } else {
            ctx.font = "bold 45pt Arial";
            ctx.fillText(stateName, canvas.width / 7, canvas.height / 1.8);
          }
        }

        ctx.font = "45pt Arial";
        ctx.fillText(date, canvas.width / 7, canvas.height / 1.2);

        // Show the Image  in the UI
        img.uri = canvas.toDataURL("image/jpg");
        setUri(img.uri);
        // };
      };
    } else {
      img.src = `./asset/images/shilaphalakam/${formInput.language}_with_name.png`;

      img.onload = function () {
        // Set Canvas resolution as per image
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.crossOrigin = "Anonymous";

        // Draw Image & Add Name
        ctx.font = "bold 35pt Arial";

        const panchayat = formInput.panchayat;
        const stateName = formInput.state;
        const date = formInput.date;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "#fff";
        ctx.textAlign = "center";
        ctx.fillText(panchayat, canvas.width / 6.9, canvas.height / 2.2);

        if (
          stateName == "The Dadra and Nagar Haveli and Daman and Diu" ||
          stateName == "Andaman and Nicobar Islands"
        ) {
          ctx.font = "bold 25pt Arial";
          if (stateName == "The Dadra and Nagar Haveli and Daman and Diu") {
            ctx.fillText(
              "The Dadra and Nagar Haveli",
              canvas.width / 7,
              canvas.height / 1.9
            );
            ctx.fillText(
              " and Daman and Diu",
              canvas.width / 7,
              canvas.height / 1.8
            );
          }
          if (stateName == "Andaman and Nicobar Islands") {
            ctx.fillText("Andaman and", canvas.width / 7, canvas.height / 1.9);
            ctx.fillText(
              " Nicobar Islands",
              canvas.width / 7,
              canvas.height / 1.8
            );
          }
        } else {
          if (stateName.length > 15) {
            ctx.font = "bold 35pt Arial";
            ctx.fillText(stateName, canvas.width / 6.9, canvas.height / 1.9);
          } else {
            ctx.font = "bold 45pt Arial";
            ctx.fillText(stateName, canvas.width / 6.9, canvas.height / 1.9);
          }
        }

        if (stateName == "The Dadra and Nagar Haveli and Daman and Diu") {
          ctx.fillText(date, canvas.width / 7, canvas.height / 1.7);
        } else {
          ctx.fillText(date, canvas.width / 7, canvas.height / 1.7);
        }

        if (stateName == "Andaman and Nicobar Islands") {
          ctx.fillText(date, canvas.width / 7, canvas.height / 1.7);
        } else {
          ctx.fillText(date, canvas.width / 7, canvas.height / 1.7);
        }

        let y = 400;

        ctx.font = "bold 45pt Inter";
        ctx.textAlign = "left";
        formInput.veerName.map((name, index) => {
          if (name.name.length <= 12) {
            ctx.fillText(index + 1 + ") " + name.name, canvas.width / 1.48, y);
          }
          if (name.name.length >= 12 && name.name.length <= 20) {
            ctx.font = "bold 35pt Inter";
            ctx.fillText(index + 1 + ") " + name.name, canvas.width / 1.48, y);
          }
          y = y + 100;
        });

        // Show the Image  in the UI
        img.uri = canvas.toDataURL("image/jpg");
        setUri(img.uri);
        // };
      };
    }
  }, []);

  const downloadCertificate = () => {
    const link = document.createElement("a");
    link.download = "Shilaphalakam.jpg";
    link.href = imageUri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // setTimeout(() => {
    //   history.push("/");
    // }, 1000);
  };

  return (
    <>
      <div className="modalBackground-certificate popup">
        <div className="modal-container-certificate">
          <div className="popup-header-certificate">
            <Link to="#" onClick={() => window.location.reload()}>
              <div
                className="close_thankyou d-flex justify-content-end"
                // onClick={() => {
                //     setShowPopup(false);
                // }}
              >
                <img src="./asset/images/x-circle.svg" alt="X" />
              </div>
            </Link>
            <canvas id="canvas" className="certificate-canvas">
              {" "}
            </canvas>
          </div>
          <div>
            <div className="row d-flex justify-content-center">
              <div
                className=" text-center cirlce orange  cursor-pointer"
                onClick={() => {
                  downloadCertificate();
                }}
              >
                <img
                  className="share pb-2"
                  src="./asset/images/download.svg"
                  alt="Download"
                />
              </div>
              <p className="download_text text-center">{t("download")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShilaphalakamPreview;
