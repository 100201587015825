import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import firebase from "../../../firebase";
import "./Header.css";
import i18n from "../../../services/i18n";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";

function Header() {
  const [user, setUser] = useState();
  const [supInstAdmin, setsupAdmin] = useState(false);
  const { t } = useTranslation();
  const screenSize = window.innerWidth;

  const handleLogout = () => {
    firebase.auth().signOut();
    localStorage.clear();
    window.location.href = "/";
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    console.log(lng);
  };
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("users"))?.users);
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const email = user.email;
        const phoneRegex = /\+?\d{10,}/;

        const phoneNumber = email.match(phoneRegex);
        const tokenDoc = await firebase
          .firestore()
          .collection("ak_nyv_officers")
          .where("phoneNumber", "==", phoneNumber[0])
          .limit(1)
          .get();
        const apiData = tokenDoc.docs[0].data();
        if (
          apiData?.super_admin &&
          apiData?.ministry_dept == "Ministry of Youth Affairs"
        ) {
          setsupAdmin(true);
        }
      }
    });
  }, []);

  return (
    <Navbar id="header-nav" collapseOnSelect expand="lg" className="">
      <Container>
        <Navbar.Brand href="/">
          <img
            src="https://merimaatimeradesh.web.app/asset/images/logos/goi.png"
            alt="youth"
            height={window.innerWidth > 990 ? 50 : 35}
          />
          {/* <img
            className="mx-2"
            src={t("moc_logo_url")}
            alt="moc"
            height={window.innerWidth > 990 ? 50 : 35}
          /> */}
          <img
            src={t("akam_logo_url")}
            alt="akam"
            height={window.innerWidth > 990 ? 50 : 35}
          />
        </Navbar.Brand>
        {/* <Navbar.Brand href="/">
          <img
            src={t("moc_logo_url")}
            alt="moc"
            height={window.innerWidth > 990 ? 50 : 33}
          />
        </Navbar.Brand>
        <Navbar.Brand href="/">
          <img
            src={t("akam_logo_url")}
            alt="akam"
            height={window.innerWidth > 990 ? 50 : 33}
          />
        </Navbar.Brand> */}

        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xxl`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-xxl`}
          aria-labelledby={`offcanvasNavbarLabel-expand-xxl`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title
              id={`offcanvasNavbarLabel-expand-xxl`}
            ></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="me-auto"></Nav>
            <Nav
              className={`align-items-${screenSize > 970 ? "center" : "start"}`}
            >
              {/* <Nav.Link href="/grand-finale" className="header_text">
                <div
                  className="row d-flex align-items-center mx-2"
                  style={{
                    backgroundImage: `url(${"./asset/images/green_bg.svg"})`,
                    backgroundColor: "#00811A",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    color: "#fff",
                    minHeight: "4vh",
                    height: "auto",
                    width: "fit-content",
                    borderRadius: "10px",
                    border: "2px solid green",
                    cursor: "pointer",
                    padding: "5px 10px",
                  }}
                >
                  <div className="">{t("grand_finale")}</div>
                </div>
              </Nav.Link> */}
              <Nav.Link href="/events" className="header_text">
                <div
                  className="row d-flex align-items-center mx-2"
                  style={{
                    backgroundImage: `url(${"./asset/images/green_bg.svg"})`,
                    backgroundColor: "#00811A",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    color: "#fff",
                    minHeight: "4vh",
                    height: "auto",
                    width: "fit-content",
                    borderRadius: "10px",
                    border: "2px solid green",
                    cursor: "pointer",
                    padding: "5px 10px",
                  }}
                >
                  <div className="">{t("events")}</div>
                </div>
              </Nav.Link>

              <Nav.Link href="/" className="header_text">
                {t("home")}
              </Nav.Link>
              <Nav.Link href="/view-pledges" className="header_text">
                {t("pledge")}
              </Nav.Link>

              {/* {user?.type == "nodal" && (
                <>
                  <Nav.Link href="/dashboard">{t("dashboard")}</Nav.Link>
                </>
              )} */}

              {/* <Nav.Link href="/events" className="header_text">
              {t('events')}
            </Nav.Link> */}
              {/* <Nav.Link href="/flag-code" className="header_text">
                {t("flag_code")}
              </Nav.Link> */}

              <Nav.Link href="/downloads" className="header_text">
                {t("downloads")}
              </Nav.Link>
              <Nav.Link href="/about" className="header_text">
                {t("about")}
              </Nav.Link>
              {user?.type == "nodal" && (
                <Nav.Link href="/nodal-officer-add" className="header_text">
                  {t("add_nodal")}
                </Nav.Link>
              )}
              {(user?.type == "nyv" ||
                user?.type == "dyo" ||
                user?.type == "institute") && (
                <Nav.Link href="/nyv-report" className="header_text">
                  Create Event
                </Nav.Link>
              )}
              {(user?.type == "nyv" ||
                user?.type == "dyo" ||
                user?.type == "institute") && (
                <Nav.Link href="/detail-report" className="header_text">
                  <div
                    className="row d-flex align-items-center mx-2"
                    style={{
                      backgroundImage: `url(${"./asset/images/green_bg.svg"})`,
                      backgroundColor: "#00811A",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      color: "#fff",
                      minHeight: "3vh",
                      height: "auto",
                      width: "fit-content",
                      borderRadius: "10px",
                      border: "2px solid green",
                      cursor: "pointer",
                      padding: "5px 10px",
                    }}
                  >
                    <div className="">Detail Report</div>
                  </div>
                </Nav.Link>
              )}
              {user?.type == "dyo" && (
                <Nav.Link href="/add-nyv" className="header_text">
                  Edit NYV
                </Nav.Link>
              )}
              {user?.type == "national_level" && (
                <Nav.Link href="/add-national-event" className="header_text">
                  Upload Event Media
                </Nav.Link>
              )}

              <NavDropdown
                title={
                  !user ? (
                    t("login")
                  ) : (
                    <svg
                      width={25}
                      class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
                      focusable="false"
                      aria-hidden="true"
                      viewBox="0 0 24 24"
                      data-testid="AccountCircleIcon"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z"></path>
                    </svg>
                  )
                }
                id="basic-nav-dropdown"
              >
                {!user && (
                  <NavDropdown.Item href="/nodal-officer-login">
                    {" "}
                    {t("block_ulb_nodal_officer")}
                  </NavDropdown.Item>
                )}
                {!user && (
                  <NavDropdown.Item href="/institute-login">
                    {" "}
                    {/* {t("institutional_organiser")} */}
                    Institution/Central Govt
                    <br />
                    /State Govt Dept
                  </NavDropdown.Item>
                )}
                {!user && (
                  <>
                    <NavDropdown.Item href="/login">
                      {" "}
                      {t("panchayat_urban_area_organiser")}
                    </NavDropdown.Item>
                  </>
                )}

                {!user && (
                  <>
                    <NavDropdown.Item href="/nyv-login">
                      {" "}
                      NYV Login
                    </NavDropdown.Item>
                  </>
                )}

                {!user && (
                  <>
                    <NavDropdown.Item href="/dyo-login">
                      {" "}
                      DYO Login
                    </NavDropdown.Item>
                  </>
                )}

                {!user && (
                  <>
                    <NavDropdown.Item href="/national-level-login">
                      {" "}
                      National Level Login
                    </NavDropdown.Item>
                  </>
                )}

                {/* {!user && (
                  <>
                    <NavDropdown.Item href="/nyv-login">
                      {" "}
                      DoPost Officials Login
                    </NavDropdown.Item>
                  </>
                )} */}

                {/* {!user?.uid && (
                <NavDropdown.Item href="/register-organiser">
                  {" "}
                  {t("register_as_organiser")}
                </NavDropdown.Item>
              )} */}

                {user &&
                  user?.type !== "nodal" &&
                  user?.type !== "institute" && (
                    <>
                      <NavDropdown.Item href="/organiser-profile">
                        {t("organiser_profile")}
                      </NavDropdown.Item>
                    </>
                  )}
                {/* HIDING TEMPORARILY */}
                {(user?.type == "nodal" || supInstAdmin) && (
                  <>
                    <NavDropdown.Item href="/dashboard">
                      {t("dashboard")}
                    </NavDropdown.Item>
                  </>
                )}

                {user && (
                  <NavDropdown.Item href="#" onClick={handleLogout}>
                    {t("logout")}
                  </NavDropdown.Item>
                )}
              </NavDropdown>

              <div className="lang_div">
                <NavDropdown
                  title={
                    <div
                      className="langLogo float"
                      style={{ display: "-webkit-box" }}
                    >
                      {/* {!navMobileActive ? ( */}
                      <img
                        className="my-float"
                        src={t("lang")}
                        alt="Language"
                        style={{ height: "30px" }}
                      />
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => changeLanguage("en")}>
                    English
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("hi")}>
                    हिन्दी
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("guj")}>
                    Gujrati
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("mal")}>
                    Malyalam
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("od")}>
                    Odiya
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("pun")}>
                    Punjabi
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("tam")}>
                    Tamil
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("tel")}>
                    Telugu
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("as")}>
                    Assamese
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("ben")}>
                    Bengali
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("kan")}>
                    Kannada
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => changeLanguage("mar")}>
                    Marathi
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
              {/* <Nav.Link
              href="#"
              className="header_text"
              onClick={handleLanguageChange}
            >
              {i18n.language == "en" ? "हिंदी" : "English"}
            </Nav.Link> */}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}

export default Header;
