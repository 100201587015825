import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import Header from "../Components/Header/Header";
import "./Home.css";
import { Link } from "react-router-dom";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import StateDistrictBlockGP from "../Components/StateDistrictBlockGP";
import { useInView } from "react-intersection-observer";
import LGD_mapping from "../Components/LGD_mapping";
import DropdownComponent from "../Components/DropdownComponent";
export default function EventList() {
  const { t } = useTranslation();

  // useEffect(() => {
  //     const db = firebase.firestore();

  //     // Step 1: Query the event_new collection
  //     const query = db.collection("event_new").where("moderated", "==", true).orderBy("timestamp").limit(1000);

  //     query.get().then(async querySnapshot => {

  //         // Step 2: Extract docid keys into an array
  //         let ids = querySnapshot.docs.map(doc => doc.id);
  //         console.log(ids);

  //         for (let id of ids) {
  //             console.log(id)
  //             // Step 3 & 4: Check the moderated_event_media collection
  //             const mediaQuery = db.collection("moderated_event_media").where("id", "==", id).limit(1);

  //             const mediaSnapshot = await mediaQuery.get();

  //             if (mediaSnapshot.empty) {
  //                 console.log(id, "Culprit Found")
  //                 // Step 5: Update the corresponding document in the event_new collection
  //                 db.collection("event_new").doc(id).update({ moderated: false });
  //             }
  //         }
  //     }).catch(error => {
  //         console.error("Error querying documents: ", error);
  //     });
  // }, [])

  const [location, setLocation] = useState({
    state: "",
    district: "",
    block: "",
    gp: "",
    ulb: "",
  });
  const [lastId, setLastDocId] = useState("");
  const [organiserProfile, setOrganiserProfile] = useState([]);
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [loading, setLoading] = useState(false);

  const [stateSelected, setStateSelected] = useState("");
  const [districtSelected, setDistrictSelected] = useState("");
  const [blockSelected, setBlockSelected] = useState("");
  const [panchayatSelected, setPanchayatSelected] = useState("");
  const [villageSelected, setVillageSelected] = useState("");
  const [ulbSelected, setUlbSelected] = useState("");
  //   const handleLocationUpdate = (updatedLocation) => {
  //     setLocation(updatedLocation);
  //   };

  const updateLocation = (field, value) => {
    setLocation((prevLocation) => ({
      ...prevLocation,
      [field]: value,
    }));
  };

  useEffect(() => {
    console.log("location", location);
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [upref, upView] = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(() => {
    if (upView) {
      getNext();
    }
  }, [upView]);
  const getNext = () => {
    //Fetch next Pledges data
    let db = firebase.firestore();
    const lastDocRef = db.collection("event_new").doc(lastId);
    lastDocRef.get().then((lastDocFetched) => {
      //making a ref and query to db
      const docRef = db
        .collection("event_new")
        .orderBy("timestamp")
        .startAfter(lastDocFetched)
        .where("moderated", "==", true)
        .limit(16);
      docRef
        .get()
        .then((querySnapshot) => {
          const dataArray = querySnapshot.docs.map((doc) => {
            return {
              id: doc.id,
              ...doc.data(),
            };
          });
          setOrganiserProfile([...organiserProfile, ...dataArray]);
          setLastDocId(dataArray[dataArray.length - 1].id);
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
        });
    });
  };
  useEffect(() => {
    //Fetch All Events data
    setLoading(true);
    let query = firebase.firestore().collection("event_new");
    if (location?.state && !location?.district) {
      query = query
        .where("state", "==", location?.state?.toUpperCase())
        .where("moderated", "==", true)
        .limit(100);
      // .orderBy("timestamp");
    }
    if (location?.district) {
      query = query
        .where("district", "==", location?.district?.toUpperCase())
        .where("moderated", "==", true)
        .limit(100);
      // .orderBy("timestamp");
    }
    if (location?.block) {
      query = query
        .where("block", "==", location?.block?.toUpperCase())
        .where("moderated", "==", true)
        .limit(100);
      // .orderBy("timestamp");
    }
    if (location?.gp) {
      query = query
        .where("panchayat", "==", location?.gp?.toUpperCase())
        .where("moderated", "==", true)
        .limit(100);
      // .orderBy("timestamp");
    }
    query
      .where("moderated", "==", true)
      .limit(16)
      .orderBy("timestamp")
      .get()
      .then((querySnapshot) => {
        const dataArray = querySnapshot?.docs?.map((doc) => {
          return {
            id: doc?.id,
            ...doc.data(),
          };
        });
        setOrganiserProfile(dataArray);
        setLastDocId(dataArray[dataArray.length - 1]?.id);
        setLoading(false);
        //console.log(dataArray);
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  }, [location]);

  const [formInput, setFormInput] = useState({
    name: "",
    state: "",
    district: "",
    block: "",
    gp: "",
    time: "",
  });

  return (
    <div className="main_body">
      <Header />
      <div>
        {/* <h1 className="main_head text-center">Meri Mati Mera Desh</h1> */}
        <p className="sub_head text-center">{t("eventpage_title")}</p>
      </div>

      {/* <StateDistrictBlockGP
        location={location}
        onUpdateLocation={updateLocation}
      /> */}

      <DropdownComponent
        setStateSelected={setStateSelected}
        setDistrictSelected={setDistrictSelected}
        setBlockSelected={setBlockSelected}
        setPanchayatSelected={setPanchayatSelected}
        setVillageSelected={setVillageSelected}
        setUlbSelected={setUlbSelected}
        location={location}
        onUpdateLocation={updateLocation}
        showVillage={false}
      />
      <br />
      {loading && (
        <div className="text-center">
          <div class="spinner-border" role="status"></div>
          <h6>Loading...Please wait </h6>
        </div>
      )}
      <br />
      <div class="container">
        <div class="row">
          {organiserProfile.map((item, index) => (
            <div class="col-sm-3" key={index}>
              <Link to={`/event/${item?.id}`}>
                <Card style={{ marginTop: "5%" }}>
                  <Card.Img variant="top" src="./asset/images/gallery.jpg" />
                  <Card.Body>
                    {/* <Card.Title className="text-white">Name : {item?.name}</Card.Title> */}
                    {item?.organiserType ? (
                      <Card.Text className="text-white">
                        <p style={{ color: "#fff", fontSize: "14px" }}>
                          {item?.organiserType}
                        </p>
                      </Card.Text>
                    ) : (
                      <Card.Text>
                        <p
                          className="text-capitalize"
                          style={{ color: "#fff", fontSize: "14px" }}
                        >
                          {item?.panchayat}, ({item?.block})
                          {/* {item?.panchayat && <>,</>},{item?.district},
                          {item?.block} {item?.block && <>,</>}
                          {item?.panchayat} {item?.panchayat && <>,</>}
                          {item?.state} */}
                        </p>
                      </Card.Text>
                    )}
                    {/* <Card.Text>
                    Caption : {item?.gram_panchayat} is a Gram panchayat in {item?.state}
                  </Card.Text> */}
                  </Card.Body>
                </Card>
              </Link>
            </div>
          ))}
          {organiserProfile?.length > 0 && <div ref={upref}></div>}
        </div>
      </div>
    </div>
  );
}
