import axios from "axios";

export const handleUpload = async (params) => {
  try {
    if (!params) {
      console.error("File is missing.");
      return;
    }

    const response = await axios.post(
      "https://asia-south1-ekta-shrinkhila-59ad9.cloudfunctions.net/getSignedURLForMultipart",
      {
        fileName: params.Key, // Use file name for generating presigned URL
        contentType: params.ContentType,
      }
    );

    const presignedUrl = response.data.url;

    await fetch(presignedUrl, {
      method: "PUT",
      body: params.Body,
    });

    console.log("File uploaded successfully.");
  } catch (error) {
    console.error("Error uploading file:", error);
  }
};
