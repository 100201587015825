import React, { useState, useEffect } from "react";
import Header from "../Components/Header/Header";
import stateDistrictMap from "../Assets/data/state_district.json";
import { Row } from "react-bootstrap";
import { getAuth, signInWithPhoneNumber } from "firebase/auth";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function ArtWorkForm() {
  const [formInput, setFormInput] = useState({
    name: "",
    state: "",
    district: "",
    block: "",
    gp: "",
    time: "",
  });
  let history = useHistory();
  const [selectedState, setSelectedState] = useState("");
  const [filteredDistricts, setFilteredDistricts] = useState([]);
  const [isLogin, setIslogin] = useState(false);
  function getDistrictsForState(stateName) {
    return stateDistrictMap[stateName] || [];
  }
  const indiaStates = Object.keys(stateDistrictMap);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormInput((prev) => ({ ...prev, [name]: value }));

    if (name === "state") {
      setSelectedState(value);
      const districts = getDistrictsForState(value);
      setFilteredDistricts(districts);
    }
  };
  // const bravehearts = ["braveheart1", "braveheart2", "braveheart3"];

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(formInput);
    setIslogin(true);
  };
  return (
    <div className="main_body ease-in-anim">
      <Header />
      <>
        <div className="container d-flex justify-content-center text-center form_login">
          <form onSubmit={handleSubmit} className="form-fields m-auto">
            <div className="form_title">
              Fill the details to generate Shilaphalakam
            </div>
            <div className="" style={{ marginTop: "40px" }}>
              <Row className="d-flex justify-content-center align-items-center">
                <div className="col-12 d-flex justify-content-center text-center">
                  <label>
                    <div className="form_input_title">Enter Location*</div>
                    <input
                      className="name-input"
                      tabIndex="1"
                      name="name"
                      placeholder="Enter your mobile no"
                      onChange={handleInputChange}
                      required
                      maxLength={10}
                    />
                  </label>
                </div>
              </Row>
              <div className="col-12 d-flex justify-content-center text-center">
                <label>
                  <div className="form_input_title">Enter Date*</div>
                  <input
                    type="date"
                    className="name-input"
                    tabIndex="1"
                    name="name"
                    placeholder="OTP"
                    onChange={handleInputChange}
                    required
                    maxLength={4}
                  />
                </label>
              </div>
              <div className="col-12 d-flex justify-content-center text-center">
                <label>
                  <div className="form_input_title">Enter name of veer</div>
                  <input
                    className="name-input"
                    tabIndex="1"
                    name="name"
                    placeholder="OTP"
                    onChange={handleInputChange}
                    required
                    maxLength={4}
                  />
                </label>
              </div>
              <div className="welcome_buttons text-center">
                <button
                  className="pledge_btn"
                  onClick={() => history.push("/shilaphalakam-preview")}
                >
                  Generate{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    </div>
  );
}
