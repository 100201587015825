import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  DropdownButton,
  Toast,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Components/Header/Header";
import Shilpimage from "../Assets/img/Shilpalakham1.png";
import Amritvatika from "../Assets/img/Amritvatika.png";
import PranImage from "../Assets/img/pran.png";
import HoistImage from "../Assets/img/hoist.png";
import HonorImage from "../Assets/img/soldier.png";
import Plant from "../Assets/img/plant.png";
import { useHistory } from "react-router-dom";
import firebase from "../../firebase";
import { useTranslation } from "react-i18next";
import { PersonCircle } from "react-bootstrap-icons"; // Assuming you have imported the Bootstrap icons library.
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  collection,
  query,
  where,
  getCountFromServer,
} from "firebase/firestore";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const OrganiserProfile = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const [organiserProfile, setOrganiserProfile] = React.useState();
  const [startDate, setStartDate] = useState();
  const [saplingPlanted, setsaplingPlanted] = useState("no");

  useEffect(() => {
    console.log(organiserProfile);
  }, [organiserProfile]);

  useEffect(() => {
    if (startDate == null) {
      return;
    }

    console.log("startDate", startDate);
    //save date to events collection
    const user_id = JSON.parse(localStorage.getItem("users"))?.users?.uid;
    let db = firebase.firestore().collection("event_new");

    if (organiserProfile?.name_of_ulb) {
      organiserProfile.name_of_ulb = organiserProfile?.name_of_ulb
        ? organiserProfile?.name_of_ulb.toUpperCase()
        : "";

      organiserProfile.state = organiserProfile?.state
        ? organiserProfile?.state.toUpperCase()
        : "";
      console.log("here");
      db.where("state", "==", organiserProfile?.state.toUpperCase())
        .where("name_of_ulb", "==", organiserProfile?.name_of_ulb.toUpperCase())
        .limit(1)
        .get()
        .then((res) => {
          console.log(organiserProfile);
          if (res.size == 0) {
            const day = startDate.getDate();
            const month = startDate.getMonth() + 1; // Months are 0 based in JS
            const year = startDate.getFullYear();
            db.add(
              {
                created_by: organiserProfile?.state ? "organiser" : "institute",
                ...organiserProfile,
                timestamp: new Date(),
                event_date: day + "/" + month + "/" + year,
              },
              { merge: true }
            ).then((res) => {
              setOrganiserProfile({
                ...organiserProfile,
                event_date: day + "/" + month + "/" + year,
              });
              toast.success("Date added successfully!");
            });
          } else {
            const day = startDate.getDate();
            const month = startDate.getMonth() + 1; // Months are 0 based in JS
            const year = startDate.getFullYear();
            if (organiserProfile.state) {
              db.doc(res.docs[0].id)
                .update({
                  event_date: day + "/" + month + "/" + year,
                })
                .then(() => {
                  console.log("Document successfully updated!");
                  setOrganiserProfile({
                    ...organiserProfile,
                    event_date: day + "/" + month + "/" + year,
                  });
                  toast.success("Date added successfully!");
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                  toast.error("Error updating document: ");
                });
            } else {
              db.where("created_by", "==", "institute")
                .where("phoneNumber", "==", organiserProfile.phoneNumber)
                .limit(1)
                .get()
                .then((res) => {
                  if (res.size == 0) {
                    db.add({
                      created_by: organiserProfile?.state
                        ? "organiser"
                        : "institute",
                      ...organiserProfile,
                      timestamp: new Date(),
                      event_date: day + "/" + month + "/" + year,
                    })
                      .then(() => {
                        console.log("Document successfully updated!");
                        setOrganiserProfile({
                          ...organiserProfile,
                          event_date: day + "/" + month + "/" + year,
                        });
                        toast.success("Date added successfully!");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        toast.error("Error updating document: ");
                      });
                  } else {
                    res.docs[0].ref
                      .update({
                        event_date: day + "/" + month + "/" + year,
                      })
                      .then(() => {
                        console.log("Document successfully updated!");
                        setOrganiserProfile({
                          ...organiserProfile,
                          event_date: day + "/" + month + "/" + year,
                        });
                        toast.success("Date added successfully!");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        toast.error("Error updating document: ");
                      });
                  }
                });
            }
          }
        });
    } else {
      organiserProfile.state = organiserProfile?.state
        ? organiserProfile?.state.toUpperCase()
        : "";
      organiserProfile.district = organiserProfile?.district
        ? organiserProfile?.district.toUpperCase()
        : "";
      organiserProfile.panchayat = organiserProfile?.panchayat
        ? organiserProfile?.panchayat.toUpperCase()
        : "";
      organiserProfile.block = organiserProfile?.block
        ? organiserProfile?.block.toUpperCase()
        : "";
      db.where("state", "==", organiserProfile?.state.toUpperCase())
        .where("district", "==", organiserProfile?.district.toUpperCase())
        .where("panchayat", "==", organiserProfile?.panchayat.toUpperCase())
        .where("block", "==", organiserProfile?.block.toUpperCase())
        .limit(1)
        .get()
        .then((res) => {
          console.log(organiserProfile);
          if (res.size == 0) {
            const day = startDate.getDate();
            const month = startDate.getMonth() + 1; // Months are 0 based in JS
            const year = startDate.getFullYear();
            db.add(
              {
                created_by: organiserProfile?.state ? "organiser" : "institute",
                ...organiserProfile,
                timestamp: new Date(),
                event_date: day + "/" + month + "/" + year,
              },
              { merge: true }
            ).then((res) => {
              setOrganiserProfile({
                ...organiserProfile,
                event_date: day + "/" + month + "/" + year,
              });
              toast.success("Date added successfully!");
            });
          } else {
            const day = startDate.getDate();
            const month = startDate.getMonth() + 1; // Months are 0 based in JS
            const year = startDate.getFullYear();
            if (organiserProfile.state) {
              db.doc(res.docs[0].id)
                .update({
                  event_date: day + "/" + month + "/" + year,
                })
                .then(() => {
                  console.log("Document successfully updated!");
                  setOrganiserProfile({
                    ...organiserProfile,
                    event_date: day + "/" + month + "/" + year,
                  });
                  toast.success("Date added successfully!");
                })
                .catch((error) => {
                  // The document probably doesn't exist.
                  console.error("Error updating document: ", error);
                  toast.error("Error updating document: ");
                });
            } else {
              db.where("created_by", "==", "institute")
                .where("phoneNumber", "==", organiserProfile.phoneNumber)
                .limit(1)
                .get()
                .then((res) => {
                  if (res.size == 0) {
                    db.add({
                      created_by: organiserProfile?.state
                        ? "organiser"
                        : "institute",
                      ...organiserProfile,
                      timestamp: new Date(),
                      event_date: day + "/" + month + "/" + year,
                    })
                      .then(() => {
                        console.log("Document successfully updated!");
                        setOrganiserProfile({
                          ...organiserProfile,
                          event_date: day + "/" + month + "/" + year,
                        });
                        toast.success("Date added successfully!");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        toast.error("Error updating document: ");
                      });
                  } else {
                    res.docs[0].ref
                      .update({
                        event_date: day + "/" + month + "/" + year,
                      })
                      .then(() => {
                        console.log("Document successfully updated!");
                        setOrganiserProfile({
                          ...organiserProfile,
                          event_date: day + "/" + month + "/" + year,
                        });
                        toast.success("Date added successfully!");
                      })
                      .catch((error) => {
                        // The document probably doesn't exist.
                        console.error("Error updating document: ", error);
                        toast.error("Error updating document: ");
                      });
                  }
                });
            }
          }
        });
    }
  }, [startDate]);

  // const onChange = (date) => {
  //   setDate(date);
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [saplingCount, setSaplingCount] = useState(0);

  const getSaplingCount = async (
    state,
    district,
    block,
    gram_panchayat,
    ulb = null
  ) => {
    const org_user = JSON.parse(localStorage.getItem("event_user"));
    if (ulb) {
    } else {
      const db = firebase.firestore();
      const coll = collection(db, "event_media");

      const whereQueryArray = [
        ["panchayat", "==", "D.CH.PALEM"],
        ["category", "==", "plants"],
      ];

      let q = query(coll);
      for (let clause of whereQueryArray) {
        q = query(q, where(...clause));
      }

      const snapshot = await getCountFromServer(q);
      setSaplingCount(snapshot.data().count);
      console.log("Sapling Count", snapshot.data().count);
    }
  };

  useEffect(() => {
    // handle Login/logout

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const org_user = JSON.parse(localStorage.getItem("event_user"))
          ? JSON.parse(localStorage.getItem("event_user"))
          : JSON.parse(localStorage.getItem("usersMain"))?.users
          ? JSON.parse(localStorage.getItem("usersMain"))?.users
          : {};

        let db = firebase.firestore().collection("event_new");
        if (org_user.loginType == "institute") {
          setOrganiserProfile(org_user);
        } else {
          if (org_user?.name_of_ulb) {
            console.log(org_user);
            db.where("state", "==", org_user?.state?.toUpperCase())
              .where("name_of_ulb", "==", org_user?.name_of_ulb?.toUpperCase())
              .limit(1)
              .get()
              .then((res) => {
                if (res.size !== 0) {
                  org_user.event_date = res?.docs[0].data().event_date;
                  if (res.docs[0].data().shilaphalakam_installed) {
                    org_user.shilaphalakam_installed =
                      res?.docs[0].data().shilaphalakam_installed;
                  }
                  if (res.docs[0].data().bravehearts_fecilitated) {
                    org_user.bravehearts_fecilitated =
                      res?.docs[0].data().bravehearts_fecilitated;
                  }
                  if (res.docs[0].data().amritvatika_planted) {
                    org_user.amritvatika_planted =
                      res?.docs[0].data().amritvatika_planted;
                  }

                  setOrganiserProfile(org_user);
                } else {
                  setOrganiserProfile(org_user);
                }
              });
            //getSaplingCount
            getSaplingCount(
              org_user?.state?.toUpperCase(),
              org_user?.district?.toUpperCase(),
              org_user?.block?.toUpperCase(),
              org_user?.panchayat?.toUpperCase(),
              org_user?.name_of_ulb?.toUpperCase()
            );
          } else {
            if (org_user?.institue_type) {
              db.where("institue_type", "==", org_user?.institue_type)
                .limit(1)
                .get()
                .then((res) => {
                  if (res.size !== 0) {
                    org_user.event_date = res?.docs[0].data().event_date;
                    if (res.docs[0].data().shilaphalakam_installed) {
                      org_user.shilaphalakam_installed =
                        res?.docs[0].data().shilaphalakam_installed;
                    }
                    if (res.docs[0].data().bravehearts_fecilitated) {
                      org_user.bravehearts_fecilitated =
                        res?.docs[0].data().bravehearts_fecilitated;
                    }
                    if (res.docs[0].data().amritvatika_planted) {
                      org_user.amritvatika_planted =
                        res?.docs[0].data().amritvatika_planted;
                    }
                    setOrganiserProfile(org_user);
                  } else {
                    setOrganiserProfile(org_user);
                  }
                });
            } else {
              console.log("llll");
              //getSaplingCount
              getSaplingCount(
                org_user?.state?.toUpperCase(),
                org_user?.district?.toUpperCase(),
                org_user?.block?.toUpperCase(),
                org_user?.panchayat?.toUpperCase()
              );

              db.where("state", "==", org_user?.state?.toUpperCase())
                .where("district", "==", org_user?.district?.toUpperCase())
                .where("panchayat", "==", org_user?.panchayat?.toUpperCase())
                .where("block", "==", org_user?.block?.toUpperCase())
                .limit(1)
                .get()
                .then((res) => {
                  console.log(res.docs[0].data());
                  if (res.size !== 0) {
                    org_user.event_date = res?.docs[0].data().event_date;

                    if (res.docs[0].data().shilaphalakam_installed) {
                      org_user.shilaphalakam_installed =
                        res?.docs[0].data().shilaphalakam_installed;
                    }
                    if (res.docs[0].data().bravehearts_fecilitated) {
                      org_user.bravehearts_fecilitated =
                        res?.docs[0].data().bravehearts_fecilitated;
                    }
                    if (res.docs[0].data().amritvatika_planted) {
                      org_user.amritvatika_planted =
                        res?.docs[0].data().amritvatika_planted;
                    }
                    setOrganiserProfile(org_user);
                  } else {
                    setOrganiserProfile(org_user);
                  }
                });
            }
          }
        }
      } else {
        // No user is signed in.
        console.log("no user");
        history.push("/login");
      }
    });
  }, []);

  const handleQuestionnaireChange = (extraValue) => (event) => {
    const value = event.target.value;

    console.log(extraValue, value);
    if (organiserProfile?.name_of_ulb) {
      const db = firebase.firestore().collection("event_new");
      db.where("state", "==", organiserProfile?.state.toUpperCase())
        .where(
          "name_of_ulb",
          "==",
          organiserProfile?.name_of_ulb?.toUpperCase()
        )
        .get()
        .then((res) => {
          if (res.size == 1) {
            console.log(res.docs[0].id);
            db.doc(res.docs[0].id)
              .update({
                [extraValue]: value,
              })
              .then(() => {
                setOrganiserProfile({
                  ...organiserProfile,
                  [extraValue]: value,
                });
                toast.success("Updated Successfully!");
              })
              .catch((error) => {
                console.log("Error", error);
              });
          } else {
            console.log("No Such Events Found");
          }
        });
    } else {
      const db = firebase.firestore().collection("event_new");
      organiserProfile.state = organiserProfile?.state
        ? organiserProfile?.state.toUpperCase()
        : "";
      organiserProfile.district = organiserProfile?.district
        ? organiserProfile?.district.toUpperCase()
        : "";
      organiserProfile.panchayat = organiserProfile?.panchayat
        ? organiserProfile?.panchayat.toUpperCase()
        : "";
      organiserProfile.block = organiserProfile?.block
        ? organiserProfile?.block.toUpperCase()
        : "";
      db.where("state", "==", organiserProfile?.state)
        .where("district", "==", organiserProfile?.district)
        .where("panchayat", "==", organiserProfile?.panchayat)
        .where("block", "==", organiserProfile?.block)
        .get()
        .then((res) => {
          if (res.size == 1) {
            console.log(res.docs[0].id);
            db.doc(res.docs[0].id)
              .update({
                [extraValue]: value,
              })
              .then(() => {
                setOrganiserProfile({
                  ...organiserProfile,
                  [extraValue]: value,
                });
                toast.success("Updated Successfully!");
              })
              .catch((error) => {
                console.log("Error", error);
              });
          } else {
            console.log("No Such Events Found");
          }
        });
    }
  };

  return (
    <div className="main_body ease-in-anim">
      <Header />
      {organiserProfile?.loginType == "institute" && (
        <button
          style={{
            position: "relative",
            top: "0.2rem",
            right: "1rem",
            float: "right",
          }}
          onClick={() => {
            window.location.href = "/nyv-report";
          }}
          className="pledge_btn"
        >
          Change to Phase 2
        </button>
      )}
      <Container className="mt-5">
        <Row className="justify-content-center text-center mx-2">
          <Card className="profile-block" id="profile-block">
            <Card.Body>
              <Row>
                {/* First Column */}
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  <PersonCircle size={60} />
                </Col>

                {/* Second Column */}
                <Col md={6}>
                  <Row>
                    <p>
                      <b>State: </b>{" "}
                      {organiserProfile ? organiserProfile?.state : "N/A"}
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <b>District:</b>{" "}
                      {organiserProfile ? organiserProfile?.district : "N/A"}
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <b>Gram Panchayat:</b>{" "}
                      {organiserProfile ? organiserProfile?.panchayat : "N/A"}
                    </p>
                  </Row>
                  <Row>
                    <p>
                      <b>Event Date: </b>
                      <DatePicker
                        popperPlacement="top"
                        className="rounded"
                        selected={
                          organiserProfile?.event_date
                            ? new Date(
                                organiserProfile.event_date.split("/")[2],
                                organiserProfile.event_date.split("/")[1] - 1,
                                organiserProfile.event_date.split("/")[0]
                              )
                            : ""
                        }
                        maxDate={new Date("2023-08-31")}
                        onChange={(date) => setStartDate(date)}
                      />
                    </p>
                  </Row>
                  {/* <Row>
                    <p>
                      <b>Sapling Planted:</b>{" "}
                      {saplingCount}
                    </p>
                  </Row> */}
                  {organiserProfile?.name_of_ulb}
                  {organiserProfile?.name_of_ulb && (
                    <Row>
                      <p>
                        <b>{t("ulb")}:</b>{" "}
                        {organiserProfile
                          ? organiserProfile?.name_of_ulb
                          : "N/A"}
                      </p>
                    </Row>
                  )}
                </Col>

                {/* Third Column */}
                {/* {!organiserProfile?.name_of_ulb && (
                  <Col md={3}>
                    <Row>
                      <p>
                        <b>District:</b>{" "}
                        {organiserProfile ? organiserProfile?.district : "N/A"}
                      </p>
                    </Row>
                    <Row>
                      <p>
                        <b>Gram Panchayat:</b>{" "}
                        {organiserProfile ? organiserProfile?.panchayat : "N/A"}
                      </p>
                    </Row>
                  </Col>
                )} */}

                {/* Fourth Column */}

                {/* <Col md={4}>
                  <Row>
                    <div className="my-3">
                      <label>Shilaphalakam Installed:&nbsp; </label>
                      <select
                        id="shilaphalakam_installed"
                        className="select-input"
                        name="shilaphalakam_installed"
                        value={
                          organiserProfile?.shilaphalakam_installed
                            ? organiserProfile?.shilaphalakam_installed
                            : "no"
                        }
                        onChange={handleQuestionnaireChange(
                          "shilaphalakam_installed"
                        )}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </Row>

                  <Row>
                    <div className="my-3">
                      <label htmlFor="bravehearts_fecilitated">
                        Bravehearts Felicitated:&nbsp;
                      </label>
                      <select
                        id="bravehearts_fecilitated"
                        className="select-input"
                        name="bravehearts_fecilitated"
                        value={
                          organiserProfile?.bravehearts_fecilitated
                            ? organiserProfile?.bravehearts_fecilitated
                            : "no"
                        }
                        onChange={handleQuestionnaireChange(
                          "bravehearts_fecilitated"
                        )}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </Row>
                  <Row>
                    <div className="my-3">
                      <label htmlFor="amritvatika_planted">
                        Amritvatika Planted:&nbsp;
                      </label>

                      <select
                        id="amritvatika_planted"
                        className="select-input"
                        name="amritvatika_planted"
                        value={organiserProfile?.amritvatika_planted}
                        onChange={handleQuestionnaireChange(
                          "amritvatika_planted"
                        )}
                      >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </Row>
                </Col> */}
              </Row>
            </Card.Body>
          </Card>
        </Row>
      </Container>
      <Container className="mt-5">
        <Row className="justify-content-center text-center">
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img
                  className="img-fluid pt-4"
                  // src={Shilpimage}
                  src="/asset/files/shilapalakam_sample.jpg"
                  alt="Amritvatika"
                  style={{ width: "70%" }}
                  x
                />
              </div>
              <h6>
                {t(
                  "design_and_errect_shilaphalakam_in_remembrance_of_bravehearts"
                )}
              </h6>
              <Link to="/generate-shilaphalakam">
                <div className="text-center">
                  <button className="pledge_btn">
                    {t("design_shilaphalakam")}
                  </button>
                </div>
              </Link>

              <Link to="/organiser-upload?category=shilaphalakam">
                <div className="text-center">
                  <button className="pledge_btn">{t("upload_media")}</button>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Plant}
                  alt="Amritvatika"
                  style={{ height: "200px" }}
                />
              </div>
              <h6>{t("add_plants")}</h6>
              <Link to="/add-plants">
                <div className="text-center">
                  <button className="pledge_btn">{t("add_plants")}</button>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={Amritvatika}
                  alt="Amritvatika"
                />
              </div>
              <h6>{t("creation_of_amrit_vatika")}</h6>
              <Link to="/organiser-upload?category=amritvatika">
                <div className="text-center">
                  <button className="pledge_btn">{t("upload_media")}</button>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img className="img-fluid" src={HonorImage} alt="Amritvatika" />
              </div>
              <h6>{t("honor_bravehearts")}</h6>
              <Link to="/organiser-upload?category=honor_bravehearts">
                <div className="text-center">
                  <button className="pledge_btn">{t("upload_media")}</button>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img
                  className="img-fluid pt-4"
                  src={PranImage}
                  alt="Amritvatika"
                  style={{ width: "90%" }}
                />
              </div>
              <h6>
                {t(
                  "take_the_panch_pran_pledge_with_a_fistful_of_soil_or_earthen_diya"
                )}
              </h6>
              <Link to="/organiser-upload?category=pledge">
                <div className="text-center">
                  <button className="pledge_btn">{t("upload_media")}</button>
                </div>
              </Link>
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="organiser-profile-block">
            <div
              className="organiser_box"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div className="text-center">
                <img
                  className="img-fluid"
                  src={HoistImage}
                  alt="Amritvatika"
                  style={{ width: "70%" }}
                />
              </div>
              <h6> {t("hoist_national_flag_and_sing_national_anthem")}</h6>
              <Link to="/organiser-upload?category=national_flag">
                <div className="text-center">
                  <button className="pledge_btn">{t("upload_media")}</button>
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrganiserProfile;
