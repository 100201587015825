import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";

export default function DashboardCard({
  title,
  counter,
  totalLocations,
  imgSrc,
  picturesCounter,
  type = "pictures",
  across = "across ",
  locations = "locations",
  phase = "phase1",
}) {
  return (
    <Col xs={12} lg={6} className="mt-1">
      <Card className="custom-card">
        <Row noGutters>
          <Col
            xs={4}
            className="d-flex align-items-center justify-content-cente"
          >
            <Image
              className="m-2 img-fluid"
              src={imgSrc}
              alt="Card Image"
              fluid
              style={{ height: "100px", width: "auto" }}
            />
          </Col>
          <Col xs={8}>
            <Card.Body>
              <Card.Text className="topic">{title}</Card.Text>
              <Card.Title className="count">
                {picturesCounter}{" "}
                <span className="span_card_text">
                  {" "}
                  {type} {across}{" "}
                </span>
                {phase == "phase1"
                  ? counter[0]
                      ?.toString()
                      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
                  : counter}
                <span className="span_card_text"> {locations} </span>
              </Card.Title>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
