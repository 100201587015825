import React, { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export default ThemeContext;

const ThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState("");

  useEffect(() => {
    const currentHour = new Date().getHours();
    const isDayTime = currentHour >= 6 && currentHour < 18;

    setThemeMode(isDayTime ? "light" : "dark");
  }, []);

  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeProvider };
