import React from "react";
import { useTranslation, Trans } from "react-i18next";

function Popup({ setShowPopup }) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="modalBackground popup">
        <div className="modalContainer" style={{ width: "min-content" }}>
          <div
            className="close_thankyou d-flex justify-content-end"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <img src="./images/x-circle.svg" alt="X" />

            <h1>Helloo</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default Popup;
